import { useContext } from "react";
import { ImpresionContext } from "../../providers/Impresion";
import { PaginatorContext } from "../../providers/Paginator";
import { Button, Icon, Typography } from "@mui/material";
import { AddCircleRounded } from "@mui/icons-material";

const AddLabel = ({ children, type, event = "", tarifa = "", pregunta = "" }) => {
  const {
    elements,
    setElements,
    index,
    setIndex,
    dimensionContainer
  } = useContext(ImpresionContext)

  // const { event } = useContext(PaginatorContext)

  const HandleAddVariable = (variableType) => {
    let width = 0
    let height = 0
    let textFill = ''
    if (variableType === "text-evento") {
      textFill = event
      width = 120
      height = 15
    }
    if (variableType === "text-tarifa") {
      textFill = tarifa
      width = 120
      height = 15
    }
    if (variableType === "text-codigo") {
      textFill = '012345678912'
      width = 120
      height = 15
    }
    if (variableType === "text-nombre") {
      textFill = 'Nombre Apellido'
      width = 120
      height = 15
    }
    if (variableType === "qr") {
      textFill = ''
      width = 40
      height = 40
    }
    if (variableType === `text-${pregunta && pregunta.id}`) {
      // console.log(pregunta)
      textFill = pregunta && pregunta?.informacion_adicional.replace(/\s+/g, '-')
      width = 120
      height = 15
    }

    const textGenerated = elements.slice();
    setIndex(index + 1)
    textGenerated.push({
      id: index.toString(),
      x: ((dimensionContainer.width) / 2) - 100,
      y: ((dimensionContainer.height) / 2) - 25,
      text: textFill,
      fontSize: 14,
      width: width,
      height: height,
      rotation: 0,
      state: false,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      textDecoration: '',
      fill: '#000000',
      align: 'left',
      type: `${variableType}`,
    })
    setElements(textGenerated)
  }

  return (
    <Button
      variant="contained"
      color="default"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "50px",
      }}
      onClick={() => HandleAddVariable(type)}
    >
      <Typography sx={{ width: "auto" }} variant="p">{children}</Typography>
      <Icon sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <AddCircleRounded color="primary" sx={{ fontSize: 24 }} />
      </Icon>
    </Button>
  )
};

export default AddLabel;