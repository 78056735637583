import Vouchers  from './Vouchers';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {Container, Button, ButtonGroup} from '@mui/material';
import { grey } from '@mui/material/colors';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import Tickets from './tickets';


export const Vales = () => {
    const [t] = useTranslation("global");

    const [view, setView] = useState(null); 

    const handleCardClick = (view) => {
        setView(view); 
    };

    return (
        <Container>
            <div className='d-flex justify-content-center'>
                <ButtonGroup size="large" variant="outlined" aria-label="Large button group" className='mb-3'>
                    <Button 
                        startIcon={<ConfirmationNumberOutlinedIcon />} 
                        onClick={() => handleCardClick('tickets')}
                        sx={{
                            color: view === 'tickets' ? "#3D2EAB" : grey[600],
                            borderColor: view === 'tickets' ? "#3D2EAB" : grey[600],
                            backgroundColor: view === 'tickets' ? "#DADAFF" : 'transparent',
                            borderWidth: '2px',
                        }}
                    >
                        {t("menuTemplate.tickets")}
                    </Button>
                    <Button 
                        startIcon={<FastfoodOutlinedIcon />} 
                        onClick={() => handleCardClick('vouchers')}
                        sx={{
                            color: view === 'vouchers' ? "#3D2EAB" : grey[600],
                            borderColor: view === 'vouchers' ? "#3D2EAB" : grey[600],
                            backgroundColor: view === 'vouchers' ? "#DADAFF" : 'transparent',
                            borderWidth: '2px',
                        }}
                    >
                        Vouchers
                    </Button>
                </ButtonGroup>
            </div>
            {view === 'vouchers' && <Vouchers />}
            {view === 'tickets' && <Tickets />}
        </Container>
    )
}

