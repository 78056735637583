import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function DeleteClientModal(props) {
const { className, eventoId, clienteId } = props;
const history = useHistory();
const [modal, setModal] = useState(false);
const [loading, setLoading] = useState(false);
const [error, setError] = useState(null);
const [t] = useTranslation("global");

const toggle = () => setModal(!modal);

async function funDelete(eventoId, clienteId) {
    setLoading(true);
    setError(null);
    try {
        const response = await api.eventos.deleteCliente(eventoId, clienteId);
        if (response.status === "success") {
        setModal(false);
        setTimeout(() => {
            history.go(0);
        }, 1000);
        } else {
        setError(response.message || "Error al eliminar el cliente");
        }
    } catch (error) {
        console.error('Error al eliminar el cliente:', error.message);
        setError(error.message || "Error al eliminar el cliente");
    }
    setLoading(false);
}


const closeBtn = (
    <button className="close" onClick={toggle} type="button">
        &times;
    </button>
);

return (
    <div>
        <Button color="danger" onClick={toggle}>
            <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Modal isOpen={modal} toggle={toggle} className={className} style={{ zIndex: 9999, paddingTop:"80px"}}>
            <ModalHeader toggle={toggle} close={closeBtn}>
            {t("button.delete")}
            </ModalHeader>
        <ModalBody>
            {t("customers.questionDelete")}            {error && <p style={{ color: "red" }}>{error}</p>}
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={() => funDelete(eventoId, clienteId)} disabled={loading}>
                {t("button.delete")}
            </Button>{" "}
        <Button color="secondary" onClick={toggle} disabled={loading}>
               {t("button.cancel")}
        </Button>
        </ModalFooter>
        </Modal>
    </div>
);
}
