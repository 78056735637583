import { useContext, useEffect, useState } from 'react';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImpresionContext } from '../../providers/Impresion';
import { Drawer, Icon, List, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ImageIcon from '@mui/icons-material/Image';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import FontSideBar from './FontSideBar';
import TemplateSideBar from './TemplateSideBar';
import ImageSideBar from './ImageSideBar';
import QrSideBar from './QrSideBar';
import DesignSideBar from './DesignSideBar';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ListGroup, Modal } from 'reactstrap';
import NewDiseno from './NewDiseno';

const NavCanva = ({ getDiseno, evento, tarifa, idTarifa }) => {
  const [openNew, setOpenNew] = useState(false);
  
  const handleToggle = (type) => {
    setOnOpen(type)
  }

  useEffect(() => {
    const handlePressKey = (event) => {
      if (event.key === 'Escape') {
        setOnOpen(null)
        console.log("se hizo clicl")
      }
    };

    window.addEventListener('keydown', handlePressKey);

    return () => {
      window.removeEventListener('keydown', handlePressKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawerItems = [
    {
      label: "Texto",
      icon: <FormatShapesIcon />,
      type: "font",
      sideBar: <FontSideBar onClose={() => setOnOpen(null)} />,
    },
    {
      label: "Dinamico",
      icon: <SortByAlphaIcon />,
      type: "qr",
      sideBar: <QrSideBar evento={evento} tarifa={tarifa} idTarifa={idTarifa} onClose={() => setOnOpen(null)} />,
    },
    {
      label: "Imagen",
      icon: <ImageIcon />,
      type: "image",
      sideBar: <ImageSideBar onClose={() => setOnOpen(null)} />,
    },
    {
      label: "Plantillas",
      icon: <DashboardIcon />,
      type: "template",
      sideBar: <TemplateSideBar evento={evento} tarifa={tarifa} onClose={() => setOnOpen(null)} />,
    },
    {
      label: "Tus disenos",
      icon: <BookmarksIcon />,
      type: "design",
      sideBar: <DesignSideBar onClose={() => setOnOpen(null)} getDiseno={getDiseno} />,
    },
  ]

  const [onOpen, setOnOpen] = useState(false);

  return (
    <>
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: { zIndex: "50000" }
        }}
      >
        <Stack
          direction="column"
          sx={{
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
          }}
        >
          <Stack
            direction="column"
          >
            <List>
              {drawerItems.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  className='d-flex flex-column justify-content-center align-items-center'
                  onClick={() => {
                    handleToggle(item.type)
                  }}
                >
                  <ListItemIcon className="d-flex justify-content-center">{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </Stack>
          <Stack
            direction="column"
          >
            <List>
              <ListItem
                button
                className='d-flex flex-column justify-content-center align-items-center'
                onClick={() => {
                  setOpenNew(true)
                }}
              >
                <ListItemIcon className="d-flex justify-content-center">
                  <AddBoxIcon
                    sx={{ fontSize: 40 }}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText primary={"Nuevo"} />
              </ListItem>
            </List>
          </Stack>
        </Stack>
      </Drawer>

      {drawerItems.map((item, index) => (
        <Drawer
          anchor='left'
          key={index}
          variant='temporary'
          open={onOpen === item.type}
          PaperProps={{
            sx: { 
              left: "120px",
            }
          }}
          sx={{
            width:"470px",
            "& .MuiBackdrop-root": {
              display: "none",
            },
            "& .MuiDrawer-root": {
              width: "470px"
            },
            "& .MuiDrawer-modal": {
              width: "470px"
            },
            "& .MuiModal-root": {
              width: "470px"
            },
          }}
        // hideBackdrop={true}
        >
          {item.sideBar}
        </Drawer>
      ))}
      {openNew &&
        <NewDiseno
          onClose={() => {
            setOpenNew(false)
          }}
        />
      }

    </>
  )
}

export default NavCanva;