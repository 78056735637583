import {
  CloseOutlined,
  ExpandLess,
  ExpandMore,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../components/Loading";
import { es } from "date-fns/locale";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import QRCode from "react-qr-code";

const Caja = () => {
  const [showResume, setShowResume] = useState(false);
  const [loadingCarrito, setLoading] = useState(false);
  const [data, setData] = useState();
  const param = useParams();
  const [cantidad, setCantidad] = useState({});
  const [tarifasSeleccionadas, setTarifasSeleccionadas] = useState([]);
  const [codigoQR, setCodigoQR] = useState("");

  const callApi = useCallback(async () => {
    try {
      const response = await api.eventos.getPublic(param.id);
      if (response.status === "success") {
        setData(response.data.evento);
      }
    } catch (error) {
      toast.error("Error en la red.", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  }, [param.id]);

  const hoy = new Date();
  const tarifasFiltradas = data?.tarifas?.filter((tarifa) => {
    const inicioVenta = new Date(tarifa.inicio_venta);
    const finVenta = new Date(tarifa.fin_venta);

    return (tarifa.idtipo === 1 || tarifa.idtipo === 2) && inicioVenta <= hoy && hoy <= finVenta;
  });

  const initialCantidad = data?.tarifas?.reduce((acc, tarifa) => {
    acc[tarifa.id] = 0;
    return acc;
  }, {});

  useEffect(() => {
    callApi();
  }, [callApi]);

  useEffect(() => {
    if (data) {
      setCantidad(initialCantidad);
    }
  }, [data]);

  /* CALCULOS TARIFAS */
  const calculoSubtotalTarifa = (tarifa) => {
    const cantidadTarifa = cantidad[tarifa.id] || 0;
    return cantidadTarifa * tarifa.precio;
  };

  const subtotal = data?.tarifas?.reduce((acc, tarifa) => {
    return acc + calculoSubtotalTarifa(tarifa);
  }, 0) || 0;

  const comision = data?.tarifas?.reduce((acc, tarifa) => {
    const cantidadTarifa = cantidad[tarifa.id] || 0;
    const precioConComision =
      tarifa?.precio *
      (!tarifa?.asumir_comision
        ? tarifa?.tipo_comision?.porcentaje / 100
        : 0);

    return acc + cantidadTarifa * precioConComision;
  }, 0) || 0;

  const formatCurrency = (value) =>
    value.toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });

  const calcularTotal = () => {
    const total = subtotal + comision;
    return total;
  };
  /* FIN CALCULOS TARIFAS */

  const incremento = (idtarifa) => {
    setCantidad((prevCantidades) => {
      const nuevaCantidad = (prevCantidades[idtarifa] || 0) + 1;
      const tarifa = data?.tarifas?.find((tarifa) => tarifa.id === idtarifa);
      let limite = tarifa
        ? Math.min(tarifa.restantes, tarifa.limite_persona)
        : 0;

      if (nuevaCantidad > parseInt(limite)) {
        return prevCantidades;
      }

      const nuevasCantidades = {
        ...prevCantidades,
        [idtarifa]: nuevaCantidad,
      };

      const seleccionadas = Object.entries(nuevasCantidades)
        .filter(([idtarifa, cantidad]) => cantidad > 0)
        .map(([idtarifa, cantidad]) => {
          const tarifa = data?.tarifas?.find(
            (t) => t.id === parseInt(idtarifa)
          );
          return {
            ...tarifa,
            cantidad,
          };
        });
      setTarifasSeleccionadas(seleccionadas);

      return nuevasCantidades;
    });
  };

  const decremento = (idtarifa) => {
    setCantidad((prevCantidades) => {
      const nuevasCantidades = {
        ...prevCantidades,
        [idtarifa]: Math.max(0, (prevCantidades[idtarifa] || 0) - 1),
      };

      const seleccionadas = Object.entries(nuevasCantidades)
        .filter(([idtarifa, cantidad]) => cantidad > 0)
        .map(([idtarifa, cantidad]) => {
          const tarifa = data?.tarifas?.find(
            (t) => t.id === parseInt(idtarifa)
          );
          return {
            ...tarifa,
            cantidad,
          };
        });
      setTarifasSeleccionadas(seleccionadas);

      return nuevasCantidades;
    });
  };

  const handleGenerateQR = async () => {
    if (codigoQR) {
      setCodigoQR("");
      setCantidad(initialCantidad);
      return;
    }

    setLoading(true);

    const idevento = parseInt(param.id);

    const items = Object.entries(cantidad)
      .map(([idtarifa, cantidad]) => ({
        idtarifa: parseInt(idtarifa),
        cantidad,
      }))
      .filter((item) => item.cantidad > 0);

    if (items.length === 0) {
      toast.info("Debes seleccionar al menos una tarifa para continuar.", {
        position: "top-right",
        autoClose: 4000,
      });
      setLoading(false);
      return;
    }

    try {
      const response = await api.carrito.crearCarrito({
        idevento,
        items,
        pago_qr: true, // porque el mismo endpoint de carrito usamos para generar el qr para la cantina o el url de redireccionamiento en lo publico
      });

      if (response.status === "success") {
        setCodigoQR(response.data.url_pago);
      } else {
        throw new Error(response.message || "Error al procesar el pago.");
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleToggleResume = () => {
    if (showResume) {
      setShowResume(false);
    } else {
      const seleccionadas = data.tarifas.filter(
        (tarifa) => (cantidad[tarifa.id] || 0) > 0
      );
      setTarifasSeleccionadas(seleccionadas);
      setShowResume(true);
    }
  };

  if (!data) {
    return <Loading />;
  }

  return (
    <div
      className="mx-auto p-4 d-flex flex-column justify-content-center gap-2"
      style={{ maxWidth: 600, minHeight: 300 }}
    >
      {!showResume ? (
        !codigoQR ? (
          <div>
            <div className="text-center mb-2">
              <p className="m-0 fs-3 fw-bold" style={{ color: "#221a60" }}>
                {data.evento}
              </p>
              <div className="d-flex justify-content-evenly gap-2">
                {data.fechas?.map((item) => (
                  <small>
                    {format(new Date(item.fecha), "d 'de' MMMM ", { locale: es })}
                    {/* {moment.parseZone(item.hora).format("HH:mm")} hs. */}
                  </small>
                ))}
              </div>
            </div>

            <p className="mb-1 fw-medium">Tarifas</p>

            {tarifasFiltradas ? (
              tarifasFiltradas.map((tarifa) => (
                <div
                  key={tarifa.id}
                  className={`position-relative my-3 ${tarifa.restantes <= 0 && "tarifa-disabled"}`}
                >
                  <div className="barra-tarifas" />

                  <div className="ps-4 d-flex flex-column gap-1">
                    <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                      <div className="d-flex flex-column gap-1">
                        <p className="fw-medium m-0">{tarifa.concepto}</p>
                        <div className="d-flex gap-1">
                          {tarifa.fechas?.map((item, index) => (
                            <small>
                              {format(new Date(item.fecha.fecha), "d 'de' MMMM ", { locale: es })}
                              {index < tarifa.fechas.length - 1 && " /"}
                            </small>
                          ))}
                        </div>
                        {tarifa?.restantes <= 0 ? (
                          <small className="cartel-agotados p-1 rounded">
                            <CloseOutlined fontSize="14px" /> Agotados
                          </small>
                        ) : (
                          <small className="text-secondary">
                            {tarifa.tipo?.tipo} restantes: {tarifa.restantes}
                          </small>
                        )}
                      </div>

                      {tarifa?.restantes > 0 && (
                        <ButtonGroup>
                          <Button
                            className="fw-bold"
                            disabled={cantidad[tarifa.id] === 0}
                            onClick={() => decremento(tarifa.id)}
                            sx={{
                              width: "40px",
                              color: "#221a60",
                              borderColor: "#221a60",
                            }}
                            aria-label="restar"
                          >
                            -
                          </Button>
                          <Button
                            className="fw-bold"
                            disabled
                            style={{
                              width: "40px",
                              color: "#221a60",
                              borderColor: "#221a60",
                              cursor: "default",
                            }}
                          >
                            {cantidad[tarifa.id] || 0}
                          </Button>
                          <Button
                            className="fw-bold"
                            onClick={() => incremento(tarifa.id)}
                            sx={{
                              width: "40px",
                              color: "#221a60",
                              borderColor: "#221a60",
                            }}
                            aria-label="sumar"
                          >
                            +
                          </Button>
                        </ButtonGroup>
                      )}
                    </div>

                    {tarifa?.restantes > 0 && (
                      <div className="d-flex gap-1 flex-wrap">
                        <p className="fw-medium m-0 d-inline-block">
                          ${" "}
                          {tarifa.precio.toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                          })}
                        </p>
                        <small className="text-secondary">
                          {"+ $ "}
                          {(
                            tarifa.precio *
                            (tarifa.tipo_comision?.porcentaje / 100)
                          ).toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                          })}{" "}
                          costo del servicio
                        </small>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">Aún no hay tarifas.</p>
            )}
          </div>
        ) : (
          <QRCode value={codigoQR} className="mx-auto" />
        )
      ) : (
        <div>
          <p className="mb-1 fw-bold fs-4">Resumen de tu compra</p>
          {tarifasSeleccionadas?.length > 0 ? (
            tarifasSeleccionadas.map((tarifa) => (
              <div key={tarifa.id} className="my-3">
                <div className="d-flex justify-content-between flex-wrap py-2">
                  <p className="fw-medium mb-0">{`${cantidad[tarifa.id]} x ${tarifa.concepto}`}</p>
                  <p className="fw-medium mb-0">
                    $ {formatCurrency(calculoSubtotalTarifa(tarifa))}
                  </p>
                </div>
                <hr className="mx-3 border-2" style={{ color: "#D9D9D9" }} />
              </div>
            ))
          ) : (
            <p className="text-center">No hay tarifas seleccionadas.</p>
          )}
          {tarifasSeleccionadas?.length > 0 && (
            <>
              <div className="d-flex justify-content-between flex-wrap py-2">
                <p className="fw-medium mb-0 fs-5">Subtotal</p>
                <p className="fw-medium mb-0 fs-5">
                  $ {formatCurrency(subtotal)}
                </p>
              </div>

              <div className="d-flex justify-content-between flex-wrap py-2">
                <div className="d-flex align-items-center gap-1">
                  <p className="fw-medium mb-0 fs-5">Costo</p>
                  <Tooltip
                    title="El costo de servicio de PassGo no es reembolsable"
                    placement="right"
                    arrow
                  >
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                </div>
                <p className="fw-medium mb-0 fs-5">
                  $ {formatCurrency(comision)}
                </p>
              </div>
              <hr className="mx-3 border-2" style={{ color: "#D9D9D9" }} />

              <div className="d-flex justify-content-between flex-wrap py-2">
                <p className="fw-medium mb-0 fs-3">Total</p>
                <span className="text-end">
                  <p className="fw-medium mb-0 fs-3">
                    $ {formatCurrency(calcularTotal())}
                  </p>
                  <small>El precio incluye impuestos</small>
                </span>
              </div>
            </>
          )}
        </div>
      )}

      {tarifasFiltradas?.length > 0 && (
        <div className="d-flex flex-column">
          <p
            className="mb-2 fs-3 fw-medium align-self-end"
            onClick={handleToggleResume}
            style={{ cursor: "pointer" }}
          >
            {!showResume ? <ExpandLess /> : <ExpandMore />} ${" "}
            {formatCurrency(calcularTotal())}
          </p>
          <Button
            id="btn-compra"
            disabled={tarifasSeleccionadas?.length === 0 && !codigoQR}
            onClick={handleGenerateQR}
            className="p-4 rounded d-flex gap-1"
            aria-label="iniciar-compra"
          >
            {loadingCarrito ? (
              <CircularProgress />
            ) : (
              <span>
                <QrCode2Icon /> {!codigoQR ? "Generar QR" : "Generar otro"}
              </span>
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Caja;
