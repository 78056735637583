import { config } from "./config";
import 'isomorphic-fetch';


const apiOrigin = config.URL_API;
const publicOrigin = config.URL_PUBLIC;


export const publicOptions = (method, data) => {
  let opt = {
    method,
    headers: {
      'Content-Type': 'application/json',
    }
  };
  if (data) {
    opt.body = JSON.stringify(data);
  }
  return opt;
};

export const pivateOptions = (method, data) => {
  let opt = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'AUTHORIZATION': `Bearer ${localStorage.getItem('token')}`
    }
  };
  if (data) {
    opt.body = JSON.stringify(data);
  }

  return opt;
};

export const mediaOptions = (method, data) => {
  let opt = {
    method,
    headers: {
      'AUTHORIZATION': `Bearer ${localStorage.getItem('token')}`
    }
  };
  if (data) {
    opt.body = data;
  }

  return opt;
};

export const api = {
  auth: {
    async getAuthenticatedUser() {
      const response = await fetch(apiOrigin + '/authenticate', publicOptions('GET'));
      const data = await response.json();
      return data;
    },
  },

  usuarios: {
    async createUsuario(data) {
      const response = await fetch(apiOrigin + '/usuarios', pivateOptions('POST', data));
      const res = await response.json();
      return res
    },
    async getUserExist(email) {
      const response = await fetch(`${apiOrigin}/usuario/exist?${email ? `&email=${email}` : ''}`, pivateOptions('GET'));
      const res = await response.json();
      return res
    },
    async getUsuario() {
      const response = await fetch(apiOrigin + '/usuario', pivateOptions('GET'));
      const res = await response.json();
      return res;
    },
    async EditUsuario(data) {
      const response = await fetch(apiOrigin + '/usuario', pivateOptions('PUT', data));
      const res = await response.json();
      return res
    },
    async BajaUsuario(email) {
      const response = await fetch(apiOrigin + '/usuario/baja', pivateOptions('PUT', { usuario: email }))
      const res = await response.json();
      return res
    },
    async AddLike(idUsuario, idPublicacion) {
      const response = await fetch(apiOrigin + '/usuario/darlike', pivateOptions('POST', { idusuario: idUsuario, idpublicacion: idPublicacion }));
      const res = await response.json();
      return res;
    },
    async RemoveLike(idUsuario, idPublicacion) {
      const response = await fetch(apiOrigin + '/usuario/quitarlike', pivateOptions('DELETE', { idusuario: idUsuario, idpublicacion: idPublicacion }));
      const res = await response.json();
      return res;
    },
  },
  eventos: {
    async getAllPublic() {
      const response = await fetch(publicOrigin + '/eventos', publicOptions('GET'));
      const data = await response.json();
      return data;
    },
    async getPublic(id) {
      const response = await fetch(publicOrigin + '/eventos/' + id, publicOptions('GET'));
      const data = await response.json();
      return data
    },
    async getAll(q = "", pageSize = 10, currentPage = 0) {
      const response = await fetch(`${apiOrigin}/eventos?q=${q}&sortField=id&sortOrder=desc&limit=${pageSize}&page=${currentPage}`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },
    async get(id) {
      const response = await fetch(apiOrigin + '/eventos/' + id, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },
    async getAllEventosTipos() {
      const response = await fetch(apiOrigin + '/eventos/tipos', pivateOptions('GET'));
      const data = await response.json();
      return data;
    },
    async confirmEvento(idEvento) {
      const response = await fetch(apiOrigin + `/eventos/confirm/${idEvento}`, pivateOptions('PUT'));
      const res = await response.json();
      return res
    },
    async createEvento(data) {
      const response = await fetch(apiOrigin + '/eventos', pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },
    async deleteEvento(id) {
      const response = await fetch(apiOrigin + '/eventos/' + id, pivateOptions('DELETE'));
      const data = await response.json();
      return data;
    },
    async setEvento(id, data) {
      const response = await fetch(apiOrigin + '/eventos/' + id, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },
    async createCliente(id, data) {
      const response = await fetch(`${apiOrigin}/eventos/${id}/cliente`, pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },
    async deleteCliente(eventoId, clienteId) {
      try {
        // const response = await fetch(`${apiOrigin}/eventos/${eventoId}/cliente/${clienteId}`, pivateOptions('DELETE'));
        const response = await fetch(`${apiOrigin}/eventos/cliente/${clienteId}`, pivateOptions('DELETE'));
        if (!response.ok) {
          throw new Error('Error al eliminar el cliente'); // Manejo genérico de error
        }
        const data = await response.json();
        return data;
      } catch (error) {
        throw error;
      }
    },
  },

  owners: {
    async createOwner(id, data) {
      const response = await fetch(apiOrigin + '/eventos/' + id + "/owner", pivateOptions('POST', data));
      const res = await response.json();
      return res
    },

    async deleteOwner(id) {
      const response = await fetch(apiOrigin + '/eventos/owner/' + id, pivateOptions('DELETE'));
      const data = await response.json();
      return data
    }
  },

  colaboradores: {
    async createColaborador(id, data) {
      const response = await fetch(apiOrigin + '/eventos/' + id + "/colaborador", pivateOptions('POST', data));
      const res = await response.json();
      return res
    },

    async deleteColaborador(id) {
      const response = await fetch(apiOrigin + '/eventos/colaborador/' + id, pivateOptions('DELETE'));
      const data = await response.json();
      return data
    }
  },

  locations: {
    async getAll(id, q = "", pageSize = 10, currentPage = 0) {
      const response = await fetch(apiOrigin + `/ubicaciones?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}&idevento=${id}`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },
    async createLocation(data) {
      const response = await fetch(apiOrigin + '/ubicaciones', pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },
    async deleteLocation(id) {
      const response = await fetch(apiOrigin + `/ubicaciones/${id}`, pivateOptions('DELETE'));
      const data = await response.json();
      return data;
    },
    async updateLocation(data) {
      const response = await fetch(apiOrigin + '/ubicaciones', pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    }
  },

  ventas: {
    async getAll(id, q = "", pageSize = 10, currentPage = 0) {
      const response = await fetch(apiOrigin + `/evento/${id}/ventas?q=${q}&sortField=id&sortOrder=desc&limit=${pageSize}&page=${currentPage}`, pivateOptions('GET'));
      const data = response.json();
      return data;
    },
    async get(id) {
      const response = await fetch(apiOrigin + `/evento/venta/${id}`, pivateOptions('GET'));
      const data = response.json();
      return data;
    },
    async getRecibo(id) {
      const response = await fetch(apiOrigin + `/evento/venta/${id}/recibo`, pivateOptions('GET'));
      const data = response.json();
      return data;
    },
    async getReciboPDF(id, idCompra) {
      const response = await fetch(`${apiOrigin}/evento/venta/${id}/recibo/download`, pivateOptions('GET'))
      const data = response.blob()
      return data
    }
  },

  categorias: {
    async getAll(id, q = '', pageSize = 10, currentPage = 0) {
      const response = await fetch(apiOrigin + `/categorias?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}&idevento=${id}`, pivateOptions('GET'));
      const data = response.json();
      return data;
    },
  },

  publicaciones: {
    async getAllActive(q = '', pageSize = 10, currentPage = 0) {
      const response = await fetch(`${publicOrigin}/eventos/publicaciones/activas?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}`, publicOptions('GET'));
      const data = await response.json();
      return data;
    },
    async getAll(id, q = "", pageSize = 10, currentPage = 0) {
      const response = await fetch(`${apiOrigin}/publicaciones?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}&idevento=${id}`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },
    async get(id) {
      const response = await fetch(`${apiOrigin}/publicaciones/${id}`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },
    async createPublicacion(data) {
      const response = await fetch(`${apiOrigin}/publicaciones`, pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },
    async deletePublicacion(id) {
      const response = await fetch(`${apiOrigin}/publicaciones/${id}`, pivateOptions('DELETE'));
      const data = await response.json();
      return data;
    },
    async updatePublicacion(data) {
      const response = await fetch(`${apiOrigin}/publicaciones`, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },
  },

  faqs: {
    async getAll(id) {
      const response = await fetch(apiOrigin + `/eventos/${id}/faqs`, pivateOptions('GET'));
      const data = response.json();
      return data;
    },
    async createFaqs(id, data) {
      const response = await fetch(apiOrigin + `/eventos/${id}/faqs`, pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },
    async editFaqs(id, idfaqs, data) {
      const response = await fetch(apiOrigin + `/eventos/${id}/faqs/${idfaqs}`, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },
    async deleteFaqs(id, idfaqs) {
      const response = await fetch(apiOrigin + `/eventos/${id}/faqs/${idfaqs}`, pivateOptions('DELETE'));
      const res = await response.json();
      return res;
    },
  },

  eventoCuentas: {
    async getAll(id) {
      const response = await fetch(apiOrigin + `/eventos/${id}/cuentas`, pivateOptions('GET'));
      const data = response.json();
      return data;
    },
    async get(id) {
      const response = await fetch(apiOrigin + `/eventos/cuentas/${id}`, pivateOptions('GET'));
      const data = response.json();
      return data;
    },
    async createCuenta(id, data) {
      const response = await fetch(apiOrigin + `/eventos/${id}/cuentas`, pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },
    async setEventoCuenta(id, data) {
      const response = await fetch(apiOrigin + `/eventos/cuentas/${id}`, pivateOptions('PUT', data));
      const res = response.json();
      return res;
    },
    async deleteEventoCuenta(id) {
      const response = await fetch(apiOrigin + `/eventos/cuentas/${id}`, pivateOptions('DELETE'));
      const data = response.json();
      return data;
    },
  },

  mercadopago: {
    async createPago(data) {
      const response = await fetch(apiOrigin + "/payment", pivateOptions('POST', data));
      const res = response.json();
      return res;
    },
    async createPagoQR(id, data) {
      const response = await fetch(`${apiOrigin}/${id}/payment/qr`, pivateOptions('POST', data));
      const res = response.json();
      return res;
    },
    async vincularCuenta(data) {
      const response = await fetch(apiOrigin + "/vinculation", pivateOptions('POST', data));
      const res = response.json();
      return res;
    },
    async desvincularCuenta(id) {
      const response = await fetch(apiOrigin + `/desvinculation/${id}`, pivateOptions('DELETE', id));
      const res = response.json();
      return res;
    },
  },

  invitaciones: {
    async setInvitacionOwner(id, data) {
      const response = await fetch(`${apiOrigin}/eventos/owner/${id}`, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },
    async setInvitacionColaborador(id, data) {
      const response = await fetch(`${apiOrigin}/eventos/colaborador/${id}`, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },
    async setInvitacionCliente(id, data) {
      const response = await fetch(`${apiOrigin}/eventos/cliente/${id}`, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },
    async searchInvitation() {
      const response = await fetch(`${apiOrigin}/invitaciones/pendientes`, pivateOptions('GET'));
      const res = await response.json();
      return res;
    }
  },

  notificaciones: {
    async getNotificaciones() {
      const response = await fetch(`${apiOrigin}/notificaciones`, pivateOptions('GET'));
      const res = await response.json();
      return res;
    },

    async atenderSolicitud(id, data) {
      const response = await fetch(`${apiOrigin}/notificacion/${id}`, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },

    async eliminarNotificacion(id) {
      const response = await fetch(`${apiOrigin}/notificacion/${id}`, pivateOptions('DELETE'));
      const res = await response.json()
      return res;
    }
  },

  inscripciones: {
    async createInscripcion(id, data) {
      const response = await fetch(`http://localhost:7001/public/eventos/${id}/inscripcion`, publicOptions('POST', data));
      const res = response.json();
      return res;
    },
  },
  tarifas: {
    async getAll(id, q = "", pageSize = 10, currentPage = 0) {
      const response = await fetch(apiOrigin + `/tarifas?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}&idevento=${id}`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },

    async getAllTickets(id, q = "", pageSize = 10, currentPage = 0) {
      const response = await fetch(apiOrigin + `/eventos/${id}/tarifas/tickets?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },

    async getAllVouchers(id, q = "", pageSize = 10, currentPage = 0) {
      const response = await fetch(apiOrigin + `/eventos/${id}/tarifas/vouchers?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },

    async createTarifa(data) {
      const response = await fetch(apiOrigin + `/tarifas`, pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },
    async updateTarifa(id, data) {
      const response = await fetch(apiOrigin + `/tarifas/${id}`, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },
    async deleteTarifa(id) {
      const response = await fetch(apiOrigin + `/tarifas/${id}`, pivateOptions('DELETE'));
      const data = await response.json();
      return data;
    },
    async GetAllTarifasTipos() {
      const response = await fetch(apiOrigin + `/tarifas/tipo`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },
    async getCantidadByTarifa(idEvento, tarifaId) {
      const response = await fetch(`${apiOrigin}/${idEvento}/tarifas/${tarifaId}/cantidad`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },
    async getTarifadByIdEvento(idevento) {
      const response = await fetch(`${apiOrigin}/${idevento}/tarifas`, pivateOptions("GET"));
      const res = await response.json();
      return res;
    },
    async getTarifasSinDiseno(idevento) {
      const response = await fetch(`${apiOrigin}/${idevento}/tarifas/sindiseno`, pivateOptions("GET"));
      const res = await response.json();
      return res;
    }
  },

  tickets: {
    async getTickets(idEvento, q = "", pageSize = 10, currentPage = 0) {
      const response = await fetch(apiOrigin + `/eventos/${idEvento}/tickets?q=${q}&sortField=id&sortOrder=desc&limit=${pageSize}&page=${currentPage}`, pivateOptions('GET'));
      const res = await response.json();
      return res
    },
    async setAccesoTicket(id, qr) {
      const response = await fetch(apiOrigin + '/eventos/' + id + "/" + qr, pivateOptions('PUT'));
      const data = await response.json();
      return data;
    },
    async getTicketEvento(id) {
      const response = await fetch(apiOrigin + '/eventos/tickets/' + id, pivateOptions('GET'));
      const data = await response.json();
      return data
    },
    async createTicket(id, data) {
      const response = await fetch(apiOrigin + '/eventos/' + id + "/ticket", pivateOptions('POST', data));
      const res = await response.json();
      return res
    },
    async createTickets(id, data) {
      const response = await fetch(apiOrigin + '/eventos/' + id + "/tickets", pivateOptions('POST', data));
      const res = await response.json();
      return res
    },
    async setSalidaTicket(id, qr, salida) {
      const response = await fetch(apiOrigin + '/eventos/' + id + "/" + qr + "/" + salida, pivateOptions('PUT'));
      const data = await response.json();
      return data;
    },
    async getTicketByEvento(idEvento, idcategoria, query = '', page = 1) {
      const response = await fetch(`${apiOrigin}/eventos/${idEvento}/tickets?q=${query}${idcategoria ? `&idcategoria=${idcategoria}` : ''}&page=${page}`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },
    async getExcelTickets(id) {
      const response = await fetch(`${apiOrigin}/eventos/${id}/ticketsexcel`, pivateOptions('GET'))
      const res = await response.blob();
      return res
    },
    async createExcelTickets(id, formData) {
      const response = await fetch(`${apiOrigin}/eventos/${id}/ticketsexcel`, mediaOptions('POST', formData));
      return response.json();
    },
  },

  vouchers: {
    async getVouchers(idEvento, q = "", pageSize = 10, currentPage = 0) {
      const response = await fetch(apiOrigin + `/eventos/${idEvento}/vouchers?q=${q}&sortField=id&sortOrder=desc&limit=${pageSize}&page=${currentPage}`, pivateOptions('GET'));
      const res = await response.json();
      return res
    },

    async getVouchersByEvento(idEvento, page = 1) {
      const response = await fetch(`${apiOrigin}/eventos/${idEvento}/vouchers?q=&page=${page}`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },

    async getVoucherEvento(id) {
      const response = await fetch(apiOrigin + '/eventos/vouchers/' + id, pivateOptions('GET'));
      const data = await response.json();
      return data
    },

    async createVouchers(id, data) {
      const response = await fetch(apiOrigin + '/eventos/' + id + "/vouchers", pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },

    async createVouchersExcel(idEvento, data) {
      const response = await fetch(apiOrigin + '/eventos/' + idEvento + "/crearvouchers", pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },
  },

  participaciones: {
    async getParticipaciones() {
      const response = await fetch(`${apiOrigin}/participaciones`, pivateOptions('GET'));
      const data = response.json();
      return data;
    }
  },

  carrito: {
    async getCarrito() {
      const response = await fetch(`${apiOrigin}/carrito/activo`, pivateOptions('GET'));
      const data = response.json();
      return data;
    },
    async crearCarrito(data) {
      const response = await fetch(`${apiOrigin}/carrito/crear`, pivateOptions('POST', data));
      const res = response.json();
      return res;
    },
    async sumarRestarItems(data) {
      const response = await fetch(`${apiOrigin}/carrito/agregar`, pivateOptions('PUT', data));
      const res = response.json();
      return res;
    },
    async eliminarItem(id) {
      const response = await fetch(`${apiOrigin}/carrito/eliminar/${id}`, pivateOptions('DELETE'));
      const data = response.json();
      return data;
    }
  },

  media: {
    async upload(files) {
      const response = await fetch(apiOrigin + '/media/upload', mediaOptions('POST', files))
      const data = response.json();
      return data
    },
    async delete(id) {
      const response = await fetch(apiOrigin + '/media/delete', pivateOptions('DELETE', id))
      const data = response.json();
      return data
    }
  },

  email: {
    async send(data) {
      const response = await fetch(apiOrigin + '/email/send', pivateOptions('POST', data));
      const res = response.json();
      return res
    }
  },
  impresion: {
    async uploadImage(id, data) {
      const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/imageUpload`, pivateOptions("POST", data))
      const res = response.json()
      return res
    },
    async getImages(id) {
      const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/imageUpload`, pivateOptions("GET"))
      const res = response.json()
      return res
    },
    async deleteImage(id, idImage, publicID) {
      const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/imageDelete/${idImage}`, pivateOptions("DELETE", publicID))
      const res = response.json()
      return res
    },
    async getAll(idevento) {
      const response = await fetch(`${apiOrigin}/eventos/${idevento}/impresion`, pivateOptions("GET"));
      const res = await response.json();
      return res;
    },
    async getById(id) {
      const response = await fetch(`${apiOrigin}/eventos/${id}/impresion`, pivateOptions('GET'));
      const res = response.json();
      return res;
    },
    async getDisenoByID(iddiseno) {
      const response = await fetch(`${apiOrigin}/eventos/${iddiseno}/diseno`, pivateOptions('GET'));
      const res = response.json();
      return res;
    },
    async create(idevento, iddiseno, data) {
      const response = await fetch(`${apiOrigin}/eventos/${idevento}/impresion/${iddiseno}`, pivateOptions('POST', data));
      const res = response.json();
      return res;
    },
    async update(idevento, iddiseno, data) {
      const response = await fetch(`${apiOrigin}/eventos/${idevento}/impresion/${iddiseno}`, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },
    async download(id) {
      const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/download`, pivateOptions('GET'));
      const res = await response.blob();
      return res;
    },
    // async verifyDesign() {
    // },
    async downloadImpresionConFiltro(id, tarifaId) {
      const response = await fetch(apiOrigin + `/eventos/${id}/impresion/${tarifaId}/download`, pivateOptions('GET'));
      if (!response.ok) {
        return await response.json();
      }
      const resBlob = await response.blob();
      return resBlob;
    },
    async downloadImpresionVoucher(id, tarifaId) {
      const response = await fetch(apiOrigin + `/eventos/${id}/impresion/${tarifaId}/downloadVoucher`, pivateOptions('GET'));
      // const response = await fetch(apiOrigin + `/eventos/${id}/impresion/${tarifaId}/downloadVoucher?desde=2010&hasta=2309`, pivateOptions('GET'));
      if (!response.ok) {
        return await response.json();
      }
      const resBlob = await response.blob();
      return resBlob;
    },
  },
  accesos: {
    async setVoucher(id, qr) {
      const response = await fetch(apiOrigin + `/eventos/${id}/voucher/${qr}`, pivateOptions('PUT'));
      const data = await response.json();
      return data;
    }
  },

  consultas: {
    async create(data) {
      const response = await fetch(publicOrigin + '/consultas', publicOptions('POST', data));
      const res = await response.json();
      return res;
    },
  },

  facturacion: {
    async getFacturacion() {
      const response = await fetch(`${apiOrigin}/facturacion`, pivateOptions('GET'));
      const data = await response.json();
      return data;
    },
    async createFacturacion(data) {
      const response = await fetch(`${apiOrigin}/facturacion`, pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },
    async updateFacturacion(data) {
      const response = await fetch(`${apiOrigin}/facturacion`, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },
  },

  cookies: {
    async updateCookies(data) {
      const response = await fetch(`${publicOrigin}/cookies/consent`, publicOptions('POST', data));
      const res = await response.json();
      return res;
    }
  },

  reporte: {
    async downloadReporte(id) {
      const response = await fetch(`${apiOrigin}/reportes/${id}`, pivateOptions('GET'))
      const res = await response.blob();
      return res
    },
    async downloadReporteTicket(id) {
      const response = await fetch(`${apiOrigin}/reportes/${id}/tickets`, pivateOptions('GET'))
      const res = await response.blob();
      return res
    },
    async downloadReporteVoucher(id) {
      const response = await fetch(`${apiOrigin}/reportes/${id}/vouchers`, pivateOptions('GET'))
      const res = await response.blob();
      return res
    }
  },

  fechas: {
    async getAllFechasEvento(id) {
      const response = await fetch(`${apiOrigin}/eventos/${id}/fechas`, pivateOptions('GET'))
      const res = await response.json();
      return res;
    }
  },

  infoAdicional: {
    async createPreguntas(id, data) {
      const response = await fetch(apiOrigin + '/eventos/' + id + "/infoadicional", pivateOptions('POST', data));
      const res = await response.json();
      return res;
    },
    async getPregunta(id, idTarifa) {
      const response = await fetch(apiOrigin + `/eventos/${id}/infoadicional?idtarifa=${idTarifa}`, pivateOptions('GET'));
      const res = await response.json();
      return res
    },
    async updatePregunta(id, data) {
      const response = await fetch(`${apiOrigin}/adicional/${id}`, pivateOptions('PUT', data));
      const res = await response.json();
      return res;
    },
    async deletePregunta(id) {
      const response = await fetch(apiOrigin + `/adicional/${id}`, pivateOptions('DELETE'));
      const data = await response.json();
      return data;
    },
  }
};
