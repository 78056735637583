import { useEffect, useState } from "react";
import useQuery from "../../../hooks/useQuery";
import queryString from "query-string";
import { getTickets } from "./api";

const useTickets = (idevento) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchedColumn, setSearchedColumn] = useState('');
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
    showSizeChanger: true
  })
  const [sorting, setSorting] = useState({
    field: "",
    order: ""
  })
  const [total, setTotal] = useState();

  const { data, loading, refetch } = useQuery({
    autoFetch: false,
    queryFn: getTickets,
    onSuccess: response => {
      setPagination(prevPagination => ({
        ...prevPagination,
        total: response.data.totalDataSize
      }));
    },
    args: [idevento],
  });

  const onSearch = (searchValue, searchedColumn) => {
    setPagination(prevPagination => ({ ...prevPagination, current: 1 }));
    setSearchValue(searchValue);
    setSearchedColumn(searchedColumn);
  };

  const onTableChange = (pagination) => {
    setPagination(ps => ({ ...ps, ...pagination }))
    // setSorting(sorting)
  };

  const fetch = (idevento) => {
    const newQuery = queryString.stringify({
      searchValue,
      searchedColumn,
      limit: pagination.pageSize,
      page: pagination.current,
      q: searchValue,
      sortField: sorting.field,
      sortOrder: sorting.order === "ascend" ? "ASC" : "DESC",
    });
    
    refetch(idevento, newQuery);
  }

  useEffect(() => {
    fetch(idevento)
  }, [pagination.current, searchValue, sorting]);

  return {
    tickets: data?.data.tikets,
    loading,
    fetch,
    pagination,
    onSearch,
    onTableChange,

  }
};

export default useTickets;