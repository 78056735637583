import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from "../../../services/api";
import { Alert, CardBody, Form, Spinner } from 'reactstrap';
import Loading from '../../../components/Loading';
import VolverButton from '../../../components/VolverButton';
import { useTranslation } from 'react-i18next';
import './cuentas.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Card, CardHeader, FormControl, FormControlLabel, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Radio, RadioGroup } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast } from 'react-toastify';

function VincularCuentas() {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const param = useParams();
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const [vinculacion, setVinculacion] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = anchorEl;

 /*  console.log("datos: ", vinculacion); */
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const callApi = async (id) => {
    setLoading(true);
    try {
      const response = await api.eventoCuentas.getAll(id);

      if (response.status === "success") {
        setVinculacion(response.data.vinculacion);
      }
      else {
        throw new Error(response.message);
      }

    } catch (error) {
      setState({
        ...state,
        error: error.message
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    document.title = `Carteras | PassGo`;
    callApi(param.id);
  }, [param.id]);

  const handleGenerateLink = async () => {
    setLoadingLink(true);
    
    try {
      const idevento = parseInt(param?.id);

      const response = await api.mercadopago.vincularCuenta({
        idevento: idevento,
      });

      if (response.status === "success") {
        window.location = response.data;
      }
    } catch (error) {
      setState({
        ...state,
        error: error
      });
    } finally {
      setLoadingLink(false);
    }
  }

  const handleDesvincular = async (id) => {
    const userConfirmed = window.confirm("¿Estás seguro de que deseas desvincular esta cuenta?");
  
    if (!userConfirmed) {
      return; // Si el usuario cancela, no hace nada
    }
    
    try {
      const response = await api.mercadopago.desvincularCuenta(id);

      if (response.status === "success") {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 1000,
          onClose: () => {
            handleClose();
            setVinculacion(null);
            callApi(param.id);
          },
        });
      }
      else {
        throw new Error(response.message);
      }

    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-center gap-4">
        {!loading ? (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="mercado_pago"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="mercado_pago"
                control={<Radio />}
                disabled={vinculacion}
                label={
                  <Card
                    sx={{
                      minWidth: 150,
                      boxShadow: 'none',
                      border: `2px solid ${!vinculacion ? '#AEAEAE' : '#008000'}`,
                      backgroundColor: `${vinculacion && '#71BC7810'}`  
                    }}>
                    <CardHeader
                      avatar={
                        <Avatar
                          src="/mp-icon.png"
                          alt="mercado pago"
                          sx={{
                            width: 100,
                            height: 100,
                            backgroundColor: 'transparent',
                          }}
                          aria-label="recipe">
                        </Avatar>
                      }
                      action={
                        <>
                          <IconButton
                            disabled={!vinculacion}
                            className="ms-2"
                            aria-label="settings"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            aria-label="short-menu"
                          >
                            <MenuItem onClick={() => handleDesvincular(vinculacion?.id)}>
                              <ListItemIcon>
                                <DeleteForeverIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Desvincular</ListItemText>
                            </MenuItem>
                          </Menu>
                        </>
                      }
                      title={<p className="fs-5">Cuenta de Mercado Pago</p>}
                      subheader={
                        <div className="d-flex flex-column">
                          <span>Titular: {vinculacion?.titular}</span>
                          <span>Estado: <i className="fw-bold">{vinculacion ? vinculacion.mercado_pago : "NO VINCULADO"}</i></span>
                        </div>
                      }
                    />
                  </Card>
                }
              />
            </RadioGroup>
          </FormControl>
        ) : (
          <Spinner color="primary" />
        )}
      </div>
      
      {!vinculacion && !loading &&
        <MercadoPagoForm
          handleGenerateLink={handleGenerateLink}
          loadingLink={loadingLink}
        />
      }
    
      <div className="container d-flex justify-content-end">
        <VolverButton />
      </div>
    </div>
  )
}

function MercadoPagoForm({ handleGenerateLink, loadingLink }) {
  return (
    <Form className="col-lg-8 mx-auto  col-12 my-3">
      <Card className="bg-body-secondary mx-auto vinculacion p-4" sx={{width: '350px', cursor: 'pointer'}}>
        <CardBody>
          {!loadingLink
            ?
            <p className="text-secondary text-center mb-0" onClick={handleGenerateLink}>
              <FontAwesomeIcon icon={faAdd} size="2xl" />
              <br/>
              Vinculá tu cuenta de Mercado Pago y comenzá a recibir los ingresos de tus ventas.
            </p>
            :
            <p className="text-secondary text-center mb-0">
              <Spinner color="secondary" />
              <br/>
              Redirigiendo...
            </p>
          }
        </CardBody>
      </Card>
    </Form>
  );
}

export default withAuthenticationRequired(VincularCuentas, {
  onRedirecting: () => <Loading />,
});