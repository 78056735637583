import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Breadcrumbs, Link, Button } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";

import "./creaEventos.css"

const SliderHeader = () => {
    const history = useHistory();
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const handleButtonClick = () => {
        if (isAuthenticated) {
            history.push("/eventos");
        } else {
            loginWithRedirect({
                appState: { returnTo: "/eventos" }
            });
        }
    };

    return (
        <>
            <div className="w-100 d-flex justify-content-start" style={{ padding: "25px" }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Inicio
                    </Link>
                    <Link
                        underline="hover"
                        color="#5656FF"
                        href="/creaeventos"
                    >
                        Crear evento
                    </Link>
                </Breadcrumbs>
            </div>
            <div className="hero-div-crear" >
                <p className=" hero-title">Creá tu evento <br /> en 3 simples pasos</p>

                <button type="button" className="d-flex align-items-center" style={{ marginLeft: "10px", marginBottom: "30px", borderRadius: "7px", backgroundColor: "#5656FF", padding: "6px 22px", color: "white" }} onClick={handleButtonClick}>
                    Crear Evento
                </button>
            </div>
        </>
    )
}
export default SliderHeader;