import React, { useState } from "react";
import { NavLink  } from "react-router-dom";
import useNotificaciones from "../hooks/hookNotifications";
import {
  AppBar,
  List,
  ListItemButton,
  ListItemIcon,
  Collapse,
  Badge,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Avatar
}from '@mui/material';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LoginIcon from '@mui/icons-material/LoginOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import EventIcon from '@mui/icons-material/Event';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { es } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "hamburgers/dist/hamburgers.css";
import "./navbar.css";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import PassGoLogo from "../../src/assets/PassGoLogo.png";
import { useNotificacionesContext } from "./context/NotificationContext";

const NavBar = () => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [isOpen, setIsOpen] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { notificaciones } = useNotificacionesContext();
  const [isScrolled, setIsScrolled] = useState(false);

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout
  } = useAuth0();

  const routeName = {
    "/eventos": "Mis Eventos",
    "/notificaciones": "Notificaciones",
    "/carrito": "Carrito",
    "/profile": "Perfil",
  }[location.pathname];

  // const vistaCreaEventos = location.pathname === '/creaeventos';

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 80) {
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }
  //   };

  //   if (vistaCreaEventos) {
  //     window.addEventListener('scroll', handleScroll);
  //   }

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [vistaCreaEventos]);

  const navItems = [
    { path: "/eventos", name: "Mis Eventos", icon: <EventIcon /> },
    { path: "/creaeventos", name: "Crear Eventos", icon: <CreateOutlinedIcon /> },
    { path: "/notificaciones", name: "Notificaciones", icon: <NotificationsNoneOutlinedIcon /> },
    { path: "/profile", name: "Perfil", icon: <AccountCircleOutlinedIcon /> },
  ].filter((item) => item.name !== routeName);

  const toggleHamburger = () => {
    setIsActive(!isActive);
    toggle();
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    if (profileMenuOpen) {
      setProfileMenuOpen(false);
    }
  };

  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [anchorElProfile, setAnchorElProfiles] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);

  const handleNotificationsClick  = (event) => {
    setAnchorElNotifications(event.currentTarget);
    setOpenNotifications(!openNotifications);
    if (openProfile) setOpenProfile(false);
  };
  const handleProfileClick = (event) => {
    setAnchorElProfiles(event.currentTarget);
    setOpenProfile(!openProfile);
    if (openNotifications) setOpenNotifications(false); 
  };

  const handleClose = () => {
    setOpenNotifications(false);
    setOpenProfile(false);
  };

  const logoutWithRedirect = () => {
    localStorage.removeItem("token");
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };


  const closeMenus = () => {
    setIsOpen(false);
    setIsActive(false);
    setNotificationsOpen(false);
    setOpenProfile(false);
  };


  const unreadCount = notificaciones.filter(
    (notificacion) => notificacion.atendido == null
  ).length;

  return (
    <>
      {isMobile ? (
        <AppBar position="static" className="passgo-navbar" style={{ top: "0", width: "100%" }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <List
              sx={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "space-between" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton 
                onClick={toggleHamburger}
                className={`hamburger hamburger--squeeze ${isActive ? "is-active" : ""} px-0`}
              >
                <ListItemIcon>
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </ListItemIcon>
              </ListItemButton>
            </List>
            <Link sx={{ flexGrow: 1, display: "flex", alignItems: "center" }} to="/" onClick={() => setIsActive(false)}>
              <svg width="39" height="32" viewBox="0 0 34 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.8809 0.202271C17.4303 0.273889 21.7993 0.0948437 26.0238 0.452934C31.2593 0.918451 34.6894 4.6784 33.7145 8.68901C32.7758 12.5564 31.5481 16.3879 30.2844 20.2553C29.8511 21.5444 28.9845 22.7978 28.0819 23.9795C25.7349 26.9516 22.0881 28.2407 17.7914 28.563C16.4554 28.6704 15.0834 28.7063 13.5669 28.7779C12.9892 30.7116 12.4115 32.5736 11.8338 34.4715C11.5449 35.4025 11.2561 36.3694 10.9672 37.3004C10.4617 39.0193 8.43971 40.3442 6.12887 40.559L0.423981 40.9887C4.6846 27.0948 8.80078 13.4874 12.8809 0.202271ZM15.4083 22.4039C19.4523 22.6187 21.1493 21.7235 22.1964 18.823C22.5936 17.7129 22.8825 16.567 23.2435 15.4569C23.8574 13.38 24.5073 11.3389 25.0489 9.26195C25.5544 7.36408 24.5795 6.43304 22.0881 6.39723C21.4743 6.39723 20.8966 6.39723 20.2106 6.39723C18.5858 11.7328 16.997 17.0325 15.4083 22.4039Z" fill="#fff"/>
              </svg>
            </Link>
          </Toolbar>
          <Collapse in={isActive} timeout="auto" unmountOnExit>
              {isAuthenticated ? (
                <>
                  {navItems.map((item) => (
                    <Link
                      to={item.path}
                      onClick={closeMenus}
                      className="d-flex align-items-center gap-3 mx-4 mb-3 text-light text-decoration-none"
                      key={item.name}
                    >
                      {notificaciones.filter(notificacion => notificacion.atendido == null).length > 0 && item.name === "Notificaciones" ? (
                        <Badge color="error" variant="dot">
                          <span>{item.icon}</span>
                        </Badge> 
                      ) : 
                        <span>{item.icon}</span> 
                      }
                      <p className="m-0">{item.name}</p>
                    </Link>
                  ))}
                  <Link
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => {
                      logoutWithRedirect();
                      closeMenus();
                    }}
                    className="d-flex align-items-center gap-3 mx-4 mb-3 text-light text-decoration-none"
                  >
                    <span><PowerSettingsNewOutlinedIcon /></span>
                    <p className="m-0">{t("navbar.logout")}</p>
                  </Link>
                </> 
              ) : (
                <>
                  <Link
                    to="/"
                    className="d-flex align-items-center gap-3 mx-4 mb-3 text-light text-decoration-none"
                    onClick={closeMenus}
                  >
                    <span><HomeOutlinedIcon/></span>
                    <p className="m-0">Inicio</p>
                  </Link>
                  <Link
                    to="/creaeventos"
                    className="d-flex align-items-center gap-3 mx-4 mb-3 text-light text-decoration-none"
                    onClick={closeMenus}  
                  >
                    <span><EditCalendarOutlinedIcon/></span>
                    <p className="m-0">{t("navbar.createEvents")}</p>
                  </Link>
                  <Link
                    id="qsLoginBtn"
                    to="#"
                    onClick={() => {
                      loginWithRedirect({
                        appState: { returnTo: window.location.pathname },
                      });
                      closeMenus();
                    }}
                    className="d-flex align-items-center gap-3 mx-4 mb-3 text-light text-decoration-none"
                  >
                    <span><ExitToAppOutlinedIcon/></span>
                    <p className="m-0">{t("navbar.login")}</p>
                  </Link>
                </>
              )}
            </Collapse>
        </AppBar>
      ) : (
        <AppBar className="passgo-navbar py-1" position="relative">
          <Toolbar className="d-flex justify-content-between">
            <div>
              <Link
                to="/"
                className="mr-5"
              >
                <img src={`${PassGoLogo}`} width="75px" alt="PassGo logo" />
              </Link>
              <NavLink
                to="/"
                className="text-decoration-none mr-5 text-white"
                onClick={closeMenus}
              >
                {t("navbar.home")}
              </NavLink>
              <NavLink
                to="/creaeventos"
                className="text-decoration-none mr-5 text-white"
                onClick={closeMenus}
              >
                {t("navbar.createEvents")}
              </NavLink>
              {isAuthenticated && (
                <NavLink
                  to="/eventos"
                  className="text-decoration-none text-white"
                  onClick={closeMenus}
                >
                  {t("navbar.myEvents")}
                </NavLink>
              )}
            </div>
            {isAuthenticated ? (
              <div className="d-flex gap-4">
                <IconButton
                  id="notificationsDropdown"
                  onClick={handleNotificationsClick}
                >
                  <Badge
                    badgeContent={unreadCount > 9 ? "9+" : unreadCount}
                    color="error"
                    overlap="circular"
                  >
                    <NotificationsNoneOutlinedIcon style={{ color: "white", fontSize: "26px"}} />
                  </Badge>
                </IconButton>
                <Menu
                  id="notifications-menu"
                  anchorEl={anchorElNotifications}
                  open={openNotifications}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "notificationsDropdown",
                  }}
                  PaperProps={{
                    sx: {
                      width: 320, 
                      maxHeight: 440, 
                      padding: 0
                    },
                  }}
                >
                  <div className="mb-0 px-3 d-flex justify-content-between" 
                    style={{paddingTop: "7px", paddingBottom: "15px"}}
                  >
                    <p 
                      style={{fontSize: "19px"}} 
                      className="fw-medium mb-0 "
                    >
                      {t("navbar.notifications")}
                    </p>
                    <Link
                      to="/Notificaciones"
                      onClick={closeMenus}
                      className="text-decoration-none"  
                    >
                      <small>Ver todas</small>
                    </Link>
                  </div>
                  
                    {notificaciones.filter(notificacion => (notificacion.atendido == null)).length ? (
                      notificaciones.filter(notificacion => (notificacion.atendido == null))
                      .sort((a, b) => new Date(b.createon) - new Date(a.createon))
                      .slice(0, 3).map((notificacion) => (
                        <MenuItem
                          component={Link} 
                          to="/Notificaciones"
                          sx={{display: "flex", alignItems: "start", padding: "12px"}} 
                          className="border-top border-light-subtle"
                        >
                          <div style={{marginRight: "15px"}}>
                            <Badge color="primary" variant="dot"/>
                          </div>
                          <div className="d-flex flex-column" style={{flex: 1}}>
                            <p className="m-0 fw-semibold">
                              {notificacion.titulo.length > 30 ? `${notificacion.titulo.substring(0,30)}...` : notificacion.titulo}
                            </p>
                            <small >
                              {notificacion.descripcion.length > 37 ? `${notificacion.descripcion.substring(0, 37)}...` : notificacion.descripcion}
                            </small>
                            <small className="text-black-50 text-end">
                              {formatDistanceToNow(new Date(notificacion.createon), { addSuffix: true, locale: es })}
                            </small>
                          </div>
                        </MenuItem>
                      ))
                    ):(
                      <div className="mb-0" style={{height: "200px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <div style={{
                          width: "60px", 
                          height: "60px", 
                          borderRadius: "15px", 
                          background: "#ebebeb",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "15px"
                          }}
                        >
                          <NotificationsActiveOutlinedIcon style={{color: "#c5c5c5", fontSize: "30px"}}/>
                        </div>
                        <p className="m-0">Aún no tienes notificaciones pendientes</p>
                        <small className="text-body-tertiary">Revisa más tarde.</small>
                      </div>
                    )}                    
                  </Menu>

                  <Avatar
                    id="notificationsDropdown"
                    src={user.picture}
                    onClick={handleProfileClick}
                    sx={{ width: 45, height: 45 }}
                    //style={{ position: "relative", padding: "5px" }}
                  />
                  <Menu
                    id="profile-menu"
                    anchorEl={anchorElProfile}
                    open={openProfile}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "notificationsDropdown",
                    }}  
                    PaperProps={{
                      sx: {
                        width: 210, 
                        padding: 0
                      },
                    }}
                  >
                    <MenuItem 
                      component={Link} 
                      to="/profile"
                      onClick={handleClose}
                    >
                      <PersonOutlineOutlinedIcon className="mr-3"/>
                      {t("navbar.profile")}
                    </MenuItem>
                    <MenuItem 
                      component={Link} 
                      to="/favoritos"
                      onClick={handleClose}
                    >
                      <StarOutlineRoundedIcon className="mr-3"/>
                      {t("navbar.favorites")}
                    </MenuItem>
                    <MenuItem 
                      onClick={() => {
                        logoutWithRedirect();
                        closeMenus();
                      }}
                    >
                      <ExitToAppOutlinedIcon className="mr-3"/>
                      {t("navbar.logout")}
                    </MenuItem>
                  </Menu>
              </div>
            ) : (
              <div className="d-flex flex-row gap-4">

              <button style={{color:"#FFD700", borderLeft:"1px solid white", borderRight:"1px solid white", paddingLeft:"30px", paddingRight:"30px" }} type="button" onClick={() => {
                loginWithRedirect({
                  appState: { returnTo: window.location.pathname },
                });
                closeMenus();
              }}>
                Iniciar sesion
              </button>
              <button style={{color:"white", paddingRight:"30px"}}type="button" onClick={() => {
                loginWithRedirect({
                  appState: { returnTo: window.location.pathname },
                });
                closeMenus();
              }}>
                Registrarse

              </button>
              
              </div>

            )}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};
export default NavBar;