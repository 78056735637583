import { useEffect, useState } from 'react';
import { api } from '../../services/api';
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CheckIcon from '@mui/icons-material/Check';

import * as XLSX from 'xlsx';
import { Container, Badge, Alert, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, CircularProgress } from '@mui/material';

export const TickerRecepImport = () => {
    const param = useParams();
    const history = useHistory();
    const [state, setState] = useState()
    const [data, setData] = useState([]);
    const [tarifas, setTarifas] = useState();
    const [ubicaciones, setUbicaciones] = useState();
    const [hasErrors, setHasErrors] = useState(false)
    const [t] = useTranslation("global");
    const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
    const [loading, setLoading] = useState(false);
    const [enviado, setEnviado] = useState(false);
    const [fileName, setFileName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect (() => {
        const fetchTarifa = async () => {
            try {
                let totalTarifas = [];
                let currentPage = 1;
                let hasMoreTickets = true;
                let filtro = '';
          
                while (hasMoreTickets) {
                    const response = await api.tarifas.getAll(param.id, filtro, 10, currentPage);
                    if (response.status === "success") {
                        const tarifas  = response.data.tarifas; 
        
                        if (tarifas && tarifas.length > 0) {
                            totalTarifas = [...totalTarifas, ...tarifas];
                            currentPage++;
                        } else {
                            hasMoreTickets = false;
                        }
                    }
                    setTarifas(totalTarifas)
                }    
            } catch (error) {
                setState({
                    ...state,
                    error: error.error,
                });
            }
        }
        fetchTarifa();
    },[param.id]);
    const ticketTarifa = tarifas?.filter((tarifa) => tarifa.idtipo === 1 );

    useEffect (() => {
        const fetchUbicaciones = async () => {
            try {
                let totalUbicaciones = [];
                let currentPage = 1;
                let hasMoreTickets = true;
                let filtro = '';
          
                while (hasMoreTickets) {
                    const response = await api.locations.getAll(param.id, filtro, 10, currentPage);
                    if (response.status === "success") {
                        const ubicaciones  = response.data.ubicaciones;
            
                        if (ubicaciones && ubicaciones.length > 0) {
                            totalUbicaciones = [...totalUbicaciones, ...ubicaciones];
                            currentPage++;
                        } else {
                            hasMoreTickets = false;
                        }
                    }
                    setUbicaciones(totalUbicaciones)
                }    
            } catch (error) {
                setState({
                    ...state,
                    error: error.error,
                })
            }
        }
        fetchUbicaciones();
    }, [param.id])

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            processExcelFile(file);
            setFileName(file.name);
        }
    };

    const processExcelFile = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const rows = XLSX.utils.sheet_to_json(sheet);
    
            const processedData = [];
            let hasErrors = false;
    
            rows.forEach((row) => {
                const nombreEgresado = row['NombreEgresado'];
                const cantidadEgresado = row['Egresado'] || 0; 
                const nameubicacion = row['Ubicación'];

                if (!nombreEgresado) {
                    setErrorMessage('El campo "NombreEgresado" está vacío en una fila. Cancelando el envío del archivo.');
                    hasErrors = true;
                    return;
                }

                if (cantidadEgresado < 0 || typeof cantidadEgresado !== 'number') {
                    setErrorMessage(`El campo "Egresado" debe ser un número válido o null para: ${nombreEgresado}. Cancelando el envío.`);
                    hasErrors = true;
                    return;
                }

                const tarifaEgresadoValida = ticketTarifa.some(tarifa => tarifa.concepto.trim() === 'Egresado');
                if (!tarifaEgresadoValida) {
                    setErrorMessage('Tarifa "Egresado" no es válida. Cancelando el envío del archivo.');
                    hasErrors = true;
                    return;
                }

                const ubicacionValida = validarUbicacion(row, nameubicacion);
                if (!ubicacionValida) {
                    setErrorMessage(`Ubicación inválida para el egresado: ${nombreEgresado}. Cancelando el envío.`);
                    hasErrors = true;
                    return;
                }
    
                if (cantidadEgresado > 0) {
                    for (let i = 0; i < cantidadEgresado; i++) {
                        processedData.push({
                            nombre: nombreEgresado,
                            apellido: "",
                            nameubicacion,
                            nametarifa: "Egresado",
                        });
                    }
                }

                Object.keys(row).forEach((key) => {
                    if (key !== 'NombreEgresado' && key !== 'Egresado' && key !== 'Ubicación') {
                        const cantidadInvitados = row[key];
                        const tarifaValida = ticketTarifa.some(tarifa => tarifa.concepto && key && tarifa.concepto.trim() === key.trim());
            
                        if (tarifaValida && cantidadInvitados > 0) {
                            for (let i = 0; i < cantidadInvitados; i++) {
                                const dataRow = {
                                    nombre: nombreEgresado,
                                    apellido: "",
                                    nametarifa: key,
                                };
            
                                if (key !== 'Baile') {
                                    dataRow.nameubicacion = nameubicacion;
                                }
                                processedData.push(dataRow);
                            }
                        }
                    }
                });
            });
            setHasErrors(hasErrors);
    
            if (hasErrors) {
                setData([]);
                setErrorMessage("Archivo no procesado debido a errores de validación.");
                return;
            }
            setData(processedData);
        };
        reader.readAsArrayBuffer(file);
    };

    const validarUbicacion = (row, nameubicacion) => {
        if (row['Tarifa'] === 'Baile') {
            return true; 
        }

        const ubicacionValida = !nameubicacion || ubicaciones.some(ubicacion => {
            return ubicacion.ubicacion && nameubicacion && ubicacion.ubicacion.trim() === nameubicacion.trim();
        });

        return ubicacionValida;
    };

    const guardarTickets = async () => {
        if (hasErrors || data.length === 0) { 
            setErrorMessage("No se pueden guardar los tickets debido a errores de validación.");
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const response = await api.tickets.createTickets(param.id, data);

            if (response.status === 'success') {
                setLoading(false);
                setEnviado(true);
                setTimeout(() => {
                    history.push(`/eventos/${param.id}`);
                }, 2000);
            } else {
                console.error('Error al crear los tickets:', response.error);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error al intentar guardar los tickets:', error.message);
            setLoading(false);
        }
    };

    const cleanFile = () => {
        setFileName('');
        setHasErrors(null);
        setData(null);
        setErrorMessage('');
    };

    return (
        <Container>
            <div>
                <h4>Formato del archivo</h4>
                <TableContainer component={Paper} sx={{marginBottom: "5px", marginTop: "15px"}}>
                    <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>NombreEgresado</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>Egresado *</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>Ubicación *</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>Cena *</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>Baile *</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Ej.: Maria Sartor</TableCell>
                            <TableCell align="center">Ej.: 1</TableCell>
                            <TableCell align="center">Ej.: Mesa 2</TableCell>
                            <TableCell align="center">Ej.: 10</TableCell>
                            <TableCell align="center">Ej.: 15</TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                </TableContainer>
                <small>(*) Campos de completado opcional. Puedes dejarlos vacíos o ingresar el valor '0'.</small>
                <div className='especificaciones mt-4'>
                    <h5>{t("excelImport.specifications")}</h5>
                    <li>{t("excelImport.extension")}</li>
                    <li>{t("excelImport.sp3")}</li>
                    <li>{t("excelImport.sp4")}</li>
                    <li>{t("excelImport.sp5")}</li>
                    <li>Las
                        <spam onClick={() => history.push("/eventos/"+param.id+"/tarifas")} className="text-decoration-underline text-primary" style={{cursor: "pointer"}}> tarifas </spam> 
                        Egresado, Baile y Cena deben estar creadas previamente en el sistema.
                    </li>
                    <li>Las
                        <spam onClick={() => history.push("/eventos/"+param.id+"/ubicaciones")} className="text-decoration-underline text-primary" style={{cursor: "pointer"}}> ubicaciones ingresadas </spam>
                        deben estar creadas previamente en el sistema
                    </li>
                    <li>
                        Si el ticket de tipo Egresado ya está creado y solo se quieren agregar tickets de tipo Cena y Baile, es posible hacerlo dejando vacío el campo de Egresado.
                    </li>
                </div>

                <div className='formulario-ingreso mt-4'>
                    <label 
                        htmlFor="exampleFile" 
                        style={{
                            width: "350px", 
                            height: "270px", 
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center", 
                            flexDirection: "column", 
                            borderRadius: "10px",
                            boxShadow: "10px 10px 30px rgba(0,32,61,.3098039216)",
                            padding: isMobile ? "15px" : "25px",
                            cursor: loading || enviado ? "not-allowed" : "pointer",
                            opacity: loading || enviado ? 0.5 : 1,
                            pointerEvents: loading || enviado ? "none" : "auto", 
                            margin: "0px auto 20px"
                        }}
                    >
                    <h4 className='fw-medium mb-3'>{t("excelImport.uploadFile")}</h4>
                    <div style={{
                        position: "relative",
                        border: "2px dashed #e1e2e6",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center", 
                        alignItems: "center", 
                        gap: "10px",
                        padding: "10px",
                        borderRadius: "10px",
                    }}>
                        {!fileName ? (
                        <>
                            <img 
                            src={process.env.PUBLIC_URL + '/subir-excel.png'} 
                            alt="Fiesta" 
                            style={{ width: '70px', height: 'auto' }} 
                            />
                            <p>Cargar archivo</p>
                        </>
                        ):(
                        <>
                            <Badge 
                            badgeContent={<CloseOutlinedIcon 
                            sx={{ fontSize: 15 }} />} 
                            color="error"
                            onClick={!loading || !enviado ? cleanFile : null}
                            >
                            <InsertDriveFileOutlinedIcon sx={{ fontSize: 40}}/>
                            </Badge>
                            <span>{fileName}</span>
                        </>
                        )}
                    </div>
                    <input
                        id="exampleFile"
                        name="file"
                        type="file"
                        accept=".xlsx,.xls"
                        hidden
                        onChange={!loading || !enviado ? handleFileUpload : null}
                    />
                    </label>
                </div>

                {hasErrors && 
                    <Alert severity="error" className='mb-3'>{errorMessage}</Alert>
                }
                {enviado && ( 
                    <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success" className="mb-3">{t("excelImport.succesCreated")}</Alert>
                )}
                
                <div className='opciones d-flex justify-content-end'>
                    <div>
                        <Button 
                        variant="outlined" 
                        className='mr-3' 
                        disabled={loading || enviado}
                        onClick={() => history.push("/eventos/"+ param.id +"/nuevoTicket")}
                        >
                        {t("button.cancel")}
                        </Button>
                    </div>
                    <div>
                        <Button 
                        variant="contained" 
                        onClick={guardarTickets}
                        disabled={loading || enviado} 
                        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                        >
                        {loading ? 'Enviando...' : t("excelImport.create")}
                        </Button>
                    </div>
                </div>
            </div>
        </Container>
    );
};

