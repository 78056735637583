import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import { api } from "../../services/api";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import "moment/locale/es";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useMediaQuery } from "react-responsive";
import { MensajeFinalizarCreacionEvento } from "../../components/modales/MensajeFinalizarCreacionEvento";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableChartIcon from '@mui/icons-material/TableChart';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Select,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
  InputAdornment, 
  TextField,
  Divider,
  CircularProgress,
  Chip, 
  Avatar,
  Drawer,
  Box, 
  Fab, 
  Pagination
} from '@mui/material';
import { toast } from "react-toastify";
moment.locale('es');
moment.locale('en-GB');

export const Evento = () => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const { t } = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [filtroEventos, setFiltroEventos] = useState("Todo");
  const [eventosFiltrados, setEventosFiltrados] = useState([]);
  const [open, setOpen] = useState(false);
  const [eventoId, setEventoId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElID, setAnchorElID] = useState(null)
  const [expanded, setExpanded] = useState(false);
  const [openDrawer , setOpenDrawer ] = React.useState(false);
  const [totalLength, setTotalLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);


  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
      if (!newOpen) {
        setFiltroEventos("Todo");
        setQuery("");
    }
  };

  const notify = (type, message, position = "bottom-center") => {
    const options = {
      position,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    switch (type) {
      case 'success':
        toast.success(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'info':
        toast.info(message, options);
        break;
      case 'warning':
        toast.warning(message, options);
        break;
      default:
        toast(message, options);
    }
  }

  const handleOpen = (id) => {
    setEventoId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setEventoId(null);
    setOpen(false);
  };

  const handleClickSeeMore = (e) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    setAnchorElID(e.currentTarget.id)
  }

  const handleCloseSeeMore = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
    setAnchorElID(null)
  }

  const openSeeMore = Boolean(anchorEl);
  const ITEM_HEIGHT = 48

  const HandleDownloadReport = (e, evento) => {
    e.stopPropagation()

    const callApi = async (e) => {

      try {
        const response = await api.reporte.downloadReporte(evento.id)
        if (!response) {
          notify("error", "Error al realizar la descarga")
          throw new Error('Error al descargar el Excel')
        }
        const blob = await response;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte-${evento.id}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        setAnchorElID(null)

        notify("success", "La descarga se realizó con éxito")
      } catch (error) {
        console.error('Error al descargar el Excel:', error.message);
        throw error;
      }
    }
    callApi()
  }

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);

      try {
        const response = await api.eventos.getAll(query, pageSize, currentPage);

        if (response.status === "success") {
          setData(response.data.eventos);
          setTotalLength(response.data.totalDataSize);
        }
      } catch (error) {
        toast.error("Error en la red.", {
          position: "top-center",
          autoClose: 4000,
        });
      } finally {
        setLoading(false);
      }
    };

    callApi();
  }, [query, pageSize, currentPage]);

  useEffect(() => {
    let filtro = data;

    if (filtroEventos !== "Todo") {
      filtro = filtro?.filter((evento) =>
        evento?.estado?.estado === filtroEventos
      )
    }

    setEventosFiltrados(filtro);
  }, [filtroEventos, data]);

  const handleRowClick = (evento) => {
    history.push("/eventos/" + evento.id);
  };


  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(e.target.q.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const colorCirculo = (estado) => {
    switch (estado) {
      case "Borrador":
        return { relleno: "#FFF2B4", texto: "#827229" };
      case "Activo":
        return { relleno: "#B7FFCC", texto: "#216735" }; 
      case "Finalizado":
        return { relleno: "#DCDBDD", texto: "#363536" };
      case "Eliminado":
        return { relleno: "#FFB4B4", texto: "#8C2828" }; 
      default:
        return { relleno: "transparent", texto: "transparent" };
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); 
  };

  const renderEvento = (evento) => (
    <div key={evento.id} style={{ marginBottom: '15px' }} 
      onClick={/* evento.estado.id === 1 ? () => handleOpen(evento.id)
        : */ (evento.estado.id === 3 || evento.estado.id === 4)
        ? null
        : () => handleRowClick(evento)}
    >
      <div className="w-100">
        <div className="d-flex align-items-center gap-1 mb-2">
          <p className="m-0" style={{ fontSize: '18px' }}>
            {evento.evento}
          </p>
          {evento.privado
                                ?
                                <span style={{color: '#aeaeae'}}>
                                  <LockIcon style={{fontSize: '.8rem'}} />
                                  <small>{t("event.private")}</small>
                                </span>
                                :
                                <span style={{color: '#aeaeae'}}>
                                  <LockOpenIcon style={{fontSize: '.8rem'}} />
                                  <small>{t("event.public")}</small>
                                </span>
                              }
          <IconButton
            aria-label="see more"
            id={evento.id}
            aria-controls={openSeeMore ? 'long-menu' : undefined}
            aria-expanded={openSeeMore ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClickSeeMore}
            sx={{ p: 0 }}
            className="ms-auto"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={evento.id}
            aria-label="report"
            anchorEl={anchorEl}
            open={evento.id == anchorEl?.id}
            onClose={handleCloseSeeMore}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={(e) => HandleDownloadReport(e, evento)}>
              <ListItemIcon>
                <TableChartIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Reporte</ListItemText>
            </MenuItem>
          </Menu>
        </div>
        <div className="d-flex gap-3">
          <Chip label={evento.estado?.estado} size="small" 
            sx={{
              background: colorCirculo(evento.estado.estado).relleno,
              color: colorCirculo(evento.estado.estado).texto, 
            }}
          />
          <Chip size="small" sx={{ background: '#DADAFF', color: '#3D2EAB' }}
            avatar={<Avatar sx={{ backgroundColor: '#988EDE' }}><span style={{ color: '#3D2EAB' }}>R</span></Avatar>} 
            label={evento.rol.replace('_', ' ')} 
          />
        </div>
      </div>
      <Divider sx={{ marginTop: '15px', bgcolor: 'gray' }} />
    </div>
  );


  return (
    <>
      {isMobile ? (
        <Container>
          <div className="py-2">
            <h1 style={{ color: '#0B0062' }}>
              {t("navbar.myEvents")}
            </h1>
            <p>{t("event.controlEvent")}</p>
          </div>
          <div className="d-flex gap-3 mb-4" style={{ width: '100%' }}>
            <TextField
              size="small"
              placeholder="Buscar evento"
              fullWidth
              onClick={toggleDrawer(true)} 
              slotProps={{
                input: {
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Select
              id="filtro-eventos"
              IconComponent={() => null}
              value={filtroEventos}
              onChange={(e) => setFiltroEventos(e.target.value)}
              renderValue={() => (
                <IconButton sx={{padding: "0px"}}>
                  <FilterAltOutlinedIcon size="small" sx={{padding: "0px"}}/>
                </IconButton>
              )}
              sx={{ height: '2.5rem', width: "2.5rem", display: "flex", justifyContent: "center", alignItems: "center", outline: 'none', boxShadow: 'none', }}
            >
              <MenuItem value="Todo">Todos</MenuItem>
              <MenuItem value="Borrador">Borrador</MenuItem>
              <MenuItem value="Activo">Activos</MenuItem>
              <MenuItem value="Eliminado">Eliminados</MenuItem>
              <MenuItem value="Finalizado">Finalizados</MenuItem>
            </Select>
          </div>
          <Drawer open={openDrawer} onClose={toggleDrawer(false)} anchor="bottom" >
            <Box sx={{ width: "100%", height: "100vh", p: 2, overflow: 'auto'}}>
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="mb-0">Mis Eventos</h2>
                <KeyboardBackspaceIcon onClick={toggleDrawer(false)}/>
              </div>
              <form onSubmit={handleSearch} className="mt-2 mb-4">
                <TextField
                  id="exampleSearch"
                  name="q"
                  size="small"
                  placeholder="Buscar evento"
                  type="search"
                  fullWidth
                  onClick={toggleDrawer(true)} 
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </form>
              {data?.length > 0 && query !== "" ? (
                <>
                  <small>Resultados</small>
                  {data.map(renderEvento)}
                </>
              ) : (
                <p>Resultados: 0</p>
              )}
            </Box>
          </Drawer>
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            eventosFiltrados?.length > 0 ? (
              eventosFiltrados.map(renderEvento)
            ) : (
              <Alert severity="info" color="secondary">
                {filtroEventos !== "Todo"
                  ? `Aún no tienes eventos ${filtroEventos.toLowerCase()}s.`
                  : "Aún no tienes eventos."
                }
              </Alert>
            )
          )}
          <div
            style={{ 
              position: 'fixed',
              bottom: 20,    
              right: 20,
              zIndex: 1000
            }}
          >
            <Fab aria-label="add"
              onClick={() => history.push("/crearEvento/creacion")}
              sx={{background: "#5656FF", color: "#fff"}}
            >
              <AddIcon />
            </Fab>
          </div>
          <MensajeFinalizarCreacionEvento open={open} handleClose={handleClose} eventoId={eventoId} />
        </Container>
      ) : (
        <Container className="my-3" maxWidth="lg">
          <div className="py-2">
            <h1 style={{ color: '#0B0062' }}>
              {t("navbar.myEvents")}
            </h1>
            <p>{t("event.controlEvent")}</p>
          </div>

          <div className="d-flex justify-content-between gap-3 mb-4">
            <div className="d-flex gap-3">
              <form onSubmit={handleSearch}>
                <TextField
                  id="exampleSearch"
                  name="q"
                  size="small"
                  placeholder="Buscar evento"
                  type="search"
                  fullWidth
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </form>

              <Select
                id="filtro-eventos"
                IconComponent={() => null}
                value={filtroEventos}
                onChange={(e) => setFiltroEventos(e.target.value)}
                renderValue={() => (
                  <IconButton sx={{padding: "0px"}}>
                    <FilterAltOutlinedIcon size="small" sx={{padding: "0px"}}/>
                  </IconButton>
                )}
                sx={{ height: '2.5rem', width: "2.5rem", display: "flex", justifyContent: "center", alignItems: "center", outline: 'none', boxShadow: 'none', }}
              >
                <MenuItem value="Todo">Todos</MenuItem>
                <MenuItem value="Borrador">Borrador</MenuItem>
                <MenuItem value="Activo">Activos</MenuItem>
                <MenuItem value="Eliminado">Eliminados</MenuItem>
                <MenuItem value="Finalizado">Finalizados</MenuItem>
              </Select>
            </div>

            <Button variant="contained" startIcon={<AddIcon/>}
              sx={{ backgroundColor: '#5656FF', color: '#fff' }}
              onClick={() => history.push("/crearEvento/creacion")}
            >
              Crear evento
            </Button>
          </div>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#EDEDED" }}>
                <TableRow>
                  <TableCell>Evento</TableCell>
                  <TableCell align="center">Estado</TableCell>
                  <TableCell align="center">Fecha</TableCell>
                  <TableCell align="center">Rol</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  eventosFiltrados?.length > 0 ? (
                    eventosFiltrados.map((evento) => (
                      <TableRow
                        hover
                        key={evento.id}
                        style={{
                          cursor: (evento.estado.id === 1 || evento.estado.id === 2)
                            ? 'pointer'
                            : 'inherit'
                        }}
                        onClick={/* evento.estado.id === 1
                          ? () => handleOpen(evento.id)
                          : */ (evento.estado.id === 3 || evento.estado.id === 4)
                            ? null
                            : () => handleRowClick(evento)
                        }
                      >
                        <TableCell scope="row" className="pl-2 ">
                          <div className="d-flex gap-3">
                            <div className="d-flex align-items-center gap-2">
                              <OpenInNewIcon fontSize="small" sx={{color: "gray"}}/>
                              <p className="m-0" style={{fontSize: "18px"}}>
                                {evento.evento}
                              </p>
                              {evento.privado
                                ?
                                <span style={{color: '#aeaeae'}}>
                                  <LockIcon style={{fontSize: '.8rem'}} />
                                  <small>{t("event.private")}</small>
                                </span>
                                :
                                <span style={{color: '#aeaeae'}}>
                                  <LockOpenIcon style={{fontSize: '.8rem'}} />
                                  <small>{t("event.public")}</small>
                                </span>
                              }
                            </div>
                          </div>
                        </TableCell>
                        <TableCell width={60}>
                        <Chip label={evento.estado?.estado} size="small" 
                                sx={{
                                  background: colorCirculo(evento.estado.estado).relleno,
                                  color: colorCirculo(evento.estado.estado).texto, 
                                }}
                              />
                        </TableCell>
                        <TableCell align="center" width={60}>
                          <div className="d-flex align-items-center gap-1">
                            <CalendarMonthOutlinedIcon/>
                            <p style={{marginBottom: "0px", fontSize: "14px"}}>{moment(evento.fechas[0].fecha).clone().local().format("DD/MM/YYYY")}</p>
                          </div>
                        </TableCell>
                        <TableCell align="center" width={100}>
                          <Chip size="small" sx={{background: "#DADAFF", color: "#3D2EAB"}}
                            avatar={<Avatar sx={{backgroundColor: "#988EDE"}}><span style={{ color: "#3D2EAB" }}>R</span></Avatar>} 
                            label={evento.rol.replace('_', ' ')} 
                          />
                        </TableCell>
                        <TableCell scope="row" align="center" width={30}>
                          <IconButton
                            aria-label="see more"
                            id={evento.id}
                            aria-controls={openSeeMore ? 'long-menu' : undefined}
                            aria-expanded={openSeeMore ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClickSeeMore}
                            sx={{color: "black"}}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={evento.id}
                            aria-label="report"
                            anchorEl={anchorEl}
                            open={evento.id == anchorElID}
                            onClose={handleCloseSeeMore}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <MenuItem onClick={(e) => HandleDownloadReport(e, evento)}>
                              <ListItemIcon>
                                <TableChartIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Reporte</ListItemText>
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Alert severity="info" color="secondary">
                          {filtroEventos !== "Todo"
                            ? `Aún no tienes eventos ${filtroEventos.toLowerCase()}s.`
                            : "Aún no tienes eventos."
                          }
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )
                )}
                <MensajeFinalizarCreacionEvento open={open} handleClose={handleClose} eventoId={eventoId} />
              </TableBody>
            </Table>
          </TableContainer>
          {!loading &&  eventosFiltrados?.length > 0 && (
            <div style={{ display: "flex", alignItems: "center", marginTop: "1rem", position: "relative" }}>
              <small>{t("publications.totalEntries")} {totalLength}</small>
              <div style={{ margin: "0 auto" }}>
                <Pagination
                  count={Math.ceil(totalLength / pageSize)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            </div>
          )}
        </Container>
      )}
    </>
  );
};
export default withAuthenticationRequired(Evento, {
  onRedirecting: () => <Loading />,
});