import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const RouteTitleManager = () => {
  const location = useLocation();
  const {id} = useParams();

  useEffect(() => {
    const pathname = location.pathname;

    if (id) {
      //EN ALGUNAS RUTAS EL "TITLE" FUE DEFINIDO DENTRO DEL COMPONENTE.
      if (pathname.match(new RegExp(`/eventos/${id}/publicaciones`))) {
        document.title = `Publicaciones | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/tickets`))) {
        document.title = `Tickets | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/inscripcion`))) {
        document.title = `Inscripción al Evento | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/ventas`))) {
        document.title = `Ventas | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/ventas/${id}/recibo`))) {
        document.title = `Recibo | PassGo`;
      } else if (pathname.match(new RegExp(`/compra/${id}`))) {
        document.title = `Compra | PassGo`;
      } else if (pathname.match(new RegExp(`/compra/${id}/recibo`))) {
        document.title = `Recibo | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/qrtickets`))) {
        document.title = `QR Ticket | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/qrvouchers`))) {
        document.title = `QR Vouckers | PassGo`;
      } else if (pathname.match(new RegExp(`/evento/${id}/informacion`))) {
        document.title = `Información del envento | PassGo`;
      } else if (pathname.match(new RegExp(`/publicaciones${id}/editar`))) {
        document.title = `Editar publicación | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}`))) {
        document.title = `Mi evento | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/owners`))) {
        document.title = `Mi evento | PassGo`;
      } else if (pathname.inlcudes(new RegExp(`/eventos/${id}/colaboradores`))) {
        document.title = `Colaboradores | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/clientes`))) {
        document.title = `Clientes | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/ubicaciones`))) {
        document.title = `Ubicaciones | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/tarifas`))) {
        document.title = `Tarifas | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/crearTarifa`))) {
        document.title = `Crear Tarifa | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/nuevoTicket`))) {
        document.title = `Nuevo Ticket | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/nuevoTicket/importarExcel`))) {
        document.title = `Importar Excel | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/cuentas`))) {
        document.title = `Cuentas | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/ventas`))) {
        document.title = `Ventas | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/tickets`))) {
        document.title = `Tickets | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/publicaciones/nueva`))) {
        document.title = `Crear Publicación | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/vouchers`))) {
        document.title = `Vouchers | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/NuevoVoucher`))) {
        document.title = `Nuevo voucher | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/NuevoVoucher/importarExcel`))) {
        document.title = `Importar Excel | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/nuevoTicket/recepcion`))) {
        document.title = `Recepción ticket | PassGo`;
      } else if (pathname.match(new RegExp(`/eventos/${id}/tickets&vouchers`))) {
        document.title = `Vales | PassGo`;
      } else {
        document.title = `Evento | PassGo`;
      }
    } else {
      switch (location.pathname) {
        //PUBLICAS
        case "/":
          document.title = "Inicio | PassGo";
          break;
        case "/eventos":
          document.title = "Mis Eventos | PassGo";
          break;
        case "/profile":
          document.title = "Perfil | PassGo";
          break;
        case "/ayuda":
          document.title = "Ayuda | PassGo";
          break;
          case "/notificaciones":
            document.title = "Notificaciones | PassGo";
            break;
          case "/ecuentro/comprar":
            document.title = "Comprar | PassGo";
            break;
          case "/precios":
            document.title = "Precios | PassGo";
            break;
            case "/payment":
              document.title = "Pagos | PassGo";
              break;
            case "/unauthorized":
              document.title = "No autorizado | PassGo";
              break;
            case "/carrito":
                document.title = "Carrito | PassGo";
                break;
            case "/favoritos":
                document.title = "Favoritos | PassGo";
                break;
            case "/crearEvento/creacion":
                  document.title = "Creación de evento | PassGo";
                  break;
             case "/invitacion":
                  document.title = "Invitación | PassGo";
                  break;
        default:
          document.title = "PassGo";
      }
    }
    
  
  }, [id, location.pathname]);

  return null;
};

export default RouteTitleManager;

