import React from "react";
import { Table, Alert, Button } from "reactstrap";
import { GoCheckCircle } from "react-icons/go";
import { MdOutlineCancel } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';

const Invitaciones = ({ invitaciones, handleAccept, handleReject, responseMessage }) => {

  const isMobile = useMediaQuery({ query: '(max-width: 770px)' });
  const [t] = useTranslation("global");

  return (
    <div className="container">
      {isMobile ? (
        <div>
          <h3 className="mt-2">{t("invitations.title")}</h3>
          {responseMessage && <Alert color="info">{responseMessage}</Alert>}
          {invitaciones?.length > 0 ? (
            <div className="container">
              <Table className="mb-0 table">
                <thead>
                  <tr>
                    <th className="align-middle">{t("invitations.event")}</th>
                    <th className="align-middle">{t("invitations.role")}</th>
                    <th className="align-middle">{t("invitations.status")}</th>
                    <th className="align-middle">{t("invitations.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {invitaciones?.map(invitacion => (
                    <tr key={invitacion.id}>
                      <td className="p-2 align-middle">{invitacion.evento.evento}</td>
                      <td className="p-2 align-middle">{invitacion.tipo === 'Colaborador' ? t("invitations.collaborator") : t("invitations.client")}</td>
                      <td className="p-2 align-middle">{invitacion.estado.estado === 'Pendiente' ? t("invitations.pending") : t("invitations.completed")}</td>
                      <td className="p-2 align-middle">
                        <Button className="btn btn-outline-success btn-sm m-1"
                          onClick={() => handleAccept(invitacion.id, invitacion.tipo)}
                          style={{ background: 'transparent' }}
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(40, 167, 69, 0.85)'}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                          <GoCheckCircle />
                        </Button>
                        <Button className="btn btn-outline-danger btn-sm m-1"
                          onClick={() => handleReject(invitacion.id, invitacion.tipo)}
                          style={{ background: 'transparent' }}
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(220, 53, 69, 0.95)'}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                          <MdOutlineCancel />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <Alert color="secondary">
              {t("invitations.nopendinginvitations")}
            </Alert>
          )}
        </div>
      ) : (
        <div>
          <h3 className="ml-4 my-2">{t("invitations.title")}</h3>
          {responseMessage && <Alert color="info">{responseMessage}</Alert>}
          {invitaciones?.length > 0 ? (
            <div className="container">
              <Table className="mb-0 table">
                <thead>
                  <tr>
                    <th className="align-middle">{t("invitations.event")}</th>
                    <th className="align-middle">{t("invitations.role")}</th>
                    <th className="align-middle">{t("invitations.status")}</th>
                    <th className="align-middle">{t("invitations.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {invitaciones?.map(invitacion => (
                    <tr key={invitacion.id}>
                      <td className="p-2 align-middle">{invitacion.evento.evento}</td>
                      <td className="p-2 align-middle">{invitacion.tipo === 'Colaborador' ? t("invitations.collaborator") : t("invitations.client")}</td>
                      <td className="p-2 align-middle">{invitacion.estado.estado === 'Pendiente' ? t("invitations.pending") : t("invitations.completed")}</td>
                      <td className="p-2 align-middle">
                        <Button className="btn btn-outline-success btn-sm m-1"
                          onClick={() => handleAccept(invitacion.id, invitacion.tipo)}
                          style={{ background: 'transparent' }}
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(40, 167, 69, 0.85)'}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                          <GoCheckCircle />
                        </Button>
                        <Button className="btn btn-outline-danger btn-sm m-1"
                          onClick={() => handleReject(invitacion.id, invitacion.tipo)}
                          style={{ background: 'transparent' }}
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(220, 53, 69, 0.95)'}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                          <MdOutlineCancel />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <Alert color="secondary">
              {t("invitations.nopendinginvitations")}
            </Alert>
          )}
        </div>
      )}

    </div>
  );
};

export default Invitaciones;