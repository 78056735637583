import { useState, useEffect } from 'react'
import * as XLSX from 'xlsx';
import { useParams, useHistory } from "react-router-dom";
import { api } from '../../services/api';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { Container, Badge, Alert, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, CircularProgress } from '@mui/material';
import { InsertDriveFileOutlined, SaveAltOutlined, Check, DeleteOutlineOutlined} from '@mui/icons-material';

export const ExcelImport = () => {
  const [typeError, setTypeError] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const param = useParams();
  const history = useHistory();
  const [categorias, setCategorias] = useState();
  const [ubicaciones, setUbicaciones] = useState();
  const [tarifas, setTarifas] = useState();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [enviado, setEnviado] = useState(false);
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
  /* const excelTickets = "/Tickets.xlsx" */
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const notify = (type, message, position = "bottom-center") => {
    const options = {
      position,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    switch (type) {
      case 'success':
        toast.success(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'info':
        toast.info(message, options);
        break;
      case 'warning':
        toast.warning(message, options);
        break;
      default:
        toast(message, options);
    }
  }


  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        let totalCategorias = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';

        while (hasMoreTickets) {
          const response = await api.categorias.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const categorias = response.data.categorias;

            if (categorias && categorias.length > 0) {
              totalCategorias = [...totalCategorias, ...categorias];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
          setCategorias(totalCategorias)
        }
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
    }
    fetchCategorias();
    document.title = `Importar Excel | PassGo`;
  }, [param.id]);


  useEffect(() => {
    const fetchUbicaciones = async () => {
      try {
        let totalUbicaciones = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';

        while (hasMoreTickets) {
          const response = await api.locations.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const ubicaciones = response.data.ubicaciones;

            if (ubicaciones && ubicaciones.length > 0) {
              totalUbicaciones = [...totalUbicaciones, ...ubicaciones];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
          setUbicaciones(totalUbicaciones)
        }
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        })
      }
    }
    fetchUbicaciones();
  }, [param.id])

  useEffect(() => {
    const fetchTarifa = async () => {
      try {
        let totalTarifas = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';

        while (hasMoreTickets) {
          const response = await api.tarifas.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const tarifas = response.data.tarifas;

            if (tarifas && tarifas.length > 0) {
              totalTarifas = [...totalTarifas, ...tarifas];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
          setTarifas(totalTarifas)
        }
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
    }
    fetchTarifa();
  }, [param.id]);
  const entradasTarifas = tarifas?.filter((tarifa) => tarifa.idtipo === 1);

  const procesarArchivoExcel = (e) => {
    const archivo = e.target.files[0];
    const filtro = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (archivo) {
      if (archivo && filtro.includes(archivo.type)) {
        setTypeError(null);
        setFileName(archivo.name);
        leerArchivoExcel(archivo);
      }
      else {
        setTypeError('Por favor, asegúrese de que el archivo seleccionado sea de tipo Excel(.xlsx o .xls.)')
        setFileName('');
      }
    }
  }

  const validaryGuardar = () => {
    setLoading(true);
    if (!excelData) {
      setTypeError('Por favor, cargue un archivo Excel válido antes de enviar.');
      setLoading(false);
      return;
    }

    let errores = [];
    let valido = true;

    excelData.forEach((fila, index) => {
      // busco las columnas obligatorias
      const nombreKey = Object.keys(fila).find(key =>
        key.toLowerCase() === 'nombre comprador*'.toLowerCase());
      const apellidoKey = Object.keys(fila).find(key =>
        key.toLowerCase() === 'apellido comprador*'.toLowerCase());

      if (!nombreKey || !fila[nombreKey] || fila[nombreKey].trim() === '') {
        errores.push(`Fila ${index + 1}: El nombre del comprador es obligatorio`);
        valido = false;
      }

      if (!apellidoKey || !fila[apellidoKey] || fila[apellidoKey].trim() === '') {
        errores.push(`Fila ${index + 1}: El apellido del comprador es obligatorio`);
        valido = false;
      }

      const nombreRegex = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
      if (nombreKey && fila[nombreKey] && !nombreRegex.test(fila[nombreKey].trim())) {
        errores.push(`Fila ${index + 1}: El nombre del comprador contiene caracteres no válidos`);
        valido = false;
      }

      if (apellidoKey && fila[apellidoKey] && !nombreRegex.test(fila[apellidoKey].trim())) {
        errores.push(`Fila ${index + 1}: El apellido del comprador contiene caracteres no válidos`);
        valido = false;
      }
    });


    if (!valido) {
      setTypeError(`Los datos del archivo Excel contienen errores:\n${errores.join('\n')}`);
      setLoading(false);
      return;
    }
    guardarTickets();
  };

  const guardarTickets = async () => {
    try {
      setLoading(true);

      //creo el libro del excel
      const wb = XLSX.utils.book_new();

      // agrupo la info por tarifa
      const datosPorTarifa = excelData.reduce((acc, row) => {
        if (!row.nametarifa) {
          return acc;
        }

        const sheetName = `Tarifa_${row.nametarifa}`;

        if (!acc[sheetName]) {
          acc[sheetName] = [];
        }

        const rowData = {
          'nombre': row.nombre,
          'apellido': row.apellido
        };

        Object.keys(row).forEach(key => {
          if (key !== 'nombre' && key !== 'apellido') {
            rowData[key] = row[key];
          }
        });

        acc[sheetName].push(rowData);
        return acc;
      }, {});

      if (Object.keys(datosPorTarifa).length === 0) {
        throw new Error('No hay datos válidos para procesar');
      }

      // una hoja por tarifa
      Object.entries(datosPorTarifa).forEach(([sheetName, data]) => {
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      });

      // convierto a blob
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      // creo el archivo y lo envío 
      const file = new File([blob], 'tickets.xlsx', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const formData = new FormData();
      formData.append('file', file);

      const response = await api.tickets.createExcelTickets(param.id, formData);

      if (response.status === 'success') {
        setEnviado(true);
        cleanFile();
        notify('success', 'Tickets creados exitosamente');
        setTimeout(() => {
          history.push(`/eventos/${param.id}`);
        }, 2000);
      } else {
        notify('error', response.message || 'Error al crear los tickets');
      }
    } catch (error) {
      console.error('Error al intentar guardar los tickets:', error);
      notify('error', error.message || 'Error al intentar guardar los tickets');
    } finally {
      setLoading(false);
    }
  };

  const leerArchivoExcel = (archivo) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });

        //combino las hojas
        let allData = [];
        workbook.SheetNames.forEach(sheetName => {
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);

          if (jsonData && jsonData.length > 0) {
            const tarifaName = sheetName.replace('Tarifa_', '');
            const sheetData = jsonData.map(row => ({
              ...row,
              nametarifa: tarifaName
            }));
            allData = [...allData, ...sheetData];
          }
        });

        if (!allData || allData.length === 0) {
          setTypeError('El archivo Excel está vacío o no tiene el formato correcto');
          setFileName('');
          return;
        }

        const cleanedJson = allData.map(row => {
          const cleanedRow = {};
          for (const key in row) {
            // Convertir a minúsculas y eliminar espacios
            const normalizedKey = key.toLowerCase().trim();
            let value = String(row[key]).replace(/\t/g, '').trim();

            // Manejar tipos específicos
            switch (normalizedKey) {
              case 'nombre':
              case 'apellido':
              case 'nametarifa':
                cleanedRow[normalizedKey] = value;
                break;
              case 'idevento':
                cleanedRow[normalizedKey] = parseInt(value, 10);
                break;
              case 'cantidadtarifa':
                cleanedRow[normalizedKey] = Number(value);
                break;
              case 'importe':
                cleanedRow[normalizedKey] = parseFloat(value);
                break;
              default:
                cleanedRow[normalizedKey] = value;
            }
          }
          return cleanedRow;
        });

        setExcelData(cleanedJson);
      } catch (error) {
        console.error('Error al procesar el Excel:', error);
        setTypeError('Error al procesar el archivo Excel: ' + error.message);
        setFileName('');
      }
    };

    reader.onerror = (error) => {
      console.error('Error al leer el archivo:', error);
      setTypeError('Error al leer el archivo Excel');
      setFileName('');
    };

    reader.readAsBinaryString(archivo);
  };

  const cleanFile = () => {
    setFileName('');
    setTypeError(null);
    setExcelData(null);
  };

  //descargar la plantilla del excel
  const handleDownloadExcel = async () => {
    setLoadingDownload(true);
    try {
      const response = await api.tickets.getExcelTickets(param.id);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `plantilla_tickets_${param.id}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error al descargar el archivo Excel:', error);
    } finally {
      setLoadingDownload(false);
    }
  };

  return (
    <>
      <Container>
        <h5>{t("excelImport.format")}</h5>
        <div style={{ display: isMobile ? 'block' : 'flex', justifyContent: "end" }}>
          <Button variant="contained" color="success" type="button" onClick={handleDownloadExcel} className='d-flex justify-content-center align-items-center gap-2'>
            {loadingDownload ? (
              <>
                <CircularProgress size={24} />
                Descargando plantilla...
              </>
            ) : (
              <>
                <SaveAltOutlined sx={{ color: "white" }} />
                Descargar plantilla
              </>
            )}
          </Button>
        </div>

        {!isMobile && (
          <TableContainer component={Paper} sx={{ marginBottom: "15px", marginTop: "15px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>nombre</TableCell>
                  <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>apellido</TableCell>
                  <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>nameubicacion</TableCell>
                  <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>nametarifa</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">({t("excelImport.example")} Maria)</TableCell>
                  <TableCell align="center">({t("excelImport.example")} Fabbro)</TableCell>
                  <TableCell align="center">({t("excelImport.example")} Mesa 01)</TableCell>
                  <TableCell align="center">({t("excelImport.example")} General)</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <div style={{ display: "flex", gap: "20px", flexDirection: isMobile ? 'column' : 'row' }}>
          <div className='especificaciones mt-4'>
            <h4>{t("excelImport.specifications")}</h4>
            <li>{t("excelImport.extension")}</li>
            <li>{t("excelImport.sp3")}</li>
            <li>{t("excelImport.sp4")}</li>
            <li>{t("excelImport.sp5")}</li>
          </div>
          <div className='formulario-ingreso mt-4'>
            <label
              htmlFor="exampleFile"
              style={{
                width: "350px",
                height: "270px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "10px",
                boxShadow: "10px 10px 30px rgba(0,32,61,.3098039216)",
                padding: isMobile ? "15px" : "25px",
                cursor: loading || enviado ? "not-allowed" : "pointer",
                opacity: loading || enviado ? 0.5 : 1,
                pointerEvents: loading || enviado ? "none" : "auto",
                margin: "0px auto 20px"
              }}
            >
              <h4 className='fw-medium mb-3'>{t("excelImport.uploadFile")}</h4>
              <div style={{
                position: "relative",
                border: "2px dashed #e1e2e6",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                padding: "10px",
                borderRadius: "10px",
              }}>
                {!fileName ? (
                  <>
                    <img
                      src={process.env.PUBLIC_URL + '/subir-excel.png'}
                      alt="Fiesta"
                      style={{ width: '70px', height: 'auto' }}
                    />
                    <p>Cargar archivo</p>
                  </>
                ) : (
                  <>
                    <Badge
                      badgeContent={<DeleteOutlineOutlined
                        sx={{ fontSize: 15 }} />}
                      color="error"
                      onClick={!loading || !enviado ? cleanFile : null}
                    >
                      <InsertDriveFileOutlined sx={{ fontSize: 40 }} />
                    </Badge>
                    <span>{fileName}</span>
                  </>
                )}
              </div>
              <input
                id="exampleFile"
                name="file"
                type="file"
                accept=".xlsx,.xls"
                hidden
                onChange={!loading || !enviado ? procesarArchivoExcel : null}
              />
            </label>
          </div>
        </div>

        {typeError &&
          <Alert severity="error" className='mb-3'>{typeError}</Alert>
        }
        {enviado && (
          <Alert icon={<Check fontSize="inherit" />} severity="success" className="mb-3">{t("excelImport.succesCreated")}</Alert>
        )}

        <div className='opciones d-flex justify-content-end'>
          <div>
            <Button
              variant="outlined"
              className='mr-3'
              disabled={loading || enviado}
              onClick={() => history.push("/eventos/" + param.id + "/nuevoTicket")}
            >
              {t("button.cancel")}
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={validaryGuardar}
              disabled={loading || enviado}
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {loading ? 'Enviando...' : t("excelImport.create")}
            </Button>
          </div>
        </div>
      </Container>
    </>
  )
}
