import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography, FormControl, CircularProgress, Button, Select, InputLabel, MenuItem, Stack, TextField, InputAdornment, OutlinedInput } from "@mui/material";
import { api } from "../../../services/api";
import { getCantidadByTarifa } from "./api";
import { showErrorToastify } from "../../../utils";

import useImprimir from "./useImprimir";
import useQuery from "../../../hooks/useQuery";
import queryString from "query-string";

const ImprimirTickets = ({ onClose, filtroTarifas, tarifasId, idevento, tipo = "tickets" }) => {
  const [selectedTarifaId, setSelectedTarifaId] = useState(null);
  const [cantidadItems, setCantidadItems] = useState(0);
  const [tarifas, setTarifas] = useState([])
  const [ides, setIdes] = useState([]);
  const [numeroDePaso, setNumeroDePaso] = useState(0)
  const { fetch, archivo, loading, onFormChange, onFormDimensionesChange, onTarifaChange, filter } = useImprimir();
  const [formErrors, setFormErrors] = useState({
    cantidad_fila: false,
    alto: false,
    ancho: false,
    margen: false,
  });

  const tarifaContador = useQuery({
    autoFetch: false,
    queryFn: getCantidadByTarifa,
    onSuccess: res => {
      setCantidadItems(res.data?.totalDataSize);
      setIdes(res.data?.ides);
    },
    onError: err => showErrorToastify({ err }),
  })

  const handleTarifaChange = async (event) => {
    const tarifaId = parseInt(event.target.value);
    setSelectedTarifaId(event.target.value);
    onTarifaChange(tarifaId);


    const query = queryString.stringify({
      desde: filter.desde,
      hasta: filter.hasta,
      nombre: filter.nombre
    });

    tarifaContador.refetch(idevento, tarifaId, query);
  };

  useEffect(() => {
    if (selectedTarifaId) {
      const query = queryString.stringify({
        desde: filter.desde,
        hasta: filter.hasta,
        nombre: filter.nombre
      });

      tarifaContador.refetch(idevento, selectedTarifaId, query);
    }
  }, [filter])

  const HandleDownload = () => {
    const alto = document.getElementById('alto').value;
    const ancho = document.getElementById('ancho').value;
    const cantidad_fila = document.getElementById('cantidad_fila').value;
    const margen = document.getElementById('margen').value;
  
    const newErrors = {
      alto: !alto,
      ancho: !ancho,
      cantidad_fila: !cantidad_fila,
      margen: !margen
    };
  
    setFormErrors(newErrors);
  
    if (!alto || !ancho || !cantidad_fila || !margen) {
      return;
    }
    
    fetch();
  }

  useEffect(() => {

    if (archivo) {
      const blob = archivo;
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `impresion-${idevento}-${selectedTarifaId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      onClose();
    }

  }, [archivo])

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await api.tarifas.getAll(idevento)
        if (response.status === "error") {
          throw Error("Error al obtener las tarifas del evento");
        }
        if (tipo === "tickets") {
          const tarifas = response.data.tarifas.slice()
          const tarifasTickets = tarifas.filter(tarifa => parseInt(tarifa.idtipo) === 1)
          setTarifas(tarifasTickets);
        }
        if (tipo === "vouchers") {
          const tarifas = response.data.tarifas.slice()
          const tarifasVouchers = tarifas.filter(tarifa => parseInt(tarifa.idtipo) === 2)
          setTarifas(tarifasVouchers);
        }
      } catch (error) {
        console.log(error.message);
      } finally {

      }
    }
    fetch()
  }, [])

  const handleFormChange = (e) => {
    const { name, value } = e.target
    onFormChange({ [name]: value })
  };

  const handleDimensionFormChange = (e) => {
    const { name, value } = e.target;
    setFormErrors({
      ...formErrors,
      [name]: value.trim() === ''
    });
    onFormDimensionesChange({ [name]: value });
  };

  const handleNextStep = (e) => {
    if (!selectedTarifaId) {
      return showErrorToastify({ err: "Seleccione una tarifa para continuar" })
    }
    setNumeroDePaso(1)
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "0rem 2rem 1rem 2rem"
        }
      }}
    >
      <DialogTitle
      // sx={{ paddingTop: "0.5rem" }}
      >
        Impresión de {tipo === "tickets" ? "tickets" : "vouchers"}
      </DialogTitle>
      {numeroDePaso === 0 &&
        <Stack
          direction="column"
          sx={{ gap: "1rem" }}
        >
          <FormControl
            onChange={handleFormChange}
            sx={{ gap: "1rem" }}
            size="small"
          >
            <Box
              component="fieldset"
              sx={{ width: "100%" }}
            >
              <InputLabel id="tarifa">Tarifa</InputLabel>
              <Select
                labelId="tarifa"
                label="Tarifa"
                onChange={handleTarifaChange}
                fullWidth
                required
                value={selectedTarifaId}
              >
                {(!loading || tarifas) &&
                  tarifas?.map(tarifa => (
                    <MenuItem
                      key={tarifa.id}
                      value={tarifa.id}
                      id={tarifa.id}
                    >
                      {tarifa.concepto}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>
            <Stack
              direction="row"
              sx={{ width: "100%", gap: "1rem" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  name="desde"
                  label="Desde"
                  autoComplete="off"
                />
                {/* <InputLabel id="tarifa">Tarifa</InputLabel>
              <Select
                labelId="desde"
                label="desde"
                // onChange={handleTarifaChange}
                // fullWidth
                // required
                // value={selectedTarifaId}
              >
                {(!loading || ides) &&
                  ides?.map((tarifa, i) => (
                    <MenuItem
                      key={i}
                      value={ides}
                      id={ides}
                    >
                      {ides}
                    </MenuItem>
                  ))
                }
              </Select> */}
              </FormControl>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  name="hasta"
                  label="Hasta"
                  autoComplete="off"
                />
              </FormControl>
            </Stack>
            <Box>
              <TextField size="small"
                name="nombre"
                label="Nombre"
                autoComplete="off"
                fullWidth
              />
            </Box>
            <Box>
              <Typography
                sx={{ marginLeft: "1rem" }}
              >
                Cantidad de {tipo === "tickets" ? "tickets" : "vouchers"} a imprimir: {cantidadItems ? cantidadItems : 0}
              </Typography>
            </Box>
            <Stack
              direction="row-reverse"
              sx={{ gap: "1rem" }}
            >
              <Button
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleNextStep}
                variant="contained"
                color="primary"
                style={{
                  fontFamily: "Inter",
                  fontWeight: "600",
                  borderRadius: "4px",
                  padding: "7px 15px",
                }}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : ''}
                disabled={loading}
              >
                Continuar
              </Button>
            </Stack>
          </FormControl>
        </Stack>
      }
      {numeroDePaso === 1 &&
        <Stack
          direction="column"
          sx={{ gap: "1rem" }}
        >
          <FormControl
            size="small"
            onChange={handleDimensionFormChange}
            sx={{ gap: "1rem" }}
          >
            <Box>
              <Typography
                // sx={{ marginLeft: "1rem" }}
                variant="body1"
              >
                Hoja:
              </Typography>
              <Stack
                direction="row"
                sx={{ width: "100%", gap: "1rem", marginTop: "0.5rem" }}
              >
                <FormControl fullWidth>
                  <InputLabel htmlFor="ancho" size="small">
                    Ancho
                  </InputLabel>
                  <OutlinedInput
                    variant="outlined"
                    size="small"
                    id="ancho"
                    autoComplete="off"
                    label="Ancho"
                    name="ancho"
                    required
                    error={formErrors.ancho}
                    helperText={formErrors.ancho ? "Este campo es obligatorio" : ""}
                    onChange={handleDimensionFormChange}
                    endAdornment={
                      <InputAdornment position="start">
                        mm
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel htmlFor="Alto" size="small">
                    Alto
                  </InputLabel>
                  <OutlinedInput
                    size="small"
                    variant="outlined"
                    id="alto"
                    label="Alto"
                    name="alto"
                    autoComplete="off"
                    required
                    error={formErrors.alto}
                    helperText={formErrors.alto ? "Este campo es obligatorio" : ""}
                    onChange={handleDimensionFormChange}
                    endAdornment={
                      <InputAdornment position="start">
                        mm
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Stack>
              <Typography
                // sx={{ marginLeft: "1rem" }}
                variant="caption"
              >
                Configuración de las dimensiones de la pagina
              </Typography>
            </Box>
            <Box>
              <Typography
                // sx={{ marginLeft: "1rem" }}
                variant="body1"
              >
                {tipo === "tickets" ? "Tickets:" : "Vouchers:"}
              </Typography>
              <Stack
                direction="row"
                sx={{ width: "100%", gap: "1rem", marginTop: "0.5rem" }}
              >
                <FormControl fullWidth>
                  <InputLabel htmlFor="cantidad_fila" size="small">
                    Tarjeta por fila
                  </InputLabel>
                  <OutlinedInput
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    id="cantidad_fila"
                    label="Tarjetas por fila"
                    name="cantidad_fila"
                    required
                    error={formErrors.cantidad_fila}
                    helperText={formErrors.cantidad_fila ? "Este campo es obligatorio" : ""}
                    onChange={handleDimensionFormChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel htmlFor="margen" size="small">
                    Margen de separación
                  </InputLabel>
                  <OutlinedInput
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    id="margen"
                    label="Margen de separación"
                    name="margen"
                    required
                    error={formErrors.margen}
                    helperText={formErrors.margen ? "Este campo es obligatorio" : ""}
                    onChange={handleDimensionFormChange}
                    endAdornment={
                      <InputAdornment position="start">
                        mm
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Stack>
              <Typography
                // sx={{ marginLeft: "1rem" }}
                variant="caption"
              >
                Disposicion de los {"tickets" ? "tickets" : "vouchers"} en la pagina
              </Typography>
            </Box>
            <Stack
              direction="row-reverse"
              sx={{ gap: "1rem" }}
            >
              <Button
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                onClick={HandleDownload}
                variant="contained"
                color="primary"
                style={{
                  fontFamily: "Inter",
                  fontWeight: "600",
                  borderRadius: "4px",
                  padding: "7px 15px",
                }}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : ''}
                disabled={loading}
              >
                Imprimir {tipo === "tickets" ? "tickets" : "vouchers"}
              </Button>
            </Stack>
          </FormControl>
        </Stack>
      }
    </Dialog>
  )
}

export default ImprimirTickets;