import { 
  Button, 
  TextField, 
  FormControlLabel, 
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput,
  IconButton
} from '@mui/material';
import moment from 'moment-timezone';
import { useEffect, useState } from "react"
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap"
import { api } from "../../../services/api";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { TarifaDetalles } from "../../../components/modales/TarifaDetalles"
import { toast } from 'react-toastify';
import { useMediaQuery } from "react-responsive";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import useGetFechasEvento from '../../../hooks/useGetFechasEvento';
import DeleteIcon from '@mui/icons-material/Delete';

export const SegundoPaso = ({ eventId: propEventId }) => {
  const location = useLocation();
  const { eventId: stateEventId } = location.state || {};
  const eventId = stateEventId || propEventId;
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [tarifasTipo, setTarifasTipo] = useState([]);
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 610px)` });
  const [errorDate, setErrorDate] = useState(false);
  const { fechasEvento } = useGetFechasEvento(eventId);
  const initForm = {
    idevento: eventId,
    idmoneda: 1,
    concepto: '',
    precio: 0,
    idtipo: '',
    limite_persona: 10,
    limite_maximo: 100,
    asumir_comision: false,
    inicio_venta_fecha: "",
    inicio_venta_hora: "",
    fin_venta_fecha: "",
    fin_venta_hora: "",
    privado: false,
    fechas: [],
    informacion_adicional: false
  }
  const [formData, setFormData] = useState(initForm);

  const [formErrors, setFormErrors] = useState({
    concepto: false,
    //precio: false,
    limite_persona: false,
    limite_maximo: false,
  });

  const callTipoTarifas = async () => {
    try {
      const response = await api.tarifas.GetAllTarifasTipos();
      if (response.status === "success") {
        setTarifasTipo(response.data.tarifasTipo);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    callTipoTarifas();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "limite_persona" || name === "precio" || name === "limite_maximo") {
      if (/^\d*\.?\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } 
    else if (name === "fechas") {
      setFormData({
        ...formData,
        [name]: [...new Set(value)],  // Usamos `new Set` para asegurarnos de que no haya valores duplicados
      });
    }
    else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name !== "fechas" && typeof value === "string" && value.trim() !== "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,  
      }));
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.privado) {
      if (!formData.inicio_venta_fecha || !formData.inicio_venta_hora || !formData.fin_venta_fecha || !formData.fin_venta_hora) {
        setErrorDate(true);
        return;
      }
    }

    let inicioVenta = null;
    let finVenta = null;
    if (!formData.privado) {
      inicioVenta = new Date(`${formData.inicio_venta_fecha}T${formData.inicio_venta_hora}`);
      finVenta = new Date(`${formData.fin_venta_fecha}T${formData.fin_venta_hora}`);
    }
   /*  console.log(formData.idtipo); */

    const errors = {
      concepto: formData.concepto === '',
      //precio: selectedOption === 'de pago' ? !formData.precio : false,
      limite_persona: formData.limite_persona === '',
      limite_maximo: formData.limite_maximo === '',
      fechas: formData.fechas?.length === 0,
    };
    setFormErrors(errors);

    const isValid = Object.values(errors).every(error => !error);

    const newTarifa = {
      ...formData,
      idevento: parseInt(eventId),
      precio: parseFloat(formData.precio),
      idtipo: parseInt(formData.idtipo),
      limite_persona: parseInt(formData.limite_persona),
      limite_maximo: parseInt(formData.limite_maximo),
      fechas: formData.fechas?.map(fechaId => ({ id: fechaId })),
      ...(inicioVenta && { inicio_venta: inicioVenta.toISOString() }),
      ...(finVenta && { fin_venta: finVenta.toISOString() }),
      informacion_adicional: formData.informacion_adicional,
    }

    if (isValid) {
      try {
        const response = await api.tarifas.createTarifa(newTarifa);
        if (response.status === "success") {
          const tarifaId = response.data?.tarifa.id;
          if (tarifaId && preguntas.length > 0 && !preguntas.some(pregunta => pregunta.informacion_adicional?.trim() === "")) {
            handleGuardarPreguntas(tarifaId);
          }
          setShowOffcanvas(!showOffcanvas);
          setFormData(initForm)
          toast.success("Tarifa creada correctamente.", {
            position: "top-right",
            autoClose: 2000,
          });
        }
        else {
          throw new Error(response.message);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 4000,
        });
      } 
    }
  }

  const handleChangeVisivilidad = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value === "true", 
    });
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      asumir_comision: checked
    }));
  }

  const toggleOffcanvas = (opcion) => {
    if (opcionSeleccionada === opcion) {
      setShowOffcanvas(!showOffcanvas);
    } else {
      setShowOffcanvas(true);
    }
    setOpcionSeleccionada(opcion);
    setFormErrors(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      idtipo: opcion === 'tickets' ? 1 : 2
    }));
  };

  const closeOffcanvas = () => {
    setShowOffcanvas(false);
  };

    const [checked, setChecked] = useState(false);
    const [preguntas, setPreguntas] = useState([{
        informacion_adicional: "",
        id_tarifa: 0, 
        id_evento: eventId, 
      }]);

    useEffect(() => {
        if (opcionSeleccionada != 1) {
          setPreguntas([{informacion_adicional: "",
            id_tarifa: 0,  
            id_evento: parseInt(eventId) }]); 
          setChecked(false); 
          setFormData((prevFormData) => ({
            ...prevFormData,
            informacion_adicional: false,
          }));
        }
      }, [opcionSeleccionada]);
  
    const handleCheckboxChange2 = (event) => {
      const isChecked = event.target.checked;
      setChecked(isChecked);
      setFormData((prevData) => ({
        ...prevData,
        informacion_adicional: isChecked,
      }));
  
      if (isChecked && preguntas.length === 0) {
        setPreguntas([{ id_tarifa: 0, id_evento: eventId, informacion_adicional: "" }]);
      } else if (!isChecked) {
        setPreguntas(preguntas.map(pregunta => ({
          ...pregunta,
          informacion_adicional: "",
        })));
      }
    };
  
    const agregarPregunta = () => {
      setPreguntas([
        ...preguntas,
        { id_tarifa: 0, id_evento: eventId, informacion_adicional: "" }
      ]);
    };
  
    const handlePreguntaChange = (index, value) => {
      if (preguntas.length === 0) return;
    
      const nuevasPreguntas = [...preguntas];
      if (index >= 0 && index < nuevasPreguntas.length) {
        nuevasPreguntas[index].informacion_adicional = value;
        setPreguntas(nuevasPreguntas);
      }
    };
  
    //elimina una pregunta solo si hay mas de una
    const eliminarPregunta = (index) => {
      if (preguntas.length > 1) {
        setPreguntas(preguntas.filter((_, i) => i !== index));
      }
    };

    const handleGuardarPreguntas = async (tarifaId) => {
      const preguntasFiltradas = preguntas.filter(pregunta => pregunta.informacion_adicional.trim() !== "");
    
      if (preguntasFiltradas.length === 0) return;
    
      const dataToSend = preguntasFiltradas.map(pregunta => ({
        id_tarifa: tarifaId,
        id_evento: eventId,
        informacion_adicional: pregunta.informacion_adicional
      }));
      
      try {
        
      
          const response = await api.infoAdicional.createPreguntas(eventId, dataToSend);
    
          if (response.status !== "success") {
            throw new Error(response.message);
          }
        
    
        setChecked(false); // Opcional: desmarcar checkbox después de guardar
        setPreguntas([]); // Limpiar preguntas después de guardar
    
        toast.success("Preguntas guardadas correctamente.", {
          position: "top-right",
          autoClose: 2000,
        });
    
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    };
    
  
   /*  console.log(preguntas) */

  return (
    <>
      <div style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "30px"
      }}>
        <div style={{
          width: "270px",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #D9D9D9",

        }}>
          <div style={{
            width: "65px",
            height: "65px",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ede8fd"
          }}
            className='rounded-4'>
            <ConfirmationNumberOutlinedIcon style={{
              fontSize: "35px",
              color: "#5c3acb"
            }} />
          </div>
          <div>
            <h5>Tickets</h5>
            <p style={{
              fontSize: "13px",
              marginBottom: "5px"
            }}>Administra el acceso a tu evento creando y personalizando tickets. Define el nombre, precio, cantidad disponible y límite de compra para cada tipo de ticket.</p>
          </div>
          <div style={{
            display: "flex",
            justifyContent: "end"
          }}>
            <AddCircleOutlineOutlinedIcon
              color="action"
              onClick={() => toggleOffcanvas("tickets")}
              style={{ cursor: "pointer", fontSize: "30px" }} />
          </div>
        </div>
        <div style={{
          width: "270px",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #D9D9D9"
        }}>
          <div style={{
            width: "65px",
            height: "65px",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#def2ff"
          }}
            className='rounded-4'>
            <FastfoodOutlinedIcon style={{
              fontSize: "35px",
              color: "#249ae6"
            }} />
          </div>
          <div>
            <h5>Consumiciones</h5>
            <p style={{
              fontSize: "13px",
              marginBottom: "5px"
            }}>Gestiona las consumiciones para tu evento. Crea opciones de comida y bebida, estableciendo nombre, precio, cantidad disponible y límites de compra.</p>
          </div>
          <div style={{
            display: "flex",
            justifyContent: "end"
          }}>
            <AddCircleOutlineOutlinedIcon
              color="action"
              onClick={() => toggleOffcanvas("Consumicion")}
              style={{ cursor: "pointer", fontSize: "30px" }} />
          </div>
        </div>

        <Offcanvas
          backdrop={false}
          direction="end"
          isOpen={showOffcanvas}
          toggle={toggleOffcanvas}
          style={{
            width: isMobile && "100%"
          }}
        >
          <OffcanvasHeader toggle={closeOffcanvas}>
            {opcionSeleccionada === 'tickets' ? (
              "Crear tickets"
            ) : (
              "Crear consumiciones"
            )}
          </OffcanvasHeader>
          <OffcanvasBody>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth>
                <InputLabel id="privado-label">Visibilidad</InputLabel>
                <Select
                  labelId="privado-label"
                  id="privado"
                  name="privado"
                  value={formData.privado.toString()} 
                  label="Privacidad"
                  onChange={handleChangeVisivilidad}
                >
                  <MenuItem value="true">Privado</MenuItem>
                  <MenuItem value="false">Público</MenuItem>
                </Select>
                <FormHelperText>
                  {formData.privado === false ? 
                      "Las tarifas públicas serán visibles para el público y estarán disponibles para la venta." 
                    : (
                      "Las tarifas privadas no se mostrarán al público ni estarán disponibles para la venta."
                    )}
                </FormHelperText>
              </FormControl>
              <TextField
                label={t("rates.concept")}
                variant="outlined"
                fullWidth
                name="concepto"
                onChange={handleChange}
                margin="normal"
                error={formErrors.concepto}
                helperText={formErrors.concepto && 'Este campo es obligatorio'}
              />
              <TextField 
              label={t("rates.price")} 
              variant="outlined"
              fullWidth
              name="precio" 
              onChange={handleChange}
              value={formData.precio || ''}
              margin="normal"
              helperText={formErrors.precio && 'Este campo es obligatorio'}
            />
              {formData.precio > 0 && (
                <FormControlLabel
                  control={<Checkbox checked={formData.asumir_comision} />}
                  label={t("tickets.assumeTheCosts")}
                  name="asumirCostos"
                  onChange={handleCheckboxChange}
                />
              )}
              {formData.privado === false && (
                <>
                  <div style={{ width: "100%", display: "flex", gap: "10px", marginTop: "15px" }}>
                    <TextField
                      label="Inicio de Venta - Fecha"
                      type="date"
                      name="inicio_venta_fecha"
                      value={formData.inicio_venta_fecha}
                      onChange={handleChange}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      // required
                      fullWidth
                    />
                    <TextField
                      label="Inicio de Venta - Hora"
                      type="time"
                      name="inicio_venta_hora"
                      value={formData.inicio_venta_hora}
                      onChange={handleChange}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      // required
                      fullWidth
                    />
                  </div>
                  {/* <p style={{ margin: "0px", color: "#555", fontSize: "12px" }}>
                    Las tarifas se publicarán a la venta el día y hora seleccionados.
                  </p> */}
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    <TextField
                      label="Fin de Venta - Fecha"
                      type="date"
                      name="fin_venta_fecha"
                      value={formData.fin_venta_fecha}
                      onChange={handleChange}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      margin="normal"
                      // required
                      fullWidth
                    />
                    <TextField
                      label="Fin de Venta - Hora"
                      type="time"
                      name="fin_venta_hora"
                      value={formData.fin_venta_hora}
                      onChange={handleChange}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      margin="normal"
                      // required
                      fullWidth
                    />
                  </div>
                </>
              )}
              <FormControl fullWidth margin="normal">
                <InputLabel>Fechas del evento</InputLabel>
                <Select
                  multiple
                  name="fechas"
                  onChange={handleChange}
                  value={formData.fechas || []}
                  input={<OutlinedInput label="Fechas del evento" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const selectedOption = fechasEvento.find((opt) => opt.id === value);
                        return <Chip key={value} label={moment(selectedOption?.fecha).clone().local().format("D [de] MMMM")} />;
                      })}
                    </Box>
                  )}
                >
                  {fechasEvento?.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >
                      <Checkbox checked={formData.fechas?.includes(item.id)} />
                      {moment(item.fecha).clone().local().format("D [de] MMMM Y")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Límite de compra por pedido"
                variant="outlined"
                fullWidth
                name="limite_persona"
                onChange={handleChange}
                value={formData.limite_persona}
                margin="normal"
                error={!!formErrors.limite_persona}
                helperText={formErrors.limite_persona && 'Este campo es obligatorio'}
              />

              <TextField
                label="Limite de tarifas"
                variant="outlined"
                fullWidth
                name="limite_maximo"
                onChange={handleChange}
                value={formData.limite_maximo}
                margin="normal"
                error={formErrors.limite_maximo}
                helperText={formErrors.limite_maximo && 'Este campo es obligatorio'}
              />
              {opcionSeleccionada === 'tickets' && (
                <div>
                  <FormControlLabel
                    control={<Checkbox checked={formData.informacion_adicional} onChange={handleCheckboxChange2}/>}
                    label="Solicitar información adicional"
                  />
                  {formData.informacion_adicional && (
                    <div mt={2}>
                      {preguntas.map((pregunta, index) => (
                        <Box key={index} display="flex" alignItems="center" gap={1} mb={1}>
                          <TextField
                            label="Pregunta"
                            value={pregunta.informacion_adicional}
                            onChange={(e) => handlePreguntaChange(index, e.target.value)}
                            fullWidth
                            size="small"
                          />
                          {preguntas.length > 1 && (
                            <IconButton 
                              aria-label="delete" 
                              color="error"
                              onClick={() => eliminarPregunta(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            
                          )}
                        </Box>
                      ))}
                      <div style={{display: 'flex', justifyContent: 'end', marginBottom: '15px'}}>
                        <Button 
                          variant="text" 
                          style={{padding: '0px'}} 
                          onClick={agregarPregunta}
                          disabled={preguntas[preguntas.length - 1]?.informacion_adicional?.trim() === ""}
                        >
                          Agregar +
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <Button
                type="submit"
                variant="contained"
                style={{ margin: "5px" }}
              >
                {t("button.create")}
              </Button>
            </form>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <div style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop:"20px"
      }}>
        <div style={{
          width: "770px",
          padding: "10px",
          borderRadius: "15px",
          border: "none",
        }}>
          <p className='mb-0 text-center font-italic'>Si tu evento es público, las tarifas estarán disponibles una vez vincules tu cartera.</p>
        </div>
      </div>
    </>
  )
}