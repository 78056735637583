import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { api } from "../services/api";
import "./Profile.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Compras from "../components/perfil/Compras";
import PerfilFacturacion from "../components/perfil/perfil_facturacion";
import Perfil from "../components/perfil/perfil_informacion";
import Invitaciones from "../components/perfil/perfil_invitaciones";
import { useLocation } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";

const ProfileComponent = () => {
  const { user } = useAuth0();
  const [participaciones, setParticipaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [invitaciones, setInvitaciones] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [usuario, setUsuario] = useState();
  const [botonPerfil, setBotonPerfil] = useState(1);
  const [t] = useTranslation("global");
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    telefono: "",
    direccion: "",
    ciudad: "",
    provincia: "",
    cp: "",
    pais: "",
  });

  const isMobile = useMediaQuery({ query: "(max-width: 770px)" });
  const location = useLocation();
  const { view } = location.state || {};

  useEffect(() => {
    if (view) {
      //CODIGO
      if (isMobile) {
        switch (view) {
          case "facturacion":
            setBotonPerfil(5);
            break;
          case "invitaciones":
            setBotonPerfil(2);
            break;
          case "compras":
            setBotonPerfil(4);
            break;
          default:
            setBotonPerfil(1);
        }
      } else {
        switch (view) {
          case "facturacion":
            setBotonPerfil(5);
            break;
          case "invitaciones":
            setBotonPerfil(2);
            break;
          case "compras":
            setBotonPerfil(4);
            break;
          default:
            setBotonPerfil(1);
        }
      }
    }
  }, [view]);

  const callApi = async () => {
    try {
      const response = await api.usuarios.getUsuario();
      if (response.status === "success") {
        setUsuario(response.data.usuario);
        const invitacionesCliente =
          response.data.invitaciones.invitacionesCliente?.map((invitacion) => ({
            ...invitacion,
            tipo: "Cliente",
          })) ?? [];
        const invitacionesColaborador =
          response.data.invitaciones.invitacionesColaborador?.map(
            (invitacion) => ({
              ...invitacion,
              tipo: "Colaborador",
            })
          ) ?? [];
          const invitacionesOwner = response.data.invitaciones.invitacionesOwner?.map(invitacion => ({
            ...invitacion,
            tipo: 'Owner'
        })) ?? [];

        const todasInvitaciones = [...invitacionesCliente, ...invitacionesColaborador, ...invitacionesOwner];

        setParticipaciones(response.data.usuario.participaciones);
        setInvitaciones(todasInvitaciones);
      }
    } catch (error) {
      setError(error.message || "Error al cargar el usuario");
    }
    setLoading(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    if (usuario) {
      setFormData({
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        telefono: usuario.telefono,
        direccion: usuario.direccion,
        ciudad: usuario.ciudad,
        provincia: usuario.provincia,
        cp: usuario.cp,
        pais: usuario.pais,
      });
    }
  }, [usuario]);

  const updateMyEvents = async () => {
    try {
      await callApi();
    } catch (error) {
      setError(error.message || "Error al actualizar los eventos");
    }
  };

  const handleAccept = async (id, tipo) => {
    const data = {
        id: id,
        idestado: 2,
    };

    const handlers = {
        Cliente: api.invitaciones.setInvitacionCliente,
        Colaborador: api.invitaciones.setInvitacionColaborador,
        Owner: api.invitaciones.setInvitacionOwner,
    };

    try {

        const handler = handlers[tipo];
        const response = await handler(id, data);

        if (response.status === "success") {
            setResponseMessage('Invitación aceptada.');
            await updateMyEvents();
        } else {
            setResponseMessage('Error al procesar la solicitud.');
        }
       } catch (err) {
    setResponseMessage('Error al procesar la solicitud.');
    }
};
     

const handleReject = async (id, tipo) => {
const confirmReject = window.confirm("¿Estás seguro de que deseas rechazar esta invitación?");

if (!confirmReject) return;

const data = {
    id: id,
    idestado: 3,
};

const handlers = {
    Cliente: api.invitaciones.setInvitacionCliente,
    Colaborador: api.invitaciones.setInvitacionColaborador,
    Owner: api.invitaciones.setInvitacionOwner,
};

try {
    const handler = handlers[tipo];

    const response = await handler(id, data);

    if (response.status === "success") {
        setResponseMessage('Invitación rechazada.');
        await updateMyEvents();
    } else {
        setResponseMessage('Error al procesar la solicitud.');
    }
} catch (err) {
    setResponseMessage('Error al procesar la solicitud.');
}
};

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  return (
    <div className="container-fluid">
      <div className="row align-items-start">
        {isMobile ? (
          <>
            <div className="d-flex flex-column align-items-center w-100 max-w-md mx-auto bg-white">
              <div className="pt-4 pb-2 ">
                <img
                  className="rounded-circle object-cover"
                  style={{ width: "80px", height: "80px" }}
                  alt={user.name + " avatar"}
                  src={user.picture}
                />
              </div>
              <div className="col-8 align-items-center justify-content-center">
                <h2 className="text-center text-capitalize mt-1 mb-4 fs-2 font-bold"> 
                  {usuario && usuario.nombre && usuario.apellido
                    ? `${usuario.nombre} ${usuario.apellido}`
                    : `${user?.email}`}
                </h2>
              </div>
            </div>
            <div className="d-flex w-100 justify-content-between overflow-x-auto scroll-contenedor-menu mb-2">
              <button
                type="button"
                className={`flex-1 py-2 px-4 text-center ${
                  botonPerfil === 1 ? "btn-activo-mobile" : ""
                }`}
                style={{
                  borderBottom: botonPerfil === 1 ? "2px solid black" : "none",
                }}
                onClick={() => setBotonPerfil(1)}
              >
                {t("profile.profile")}
              </button>
              <button
                type="button"
                className={`position-relative flex-1 py-2 px-4 text-center ${
                  botonPerfil === 2 ? "btn-activo-mobile" : ""
                }`}
                style={{
                  borderBottom: botonPerfil === 2 ? "2px solid black" : "none",
                }}
                onClick={() => setBotonPerfil(2)}
              >
                {t("profile.invitations")}
                {invitaciones?.length > 0 && (
                  <span
                    className="position-absolute p-1 bg-danger border border-light rounded-circle"
                    style={{ fontSize: "0.5rem" }}
                  />
                )}
              </button>
              <button
                type="button"
                className={`flex-1 py-2 px-4 text-center ${
                  botonPerfil === 4 ? "btn-activo-mobile" : ""
                }`}
                style={{
                  borderBottom: botonPerfil === 4 ? "2px solid black" : "none",
                }}
                onClick={() => setBotonPerfil(4)}
              >
                Mis compras
              </button>
              <button
                type="button"
                className={`flex-1 py-2 px-4 text-center ${
                  botonPerfil === 5 ? "btn-activo-mobile" : ""
                }`}
                style={{
                  borderBottom: botonPerfil === 5 ? "2px solid black" : "none",
                }}
                onClick={() => setBotonPerfil(5)}
              >
                {t("profile.billing")}
              </button>
            </div>

            <div className="col-12">
              {botonPerfil === 1 && <Perfil usuario={usuario} />}
              {botonPerfil === 2 && (
                <Invitaciones
                  invitaciones={invitaciones}
                  handleAccept={handleAccept}
                  handleReject={handleReject}
                  responseMessage={responseMessage}
                />
              )}
{/*               {botonPerfil === 3 && (
                <Eventos
                  participaciones={participaciones}
                  invitaciones={invitaciones}
                  handleAccept={handleAccept}
                  handleReject={handleReject}
                  responseMessage={responseMessage}
                />
              )} */}
              {botonPerfil === 4 && <Compras />}
              {botonPerfil === 5 && <PerfilFacturacion usuario={usuario} />}
            </div>
          </>
        ) : (
          <>
            <div className="col-3">
              <div className="container">
                <div className="row">
                  <div className="col-3">
                    <img
                      className="rounded-circle img-fluid profile-picture mt-3 mb-3"
                      alt={user.name + " avatar"}
                      src={user.picture}
                    />
                  </div>
                  <div className="col-9 d-flex align-items-center">
                    <h2 className="text-start fs-5 text-capitalize mt-3 mb-3 romper-texto nombre-usuario">
                      {usuario && usuario.nombre && usuario.apellido
                        ? `${usuario.nombre} ${usuario.apellido}`
                        : `${user?.email}`}
                    </h2>
                  </div>

                  <button
                    type="button"
                    className={` mt-2 mb-2 btn d-flex align-items-center text-start ${
                      botonPerfil === 1
                        ? "btn-activo-profile"
                        : "btn-transparente"
                    }`}
                    onClick={() => setBotonPerfil(1)}
                  >
                    <span className="mr-2">
                      <AccountCircleOutlinedIcon />
                    </span>
                    {t("profile.profile")}
                  </button>

                  <button
                    type="button"
                    className={` mt-2 mb-2 btn d-flex align-items-center text-start ${
                      botonPerfil === 2
                        ? "btn-activo-profile"
                        : "btn-transparente"
                    }`}
                    onClick={() => setBotonPerfil(2)}
                  >
                    <span className="mr-2">
                      <EmailOutlinedIcon />
                    </span>
                    {t("profile.invitations")}
                  </button>

                  <button
                    type="button"
                    className={` mt-2 mb-2 btn d-flex align-items-center text-start ${
                      botonPerfil === 4
                        ? "btn-activo-profile"
                        : "btn-transparente"
                    }`}
                    onClick={() => setBotonPerfil(4)}
                  >
                    <span className="mr-2">
                      <ShoppingCartOutlinedIcon />
                    </span>
                    Mis compras
                  </button>

                  <button
                    type="button"
                    className={` mt-2 mb-2 btn d-flex align-items-center text-start ${
                      botonPerfil === 5
                        ? "btn-activo-profile"
                        : "btn-transparente"
                    }`}
                    onClick={() => setBotonPerfil(5)}
                  >
                    <span className="mr-2">
                      <ReceiptLongOutlinedIcon />
                    </span>
                    {t("profile.billing")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-9">
              {botonPerfil === 1 && <Perfil usuario={usuario} />}
              {botonPerfil === 2 && (
                <Invitaciones
                  invitaciones={invitaciones}
                  handleAccept={handleAccept}
                  handleReject={handleReject}
                  responseMessage={responseMessage}
                />
              )}
{/*               {botonPerfil === 3 && (
                <Eventos
                  participaciones={participaciones}
                  invitaciones={invitaciones}
                  handleAccept={handleAccept}
                  handleReject={handleReject}
                  responseMessage={responseMessage}
                />
              )} */}
              {botonPerfil === 4 && <Compras />}
              {botonPerfil === 5 && <PerfilFacturacion usuario={usuario} />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
