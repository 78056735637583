import { useEffect } from "react";
import { api } from "../services/api";
import { showErrorToastify } from "../utils";
import useQuery from "./useQuery";

const useGetFechasEvento = (eventoId) => {
  const { data, refetch } = useQuery({
    autoFetch: false,
    queryFn: api.fechas.getAllFechasEvento,
    onError: (err) => showErrorToastify({ err }),
  });

  const fechasEvento = data?.data?.fechas;
  const fechasEventoOptions = fechasEvento?.map((f) => ({
    value: f.id,
    label: f.fecha,
  }));

  useEffect(() => {
    if (!eventoId) return;

    refetch(eventoId);
  }, [eventoId]);

  return {
    fechasEvento,
    fechasEventoOptions,
    fetchFechasEvento: () => refetch(),
  }
}

export default useGetFechasEvento;