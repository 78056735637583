import { config } from "../../../services/config";
import { privateOptions } from "../../../utils";

const apiOrigin = config.URL_API;

export const getTickets = async (idEvento, q="") => {
  const response = await fetch(`${apiOrigin}/eventos/${idEvento}/tickets?${q}`, privateOptions('GET'));
  const res = await response.json();
  return res;
};
///eventos/:id/tickets/:ticket/deshabilitar
export const setDisableTicket = async (idEvento, idTicket) => {
  const response = await fetch(`${apiOrigin}/eventos/${idEvento}/tickets/${idTicket}/deshabilitar`, privateOptions("PUT"));
  const res = await response.json();
  return res;
};