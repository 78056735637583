import React from 'react';
import { useMediaQuery } from "react-responsive";
import { FavoriteBorder, } from '@mui/icons-material';
import { styled } from '@mui/system';
import { Breadcrumbs, Typography, Link } from "@mui/material";
import ShareButton from "../../../../components/shareButton/shareButton";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
    '& .MuiBreadcrumbs-separator': {
        color: 'white',
    },
}));

const HeaderPublic = ({ data }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });

    return (
        <>
            {isMobile ? (
                <div className="header-public-mobile" style={{
                    backgroundImage: data?.attachs?.find(attach => attach.banner && attach.type !== 'video')
                        ? `url(${data?.attachs.find(attach => attach.banner && attach.type !== 'video')?.url})`
                        : 'none'
                }}>
                    {data?.attachs?.find(attach => attach.banner && attach.type === 'video') && (
                        <video
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                zIndex: -1,
                            }}
                            muted
                            preload="auto"
                            loop
                        >
                            <source src={data?.attachs.find(attach => attach.banner && attach.type === 'video')?.url} type="video/mp4" />
                        </video>
                    )}
                    <span className="overlay" />
                    <div className="breadcrumb-public-mobile my-2">
                        <StyledBreadcrumbs aria-label="breadcrumb" separator="›">
                            <Link className="texto-evento" sx={{ color: "white", fontSize: "18px" }} underline="hover" href="/">
                                Inicio
                            </Link>
                            <Link
                                className="texto-evento"
                                underline="hover"
                                sx={{ color: "white", fontSize: "18px" }}
                                href="/"
                            >
                                {data.evento_tipo.evento_tipo}
                            </Link>
                            <Typography className="texto-evento" sx={{ color: "white", fontSize: "18px" }}>
                                {data.evento}
                            </Typography>
                        </StyledBreadcrumbs>
                    </div>
                    <div className="img-header-mobile">
                        {data?.attachs?.find(attach => attach.type === "image") ? (
                            <>
                                <img
                                    src={data?.attachs.find(attach => attach.avatar)?.url}
                                    alt="Imagen del evento"
                                    className="imagen-evento-mobile"
                                />
                            </>
                        ) : (
                            <>
                                <video
                                    src={data?.attachs?.find(attach => attach.avatar)?.url}
                                    alt="Imagen del evento"
                                    className="imagen-evento-mobile"
                                />
                            </>
                        )}

                    </div>
                </div>
            ) : (
                <>

                    <div className="header-public" style={{
                        backgroundImage: data?.attachs?.find(attach => attach.banner && attach.type !== 'video')
                            ? `url(${data?.attachs?.find(attach => attach.banner && attach.type !== 'video')?.url})`
                            : 'none'
                    }}>
                        {data?.attachs?.find(attach => attach.banner && attach.type === 'video') && (
                            <video
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    zIndex: -1,
                                }}
                                muted
                                preload="auto"
                                loop
                            >
                                <source src={data?.attachs?.find(attach => attach.banner && attach.type === 'video')?.url} type="video/mp4" />
                            </video>
                        )}
                        <div className="overlay"></div>
                        <div className="breadcrumb-public">
                            <StyledBreadcrumbs aria-label="breadcrumb" separator="›">
                                <Link className="texto-evento" sx={{ color: "white", fontSize: "16px" }} underline="hover" href="/">
                                    Inicio
                                </Link>
                                <Link
                                    className="texto-evento"
                                    underline="hover"
                                    sx={{ color: "white", fontSize: "16px" }}
                                    href="/"
                                >
                                    {data.evento_tipo.evento_tipo}
                                </Link>
                                <Typography className="texto-evento"
                                    sx={{ color: "white", fontSize: "16px" }}>{data.evento}</Typography>
                            </StyledBreadcrumbs>
                        </div>
                        <div className="contenido-public">
                            <>

                                {data?.attachs?.find(attach => attach.type === "image") ? (
                                    <>
                                        <img
                                            src={data?.attachs.find(attach => attach.avatar)?.url}
                                            alt="Imagen del evento"
                                            className="imagen-evento"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <video
                                            src={data?.attachs?.find(attach => attach.avatar)?.url}
                                            alt="Imagen del evento"
                                            className="imagen-evento"
                                        />
                                    </>
                                )}
                            </>

                            <div className="d-flex justify-content-end align-items-center w-100">
                                <h1 className="titulo-evento texto-evento">{data.evento}</h1>
                                <FavoriteBorder sx={{
                                    color: "white", cursor: "pointer", marginRight: "15px"
                                }} />
                                <ShareButton idEvento={data.id} sx={{ marginRight: "9px" }} />
                            </div>
                        </div>
                    </div>
                </>
            )
            }
        </>
    )
}
export default HeaderPublic;