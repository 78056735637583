import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useAuth0 } from "@auth0/auth0-react";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconButton } from "@mui/material";

const LikeButton = ({ publicacionId, usuario, isLoading, onClickDislike }) => {
  const { isAuthenticated } = useAuth0();
  const [hasLiked, setHasLiked] = useState(false);
  const [state, setState] = useState({
    error: null,
  });

  function checkIfLiked() {
    if (isAuthenticated) {
      const liked = usuario?.usuario?.favoritos?.some(like => like.idpublicacion === publicacionId && like.like === true);
      setHasLiked(liked);
    };
  }

  useEffect(() => {
    checkIfLiked()
  }, [isAuthenticated, publicacionId, usuario, isLoading]);

  const handleLikeToggle = async (e) => {
    if (!usuario) return;

    if (onClickDislike) {
      onClickDislike(e)
    }

    const body = {
      idusuario: usuario?.usuario?.id,
      idpublicacion: publicacionId,
    };

    const action = hasLiked ? eliminarLike : darLike;

    try {
      const response = await action(body);
      if (response.status === "success") {
        setHasLiked(!hasLiked);
      } else {
        setState({ error: `Error al ${hasLiked ? 'eliminar' : 'dar'} like.` });
      }
    } catch (error) {
      console.error(`Error en la red al ${hasLiked ? 'eliminar' : 'dar'} like:`, error);
      setState({ error: "Error en la red." });
    }
  };

  const darLike = async (body) => {
    const { idusuario, idpublicacion } = body;
    return await api.usuarios.AddLike(idusuario, idpublicacion);
  };

  const eliminarLike = async (body) => {
    const { idusuario, idpublicacion } = body;
    return await api.usuarios.RemoveLike(idusuario, idpublicacion);
  };

  return (
    <>
      {isAuthenticated && (
        <IconButton
          id={publicacionId}
          onClick={handleLikeToggle}
          sx={{ color: hasLiked ? 'red' : '#5656FF', padding: "0px" }}
        >
          {hasLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconButton>
      )}
      {state.error && <div className="error-message">{state.error}</div>}
    </>
  );
};

export default LikeButton;
