import React from "react";
import P from "../assets/P.png";

const Loading = () => (

  <div className="contenedor-loading">
    <div className="contenedor-logo">
      <img className="logo-loading" src={`${P}`} alt="logo PassGo" />
      <div className="capa-loading"/>
    </div>
    {/* <span>
      Cargando...
    </span> */}
  </div>
);

export default Loading;
