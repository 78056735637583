import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useAuth0 } from "@auth0/auth0-react";
import { ShoppingCartOutlined, CloseOutlined, InfoOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Drawer, Divider, Tooltip, Zoom, FormControlLabel, Checkbox } from "@mui/material";
import logoMeLi from "../../../../../../assets/logoMeLi.svg";
import SecondDrawer from "../second-drawer/SecondDrawer";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { api } from "../../../../../../services/api";

const FirstDrawerDesktop = ({
  data,
  cantidad,
  setCantidad,
  toggleDrawer,
  openDrawer,
  tarifasSeleccionadas,
}) => {
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [loadingCarrito, setLoading] = useState(false);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const param = useParams();
  const [openSecondDrawer, setOpenSecondDrawer] = useState(false);
  const [dataTarifas, setDataTarifas] = useState([]);

  const initialCantidad = data?.tarifas?.reduce((acc, tarifa) => {
    acc[tarifa.id] = 0;
    return acc;
  }, {});

  useEffect(() => {
    if (data) {
      setCantidad(initialCantidad);
    }
  }, [data]);

  const handlePagoDirecto = async () => {
    if (!isAuthenticated) {
      await loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
      return;
    }

    // si si esta logeado, sigo con el pago
    setLoading(true);

    const idevento = parseInt(param.id);
    const items = Object.entries(cantidad)
      .map(([idtarifa, cantidad]) => {
        const tarifaResponses = Object.entries(dataTarifas)
          .filter(([key]) => key.startsWith(`${idtarifa}-`))
          .map(([, data]) => {

            return Object.entries(data.respuestas || {}).map(([preguntaId, respuesta]) => ({
              id_informacion_adicional: parseInt(preguntaId),
              respuesta: respuesta || "",
            }));
          });

        return {
          idtarifa: parseInt(idtarifa),
          cantidad,
          respuestas: tarifaResponses.flat()
        };
      })
      .filter((item) => item.cantidad > 0);
    /* 
    console.log("ITEMS", items); */
    if (items.length === 0) {
      toast.info("Debes seleccionar al menos una tarifa para continuar.", {
        position: "top-right",
        autoClose: 4000,
      });
      setLoading(false);
      return;
    }

    try {
      const response = await api.carrito.crearCarrito({
        idevento,
        items,
        pago_qr: false, // porque el mismo endpoint de carrito usamos para generar el url de redireccionamiento o el qr para la cantina
      });

      if (response.status === "success") {
        window.location.href = response.data.url_pago;
      } else {
        throw new Error(response.message || "Error al procesar el pago.");
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    } finally {
      setLoading(false);
    }
  };

  const checkTarifasPreguntas = () => {
    const hasQuestions = tarifasSeleccionadas.some(tarifa => {
      return tarifa.adicionales?.some(adicional => {
        return adicional.informacion_adicional.length > 0;
      });
    });
    return hasQuestions;
  };

  const handleButtonClick = () => {
    if (checkTarifasPreguntas()) {
      handleOpenSecondDrawer();
    } else {
      handlePagoDirecto();
    }
  };

  const handleCloseDrawer = () => {
    const hasSelectedTarifas = Object.values(cantidad).some(
      (value) => value > 0
    );

    if (hasSelectedTarifas) {
      const userConfirmed = window.confirm(
        "¿Estás seguro de que quieres cerrar? Se perderán las tarifas seleccionadas."
      );

      if (userConfirmed) {
        setMostrarFormulario(false);
        setOpenSecondDrawer(false);
        setCantidad({});
        toggleDrawer(false)();
      }
    } else {
      setMostrarFormulario(false);
      setCantidad({});
      toggleDrawer(false)();
    }
  };

  /* CALCULOS TARIFAS */
  const calculoSubtotalTarifa = (tarifa) => {
    const cantidadTarifa = cantidad[tarifa.id] || 0;
    return cantidadTarifa * tarifa.precio;
  };

  const subtotal = data?.tarifas?.reduce((acc, tarifa) => {
    return acc + calculoSubtotalTarifa(tarifa);
  }, 0) || 0;

  const comision = data?.tarifas?.reduce((acc, tarifa) => {
    const cantidadTarifa = cantidad[tarifa.id] || 0;
    const precioConComision =
      tarifa?.precio *
      (!tarifa?.asumir_comision
        ? tarifa?.tipo_comision?.porcentaje / 100
        : 0);

    return acc + cantidadTarifa * precioConComision;
  }, 0) || 0;

  const formatCurrency = (value) =>
    value.toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });

  const calcularTotal = () => {
    const total = subtotal + comision;
    return total;
  };

  const hayComision = data?.tarifas?.some((tarifa) => {
    const cantidadTarifa = cantidad[tarifa.id] || 0;
    return cantidadTarifa > 0 && !tarifa?.asumir_comision;
  });

  /* FIN CALCULOS TARIFAS */

  const handleOpenSecondDrawer = () => {
    setOpenSecondDrawer(true);
  };

  const handleBack = () => {
    setOpenSecondDrawer(false);
  };

  const totalFormateado = formatCurrency(calcularTotal());


  //detectar link en la descripcion para terminos legales
  const [tieneLink, setTieneLink] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  function contieneLink(descripcion) {
    if (!descripcion) return false;

    const parser = new DOMParser();
    const doc = parser.parseFromString(descripcion, "text/html");
    const enlaces = doc.getElementsByTagName("a");

    return enlaces.length > 0;
  }

  useEffect(() => {
    const resultado = contieneLink(data?.descripcion);
    setTieneLink(resultado);
    setCheckboxChecked(false);

    /* console.log("¿El texto tiene un link?", resultado); */
  }, [data]);

  const handleCheckboxChangeTerminos = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  return (
    <>
      <Drawer
        sx={{
          minWidth: "80%",
          padding: "40px",
          "& .MuiDrawer-paper": { borderRadius: "10px 10px 0 0", minHeight: "80%" }
        }}
        transitionDuration={500}
        anchor="bottom"
        open={openDrawer}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            handleCloseDrawer();
          }
        }}
      >
        <div style={{ padding: "40px" }}>
          <div
            className="d-flex flex-row justify-content-between"
            style={{}}
          >
            <p
              className="fw-bold mb-3"
              style={{ color: "#221A60", fontSize: "20px" }}
            >
              Resumen de tu compra
            </p>
            <button
              type="button"
              onClick={handleCloseDrawer}
              style={{ paddingRight: "16px" }}
            >
              <CloseOutlined
                sx={{ width: "16px", height: "17px", marginRight: "0px" }}
              />
            </button>
          </div>
          <div className="d-flex flex-row w-100" style={{}}>
            <div className="" style={{ flex: 1, paddingRight: "28px" }}>
              <p
                style={{
                  color: "#221A60",
                  fontSize: "20px",
                  marginBottom: "5px",
                }}
              >
                Tu compra
              </p>
              <div className="d-flex flex-column px-3 pt-4">
                <div className="mb-3">
                  {tarifasSeleccionadas?.length > 0 ? (
                    tarifasSeleccionadas.map((tarifa) => (
                      <div
                        key={tarifa.id}
                        className="d-flex justify-content-between align-items-center py-1 mb-2"
                      >
                        <div className="d-flex flex-column">
                          <span className="">{`${cantidad[tarifa.id]} x ${tarifa.concepto
                            }`}</span>
                        </div>
                        <span className="">
                          ${" "}
                          {calculoSubtotalTarifa(tarifa).toLocaleString(
                            "es-ES",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              useGrouping: true,
                            }
                          )}
                        </span>
                      </div>
                    ))
                  ) : (
                    <p className="mb-0">No hay tarifas seleccionadas</p>
                  )}
                </div>

                {tarifasSeleccionadas?.length > 0 && (
                  <>
                    <Divider
                      variant="middle"
                      sx={{ backgroundColor: "black" }}
                    />
                    <div
                      className={`d-flex flex-column ${hayComision ? "py-4" : " pt-3"
                        }`}
                    >
                      <div className="d-flex flex-row justify-content-between">
                        <p className="mb-3">Subtotal</p>
                        <p className="mb-3">$ {formatCurrency(subtotal)}</p>
                      </div>
                      {hayComision && (
                        <div className="d-flex flex-row justify-content-between">
                          <div className="d-flex flex-row align-items-center">
                            <p className="mb-0">Costo</p>
                            <Tooltip
                              arrow
                              TransitionComponent={Zoom}
                              placement="right"
                              title="El costo de servicio de PassGo no es reembolsable"
                            >
                              <InfoOutlined
                                sx={{
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Tooltip>
                          </div>
                          <p className="mb-0">
                            $ {formatCurrency(comision)}
                          </p>
                        </div>
                      )}
                    </div>
                    <Divider
                      variant="middle"
                      sx={{ backgroundColor: "black" }}
                    />
                    <div className="d-flex flex-row justify-content-between py-4">
                      <p className="mb-3 fw-bold">Total</p>
                      <div className="d-flex flex-column text-right">
                        <p className="mb-0 ">
                          $ {formatCurrency(calcularTotal())}
                        </p>
                        <span
                          className="fw-light"
                          style={{ fontSize: "14px" }}
                        >
                          El precio incluye impuestos
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="" style={{ flex: 1, paddingRight: "8px" }}>
              <p
                style={{
                  color: "#221A60",
                  fontSize: "24px",
                  marginBottom: "5px",
                }}
              >
                Pagar con
              </p>
              <div style={{ width: "100%", textAlign: "left" }}>
                <div
                  className="d-flex justify-content-between align-items-center px-3"
                  style={{
                    border: "1px solid #BDBDBD",
                    borderRadius: "4px",
                  }}
                >
                  <input
                    type="radio"
                    id="mercadopago"
                    name="mercadopago"
                    value="mercadopago"
                    defaultChecked
                  />
                  <label htmlFor="mercadopago" className="mb-0 ml-3">
                    Mercado Pago
                  </label>
                  <img
                    src={logoMeLi}
                    alt="Logo Mercado Libre"
                    style={{
                      width: "45px",
                      height: "45px",
                      marginLeft: "auto",
                    }}
                  />
                </div>
                <div
                  className="d-flex flex-row justify-content-end align-items-center gap-3"
                  style={{ paddingTop: "15px", paddingRight: "10px" }}
                >
                  <p className="mb-0" style={{ fontSize: "14px" }}>
                    {format(
                      new Date(),
                      "E. d 'de' MMM. 'de' u HH:mm 'hs'",
                      {
                        locale: es,
                      }
                    )}
                  </p>
                  <div className="d-flex flex-row align-items-center">
                    <Tooltip
                      arrow
                      TransitionComponent={Zoom}
                      placement="bottom"
                      title="El costo de servicio de PassGo no es reembolsable"
                    >
                      <InfoOutlined
                        sx={{
                          width: "18px",
                          height: "18px",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                    <p
                      className="mb-0"
                      style={{ fontSize: "18px", fontWeight: "500" }}
                    >
                      $ {totalFormateado}
                    </p>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "14px",
                    paddingTop: "8px",
                    paddingBotton: "15px",
                  }}
                  className="mb-3"
                >
                  Al realizar el pago, acepta las{" "}
                  <a href="/">Condiciones de servicio de PassGo</a>
                </p>
              </div>
              {tieneLink && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxChecked}
                      onChange={handleCheckboxChangeTerminos}
                      required
                      size="small"
                    />
                  }
                  label={<small>Confirmo haber leído y acepto los términos y condiciones del organizador del evento</small>}
                />
              )}
              <Button
                className="boton-submit w-100"
                onClick={handleButtonClick}
                disabled={tieneLink ? !checkboxChecked : false}
              >
                {loadingCarrito ? (
                  <CircularProgress size="18px" sx={{ color: "white" }} />
                ) : (
                  <span>
                    <ShoppingCartOutlined
                      sx={{
                        color: "white",
                        width: "18px",
                        height: "18px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    {checkTarifasPreguntas() ? "Continuar con la compra" : "Finalizar compra"}
                  </span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      <SecondDrawer onBack={handleBack} handlePagoDirecto={handlePagoDirecto} dataTarifas={dataTarifas} setDataTarifas={setDataTarifas} loadingCarrito={loadingCarrito} cantidad={cantidad} open={openSecondDrawer} onClose={handleCloseDrawer} setOpenSecondDrawer={setOpenSecondDrawer} tarifasSeleccionadas={tarifasSeleccionadas} />
    </>
  );
};
export default FirstDrawerDesktop;
