import { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({children}) => {
    const [userRole, setUserRole] = useState(null);

    return(
        <UserContext.Provider value={{userRole, setUserRole}}>
            {children}
        </UserContext.Provider>
    )
}