import { config } from "../../../services/config";
import { privateOptions } from "../../../utils";

const apiOrigin = config.URL_API;

export const getTicket = async (idEvento, idTicket) => {
  const response = await fetch(`${apiOrigin}/eventos/tickets/${idTicket}`, privateOptions('GET'));
  const res = await response.json();
  return res;
};

export const updateTicketImage = async (id, idTicket) => {
  const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/updateTicket/${idTicket}`, privateOptions('PUT'));
  const res = await response.json();
  return res;
};