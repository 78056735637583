import { useContext, useEffect, useRef } from 'react';
import { Text, Transformer } from 'react-konva';
import { ImpresionContext } from '../../providers/Impresion';

const TextField = ({ textProp, isSelected, onSelect, onChange, margenes }) => {
  const textRef = useRef(null);
  const trRef = useRef(null);
  const {
    textArea,
    setTextArea,
    dimensionContainer,
    setIsMoving,
  } = useContext(ImpresionContext);
  // let textArea = false

  const getTotalBox = (box) => {
    let minX = dimensionContainer.width
    let minY = dimensionContainer.height - textProp.height
    let maxX = 0
    let maxY = 0

    minX = Math.min(minX, box.x)
    minY = Math.min(minY, box.y)
    maxX = Math.max(maxX, box.x + box.width)
    maxY = Math.max(maxY, box.y + box.height)
    return { x: minX, y: minY, width: maxX - minX, height: maxY - minY }
  }

  useEffect(() => {
    // if (isSelected) {
    //   trRef.current.nodes([textRef.current]);
    //   trRef.current.getLayer().batchDraw();
    // }
    const tr = trRef.current
    const stage = {
      width: dimensionContainer.width,
      height: dimensionContainer.height,
    }

    if (tr) {
      tr.nodes([textRef.current]);

      tr.on('dragmove', () => {
        const boxes = tr.getClientRect()

        const box = getTotalBox(boxes);
        const absPos = tr.getAbsolutePosition();

        const newAbsPos = { ...absPos };
        if (box.x < 0) newAbsPos.x = 0;
        if (box.y < 0) newAbsPos.y = 0;
        if (box.x + box.width > stage.width) newAbsPos.x = stage.width - box.width;
        if (box.y + box.height > stage.height) newAbsPos.y = stage.height - box.height;
        tr.setAbsolutePosition(newAbsPos);
      })

      tr.getLayer().batchDraw();
    }
  }, [isSelected])

  return (
    <>
      <Text
        onClick={onSelect}
        onTap={onSelect}
        ref={textRef}
        {...textProp}
        draggable

        onDragMove={(e) => {
          setIsMoving(true)
          const newX = Math.max((margenes.left), Math.min(e.target.x(), dimensionContainer.width - textProp.width - margenes.right))
          const newY = Math.max((margenes.top), Math.min(e.target.y(), dimensionContainer.height - textProp.height - margenes.bottom))
          textRef.current.x(newX);
          textRef.current.y(newY);

          const node = textRef.current;

          node.getLayer().batchDraw();
        }}

        onDragEnd={(e) => {
          setIsMoving(false)
          const node = textRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChange({
            ...textProp,
            x: e.target.x(),
            y: e.target.y(),

            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY)
          })
          node.getLayer().batchDraw();
        }}

        onTransformStart={() => {
          setIsMoving(true)
        }}

        onTransformEnd={() => {
          setIsMoving(false)

          const node = textRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          const x = node.x()
          const y = node.y()

          node.scaleX(1)
          node.scaleY(1)

          onChange({
            ...textProp,
            x: x,
            y: y,

            width: Math.max(5, node.width() * scaleX),
            height: Math.max(5, node.height() * scaleY),
          })
          node.getLayer().batchDraw();
        }}

        visible={isSelected & textArea ? false : true}

        onDblClick={(e) => {
          if (textProp.type === 'text') {
            setTextArea(true)
          }
        }}
      />

      {isSelected && (
        <Transformer
          id={textProp.id}
          onDblClick={(e) => {
            console.log('dblclick')
          }}
          visible={textArea ? false : true}

          onKeyDown={(e) => {
            console.log(e.key);
          }}

          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            const isOut =
              newBox.x < 0 ||
              newBox.y < 0 ||
              newBox.x + newBox.width > dimensionContainer.width ||
              newBox.y + newBox.height > dimensionContainer.height;

            if (isOut) {
              return oldBox;
            }
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }

            return newBox;
          }}


        />


      )}

    </>
  )
}

export default TextField;