import React, { useMemo, useState } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Card, CardBody, CardFooter, CardHeader, CardSubtitle, CardTitle, Container, Spinner } from 'reactstrap';
import useCarrito from '../../hooks/hookCarrito';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api } from '../../services/api';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import '../../components/Socket/toast.css'
import useCarritoStore from '../../providers/Carrito';
import './carritoCompra.css'
import Loading from '../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const CarritoCompra = () => {
  const { t } = useTranslation("global");
  const { loadingItem, actualizarCantidad, eliminarItem } = useCarrito(); //hook del carrito
  const { carrito } = useCarritoStore();  // zustand del carrito
  const [loadingMP, setLoadingMP] = useState(false);

  // Calcular el total
  const total = useMemo(() => {
    return carrito?.items?.reduce((acc, item) => {
      const precioConComision = item.tarifa?.precio * 
        (!item.tarifa?.asumir_comision ? (1 + item.tarifa?.tipo_comision?.porcentaje / 100) : 1);
      return acc + (precioConComision * item.cantidad);
    }, 0);
  }, [carrito?.items]);
  
  const handleActualizarCantidad = async (idtarifa, delta) => {
    const item = carrito?.items.find(item => item.idtarifa === idtarifa);

    if (item) {
      const nuevaCantidad = item.cantidad + delta;
      // Aseguramos que la nueva cantidad no sea negativa
      if (nuevaCantidad < 1) {
        toast.info("No puede ser menor a 1", {
          position: "top-right",
          autoClose: 2000,
        });
        return;
      }

      if (nuevaCantidad > item.tarifa.limite_persona) {
        toast.info(`No puede ser mayor a ${item.tarifa.limite_persona}`, {
          position: "top-right",
          autoClose: 2000,
        });
      }

      await actualizarCantidad(idtarifa, nuevaCantidad);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoadingMP(true);

      const idcarrito = parseInt(carrito?.id);
      
      const response = await api.mercadopago.createPago({
        idcarrito: idcarrito,
      });
      
      if (response.status === "success") {
        window.location = response.data.init_point;
      }
      else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    } finally {
      setLoadingMP(false);
    }
  }
  
  return (
    <Container className="container-fluid md-container d-flex flex-wrap justify-content-center flex-md-nowrap gap-3 p-4 backss">
      <Card className="col-12 col-md-8 p-0 custom-card">
        <CardHeader className="py-4" tag="h6">
          {carrito?.evento ? ("Vendido por " + carrito?.evento?.evento) : t("cart.shoppingCart")}
        </CardHeader>
        
        {carrito?.items?.map(c => (
          <CardBody key={c.id} className="border-bottom position-relative">
            <button className="position-absolute top-0 end-0 px-1"
              style={{cursor: 'pointer'}}
              onClick={() => eliminarItem(c.id)}
            >
              {loadingItem[c.id]
                ?
                <Spinner color="primary" size="sm" />
                :
                <FontAwesomeIcon icon={faXmark} size="lg" />
              }
            </button>

            <div className="d-flex gap-2">
              <CardTitle tag="h5">
                {c.tarifa?.concepto}
              </CardTitle>

              <CardSubtitle className="mb-2 text-muted">
                <small>({c.tarifa?.unidad})</small>
              </CardSubtitle>
            </div>

            <ButtonToolbar className="justify-content-between">
              <ButtonGroup>
                <Button color="secondary" outline
                  disabled={loadingItem[c.idtarifa]}
                  style={{ width: '40px' }}
                  onClick={() => handleActualizarCantidad(c.idtarifa, -1)}
                >
                  -
                </Button>
                <Button color="secondary" outline disabled style={{ minWidth: '40px' }}>
                  {loadingItem[c.idtarifa] ? <Spinner color="primary" size="sm" /> : c.cantidad}
                </Button>
                <Button color="secondary" outline
                  disabled={loadingItem[c.idtarifa]}
                  style={{ width: '40px' }}
                  onClick={() => handleActualizarCantidad(c.idtarifa, +1)}
                >
                  +
                </Button>
              </ButtonGroup>
              <span className="fs-5 align-self-end">
                $&nbsp;
                {(() => {
                  const precioConComision = !c.tarifa?.asumir_comision
                    ? (c.tarifa?.precio * (1 + (c.tarifa?.tipo_comision?.porcentaje / 100)))
                    : c.tarifa?.precio;
                  return (precioConComision * c.cantidad).toFixed(2);
                })()}
              </span>
            </ButtonToolbar>
          </CardBody>
        ))}

        {!carrito?.items &&
          <CardBody>
            {t("cart.cart")}
          </CardBody>
        }
        
        <CardFooter>
          <p className="text-end m-0">
            {t("cart.payments")}
          </p>
          <div className="d-flex justify-content-end my-2">
            <Button
              color="info"
              disabled={!carrito?.items}
              className="border border-dark text-white fw-bold"
              onClick={handleSubmit}
            >
              {loadingMP ? "Redirigiendo..." : "Mercado Pago"}
            </Button>
          </div>
        </CardFooter>
      </Card>

      <Card className="col-12 col-md-4 p-0 custom-summary-card" style={{height: '10rem'}}>
        <CardHeader className="py-4" tag="h6">
          {t("cart.summary")}
        </CardHeader>

        <CardBody className="d-flex flex-column justify-content-end gap-2">
          <small>
            {t("cart.products")} ({carrito?.items?.length || 0})
          </small>

          <div className="d-flex justify-content-between">
            <p className="fs-5 fw-bold">Total</p>
            <p className="fs-5 fw-bold">$ {total?.toFixed(2) || '0.00'}</p>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

export default withAuthenticationRequired(CarritoCompra, {
  onRedirecting: () => <Loading />,
});