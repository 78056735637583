import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useAuth0 } from "@auth0/auth0-react";
import { ShoppingCartOutlined, ExpandMore, ExpandLess, CloseOutlined, InfoOutlined, CampaignOutlined, } from "@mui/icons-material";
import { Button, ButtonGroup, CircularProgress, Drawer, Tooltip, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import SecondDrawer from "../second-drawer/SecondDrawer";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { api } from "../../../../../../services/api";

const FirstDrawerMobile = ({
  data,
  cantidad,
  setCantidad,
  toggleDrawer,
  openDrawer,
  setTarifasSeleccionadas,
  tarifasSeleccionadas,
  tarifasTicketsFiltrados,
  tarifasVouchersFiltrados,
}) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const param = useParams();
  const [openSecondDrawer, setOpenSecondDrawer] = useState(false);
  const [dataTarifas, setDataTarifas] = useState([]);
  const [showResume, setShowResume] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [loadingCarrito, setLoading] = useState(false);

  const initialCantidad = data?.tarifas?.reduce((acc, tarifa) => {
    acc[tarifa.id] = 0;
    return acc;
  }, {});

  useEffect(() => {
    if (data) {
      setCantidad(initialCantidad);
    }
  }, [data]);

  const handleCloseDrawer = () => {
    const hasSelectedTarifas = Object.values(cantidad).some(
      (value) => value > 0
    );

    if (hasSelectedTarifas) {
      const userConfirmed = window.confirm(
        "¿Estás seguro de que quieres cerrar? Se perderán las tarifas seleccionadas."
      );

      if (userConfirmed) {
        setMostrarFormulario(false);
        setOpenSecondDrawer(false);
        setCantidad({});
        toggleDrawer(false)();
      }
    } else {
      setMostrarFormulario(false);
      setCantidad({});
      toggleDrawer(false)();
    }
  };

  const handleOpenSecondDrawer = () => {
    setOpenSecondDrawer(true);
  };

  const handleBack = () => {
    setOpenSecondDrawer(false);
  };


  /* CALCULOS TARIFAS */
  const calculoSubtotalTarifa = (tarifa) => {
    const cantidadTarifa = cantidad[tarifa.id] || 0;
    return cantidadTarifa * tarifa.precio;
  };

  const subtotal = data?.tarifas?.reduce((acc, tarifa) => {
    return acc + calculoSubtotalTarifa(tarifa);
  }, 0) || 0;

  const comision = data?.tarifas?.reduce((acc, tarifa) => {
    const cantidadTarifa = cantidad[tarifa.id] || 0;
    const precioConComision =
      tarifa?.precio *
      (!tarifa?.asumir_comision
        ? tarifa?.tipo_comision?.porcentaje / 100
        : 0);

    return acc + cantidadTarifa * precioConComision;
  }, 0) || 0;

  const formatCurrency = (value) =>
    value.toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });

  const calcularTotal = () => {
    const total = subtotal + comision;
    return total;
  };

  /* FIN CALCULOS TARIFAS */

  const incremento = (idtarifa) => {
    setCantidad((prevCantidades) => {
      const nuevaCantidad = (prevCantidades[idtarifa] || 0) + 1;
      const tarifa = data?.tarifas?.find((tarifa) => tarifa.id === idtarifa);
      let limite = tarifa
        ? Math.min(tarifa.restantes, tarifa.limite_persona)
        : 0;

      if (nuevaCantidad > parseInt(limite)) {
        return prevCantidades;
      }

      const nuevasCantidades = {
        ...prevCantidades,
        [idtarifa]: nuevaCantidad,
      };

      const seleccionadas = Object.entries(nuevasCantidades)
        .filter(([idtarifa, cantidad]) => cantidad > 0)
        .map(([idtarifa, cantidad]) => {
          const tarifa = data?.tarifas?.find(
            (t) => t.id === parseInt(idtarifa)
          );
          return {
            ...tarifa,
            cantidad,
          };
        });
      setTarifasSeleccionadas(seleccionadas);

      return nuevasCantidades;
    });
  };

  const decremento = (idtarifa) => {
    setCantidad((prevCantidades) => {
      const nuevasCantidades = {
        ...prevCantidades,
        [idtarifa]: Math.max(0, (prevCantidades[idtarifa] || 0) - 1),
      };

      const seleccionadas = Object.entries(nuevasCantidades)
        .filter(([idtarifa, cantidad]) => cantidad > 0)
        .map(([idtarifa, cantidad]) => {
          const tarifa = data?.tarifas?.find(
            (t) => t.id === parseInt(idtarifa)
          );
          return {
            ...tarifa,
            cantidad,
          };
        });
      setTarifasSeleccionadas(seleccionadas);

      return nuevasCantidades;
    });
  };

  const handleToggleResume = () => {
    if (showResume) {
      setShowResume(false);
    } else {
      const seleccionadas = data.tarifas.filter(
        (tarifa) => (cantidad[tarifa.id] || 0) > 0
      );
      setTarifasSeleccionadas(seleccionadas);
      setShowResume(true);
    }
  };

  const handlePagoDirecto = async () => {
    if (!isAuthenticated) {
      await loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
      return;
    }

    // si si esta logeado, sigo con el pago
    setLoading(true);

    const idevento = parseInt(param.id);
    const items = Object.entries(cantidad)
      .map(([idtarifa, cantidad]) => {
        const tarifaResponses = Object.entries(dataTarifas)
          .filter(([key]) => key.startsWith(`${idtarifa}-`))
          .map(([, data]) => {

            return Object.entries(data.respuestas || {}).map(([preguntaId, respuesta]) => ({
              id_informacion_adicional: parseInt(preguntaId),
              respuesta: respuesta || "",
            }));
          });

        return {
          idtarifa: parseInt(idtarifa),
          cantidad,
          respuestas: tarifaResponses.flat()
        };
      })
      .filter((item) => item.cantidad > 0);
    /* 
    console.log("ITEMS", items); */
    if (items.length === 0) {
      toast.info("Debes seleccionar al menos una tarifa para continuar.", {
        position: "top-right",
        autoClose: 4000,
      });
      setLoading(false);
      return;
    }

    try {
      const response = await api.carrito.crearCarrito({
        idevento,
        items,
        pago_qr: false, // porque el mismo endpoint de carrito usamos para generar el url de redireccionamiento o el qr para la cantina
      });

      if (response.status === "success") {
        window.location.href = response.data.url_pago;
      } else {
        throw new Error(response.message || "Error al procesar el pago.");
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    } finally {
      setLoading(false);
    }
  };

  const checkTarifasPreguntas = () => {
    const hasQuestions = tarifasSeleccionadas.some(tarifa => {
      return tarifa.adicionales?.some(adicional => {
        return adicional.informacion_adicional.length > 0;
      });
    });
    return hasQuestions;
  };

  const handleButtonClick = () => {
    if (checkTarifasPreguntas()) {
      handleOpenSecondDrawer();
    } else {
      handlePagoDirecto();
    }
  };

  //detectar link en la descripcion para terminos legales
  const [tieneLink, setTieneLink] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  function contieneLink(descripcion) {
    if (!descripcion) return false;

    const parser = new DOMParser();
    const doc = parser.parseFromString(descripcion, "text/html");
    const enlaces = doc.getElementsByTagName("a");

    return enlaces.length > 0;
  }

  useEffect(() => {
    const resultado = contieneLink(data?.descripcion);
    setTieneLink(resultado);
    setCheckboxChecked(false);

    /* console.log("¿El texto tiene un link?", resultado); */
  }, [data]);

  const handleCheckboxChangeTerminos = () => {
    setCheckboxChecked(!checkboxChecked);
  };
  return (
    <>
      <div className="p-4 rounded-top container-btn-compra">
        <Button
          id="btn-compra"
          onClick={toggleDrawer(true)}
          className="p-4 d-flex gap-1 rounded"
          aria-label="ver-tarifas"
        >
          <ShoppingCartOutlined /> Comenzar compra
        </Button>
      </div>

      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#f8f8ff",
            borderRadius: "10px 10px 0 0",
          },
        }}
        transitionDuration={500}
        anchor="bottom"
        open={openDrawer}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            handleCloseDrawer();
          }
        }}
      >
        {!mostrarFormulario && (
          <IconButton
            sx={{ position: "absolute", right: "0" }}
            onClick={handleCloseDrawer}
            aria-label="close"
            size="small"
          >
            <CloseOutlined style={{ color: "#221a60" }} />
          </IconButton>
        )}
        <div
          className="p-4 d-flex flex-column justify-content-between gap-2"
          style={
            !showResume ? { minHeight: "30vh" } : { minHeight: "100vh" }
          }
        >
          {!showResume ? (
            <div>
              <div className="text-center mb-2">
                <p
                  className="m-0 fs-3 fw-bold"
                  style={{ color: "#221a60" }}
                >
                  Tarifas
                </p>
              </div>

              {(!tarifasTicketsFiltrados && !tarifasVouchersFiltrados) ||
                (tarifasTicketsFiltrados?.length === 0 &&
                  tarifasVouchersFiltrados?.length === 0) ? (
                <>
                  <div className="d-flex align-items-center flex-column ">
                    <div
                      className="contenedor-badge px-4 py-2"
                      style={{
                        background: "#dadaff",
                        color: "#3d2eab",
                        fontSize: "24px",
                      }}
                    >
                      <CampaignOutlined
                        sx={{
                          width: "45px",
                          height: "45px",
                          marginRight: "22px",
                        }}
                      />
                      <div className="d-flex flex-column">
                        <small
                          className="fw-bold"
                          style={{ fontSize: "18px" }}
                        >
                          ¡Próxima apertura!
                        </small>
                        <small style={{ fontSize: "14px" }}>
                          Estamos trabajando en algo único para ti.
                          ¡Esperalo!
                        </small>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {data.tarifas ? (
                    data.tarifas.map((tarifa) => (
                      <div
                        key={tarifa.id}
                        className={`position-relative my-3 ${tarifa.restantes <= 0 && "tarifa-disabled"
                          }`}
                      >
                        <div className="barra-tarifas" />

                        <div className="ps-4 d-flex flex-column gap-1">
                          <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                            <div className="d-flex flex-column gap-1">
                              <p className="fw-medium m-0">
                                {tarifa.concepto}
                              </p>
                              {tarifa?.restantes <= 0 ? (
                                <small className="cartel-agotados p-1 rounded">
                                  <CloseOutlined fontSize="14px" /> Agotados
                                </small>
                              ) : (
                                <small className="text-secondary">
                                  {tarifa.tipo?.tipo} restantes:{" "}
                                  {tarifa.restantes}
                                </small>
                              )}
                            </div>

                            {tarifa?.restantes > 0 && (
                              <ButtonGroup>
                                <Button
                                  translate="no"
                                  className="fw-bold"
                                  disabled={cantidad[tarifa.id] === 0}
                                  onClick={() => decremento(tarifa.id)}
                                  sx={{
                                    width: "40px",
                                    color: "#221a60",
                                    borderColor: "#221a60",
                                  }}
                                  aria-label="restar"
                                >
                                  -
                                </Button>
                                <Button
                                  translate="no"
                                  className="fw-bold"
                                  disabled
                                  style={{
                                    width: "40px",
                                    color: "#221a60",
                                    borderColor: "#221a60",
                                    cursor: "default",
                                  }}
                                  aria-label={`Cantidad ${cantidad[tarifa.id] || 0
                                    }`}
                                >
                                  {cantidad[tarifa.id] || 0}
                                </Button>
                                <Button
                                  translate="no"
                                  className="fw-bold"
                                  onClick={() => incremento(tarifa.id)}
                                  sx={{
                                    width: "40px",
                                    color: "#221a60",
                                    borderColor: "#221a60",
                                  }}
                                  aria-label="sumar"
                                >
                                  +
                                </Button>
                              </ButtonGroup>
                            )}
                          </div>

                          {tarifa?.restantes > 0 && (
                            <div className="d-flex flex-wrap">
                              <p className="fw-medium m-0 d-inline-block">
                                ${" "}
                                {tarifa.precio.toLocaleString("es-ES", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  useGrouping: true,
                                })}
                              </p>
                              <small className="text-secondary">
                                {" + $ "}
                                {(
                                  tarifa.precio *
                                  (tarifa.tipo_comision?.porcentaje / 100)
                                ).toLocaleString("es-ES", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  useGrouping: true,
                                })}{" "}
                                costo del servicio
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-center">Aún no hay tarifas.</p>
                  )}
                </>
              )}
            </div>
          ) : (
            <div>
              <p className="mb-1 fw-bold fs-4">Resumen de tu compra</p>
              {tarifasSeleccionadas?.length > 0 ? (
                tarifasSeleccionadas.map((tarifa) => (
                  <div key={tarifa.id} className="my-3">
                    <div className="d-flex justify-content-between flex-wrap py-2">
                      <p className="fw-medium mb-0">{`${cantidad[tarifa.id]
                        } x ${tarifa.concepto}`}</p>
                      <p className="fw-medium mb-0">
                        $ {formatCurrency(calculoSubtotalTarifa(tarifa))}
                      </p>
                    </div>
                    <hr
                      className="mx-3 border-2"
                      style={{ color: "#D9D9D9" }}
                    />
                  </div>
                ))
              ) : (
                <p className="text-center">No hay tarifas seleccionadas.</p>
              )}
              {tarifasSeleccionadas?.length > 0 && (
                <>
                  <div className="d-flex justify-content-between flex-wrap py-2">
                    <p className="fw-medium mb-0 fs-5">Subtotal</p>
                    <p className="fw-medium mb-0 fs-5">
                      $ {formatCurrency(subtotal)}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between flex-wrap py-2">
                    <div className="d-flex align-items-center gap-1">
                      <p className="fw-medium mb-0 fs-5">Costo</p>
                      <Tooltip
                        title="El costo de servicio de PassGo no es reembolsable"
                        placement="right"
                        arrow
                      >
                        <InfoOutlined fontSize="small" />
                      </Tooltip>
                    </div>
                    <p className="fw-medium mb-0 fs-5">
                      $ {formatCurrency(comision)}
                    </p>
                  </div>
                  <hr
                    className="mx-3 border-2"
                    style={{ color: "#D9D9D9" }}
                  />

                  <div className="d-flex justify-content-between flex-wrap py-2">
                    <p className="fw-medium mb-0 fs-3">Total</p>
                    <span className="text-end">
                      <p className="fw-medium mb-0 fs-3">
                        $ {formatCurrency(calcularTotal())}
                      </p>
                      <small>El precio incluye impuestos</small>
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
          {(!tarifasTicketsFiltrados && !tarifasVouchersFiltrados) ||
            (tarifasTicketsFiltrados?.length === 0 &&
              tarifasVouchersFiltrados?.length === 0) ? (
            <>
              <div className="d-flex align-items-center flex-column ">
                <div
                  className="contenedor-badge px-4 py-2"
                  style={{
                    background: "#dadaff",
                    color: "#3d2eab",
                    fontSize: "24px",
                  }}
                >
                  <CampaignOutlined
                    sx={{
                      width: "45px",
                      height: "45px",
                      marginRight: "22px",
                    }}
                  />
                  <div className="d-flex flex-column">
                    <small className="fw-bold" style={{ fontSize: "18px" }}>
                      ¡Próxima apertura!
                    </small>
                    <small style={{ fontSize: "14px" }}>
                      Estamos trabajando en algo único para ti. ¡Esperalo!
                    </small>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {data?.tarifas?.length > 0 && (
                <div className="d-flex flex-column">
                  <p
                    className="mb-2 fs-3 fw-medium align-self-end"
                    onClick={handleToggleResume}
                    style={{ cursor: "pointer" }}
                  >
                    {!showResume ? <ExpandLess /> : <ExpandMore />} ${" "}
                    {formatCurrency(calcularTotal())}
                  </p>
                  <small className="mb-2 align-self-end">
                    {format(
                      new Date(),
                      "E. d 'de' MMM. 'de' u HH:mm 'hs'",
                      {
                        locale: es,
                      }
                    )}
                  </small>
                  {tieneLink && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxChecked}
                          onChange={handleCheckboxChangeTerminos}
                          required
                          size="small"
                        />
                      }
                      label={<small>Confirmo haber leído y acepto los términos y condiciones del organizador del evento</small>}
                    />
                  )}
                  <Button
                    id="btn-compra"
                    onClick={handleButtonClick}
                    className="p-4 rounded d-flex gap-1"
                    aria-label="iniciar-compra"
                    disabled={tieneLink ? !checkboxChecked : false || tarifasSeleccionadas?.length === 0}
                  >
                    {loadingCarrito ? (
                      <CircularProgress />
                    ) : (
                      <span>
                        <ShoppingCartOutlined /> Finalizar compra
                      </span>
                    )}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </Drawer>
      <SecondDrawer onBack={handleBack} handlePagoDirecto={handlePagoDirecto} dataTarifas={dataTarifas} setDataTarifas={setDataTarifas} loadingCarrito={loadingCarrito} cantidad={cantidad} open={openSecondDrawer} onClose={handleCloseDrawer} setOpenSecondDrawer={setOpenSecondDrawer} tarifasSeleccionadas={tarifasSeleccionadas} />
    </>
  );
};
export default FirstDrawerMobile;
