import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Button,
  Input,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import { Icon } from '@mui/material';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next';
import './ayuda.css'

export const Ayuda = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation("global");

  useEffect(() => {
    // simulacion para el loading
    const tiempo = setTimeout(() => {
      setIsLoading(false);
    }, 1000); 

    return () => clearTimeout(tiempo);
  }, []);

  const faqData = [
    {
      category: "help.creation",
      icon: <EditCalendarIcon style={{fontSize:"2rem", marginRight:"10px"}}/>,
      questions: [
        {
          question: "help.answerCreation.q1",
          answer: "help.answerCreation.a1"
        },
        {
          question: "help.answerCreation.q2",
          answer: "help.answerCreation.a2"
        }
      ]
    },
    {
      category: "help.management",
      icon: <SettingsIcon style={{fontSize:"2rem", marginRight:"10px"}} />,
      questions: [
        {
          question: "help.answerManagement.q1",
          answer: "help.answerManagement.a1"
        },
        {
          question: "help.answerManagement.q2",
          answer: "help.answerManagement.a2"
        }
      ]
    },
    {
      category: "help.guests",
      icon: <ManageAccountsIcon style={{fontSize:"2rem", marginRight:"10px"}}/>,
      questions: [
        {
          question: "help.answerGuests.q1",
          answer: "help.answerGuests.a1"
        },
        {
          question: "help.answerGuests.q2",
          answer: "help.answerGuests.a2"
        },
        {
          question: "help.answerGuests.q3",
          answer: "help.answerGuests.a3"
        }
      ]
    },
    {
      category: "help.account",
      icon: <AccountCircleIcon style={{fontSize:"2rem", marginRight:"10px"}}/> ,
      questions: [
        {
          question: "help.answerAccount.q1",
          answer: "help.answerAccount.a1"
        },
        {
          question: "help.answerAccount.q2",
          answer: "help.answerAccount.a2"
        }
      ]
    }
  ];

  const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Card className="mb-2">
        <CardHeader>
          <Button 
            color="link" 
            onClick={() => setIsOpen(!isOpen)} 
            className="text-left w-100" 
            style={{
              textDecoration: "none",
              position: "relative",
              paddingRight: "40px",
              paddingLeft: "1.25rem",
              paddingTop: "0.75rem",
              paddingBottom: "0.75rem",
              fontWeight:"bolder"
            }}
          >
            {t(question)}
            {isOpen ? (
            <ArrowDropUpIcon 
              style={{
                position: "absolute",
                right: "10px",
                fontSize:"2rem",
                top: "50%",
                transform: 'translateY(-50%)',
                transition: 'transform 1s ease'
              }}
            />
          ) : (
            <ArrowDropDownIcon 
              style={{
                position: "absolute",
                right: "10px",
                fontSize:"2rem",
                top: "50%",
                transform: 'translateY(-50%)',
                transition: 'transform 1s ease'
              }}
            />
          )}
          </Button>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody dangerouslySetInnerHTML={{ __html: t(answer) }}></CardBody>
        </Collapse>
      </Card>
    );
  };

  const filteredFAQ = faqData.map(category => ({
    ...category,
    questions: category.questions.filter(q => 
      t(q.question).toLowerCase().includes(searchTerm.toLowerCase()) ||
      t(q.answer).toLowerCase().includes(searchTerm.toLowerCase())
    )
  })).filter(category => category.questions.length > 0);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container className="help-container">
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 className="text-center mb-5" style={{fontWeight:"bold"}}>{t("help.title")}</h1>
          <InputGroup className="mb-4">
            <Input 
              placeholder={t("help.search")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <InputGroupText>
              <SearchIcon style={{cursor:"pointer"}}></SearchIcon>
            </InputGroupText>
          </InputGroup>
          {filteredFAQ.map((category, index) => (
            <div key={index} className="mb-4">
              <h2 className="top-help" style={{marginTop:"50px"}}>
                {category.icon}
                {t(category.category)}
              </h2>
              {category.questions.map((faq, faqIndex) => (
                <FAQItem key={faqIndex} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};
export default Ayuda;