import { useContext, useEffect, useRef } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";
import { ImpresionContext } from "../../providers/Impresion";

const QRField = ({ onChange, qrProp, onSelect, isSelected, margenes }) => {
  // const {  } = useContext(PaginatorContext);
  const [image] = useImage('https://upload.wikimedia.org/wikipedia/commons/5/5b/Qrcode_wikipedia.jpg');
  const {
    dimensionContainer,
    // elements,
    // setElements,
    // setSelectedId,
    // setTextArea,
    // setOpenFontToolBar,
    setIsMoving,
  } = useContext(ImpresionContext);
  const qrRef = useRef();
  const trRef = useRef();

  const getTotalBox = (box) => {
    let minX = dimensionContainer.width
    let minY = dimensionContainer.height - qrProp.height
    let maxX = 0
    let maxY = 0

    minX = Math.min(minX, box.x)
    minY = Math.min(minY, box.y)
    maxX = Math.max(maxX, box.x + box.width)
    maxY = Math.max(maxY, box.y + box.height)
    return { x: minX, y: minY, width: maxX - minX, height: maxY - minY }
  }


  useEffect(() => {
    const tr = trRef.current
    const stage = {
      width: dimensionContainer.width,
      height: dimensionContainer.height,
    }

    if (tr) {
      tr.nodes([qrRef.current]);

      tr.on('dragmove', () => {
        const boxes = tr.getClientRect()

        const box = getTotalBox(boxes);
        const absPos = tr.getAbsolutePosition();

        const newAbsPos = { ...absPos };
        if (box.x < 0) newAbsPos.x = 0;
        if (box.y < 0) newAbsPos.y = 0;
        if (box.x + box.width > stage.width) newAbsPos.x = stage.width - box.width;
        if (box.y + box.height > stage.height) newAbsPos.y = stage.height - box.height;
        tr.setAbsolutePosition(newAbsPos);
      })

      tr.getLayer().batchDraw();
    }
  }, [isSelected])

  return (
    <>
      <Image
        onClick={onSelect}
        onTap={onSelect}
        ref={qrRef}
        draggable
        image={image}
        {...qrProp}

        onDragMove={(e) => {
          setIsMoving(true)
          const newX = Math.max((margenes.left), Math.min(e.target.x(), dimensionContainer.width - qrProp.width - margenes.right))
          const newY = Math.max((margenes.top), Math.min(e.target.y(), dimensionContainer.height - qrProp.height - margenes.bottom))
          qrRef.current.x(newX);
          qrRef.current.y(newY);

          const node = qrRef.current;

          node.getLayer().batchDraw();
        }}

        onDragEnd={(e) => {
          setIsMoving(false)
          const node = qrRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChange({
            ...qrProp,
            x: e.target.x(),
            y: e.target.y(),

            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY)
          })

          node.getLayer().batchDraw();
        }}

        onTransformStart={() => {
          setIsMoving(true)
        }}

        onTransformEnd={() => {
          setIsMoving(false)

          const node = qrRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const x = node.x()
          const y = node.y()

          node.scaleX(1)
          node.scaleY(1)

          onChange({
            ...qrProp,
            x: x,
            y: y,

            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY)
          })

          node.getLayer().batchDraw();
        }}
      />
      {isSelected && (
        <Transformer
          id={qrProp.id}
          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            const isOut =
              newBox.x < 0 ||
              newBox.y < 0 ||
              newBox.x + newBox.width > dimensionContainer.width ||
              newBox.y + newBox.height > dimensionContainer.height;

            if (isOut) {
              return oldBox;
            }
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  )
};

export default QRField;