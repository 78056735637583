import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { PaginatorContext } from "../../../providers/Paginator";
import { toast } from "react-toastify";
import { Alert, Button, CircularProgress, Chip, Container, IconButton, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Search as SearchIcon } from '@mui/icons-material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PrintIcon from '@mui/icons-material/Print';
// import ImprimirTickets from "../../components/modales/ImprimirTickets/ImprimirTicketsModal";
import VolverButton from "../../../components/VolverButton";
import { api } from "../../../services/api";
import useTickets from "./useTickets";
import BlockIcon from '@mui/icons-material/Block'
import useMutation from "../../../hooks/useMutation";
import { showErrorToastify, showSuccessToastify } from "../../../utils";
import { setDisableTicket } from "./api";
import ImprimirTickets from "../../../components/modales/imprimirModal";

const Tickets = () => {
  const history = useHistory();
  const param = useParams()
  const [t] = useTranslation("global");
  const [showSearch, setShowSearch] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElID, setAnchorElID] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDisable, setOpenDisable] = useState(null);
  const [openMore, setOpenMore] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const {
    setQuery,
    setCurrentPage,
  } = useContext(PaginatorContext);

  const { tickets, fetch, onTableChange, pagination, onSearch, loading } = useTickets(param.id);

  const handleDisable = (ticket) => {
    //logica para deshabilitar
    setOpenMore(null);
    setOpenDisable(true);
    setSelectedTicket(ticket);
  };

  const handleDelete = (ticket) => {
    //logica para elimnar
    setOpenMore(null);
  };

  const handleRowClick = (ticket) => {
    const url = `/eventos/${param.id}/tickets/${ticket.id}`;
    window.open(url, "_blank");
  };

  const notify = (type, message, position = "bottom-center") => {
    const options = {
      position,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    switch (type) {
      case 'success':
        toast.success(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'info':
        toast.info(message, options);
        break;
      case 'warning':
        toast.warning(message, options);
        break;
      default:
        toast(message, options);
    }
  }

  const openSeeMore = Boolean(anchorEl);

  const handleClickSeeMore = (e) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    setAnchorElID(e.currentTarget.id)
  }

  const handleCloseSeeMore = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
    setAnchorElID(null)
  }

  const HandleDownloadReport = (e) => {
    e.stopPropagation()
    setLoadingDownload(true);

    const callExcel = async (e) => {
      try {
        const response = await api.reporte.downloadReporteTicket(param.id)
        if (!response) {
          notify("error", "Error al realizar la descarga")
          throw new Error('Error al descargar el Excel')
        }
        const blob = await response;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ReporteTickets-${param.id}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        notify("success", "La descarga se realizó con éxito")
      } catch (error) {
        notify("error", "Hubo un problema al descargar el archivo");
      } finally {
        setLoadingDownload(false);
      }
    }
    callExcel()
  }
  // Manejar el cambio de página
  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    onTableChange({ current: newPage });
  };

  // Manejar el cambio de tamaño de página
  const handleChangeRowsPerPage = (event) => {
    onTableChange({ pageSize: parseInt(event.target.value, 10), current: 0 });
    // setCurrentPage(0); // Reiniciar la página al cambiar el tamaño
  };

  const handleToggleSearch = () => {
    setShowSearch(!showSearch); // Cambia el estado de visibilidad del campo de búsqueda
    setQuery(""); // Limpia el campo de búsqueda al mostrarlo
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(e.target.q.value);
    setCurrentPage(0);
  };

  const disableTicket = useMutation({
    mutationFn: setDisableTicket,
    onSuccess: res => {
      showSuccessToastify({ res });
      setOpenDisable(null);
      setSelectedTicket(null);
      fetch(param.id);
    },
    onError: err => showErrorToastify({ err }),
  });

  const handleDisableTicket = async () => {
    //logica para deshabilitar el ticket
    disableTicket.mutate(param.id, selectedTicket.id);

  };

  return (
    <Container sx={{width: "100%"}}>
      <div className="d-flex justify-content-between flex-wrap">
        <div className="d-flex flex-row gap-2">
          <form onSubmit={handleSearch}>
            <div className="w-100 d-flex" >
              <TextField
                id="exampleSearch"
                name="q"
                placeholder="Buscar..."
                type="search"
                variant="outlined"
                size="small"
                sx={{ height: "100%" }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </div>
          </form>
        </div>
        <div className="d-flex flex-row gap-3 align-items-center">
          <Button variant="contained" color="success" startIcon={<AddIcon />}
            onClick={() => history.push("/eventos/" + param.id + "/nuevoTicket")}
          >
            {t("tickets.newTicket")}
          </Button>

          <IconButton
            aria-label="see more"
            id={param.id}
            aria-controls={openSeeMore ? 'long-menu' : undefined}
            aria-expanded={openSeeMore ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClickSeeMore}
            sx={{ p: 0 }}
            className="ms-auto"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={param.id}
            aria-label="report"
            anchorEl={anchorEl}
            open={param.id === anchorEl?.id}
            onClose={handleCloseSeeMore}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={HandleDownloadReport}>
              <ListItemIcon>
                {loadingDownload ? (
                  <CircularProgress size={24} />
                ) : (
                  <FileDownloadOutlinedIcon sx={{ color: "#5656FF" }} />
                )}
              </ListItemIcon>
              {loadingDownload ? (
                <ListItemText>Descargando tickets...</ListItemText>
              ) : (
                <ListItemText>Descargar tickets</ListItemText>
              )}
            </MenuItem>
            <MenuItem onClick={handleOpenModal}>
              <ListItemIcon>
                <PrintIcon sx={{ color: "#5656FF" }} />
              </ListItemIcon>
              <ListItemText>Imprimir tickets</ListItemText>
            </MenuItem>
            {openModal &&
              <ImprimirTickets
                onClose={() => {
                  setOpenModal(false);
                }}
                // filtroTarifas={filtroTarifas}
                // tarifasId={tarifasId}
                idevento={param.id}
                tipo="tickets"
              />
            }
          </Menu>
        </div>
      </div>

      <TableContainer component={Paper} className="mt-3" >
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{t("tickets.code")}</TableCell>
              <TableCell>Ticket</TableCell>
              <TableCell>{t("tickets.name")}</TableCell>
              <TableCell>{t("tickets.lastName")}</TableCell>
              <TableCell sx={{ width: "3rem" }}>Acción</TableCell>
            </TableRow>
          </TableHead>


          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              tickets?.length > 0 ? (
                tickets?.map((ticket) => (
                  <TableRow key={ticket.id}
                    hover
                    onDoubleClick={() => handleRowClick(ticket)}>
                    <TableCell component="th" scope="row">
                      <div className="d-flex align-items-center gap-2">
                        {/* <Button variant="contained" size="small" onClick={() => history.push(`/eventos/${param.id}/tickets/${ticket.id}`)}>
                          <LocalPrintshopOutlinedIcon/>
                        </Button> */}
                        {ticket.id}
                      </div>
                    </TableCell>

                    <TableCell>{ticket.codigo}</TableCell>

                    <TableCell>{ticket?.tarifa?.concepto}</TableCell>

                    <TableCell>{ticket.nombre}</TableCell>

                    <TableCell>{ticket.apellido}</TableCell>

                    <TableCell >
                      <div style={{ display: "flex", justifyContent: ticket.consumido == 1 ? "space-between" : "end", alignItems: "center" }}>
                        {(ticket.consumido == 1) ? (
                          <Chip
                            size="small"
                            label="Consumido"
                            icon={<BlockIcon />}
                            color="error"
                            variant="outlined"
                          />
                        ) : (
                          <></>
                        )}
                        <IconButton onClick={(e) => setOpenMore(e.currentTarget)} id={ticket.id}>
                          <MoreVertIcon fontSize="inherit" />
                        </IconButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={openMore}
                          open={ticket.id == parseInt(openMore?.id)}
                          onClose={() => { setOpenMore(null); setSelectedTicket(null) }}
                          MenuListProps={{
                            'aria-labelledby': ticket.id,
                          }}
                        >
                          <MenuItem onClick={() => handleDisable(ticket)}>
                            <ListItemIcon>
                              <BlockIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Deshabilitar</ListItemText>
                          </MenuItem>
                          {/* <MenuItem onClick={() => handleDelete(ticket)}>
                          <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Eliminar</ListItemText>
                        </MenuItem> */}
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Alert severity="info" >
                      {t("tickets.ticketsAlert")}
                    </Alert>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          className="d-flex justify-content-center"
          rowsPerPageOptions={[10, 25, 50]} // Opciones de tamaño de página
          count={pagination.total || 0} // Total de registros
          rowsPerPage={pagination.pageSize} // Tamaño de la página actual
          page={pagination.current} // Página actual
          onPageChange={handleChangePage} // Evento de cambio de página
          onRowsPerPageChange={handleChangeRowsPerPage} // Evento de cambio de tamaño de página
          labelRowsPerPage="Registros p/página"
          showFirstButton
          showLastButton
        />
      </TableContainer>

      {openDisable &&
        <Dialog
          open={true}
          onClose={() => setOpenDisable(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              padding: "0.5rem"
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {"¿Desea deshabilitar este ticket?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Una vez realizado el dueño del ticket no podrá ingresar al evento. Esta acción no se puede revertir.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ gap: "0.5rem", paddingLeft: "1rem", justifyContent: "start" }}
          >
            <Button
              color="default"
              onClick={() => setOpenDisable(false)}
            >
              No, mantener Ticket
            </Button>
            <Button
              color="error"
              variant="contained"
              autoFocus
              startIcon={<BlockIcon fontSize="small" />}
              onClick={handleDisableTicket}
            >
              Si, Deshabilitar el Ticket
            </Button>
          </DialogActions>
        </Dialog>
      }
      <div className="d-flex justify-content-end mt-3">
        <VolverButton />
      </div>
    </Container>
  )
};

export default Tickets;
