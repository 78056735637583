import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../components/Loading";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { useMediaQuery } from "react-responsive";
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import { toast, Bounce } from 'react-toastify';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

export const Eventos = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const history = useHistory();
  const [evento, setEvento] = useState();
  const [stateEvento, setStateEvento] = useState();
  const [eventosumary, setEventoSumary] = useState();
  const [publicaciones, setPublicaciones] = useState();
  const [tarifas, setTarifas] = useState();
  const [tickets, setTickets] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const param = useParams();
  const [t, i18n] = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });


  useEffect(() => {

    const callApi = async () => {
      try {
        const response = await api.eventos.get(param.id);
        if (response.status === "success") {
          setEvento(response.data.evento);
          setEventoSumary(response.data.eventosumary);
          setTickets(response.data.eventosumary.tickets);
          setVouchers(response.data.eventosumary.vouchers);
          setStateEvento(response.data.evento.id_estados_eventos);
          setPublicaciones(response.data.publicacionessumary);
          setTarifas(response.data.tarifassumary);
        }
      } catch (error) {
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };
    callApi();
  }, []);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleFinalizarConfiguracion = () => {
    if (evento.privado === false && tarifas.count === 0) {
      history.push(`/eventos/${param.id}/tarifas`);
    } else if (evento.privado === false && publicaciones.count === 0) {
      history.push(`/eventos/${param.id}/publicaciones`);
    } else {
      api.eventos.confirmEvento(param.id)
        .then(response => {
          if (response.status === 'success') {
            toast.success('Tu evento se ha publicado exitosamente', {
              position: 'top-center',
              autoClose: 2000,
              theme: 'dark',
              transition: Bounce,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        })
        .catch(error => {
          toast.error('Hubo un problema al confirmar el evento', {
            position: 'top-center',
            autoClose: 3000,
            theme: 'dark',
          });
        });
    }
  };

  return (
    <>
      {evento && (
        <Container fluid="sm">
          {stateEvento === 1 ? (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div style={{ width: "90%" }}>
                <div className="" style={{ color:"#8C2828", border: "1px solid #D02A2A", borderRadius: "5px", padding: "20px 15px", backgroundColor: "rgba(255, 180, 180, 0.2)"  }}>
                  <div className="">
                    <p className="mb-2 fw-bold fs-4"> {(evento.privado === false && tarifas.count === 0) || (evento.privado === false && publicaciones.count === 0)
                      ? "Tu evento no está listo"
                      : "Evento listo para publicar"}</p>
                    <p className="mb-1 fs-6" style={{color:"#8C2828"}}>
                      {evento.privado === false
                        ? (
                          tarifas.count === 0 && publicaciones.count === 0
                            ? "Tu evento no tiene tarifas ni publicaciones configuradas. Completa estos detalles para que el evento esté listo para ser publicado."
                            : (tarifas.count === 0
                              ? "Aún no has configurado tarifas para tu evento. Por favor, agrega al menos una tarifa para publicar el evento."
                              : (publicaciones.count === 0
                                ? "Aún no has creado una publicación para tu evento. Agrega una publicación para que los usuarios puedan verlo."
                                : null
                              )
                            )
                        )
                        : (
                          /* tarifas.count === 0
                            ? "Aún no has configurado tarifas para tu evento. Por favor, agrega al menos una tarifa para publicar el evento."
                            : */ null
                        )
                      }
                    </p>
                    <div className="d-flex align-items-center justify-content-end">
                      <button type="button" onClick={handleFinalizarConfiguracion} style={{color:"#3D2EAB"}}>
                        {(evento.privado === false && tarifas.count === 0) || (evento.privado === false && publicaciones.count === 0) 
                          ? "Finalizar configuración"
                          : "Publicar evento"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "30px", padding: "0px 25px", flexDirection: "column", height: "100%" }}>
                <div className="d-flex flex-column">
                  <p style={{ fontSize: "28px", marginBottom: "0px" }}>Tickets</p>
                  {tickets?.length > 0 ? (
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                      {tickets?.map((tarifa, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                            width: isMobile ? "100%" : "225px",
                            borderRadius: "12px",
                          }}
                        >
                          <div style={{ padding: "20px 15px", display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
                            <div className="d-flex flex-row align-items-center justify-content-between">
                              <div className="d-flex flex-column">
                                <p style={{ marginBottom: "0", fontSize: "14px", lineHeight: "22px", letterSpacing: "0.15px" }}>
                                  {tarifa.concepto}
                                </p>
                                <p style={{ fontSize: "28px", lineHeight: "32px" }}>{tarifa.cantidad}</p>
                              </div>
                              <div
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  borderRadius: "50%",
                                  marginBottom: "10px",
                                  backgroundColor: "#e4f3fc",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <ConfirmationNumberOutlinedIcon sx={{ fontSize: "30px", color: "#067bea" }} />
                              </div>
                            </div>
                            <div>
                              <p style={{ fontSize: "10px", lineHeight: "16px", letterSpacing: "0.15px", marginBottom: "3px" }}>
                                Ingresos
                              </p>
                              <div className="d-flex flex-row align-items-center">
                                <HowToRegOutlinedIcon sx={{ color: "black", width: "16px", height: "16px", marginRight: "4px" }} />
                                <LinearProgress
                                  variant="determinate"
                                  value={(tarifa.escaneados / tarifa.cantidad) * 100}
                                  sx={{
                                    height: "10px !important",
                                    width: isMobile ? "75%" : "140px",
                                    minWidth: isMobile ? "75%" : "140px",
                                    maxWidth: isMobile ? "75%" : "140px",
                                    borderRadius: "5px",
                                    backgroundColor: "#F5F5F5",
                                    '& .MuiLinearProgress-bar': {
                                      backgroundColor: '#9797E4',
                                      borderRadius: "5px"
                                    },
                                  }}
                                />
                                <div className="ml-auto">
                                  <p style={{ color: "black", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", marginBottom: "0", marginLeft: "3px" }}>
                                    {tarifa.escaneados}
                                  </p>
                                </div>
                              </div>
                              <p style={{ fontSize: "10px", lineHeight: "16px", letterSpacing: "0.15px", marginBottom: "3px" }}>
                                Pendientes
                              </p>
                              <div className="d-flex flex-row  align-items-center">
                                <PendingActionsOutlinedIcon sx={{ color: "black", width: "16px", height: "16px", marginRight: "4px" }} />
                                <LinearProgress
                                  variant="determinate"
                                  value={((tarifa.cantidad - tarifa.escaneados) / tarifa.cantidad) * 100}
                                  sx={{
                                    height: "10px !important",
                                    width: isMobile ? "75%" : "140px",
                                    minWidth: isMobile ? "75%" : "140px",
                                    maxWidth: isMobile ? "75%" : "140px",
                                    borderRadius: "5px",
                                    backgroundColor: "#F5F5F5",
                                    '& .MuiLinearProgress-bar': {
                                      backgroundColor: '#4837C5',
                                      borderRadius: "5px"
                                    },
                                  }}
                                />
                                <div className="ml-auto">

                                  <p style={{ color: "black", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", marginBottom: "0", marginLeft: "3px" }}>
                                    {tarifa.cantidad - tarifa.escaneados}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "between",
                        alignItems: "center",
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                        width: "250px",
                        height: "auto",
                        borderRadius: "12px",
                        padding: "20px"
                      }}>
                      <p style={{ marginBottom: "0", fontSize: "16px", lineHeight: "20px", letterSpacing: "0.15px" }}>Aun no tienes tickets registrados</p>
                      <div
                        style={{
                          width: "55px",
                          height: "45px",
                          borderRadius: "50%",
                          backgroundColor: "#e4f3fc",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ConfirmationNumberOutlinedIcon sx={{ fontSize: "20px", color: "#067bea" }} />
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-flex flex-column">
                  {vouchers?.length > 0 && (
                    <>
                      <p style={{ fontSize: "28px", marginBottom: "0px" }}>Vouchers</p>
                      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", flexDirection: "row" }}>
                        {vouchers?.map((tarifa, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                              width: isMobile ? "100%" : "225px",
                              borderRadius: "12px",
                            }}
                          >
                            <div style={{ padding: "20px 15px", display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
                              <div className="d-flex flex-row align-items-center justify-content-between">
                                <div className="d-flex flex-column">
                                  <p style={{ marginBottom: "0", fontSize: "14px", lineHeight: "22px", letterSpacing: "0.15px" }}>
                                    {tarifa.concepto}
                                  </p>
                                  <p style={{ fontSize: "28px", lineHeight: "32px" }}>{tarifa.cantidad}</p>
                                </div>
                                <div
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "50%",
                                    marginBottom: "10px",
                                    backgroundColor: "#e4f3fc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FastfoodOutlinedIcon sx={{ fontSize: "30px", color: "#067bea" }} />
                                </div>
                              </div>
                              <div>
                                <p style={{ fontSize: "10px", lineHeight: "16px", letterSpacing: "0.15px", marginBottom: "3px" }}>
                                  Reclamados
                                </p>
                                <div className="d-flex flex-row justify-content-around align-items-center">
                                  <HowToRegOutlinedIcon sx={{ color: "black", width: "16px", height: "16px", marginRight: "4px" }} />
                                  <LinearProgress
                                    variant="determinate"
                                    value={(tarifa.escaneados / tarifa.cantidad) * 100}
                                    sx={{
                                      height: "10px !important",
                                      width: isMobile ? "75%" : "140px",
                                      minWidth: isMobile ? "75%" : "140px",
                                      maxWidth: isMobile ? "75%" : "140px",
                                      borderRadius: "5px",
                                      backgroundColor: "#F5F5F5",
                                      '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#9797E4',
                                        borderRadius: "5px"
                                      },
                                    }}
                                  />
                                  <div className="ml-auto">
                                    <p style={{ color: "black", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", marginBottom: "0", marginLeft: "3px" }}>
                                      {tarifa.escaneados}
                                    </p>
                                  </div>
                                </div>
                                <p style={{ fontSize: "10px", lineHeight: "16px", letterSpacing: "0.15px", marginBottom: "3px" }}>
                                  Pendientes
                                </p>
                                <div className="d-flex flex-row justify-content-around align-items-center">
                                  <PendingActionsOutlinedIcon sx={{ color: "black", width: "16px", height: "16px", marginRight: "4px" }} />
                                  <LinearProgress
                                    variant="determinate"
                                    value={((tarifa.cantidad - tarifa.escaneados) / tarifa.cantidad) * 100}
                                    sx={{
                                      height: "10px !important",
                                      width: isMobile ? "75%" : "140px",
                                      minWidth: isMobile ? "75%" : "140px",
                                      maxWidth: isMobile ? "75%" : "140px",
                                      borderRadius: "5px",
                                      backgroundColor: "#F5F5F5",
                                      '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#4837C5',
                                        borderRadius: "5px"
                                      },
                                    }}
                                  />
                                  <div className="ml-auto">
                                    <p style={{ color: "black", fontSize: "18px", lineHeight: "24px", letterSpacing: "0.15px", marginBottom: "0", marginLeft: "3px" }}>
                                      {tarifa.cantidad - tarifa.escaneados}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </Container>
      )}
    </>
  );
};
export default withAuthenticationRequired(Eventos, {
  onRedirecting: () => <Loading />,
});