import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../../../components/Footer";
import "./creaEventos.css";
import SliderHeader from "./SliderHeader";
import SectionCheckbox from "./SectionCheckbox";
import SectionServices from "./SectionServices";
import FormConsultas from "./FormConsultas";

export const CreaEventos = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", position: "relative" }}>
      <SliderHeader />

      {/* ------------- BADGE CELESTE ----------------- */}

      <div className="container-badge-celeste">
        <p className="hero-title" style={{ color: "white", paddingTop: "10px" }}>
          Sumate a la experiencia
        </p>
        <p className="text-badge-celeste">
          Descubrí la plataforma diseñada para transformar tu experiencia de eventos:
          organizá y gestioná cada aspecto de tu evento en un solo lugar,
          mientras que los asistentes pueden comprar sus entradas de manera rápida y segura.
        </p>
      </div>

      <SectionCheckbox />

      <SectionServices />

      <FormConsultas/>

      <div className="w-100" style={{ zIndex: "3" }}>
        <Footer />
      </div>
    </div>
  )
}


export default CreaEventos;