import React, { useState, useEffect } from 'react';
import './invitacion.css';
import { api } from '../../services/api';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../Loading";
import { useHistory } from 'react-router-dom';
import { Alert, Spinner } from "reactstrap";

export default function Invitacion() {
    const [t] = useTranslation("global");
    const { user } = useAuth0();
    const [participaciones, setParticipaciones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [invitaciones, setInvitaciones] = useState([]);
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');

    const callApi = async () => {
        try {
            const response = await api.usuarios.getUsuario();
            if (response.status === "success") {
                const invitacionesCliente = response.data.invitaciones.invitacionesCliente?.map(invitacion => ({
                    ...invitacion,
                    tipo: 'Cliente'
                })) ?? [];

                const invitacionesColaborador = response.data.invitaciones.invitacionesColaborador?.map(invitacion => ({
                    ...invitacion,
                    tipo: 'Colaborador'
                })) ?? [];

                const todasInvitaciones = [...invitacionesCliente, ...invitacionesColaborador];

                setParticipaciones(response.data.usuario.participaciones);
                setInvitaciones(todasInvitaciones);
            }
        } catch (error) {
            setError(error.message || "Error al cargar el usuario");
        }
        setLoading(false);
    };

    useEffect(() => {
        callApi();
    }, []);

    const handleAccept = async (invitaciones) => {
        setSubmitting(true);
        setSubmitMessage(''); // Resetear el mensaje de éxito
        try {
            for (const invitacion of invitaciones) {
                const { id, tipo } = invitacion;

                const data = {
                    id: id,
                    idestado: 2,
                };

                let response;
                if (tipo === 'Cliente') {
                    response = await api.invitaciones.setInvitacionCliente(id, data);
                } else if (tipo === 'Colaborador') {
                    response = await api.invitaciones.setInvitacionColaborador(id, data);
                }

                if (response && response.status === "success") {
                    setSubmitting(false);
                    setSubmitted(true);
                    setSubmitMessage('Invitación aceptada correctamente.');
                    setTimeout(() => {
                        history.push('/profile');
                    }, 3000);
                } else {
                    setError("Error: " + response.message);
                    setSubmitting(false);
                    setTimeout(() => {
                        history.push('/profile');
                    }, 3000);
                }
            }
        } catch (err) {
            setError('Error al procesar la solicitud.');
            setSubmitting(false);
            setTimeout(() => {
                history.push('/profile');
            }, 3000);
        }
    };

    const handleReject = async (invitaciones) => {
        setSubmitting(true);
        setSubmitMessage(''); // Resetear el mensaje de éxito
        try {
            for (const invitacion of invitaciones) {
                const { id, tipo } = invitacion;
                const confirmReject = window.confirm("¿Estás seguro de que deseas rechazar esta invitación?");

                if (confirmReject) {
                    const data = {
                        id: id,
                        idestado: 3
                    };

                    let response;
                    if (tipo === 'Cliente') {
                        response = await api.invitaciones.setInvitacionCliente(id, data);
                    } else if (tipo === 'Colaborador') {
                        response = await api.invitaciones.setInvitacionColaborador(id, data);
                    }

                    if (response && response.status === "success") {
                        setSubmitting(false);
                        setSubmitted(true);
                        setSubmitMessage('Invitación rechazada correctamente.');
                        setTimeout(() => {
                            history.push('/profile');
                        }, 3000);
                    } else {
                        setError('Error al procesar la solicitud.');
                        setSubmitting(false);
                        setTimeout(() => {
                            history.push('/profile');
                        }, 3000);
                    }
                } else {
                    setSubmitting(false);
                    setTimeout(() => {
                        history.push('/profile');
                    }, 3000);
                }
            }
        } catch (err) {
            setError('Error al procesar la solicitud.');
            setSubmitting(false);
            setTimeout(() => {
                history.push('/profile');
            }, 3000);
        }
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <Alert color="danger">
                {error}
            </Alert>
        );
    }

    return (
        <div className="alert-container">
            <div className="main">
                <div className="wrapper">
                    <p className="event-name">¡{t("invitations.hi")} {user.given_name}!</p>
                    <p>{t("invitations.invitation-p1")} "{invitaciones?.map(invitacion => (invitacion.evento.evento))}" {t("invitations.invitation-p2")}.</p>
                    <div className="btn-container">
                        <div className="button-group">
                            <button onClick={() => handleAccept(invitaciones.map(invitacion => ({ id: invitacion.id, tipo: invitacion.tipo })))} className="btn-primary-link">{t("button.accept")}</button>
                            <button onClick={() => handleReject(invitaciones.map(invitacion => ({ id: invitacion.id, tipo: invitacion.tipo })))} className="btn-secondary-link">{t("button.reject")}</button>
                        </div>
                    </div>
                    {submitting && <Alert color="warning"> <Spinner color="primary" className="mr-3"></Spinner> Cargando.. por favor espere.</Alert>}
                    {error && <Alert color="danger">{error}</Alert>}
                    {submitted && (
                        <Alert color="success">
                            {submitMessage}
                        </Alert>
                    )}
                </div>
            </div>
        </div>
    );
}