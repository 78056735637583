import moment from "moment-timezone";
import "moment/locale/es";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { PaginatorContext } from "../../providers/Paginator";
import { api } from "../../services/api";
import "./styles.css";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StoreIcon from '@mui/icons-material/Store';

export const MenuTemplate = ({ evento }) => {
  const param = useParams();
  const [t] = useTranslation("global");
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const { isToggled, setIsToggled } = useContext(PaginatorContext);
  const [userRole, setUserRole] = useState(null);
  const location = useLocation();
  const [qrOption, setQROption] = useState('')

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await api.participaciones.getParticipaciones();
        if (response.status === "success") {
          const rol = response.data.participaciones.find(
            (p) => p.idevento == param.id
          )?.idrol;
          setUserRole(rol);
        } else {
          setState({ ...state, error: "Error al obtener el rol." });
        }
        setIsToggled(false);
      } catch (error) {
        setState({ ...state, error: "Error en la red." });
      }
    };
    fetchRole([]);
  }, [param.id]);

  const attach = evento?.attachs?.[0];
  const urlImg = attach?.url;
  const imagen = urlImg || process.env.PUBLIC_URL + "/img_fiesta.jpg";

  const [openMenu, setOpenMenu] = useState(null);
  const toggleMenu = (menuName) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };

  const [activeItem, setActiveItem] = useState("inicio");
  const handleClick = (item) => {
    setActiveItem(item);
  };

  return (
    <>
      <div
        className="menu"
        style={{
          display: isToggled ? "none" : "",
          width: "270px",
          padding: "15px",
        }}
      >
        <div className="menu-header d-flex align-items-center gap-2">
          <div
            className="menu-header-img"
            style={{ width: "50px", height: "50px", flexShrink: "0" }}
          >
            <img
              src={imagen}
              alt="Fiesta"
              className="rounded-circle"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            ></img>
          </div>
          <div className="menu-header-info_evento">
            <p className="m-0">{evento?.evento}</p>
            <small>
              {moment(evento?.fechas[0].fecha).clone().local().format("D [de] MMMM YYYY")}
            </small>
          </div>
        </div>

        <div className="menu-body" style={{ marginTop: "25px" }}>
          <ul>
            {userRole !== 3 && (
              <li
                className={`menu-body-item ${location.pathname === "/eventos/" + param.id ? "active" : ""
                  }`}
              >
                <Link className="link" to={"/eventos/" + param.id}>
                  <HomeOutlinedIcon />
                  <p style={{ fontSize: "16px" }}>{t("menuTemplate.home")}</p>
                </Link>
              </li>
            )}

            {(userRole === 6) && (

              <>
                {/* <li
                  className={`menu-body-item dropdown_sub ${qrOption === "Camara" && location.pathname === "/eventos/" + param.id + "/qrvouchers/camara"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link
                    className="link"
                    to={"/eventos/" + param.id + "/qrvouchers/camara"}
                    onClick={() => setQROption("Camara")}
                  >
                    <CameraAltIcon />
                    <p>{t("menuTemplate.cameraVoucher")}</p>
                  </Link>
                </li> */}

                <li
                  className={`menu-body-item dropdown_sub ${qrOption === "Escaner" && location.pathname === "/eventos/" + param.id + "/qrvouchers/escaner"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link
                    className="link"
                    to={"/eventos/" + param.id + "/qrvouchers/escaner"}
                    onClick={() => setQROption("Escaner")}
                  >
                    <QrCodeScannerIcon />
                    <p>{t("menuTemplate.scanVoucher")}</p>
                  </Link>
                </li>
              </>

            )}

            {(userRole === 5) && (
              <>
                {/* <li
                  className={`menu-body-item dropdown_sub ${qrOption === "Camara" && location.pathname ===
                    "/eventos/" + param.id + "/qrtickets/camara"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link
                    className="link"
                    to={`/eventos/${param.id}/qrtickets/camara`}
                    onClick={() => setQROption("Camara")}
                  >
                    <CameraAltIcon />
                    <p>{t("menuTemplate.cameraAccess")}</p>
                  </Link>
                </li> */}

                <li
                  className={`menu-body-item dropdown_sub ${qrOption === "Escaner" && location.pathname ===
                    "/eventos/" + param.id + "/qrtickets/escaner"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link
                    className="link"
                    to={`/eventos/${param.id}/qrtickets/escaner`}
                    onClick={() => setQROption("Escaner")}
                  >
                    <QrCodeScannerIcon />
                    <p>{t("menuTemplate.scanAccess")}</p>
                  </Link>
                </li>
              </>
            )}


            {(userRole !== 3 && userRole !== 5 && userRole !== 6 && userRole !== 7 ) && (
              <li className="dropdown_list">
                <a
                  onClick={() => toggleMenu("menu1")}
                  className="dropdown_link"
                >
                  <div className="d-flex align-items-center" style={{ marginRight: "10px" }}>
                    <QrCode2OutlinedIcon
                      style={{ marginRight: "7px" }}
                    />
                    <p>{t("event.scan")}</p>
                  </div>
                  <KeyboardArrowDownOutlinedIcon
                    className={`dropdown_arrow ${openMenu === "menu1" ? "open" : ""
                      }`}
                  >
                  </KeyboardArrowDownOutlinedIcon>
                </a>
                {openMenu === "menu1" && (
                  <div className="dropdown_content">
                    <ul className="dropdown_sub" style={{ marginLeft: "12px" }}>
                      {userRole !== 5 && (
                        <>
                          {/* <li
                            className={`menu-body-item dropdown_sub ${qrOption === "Camara" && location.pathname === "/eventos/" + param.id + "/qrvouchers/camara"
                              ? "active"
                              : ""
                              }`}
                          >
                            <Link
                              className="link"
                              to={"/eventos/" + param.id + "/qrvouchers/camara"}
                              onClick={() => setQROption("Camara")}
                            >
                              <p>{t("menuTemplate.cameraVoucher")}</p>
                            </Link>
                          </li> */}

                          <li
                            className={`menu-body-item dropdown_sub ${qrOption === "Escaner" && location.pathname === "/eventos/" + param.id + "/qrvouchers/escaner"
                              ? "active"
                              : ""
                              }`}
                          >
                            <Link
                              className="link"
                              to={"/eventos/" + param.id + "/qrvouchers/escaner"}
                              onClick={() => setQROption("Escaner")}
                            >
                              <p>{t("menuTemplate.scanVoucher")}</p>
                            </Link>
                          </li>
                        </>
                      )}

                      {userRole !== 6 && (
                        <>
                          {/* <li
                            className={`menu-body-item dropdown_sub ${qrOption === "Camara" && location.pathname ===
                              "/eventos/" + param.id + "/qrtickets/camara"
                              ? "active"
                              : ""
                              }`}
                          >
                            <Link
                              className="link"
                              to={`/eventos/${param.id}/qrtickets/camara`}
                              onClick={() => setQROption("Camara")}
                            >
                              <p>{t("menuTemplate.cameraAccess")}</p>
                            </Link>
                          </li> */}

                          <li
                            className={`menu-body-item dropdown_sub ${qrOption === "Escaner" && location.pathname ===
                              "/eventos/" + param.id + "/qrtickets/escaner"
                              ? "active"
                              : ""
                              }`}
                          >
                            <Link
                              className="link"
                              to={`/eventos/${param.id}/qrtickets/escaner`}
                              onClick={() => setQROption("Escaner")}
                            >
                              <p>{t("menuTemplate.scanAccess")}</p>
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                )}
              </li>
            )}

            {userRole !== 3 && userRole !== 5 && userRole !== 7 && 
              <li
                className={`menu-body-item ${location.pathname === "/eventos/" + param.id + "/caja"
                  ? "active"
                  : ""
                  }`}
              > 
                <Link className="link" to={`/eventos/${param.id}/caja`}>
                  <StoreIcon />
                  <p>{t("menuTemplate.cashier")}</p>
                </Link>
              </li>
            }

            {userRole !== 2 && userRole !== 5 && userRole !== 6 && userRole !== 7 && (
              <li
                className={`menu-body-item ${location.pathname === "/eventos/" + param.id + "/ventas"
                  ? "active"
                  : ""
                  }`}
              >
                <Link className="link" to={`/eventos/${param.id}/ventas`}>
                  <TrendingUpOutlinedIcon />
                  <p>{t("menuTemplate.sales")}</p>
                </Link>
              </li>
            )}
            {userRole === 1 && (
              <li
                className={`menu-body-item ${location.pathname === "/eventos/" + param.id + "/cuentas" ||
                  location.pathname === "/eventos/" + param.id + "/crear-cuenta"
                  ? "active"
                  : ""
                  }`}
              >
                <Link className="link" to={"/eventos/" + param.id + "/cuentas"}>
                  <AccountBalanceWalletOutlinedIcon />
                  <p>{t("menuTemplate.wallet")}</p>
                </Link>
              </li>
            )}

            {userRole !== 2 && userRole !== 5 && userRole !== 6 && evento?.privado !== true && (
              <li
                className={`menu-body-item ${location.pathname ===
                  "/eventos/" + param.id + "/publicaciones" ||
                  location.pathname ===
                  "/eventos/" + param.id + "/publicaciones/nueva"
                  ? "active"
                  : ""
                  }`}
              >
                <Link
                  className="link"
                  to={`/eventos/${param.id}/publicaciones`}
                >
                  <NewspaperOutlinedIcon />
                  <p>{t("menuTemplate.publications")}</p>
                </Link>
              </li>
            )}
            { userRole === 1 && (
              <li className="menu-body-item">
                <Link className="link" to={`/eventos/${param.id}/impresion`}>
                  <ColorLensOutlinedIcon />
                  <p>{t("menuTemplate.design")}</p>
                </Link>
              </li>
            )}
            { userRole === 7 && (
              <li className="menu-body-item">
                <Link className="link" to={`/eventos/${param.id}/impresion`}>
                  <ColorLensOutlinedIcon />
                  <p>{t("menuTemplate.design")}</p>
                </Link>
              </li>
            )}

            {userRole !== 5 && userRole !== 6 && userRole !== 7 && (
              <li className="dropdown_list">
                <a onClick={() => toggleMenu("menu2")} className="dropdown_link">
                  <div className="d-flex" style={{ marginRight: "10px" }}>
                    <PeopleAltOutlinedIcon
                      className="material-symbols-outlined"
                      style={{ marginRight: "7px" }}
                    />
                    <p>Staff</p>
                  </div>
                  <KeyboardArrowDownOutlinedIcon
                    className={`dropdown_arrow ${openMenu === "menu2" ? "open" : ""
                      }`}
                  >
                  </KeyboardArrowDownOutlinedIcon>
                </a>
                {openMenu === "menu2" && (
                  <div className="dropdown_content">
                    <ul className="dropdown_sub" style={{ marginLeft: "12px" }}>
                      {userRole === 1 && (
                        <li
                          className={`menu-body-item dropdown_sub ${location.pathname ===
                            "/eventos/" + param.id + "/owners"
                            ? "active"
                            : ""
                            }`}
                        >
                          <Link
                            className="link"
                            to={`/eventos/${param.id}/owners`}
                          >
                            <p>Owners</p>
                          </Link>
                        </li>
                      )}
                      {userRole !== 2 && (
                        <li
                          className={`menu-body-item dropdown_sub ${location.pathname ===
                            "/eventos/" + param.id + "/clientes"
                            ? "active"
                            : ""
                            }`}
                        >
                          <Link
                            className="link"
                            to={`/eventos/${param.id}/clientes`}
                          >
                            <p>{t("menuTemplate.customers")}</p>
                          </Link>
                        </li>
                      )}
                      {userRole !== 3 && (
                        <li
                          className={`menu-body-item dropdown_sub ${location.pathname ===
                            "/eventos/" + param.id + "/colaboradores"
                            ? "active"
                            : ""
                            }`}
                        >
                          <Link
                            className="link"
                            to={`/eventos/${param.id}/colaboradores`}
                          >
                            <p>{t("menuTemplate.collaborators")}</p>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </li>
            )}

            {userRole !== 5 && userRole !== 6 && (
              <li className="dropdown_list">
                <a onClick={() => toggleMenu("menu3")} className="dropdown_link">
                  <div className="d-flex">
                    <ConfirmationNumberOutlinedIcon
                      style={{ marginRight: "7px" }}
                    />
                    <p>{t("menuTemplate.manageEntries")}</p>
                  </div>
                  <KeyboardArrowDownOutlinedIcon
                    className={`dropdown_arrow ${openMenu === "menu3" ? "open" : ""
                      }`}
                  >
                  </KeyboardArrowDownOutlinedIcon>
                </a>
                {openMenu === "menu3" && (
                  <div className="dropdown_content">
                    <ul className="dropdown_sub" style={{ marginLeft: "12px" }}>
                      {userRole === 1 && (
                        <li
                          className={`menu-body-item ${location.pathname ===
                            "/eventos/" + param.id + "/tarifas" ||
                            location.pathname ===
                            "/eventos/" + param.id + "/creartarifa"
                            ? "active"
                            : ""
                            }`}
                        >
                          <Link
                            className="link"
                            to={"/eventos/" + param.id + "/tarifas"}
                          >
                            <p>{t("rates.rate")}</p>
                          </Link>
                        </li>
                      )}
                      <li
                        className={`menu-body-item ${location.pathname ===
                          "/eventos/" + param.id + "/tickets" ||
                          location.pathname ===
                          "/eventos/" + param.id + "/nuevoTicket" ||
                          location.pathname ===
                          "/eventos/" + param.id + "/nuevoTicket/importarExcel"
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          className="link"
                          to={"/eventos/" + param.id + "/tickets"}
                        >
                          <p>Tickets</p>
                        </Link>
                      </li>
                      {userRole === 1 && (
                        <li
                          className={`menu-body-item ${location.pathname ===
                            "/eventos/" + param.id + "/vouchers" ||
                            location.pathname ===
                            "/eventos/" + param.id + "/nuevoVoucher" ||
                            location.pathname ===
                            "/eventos/" +
                            param.id +
                            "/nuevoVoucher/importarExcel"
                            ? "active"
                            : ""
                            }`}
                        >
                          <Link
                            className="link"
                            to={"/eventos/" + param.id + "/vouchers"}
                          >
                            <p>Vouchers</p>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </li>
            )}

            {userRole !== 5 && userRole !== 6 && userRole !== 7 &&  (
              <li className="dropdown_list">
                <a onClick={() => toggleMenu("menu4")} className="dropdown_link">
                  <div className="d-flex align-items-center">
                    <FolderOpenOutlinedIcon className="flex align-items-center"
                      style={{ marginRight: "7px" }}
                    />
                    <p>{t("menuTemplate.manageData")}</p>
                  </div>
                  <KeyboardArrowDownOutlinedIcon
                    className={`dropdown_arrow ${openMenu === "menu4" ? "open" : ""
                      }`}
                  >
                  </KeyboardArrowDownOutlinedIcon>
                </a>
                {openMenu === "menu4" && (
                  <div className="dropdown_content">
                    <ul className="dropdown_sub" style={{ marginLeft: "12px" }}>
                      {userRole !== 3 && (
                        <li
                          className={`menu-body-item dropdown_sub ${location.pathname ===
                            "/eventos/" + param.id + "/ubicaciones"
                            ? "active"
                            : ""
                            }`}
                        >
                          <Link
                            className="link"
                            to={"/eventos/" + param.id + "/ubicaciones"}
                          >
                            <p>{t("menuTemplate.locations")}</p>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </li>
            )}
            {userRole == 1 && (
              <li
                className={`menu-body-item ${location.pathname === "/eventos/" + param.id + "/editar" ||
                  location.pathname ===
                  "/eventos/" + param.id + "/publicaciones/nueva"
                  ? "active"
                  : ""
                  }`}
              >
                <Link className="link" to={"/eventos/" + param.id + "/editar"}>
                  <SettingsOutlinedIcon className="flex align-items-center" />
                  <p>{t("menuTemplate.eventConfiguration")}</p>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
