import "./creaEventos.css";
import ImgServices1 from "../../../assets/ImgServices1.png";
import ImgServices2 from "../../../assets/ImgServices2.png";
import ImgServices3 from "../../../assets/ImgServices3.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "react-responsive";

const SectionServices = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const history = useHistory();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleButtonClick = () => {
    if (isAuthenticated) {
      history.push("/eventos");
    } else {
      loginWithRedirect({
        appState: { returnTo: "/eventos" }
      });
    }
  };
  return (
    <>
      {isMobile ? (
        <>
          <div className="mt-4" style={{ paddingLeft: "12%", paddingRight: "12%" }}>
            <div>
              <div>
                <p style={{ color: " #5656FF", fontSize: "48px", fontWeight: "bold", lineHeight: "52px", textAlign: "center" }}>
                  Además
                </p>
                <p style={{ color: "#0B0062", textAlign: "center" }}>Simplificamos la organización, potenciamos la promoción y creamos experiencias memorables para todos.</p>
              </div>
              <div>
                <div className="d-flex flex-column align-items-center pt-3">
                  <img src={`${ImgServices1}`} alt="Servicio de pulseras" style={{ width: "90%", height: "90%" }} />
                  <p className="mb-2" style={{ fontSize: "26px", color: "#5656FF", textAlign:"center" }}>
                    Impresión personalizada
                  </p>
                  <p style={{ fontSize: "18px", color: "#0B0062", textAlign:"justify"}}>
                    Haz que cada detalle cuente.
                    Ofrecemos impresión de entradas, tarjetas y pulseras personalizadas para tu evento, combinando diseño y funcionalidad.
                  </p>
                  <p style={{ fontSize: "18px", color: "#0B0062", textAlign:"justify" }}>
                    Garantiza seguridad y estilo.
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center mt-3 pt-3">
                  <img src={`${ImgServices2}`} alt="Servicio de pulseras" style={{ width: "90%", height: "90%" }} />
                  <p className="mb-2" style={{ fontSize: "26px", color: "#5656FF", textAlign:"center" }}>
                    Alquiler de equipos
                  </p>
                  <p style={{ fontSize: "18px", color: "#0B0062", textAlign:"justify" }}>
                    Ponemos a tu disposición el servidor de alquiler de equipos,
                    asegurándonos de que siempre cuentes con las herramientas necesarias para gestionar eficientemente los accesos.
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center pt-3">
                  <img src={`${ImgServices3}`} alt="Servicio de pulseras" style={{ width: "90%", height: "90%" }} />
                  <p className="mb-2" style={{ fontSize: "26px", color: "#5656FF", textAlign:"center" }}>
                    Internet confiable para tus eventos con Starlink
                  </p>
                  <p style={{ fontSize: "18px", color: "#0B0062", textAlign:"justify" }}>
                    Asegurá una conexión estable y de alta velocidad en cualquier tipo de evento con Starlink.
                    Olvidate de las limitaciones de ubicación y garantizá que tus asistentes, organizadores y sistemas trabajen sin interrupciones.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </>
      ) : (
        <>
          <div className="container-services mt-4">
            <div className="header-services">
              <p className="title-services hero-title">
                Además
              </p>
              <p className="subtitle-services">
                Simplificamos la organización, potenciamos la promoción <br /> y creamos experiencias memorables para todos.
              </p>
            </div>

            <div className="services pb-4">
              <div className="service-picture">
                <div className="d-flex flex-column justify-content-center" style={{ width: "60%" }}>
                  <p className="mb-0" style={{ fontSize: "32px", color: "#5656FF" }}>
                    Impresión personalizada
                  </p>
                  <p style={{ fontSize: "18px", color: "#0B0062" }}>
                    Haz que cada detalle cuente.
                    Ofrecemos impresión de entradas, tarjetas y pulseras personalizadas para tu evento, combinando diseño y funcionalidad.
                  </p>
                  <p style={{ fontSize: "18px", color: "#0B0062" }}>
                    Garantiza seguridad y estilo.
                  </p>
                </div>
                <div className="d-flex justify-content-end" style={{ width: "70%" }}>
                  <img src={`${ImgServices1}`} alt="Servicio de pulseras" style={{ height: "100%" }} />
                </div>
              </div>

              <div className="picture-service">
                <div className="d-flex justify-content-start" style={{ width: "70%" }}>
                  <img src={`${ImgServices2}`} alt="Servicio de pulseras" style={{ height: "100%" }} />
                </div>
                <div className="d-flex flex-column justify-content-center" style={{ width: "60%" }}>
                  <p className="mb-0" style={{ fontSize: "32px", color: "#5656FF" }}>
                    Alquiler de equipos
                  </p>
                  <p style={{ fontSize: "18px", color: "#0B0062" }}>
                    Ponemos a tu disposición el servidor de alquiler de equipos,
                    asegurándonos de que siempre cuentes con las herramientas necesarias para gestionar eficientemente los accesos.
                  </p>
                </div>
              </div>

              <div className="service-picture">
                <div className="d-flex flex-column justify-content-center" style={{ width: "60%" }}>
                  <p className="mb-0" style={{ fontSize: "32px", color: "#5656FF" }}>
                    Internet confiable para tus eventos con Starlink
                  </p>
                  <p style={{ fontSize: "18px", color: "#0B0062" }}>
                    Asegurá una conexión estable y de alta velocidad en cualquier tipo de evento con Starlink.
                    Olvidate de las limitaciones de ubicación y garantizá que tus asistentes, organizadores y sistemas trabajen sin interrupciones.
                  </p>
                </div>
                <div className="d-flex justify-content-end" style={{ width: "70%" }}>
                  <img src={`${ImgServices3}`} alt="Servicio de pulseras" style={{ height: "100%", width: "90%" }} />
                </div>
              </div>

            </div>
            <div className="d-flex justify-content-center mt-4 mb-4 pb-4">
              <button type="button" className="d-flex align-items-center" style={{ marginLeft: "10px", marginBottom: "30px", borderRadius: "7px", backgroundColor: "#5656FF", padding: "6px 22px", color: "white" }} onClick={handleButtonClick}>
                Crear Evento
              </button>
            </div>
          </div>
        </>
      )}

    </>
  );
}

export default SectionServices;