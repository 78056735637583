import { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { api } from "../../../../services/api";
import Loading from "../../../../components/Loading";
import "./eventopublic.css";
import HeaderPublic from "./HeaderPublic";
import DrawerPublic from "./drawer/first-drawer/FirstDrawerMobile";
import PrivateEvent from "../../../../components/ProtectedRoutes/PrivateEvent";
import { useMediaQuery } from "react-responsive";
import { ContentDesktop } from "./contenido/desktop/ContentDesktop";
import ContentMobile from "./contenido/mobile/ContentMobile";
import FirstDrawerDesktop from "./drawer/first-drawer/FirstDrawerDesktop";

export const EventoPublic = () => {
	const history = useHistory();
	const [data, setData] = useState();
	const [isPrivated, setIsPrivated] = useState();
	const param = useParams();
	const [cantidad, setCantidad] = useState({});
	const [showResume, setShowResume] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [tarifasSeleccionadas, setTarifasSeleccionadas] = useState([]);
	const [mpVinculado, setMpVinculado] = useState();
	const isMobile = useMediaQuery({ query: `(max-width: 770px)` });

	const callApi = useCallback(async () => {
		try {
			const response = await api.eventos.getPublic(param.id);
			if (response.status === "success") {
				setData(response.data.evento);
				setIsPrivated(response.data.evento.privado)
			} else {
				history.push('/');
				return;
			}


		} catch (error) {
			console.error = () => { };
			history.push('/');
		}
	}, [param.id]);

	useEffect(() => {
		callApi()
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [callApi, history]);


	const hoy = new Date();

	const tarifasTicketsFiltrados = data?.tarifas?.filter((tarifa) => {
		const inicioVenta = new Date(tarifa.inicio_venta);
		const finVenta = new Date(tarifa.fin_venta);

		return (
			tarifa.idtipo === 1 &&
			inicioVenta <= hoy &&
			hoy <= finVenta &&
			tarifa.privado === false
		);
	});

	const tarifasVouchersFiltrados = data?.tarifas?.filter((tarifa) => {
		const inicioVenta = new Date(tarifa.inicio_venta);
		const finVenta = new Date(tarifa.fin_venta);

		return (
			tarifa.idtipo === 2 &&
			inicioVenta <= hoy &&
			hoy <= finVenta &&
			tarifa.privado === false
		);
	});

	const toggleDrawer = (newOpenDrawer) => () => {
		setOpenDrawer(newOpenDrawer);
		setShowResume(false);
	};

	if (!data) {
		return <Loading />;
	}

	return (
		<>
			{isPrivated ? (
				<PrivateEvent />
			) : (
				<>
					<HeaderPublic data={data} />

					{isMobile ?
						<ContentMobile data={data} />
						:
						<ContentDesktop
							data={data}
							cantidad={cantidad}
							toggleDrawer={toggleDrawer}
							setCantidad={setCantidad}
							tarifasTicketsFiltrados={tarifasTicketsFiltrados}
							tarifasVouchersFiltrados={tarifasVouchersFiltrados}
							setTarifasSeleccionadas={setTarifasSeleccionadas} />
					}

					{(tarifasTicketsFiltrados?.length > 0 || tarifasVouchersFiltrados?.length > 0) && (
						<>
							{isMobile ?
								<DrawerPublic
									data={data}
									cantidad={cantidad}
									toggleDrawer={toggleDrawer}
									openDrawer={openDrawer}
									setCantidad={setCantidad}
									mpVinculado={mpVinculado}
									tarifasTicketsFiltrados={tarifasTicketsFiltrados}
									tarifasVouchersFiltrados={tarifasVouchersFiltrados}
									tarifasSeleccionadas={tarifasSeleccionadas}
									setTarifasSeleccionadas={setTarifasSeleccionadas}
								/>
								:
								<FirstDrawerDesktop
									data={data}
									cantidad={cantidad}
									toggleDrawer={toggleDrawer}
									openDrawer={openDrawer}
									setCantidad={setCantidad}
									mpVinculado={mpVinculado}
									tarifasTicketsFiltrados={tarifasTicketsFiltrados}
									tarifasVouchersFiltrados={tarifasVouchersFiltrados}
									tarifasSeleccionadas={tarifasSeleccionadas}
									setTarifasSeleccionadas={setTarifasSeleccionadas} />
							}
						</>
					)}
				</>
			)}
		</>
	);

};
