import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function DeleteCollaboratorModal(props) {
  const { className, id } = props;

  const history = useHistory();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [t] = useTranslation("global");

  function funDelete(id) {
    const response = api.colaboradores.deleteColaborador(id);
    return response
    .then(response => {
      if (response.status === "error"){
        setError("Error: "+ response.message)
    }
    if (response.status === "success") {
      setSubmitted(true)
      setTimeout(() => {
        setModal(!modal);
        history.go(0);
      }, 1000);
    }

  })
  }

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  );

  return (
    <div>
      <Button color="danger" onClick={toggle}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className} style={{ zIndex: 9999, paddingTop:"80px"}}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {t("collaborator.deleteCollaborator")}
        </ModalHeader>
        <ModalBody>{t("collaborator.questionDelete")}</ModalBody>
        {error && <Alert color="danger">{error}</Alert>}
              {submitted && (
                <Alert color="success">
                  {t("collaborator.successDeleteAlert")}
                </Alert>
              )}
        <ModalFooter>
          <Button color="danger" onClick={() => funDelete(id)}>
          {t("button.delete")}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
          {t("button.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
