import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { Container, TextField, FormControl, InputLabel, Select, MenuItem, Button, Box, Alert, AlertTitle, FormHelperText, MobileStepper } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export const NuevoTicket = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [categorias, setCategorias] = useState();
  const [ubicaciones, setUbicaciones] = useState();
  const [tarifas, setTarifas] = useState();
  const [importe, setImporte] = useState()
  const [tarifaSeleccionada, setTarifaSeleccionada] = useState('');
  const param = useParams();
  const history = useHistory();
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    idubicacion: '',
    idcategoria: ' ',
    idtarifa: '',
    cantidadtarifas: '1',
    importe: '',
    cantidadtickets: '1'
  })
  const [dataRtas, setDataRtas] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        let totalCategorias = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';

        while (hasMoreTickets) {
          const response = await api.categorias.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const categorias = response.data.categorias;

            if (categorias && categorias.length > 0) {
              totalCategorias = [...totalCategorias, ...categorias];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
          setCategorias(totalCategorias)
        }
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
    }
    fetchCategorias();
    document.title = `Crear ticket | PassGo`;
  }, [param.id, state]);

  useEffect(() => {
    const fetchUbicaciones = async () => {
      try {
        let totalUbicaciones = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';

        while (hasMoreTickets) {
          const response = await api.locations.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const ubicaciones = response.data.ubicaciones;

            if (ubicaciones && ubicaciones.length > 0) {
              totalUbicaciones = [...totalUbicaciones, ...ubicaciones];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
          setUbicaciones(totalUbicaciones)
        }
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        })
      }
    }
    fetchUbicaciones();
  }, [param.id, state])

  useEffect(() => {
    const fetchTarifa = async () => {
      try {
        let totalTarifas = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';

        while (hasMoreTickets) {
          const response = await api.tarifas.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const tarifas = response.data.tarifas;
            /* console.log("response", totalTarifas) */

            if (tarifas && tarifas.length > 0) {
              totalTarifas = [...totalTarifas, ...tarifas];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
          setTarifas(totalTarifas)
        }
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
    }
    fetchTarifa();
  }, [param.id, state]);


  const entradasTarifas = tarifas?.filter((tarifa) => tarifa.idtipo === 1);

  const [unidad, setUnidad] = useState('');
  const selectedTarifa = tarifas?.find((tarifa) => tarifa.id === parseInt(formData.idtarifa));

  useEffect(() => {

    setUnidad(selectedTarifa ? selectedTarifa.unidad : '');
    setTarifaSeleccionada(selectedTarifa ? selectedTarifa.concepto : '');
    if (selectedTarifa && formData.cantidadtarifas) {
      const cantidad = parseInt(formData.cantidadtarifas);
      const precio = selectedTarifa.precio;
      setImporte(cantidad * precio);
    } else {
      setImporte(0);
    }
  }, [formData.idtarifa, formData.cantidadtarifas, tarifas]);



  const handleRespuestasChange = (tarifaId, indexTicket, indexPregunta, valor) => {
    setDataRtas(prevState => {
      const newState = { ...prevState };
      const uniqueId = `${tarifaId}-${indexTicket}`;

      if (!newState[uniqueId]) {
        newState[uniqueId] = {
          respuestas: {}
        };
      }

      newState[uniqueId].respuestas[indexPregunta] = valor;
      return newState;
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "cantidadtickets") {
      if (value === '' || /^[1-9]\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const idCategoriaInt = parseInt(formData.idcategoria);
  const idUbicacionInt = parseInt(formData.idubicacion);
  const idTarifa = parseInt(formData.idtarifa);
  const cantidadTarifas = parseInt(formData.cantidadtarifas);
  const cantidadTickets = parseInt(formData.cantidadtickets);
  /* console.log("formdata", cantidadTickets); */
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (formData.nombre === '') {
      validationErrors.nombre = t('errors.errorName');
    }
    if (formData.idcategoria === '') {
      validationErrors.idcategoria = t('errors.errorCategory');
    }
    // if (formData.idubicacion === '') {
    //   validationErrors.idubicacion = t('errors.errorLocation');
    // }
    if (formData.idtarifa === '') {
      validationErrors.idtarifa = t('errors.errorRate');
    }


    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }


    const respuestas = [];

    for (let i = 0; i < cantidadTickets; i++) {
      const rtas = selectedTarifa?.adicionales?.map(pregunta => ({
        id_informacion_adicional: pregunta.id,
        respuesta: dataRtas[`${selectedTarifa.id}-${i}`]?.respuestas?.[pregunta.id] || ""
      })) || [];

      if (rtas.some(rta => rta.respuesta !== "")) {
        respuestas.push({
          respuestas: rtas
        });
      }
    }

    const data = {
      ticket: {
        nombre: formData.nombre,
        apellido: formData.apellido,
        idubicacion: idUbicacionInt,
        idcategoria: idCategoriaInt,
        idtarifa: idTarifa,
        cantidad: cantidadTarifas,
        importe: importe
      },
      cantidad: cantidadTickets,
    };

    if (respuestas.length > 0) {
      data.respuestas = respuestas;
    }

    try {
      const response = await api.tickets.createTicket(param.id, data);

      if (response.status === 'success') {

        toast.success(t("tickets.ticketCreated"), {
          position: "top-right",
          autoClose: 2000,
        });

        setTimeout(() => {
          history.push(`/eventos/${param.id}`);
        }, 2000);
      }
      else {
        console.error('Error al crear el ticket:', response.error);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container>
      <div
        className="cartel-atencion d-flex"
        style={{
          width: "fit-content",
          background: "#eeeeee",
          padding: "12px 20px",
          borderRadius: "15px",
          marginBottom: "20px"
        }}
      >
        <div
          style={{
            width: "40px",
            height: "40px",
            marginRight: "10px",
            background: "#5656FF",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <PriorityHighIcon sx={{ fontSize: 30, color: "white" }} />
        </div>
        <div>
          <h4 className="mb-0">{t("tickets.attention")}</h4>
          <p className="mb-1">{t("tickets.import")}</p>
          <div className="d-flex justify-content-end">
            <Button
              size="small"
              className="m-0 fw-semibold"
              style={{ color: "#5656FF" }}
              onClick={() => history.push("/eventos/" + param.id + "/nuevoTicket/importarExcel")}
            >
              CARGAR EXCEL
            </Button>
          </div>
        </div>
      </div>

      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <div
          className="formulario-resumen"
          style={{
            display: "flex",

            flexDirection: isMobile ? 'column' : 'row',
            gap: "20px"
          }}
        >
          <div
            className="formulario-campos"
            style={{ flex: 1 }}
          >
            <div style={{ flexDirection: isMobile ? 'column' : 'row' }} className="w-100 d-flex gap-3 mb-3">
              <TextField
                name="nombre"
                label="Nombre"
                variant="outlined"
                value={formData.nombre}
                onChange={handleChange}
                style={{ width: isMobile ? "100%" : "50%" }}
                fullWidth={isMobile}
                error={errors.nombre}
                helperText={errors.nombre && errors.nombre}
              />
              <TextField
                name="apellido"
                label="Apellido"
                variant="outlined"
                value={formData.apellido}
                onChange={handleChange}
                style={{ width: isMobile ? "100%" : "50%" }}
              />
            </div>
            <div style={{ flexDirection: isMobile ? 'column' : 'row' }} className="w-100 d-flex gap-3 mb-3">
              <FormControl
                error={errors.idtarifa}
                style={{ width: isMobile ? "100%" : "50%" }}
              >
                <InputLabel id="demo-simple-select-label">{t("tickets.rates")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="idtarifa"
                  label={t("tickets.rates")}
                  variant="outlined"
                  value={formData.idtarifa}
                  onChange={handleChange}
                >
                  {entradasTarifas?.map((tarifa) => (
                    <MenuItem key={tarifa.id} value={tarifa.id}>
                      {tarifa.concepto}
                    </MenuItem>
                  ))}


                </Select>
                <FormHelperText>{errors.idtarifa}</FormHelperText>
              </FormControl>
              <FormControl
                // error={errors.idubicacion}
                style={{ width: isMobile ? "100%" : "50%" }}
              >
                <InputLabel id="demo-simple-select-label">{t("tickets.locations")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="idubicacion"
                  label={t("tickets.locations")}
                  variant="outlined"
                  value={formData.idubicacion}
                  onChange={handleChange}
                >
                  {ubicaciones?.map((ubicacion) => (
                    <MenuItem key={ubicacion.id} value={ubicacion.id}>
                      {ubicacion.ubicacion}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>{errors.idubicacion}</FormHelperText> */}
              </FormControl>
            </div>
            <TextField
              name="cantidadtickets"
              label={t("tickets.numberOfTickets")}
              variant="outlined"
              value={formData.cantidadtickets}
              onChange={handleChange}
              inputProps={{
                min: 1,
              }}
            // error={errors.cantidadvouchers}
            // helperText={errors.cantidadvouchers && errors.cantidadvouchers}
            />

          </div>

          <div
            // className="resumen px-3 border-start border-dark-subtle"
            className={`resumen px-3 ${!isMobile && "border-start border-dark-subtle"} `}
            style={{
              width: '300px',
              display: "flex",
              flexDirection: "column"
            }}
          >
            <h5 style={{ textAlign: "center" }}>
              {t("tickets.summary")}
            </h5>
            <hr className="mt-0" />
            {tarifaSeleccionada && formData.cantidadtarifas > 0 && (
              <p>
                {tarifaSeleccionada} x {formData.cantidadtarifas} {unidad}
              </p>
            )}
            <div
              className="d-flex justify-content-between mt-auto"
              style={{
                backgroundColor: '#ededed',
                padding: '4px 7px'
              }}
            >
              <h4 style={{ margin: '0px' }}>
                {t("tickets.total")}:
              </h4>
              <h4 style={{ margin: '0px' }}>
                {importe === 0 || !tarifaSeleccionada || !formData.cantidadtarifas
                  ? '0'
                  :
                  `$${importe * cantidadTickets || ""}`
                }
              </h4>
            </div>
          </div>
        </div>
        <div className="mt-3" style={{ width: "40%" }}>
          {Array.from({ length: cantidadTickets }).map((_, index) => (
            <div key={index} style={{ display: index === activeStep ? 'block' : 'none' }}>
              {selectedTarifa?.adicionales?.length > 0 && (
                <p>{`Informacion adicional ticket N° ${index + 1}`} </p>
              )}
              {selectedTarifa?.adicionales?.map((pregunta, pregIndex) => (
                <TextField
                  key={`${pregunta.id}-${index}`}
                  placeholder={pregunta.informacion_adicional}
                  fullWidth
                  required
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                  }}
                  value={dataRtas[`${selectedTarifa.id}-${index}`]?.respuestas?.[pregunta.id] || ""}
                  onChange={(e) => handleRespuestasChange(selectedTarifa.id, index, pregunta.id, e.target.value)}
                />
              ))}
            </div>
          ))}
          <MobileStepper
            variant="progress"
            steps={cantidadTickets}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === cantidadTickets - 1}>
                Siguiente
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {t("button.back")}
              </Button>
            }
          />
        </div>
        <div className="formulario-botones d-flex justify-content-end gap-3 mt-3">
          <Button
            variant="outlined"
            onClick={() => history.push("/eventos/" + param.id)}
          >
            {t("button.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
          >
            Enviar
          </Button>
        </div>
      </Box>
    </Container>
  )
}