import React, { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { 
    TextField, 
    Box, 
    Button, 
    Alert, 
    FormControl,
    Accordion,
    AccordionActions,
    AccordionSummary,
    AccordionDetails, 
    IconButton,
    Chip
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";


export const PublicarEvento = ({ eventId }) => {
    const [formData, setFormData] = useState({
        eventoId: eventId,
        titulo: "",
        descripcion: "",
        desde: "",
        hasta: "",
    });
    const [error, setError] = useState("");
    const [t] = useTranslation("global");
    const [files, setFiles] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [mediaUrls, setMediaUrls] = useState([]);
    const [mediaTypes, setMediaTypes] = useState([]);
    const [mediaFormats, setMediaFormats] = useState([]);
    const [mediaId, setMediaId] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [lengthDescrip, setLengthDescrip] = useState("");
    const [selectedFileIndex, setSelectedFileIndex] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        setLengthDescrip(value.length);
    };

    const handleAddFileClick = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*,video/*";
        fileInput.multiple = true;
        fileInput.onchange = handleFileChange;
        fileInput.click();
    };

    const handleFileChange = async (e) => {
        const selectedFiles = Array.from(e.target.files);

        setFiles((prevFiles) => {
            const combinedFiles = [...prevFiles, ...selectedFiles];
            if (selectedFileIndex === null && selectedFiles.length > 0) {
                setSelectedFileIndex(prevFiles.length);
            }
            return combinedFiles;
        });

        await uploadFiles(selectedFiles);
    };

    const uploadFiles = async (filesToUpload) => {
        const formData = new FormData();
        filesToUpload.forEach((file) => {
            formData.append("files", file);
        });

        try {
            const response = await api.media.upload(formData);
            if (response.status === "success") {
                const medias = response.data.media;
                setMediaUrls(prevUrls => [...prevUrls, ...medias.map(media => media.url)]);
                setMediaTypes(prevTypes => [...prevTypes, ...medias.map(media => media.type)]);
                setMediaFormats(prevFormats => [...prevFormats, ...medias.map(media => media.format)]);
                setMediaId(prevIds => [...prevIds, ...medias.map(media => media.public_id)]);
            }
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };

    const deleteMedia = async (index) => {
        const publicId = mediaId[index];

        try {
            const response = await api.media.delete({ public_id: publicId });
            if (response.status === "success") {
                setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                setFileNames(prevNames => prevNames.filter((_, i) => i !== index));
                setMediaUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
                setMediaTypes(prevTypes => prevTypes.filter((_, i) => i !== index));
                setMediaFormats(prevFormats => prevFormats.filter((_, i) => i !== index));
                setMediaId(prevIds => prevIds.filter((_, i) => i !== index));

                if (selectedFileIndex === index) {
                    setSelectedFileIndex(null);
                } else if (selectedFileIndex > index) {
                    setSelectedFileIndex(prevIndex => prevIndex - 1);
                }
            }
        } catch (error) {
            console.error("Error deleting media:", error);
        }
    };

    const renderFilePreview = (file, index) => {
        const isImage = file.type.startsWith("image/");
        const isVideo = file.type.startsWith("video/");
        const fileURL = URL.createObjectURL(file);

        const thumbnailClass = selectedFileIndex === index ? "thumbnail selected" : "thumbnail";

        return (
            <div key={index} className={thumbnailClass} onClick={() => handleThumbnailClick(index)}>
                {isImage && <img src={fileURL} alt={`Preview ${index}`} />}
                {isVideo && <video src={fileURL} />}
            </div>
        );
    };

    const handleThumbnailClick = (index) => {
        setSelectedFileIndex(index);
    };

    const renderMainPreview = () => {
        if (selectedFileIndex === null || files.length === 0) return null;

        const selectedFile = files[selectedFileIndex];
        const isImage = selectedFile.type.startsWith("image/");
        const isVideo = selectedFile.type.startsWith("video/");
        const fileURL = URL.createObjectURL(selectedFile);

        const handleRemoveFile = () => {
            setFiles((prevFiles) => prevFiles.filter((_, index) => index !== selectedFileIndex));
            setSelectedFileIndex(null); // Resetea la seleccion
        };

        return (
            <div className="main-preview">
                {isImage && <img src={fileURL} alt="Imagen seleccionada" />}
                {isVideo && (
                    <video src={fileURL} controls />
                )}

                <button type="button" className="remove-button" onClick={() => {
                    deleteMedia(selectedFileIndex);
                    handleRemoveFile();
                }}>
                    Eliminar
                </button>

            </div>
        );
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const hoy = new Date();

        const newErrors = {};

        if (formData.titulo.length > 50) newErrors.titulo = "El titulo de la publicacion no puede exceder 50 caracteres."
        else if (formData.titulo.length < 5) newErrors.titulo = "El titulo de la publicacion debe tener al menos 5 caracteres."

        if (formData.descripcion.trim() === "") newErrors.descripcion = "La descripción es obligatoria."
        else if (formData.descripcion.length > 80) newErrors.descripcion = "La descripción no puede superar 80 caracteres."
        else if (formData.descripcion.length < 5) newErrors.descripcion = "La descripción necesita al menos 5 caracteres."

        if (formData.desde === "" || formData.hasta === "") newErrors.fechas = "Las fecha de inicio y finalizacion de la publicacion son obligatorias."
        //if (formData.hasta > fechaEvento) newErrors.hasta = "La fecha de finalizacion de la publicacion no puede ser mayor a la fecha de realizacion del evento."
        if (formData.desde < hoy) newErrors.desde = "La fecha de inicio de la publicacion no puede ser anterior a hoy."

        if (files.length === 0) newErrors.files = "Por favor, sube un archivo.";

        // detiene el envio si hay errores
        if (Object.keys(newErrors).length > 0) {
            setError(newErrors);
            return;
        }

        const desdeFormatted = moment.tz(formData.desde, "America/Buenos_Aires").format("YYYY-MM-DDT00:00:00-03:00");
        const hastaFormatted = moment.tz(formData.hasta, "America/Buenos_Aires").format("YYYY-MM-DDT00:00:00-03:00");

        const data = {
            idevento: parseInt(eventId),
            titulo: formData.titulo,
            descripcion: formData.descripcion,
            desde: desdeFormatted,
            hasta: hastaFormatted,
            attachs: mediaUrls.map((url, index) => ({
                url: url,
                type: mediaTypes[index],
                format: mediaFormats[index],
                public_id: mediaId[index],
            })),
        };

        try {
            const response = await api.publicaciones.createPublicacion(data);
            if (response.status === "success") {
                setFormData({
                    eventoId: "",
                    titulo: "",
                    descripcion: "",
                    desde: "",
                    hasta: "",
                });
                setFiles([]);
                setMediaUrls("");
                setMediaTypes("");
                setMediaFormats("");
                setMediaId("");
                setError("");
                setSubmitted(true);
            } else {
                setError(<>{t("publications.error1")}</>);
            }
        } catch (error) {
            setError(<>{t("publications.error2")}</>);
        }
    };

    //Logica de las FAQS
    const [dataFAQSInit, setDataFAQSInit] = useState({
        idevento: eventId,
        pregunta: "",
        respuesta: "",
    });
    const [dataFAQS, setDataFAQS] = useState(dataFAQSInit);
    const [errorFAQS, setErrorFAQS] = useState({})
    const [showFAQSection, setShowFAQSection] = useState(false); 
    const [editMode, setEditMode] = useState(false); 
    const [editFAQId, setEditFAQId] = useState(null);

    const handleChangeFAQS = (e) => {
        const { name, value } = e.target;
        setDataFAQS({
            ...dataFAQS,
            [name]: value,
        });
        setErrorFAQS((prevErrors) => ({
            ...prevErrors,
            [name]: "", 
        }));
    };

    const handleSubmitFAQS = async (e) => { 
        e.preventDefault();
        let validationErrors = {};

        if (!dataFAQS.pregunta.trim()) {
            validationErrors.pregunta = "La pregunta es obligatoria";
        }

        if (!dataFAQS.respuesta.trim()) {
            validationErrors.respuesta = "La respuesta es obligatoria";
        }
          
        if (Object.keys(validationErrors).length > 0) {
            setErrorFAQS(validationErrors);
            return;
        }

        const data = {
            idevento: eventId,
            pregunta: dataFAQS.pregunta,
            respuesta: dataFAQS.respuesta
        };

        try {
            if (editMode) {
                const response = await api.faqs.editFaqs(eventId, editFAQId, dataFAQS);
                if (response.status === 'success') {
                    callApiFAQS();
                    setEditMode(false);
                    setEditFAQId(null);
                } else {
                    console.error("Error al editar la FAQ:", response.error);
                }
            } else {
                const response = await api.faqs.createFaqs(eventId, dataFAQS);
                if (response.status === 'success') {
                    callApiFAQS();
                } else {
                    console.error("Error al crear la FAQ:", response.error);
                }
            }
            setDataFAQS(dataFAQSInit);
        } catch (error) { 
            console.error('Error en la solicitud:', error.message); 
        } 
    }
    
    const handleEditFAQ = (faq) => {
        setDataFAQS({
            idevento: eventId,
            pregunta: faq.pregunta,
            respuesta: faq.respuesta,
        });
        setEditFAQId(faq.id); 
        setEditMode(true);
        setShowFAQSection(true);
    };

    const DeleteFAQS = async (id) => {
        try {
          const response = await api.faqs.deleteFaqs(eventId, id); 
          if (response.status === "error") {
            
          } else {
            callApiFAQS()
          }
        } catch (error) {

        }
      };


    const [faqs, setFaqs] = useState([]);
    const callApiFAQS = async () =>{
        try {
          const response = await api.faqs.getAll(eventId);
            if(response.status === "success"){
                setFaqs(response.data.FAQS);
            }
        } catch (error) {

        }
    }
    useEffect(() => {
        callApiFAQS();
    }, []);

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px", padding: "0 10px" }}>
                <div style={{ maxWidth: "600px", width: "100%" }}>
                    <div>
                        <Box component="form" id="formPublicacion" onSubmit={handleSubmit}>
                            <FormControl sx={{ width: "100%" }}>
                                <p className="subtitulo-seccion-evento-expanded">Título <span className="text-danger">*</span></p>
                                <p className="descrip-seccion-evento-expanded mb-0">Ingrese un titulo para su publicacion</p>
                                <TextField
                                    sx={{
                                        marginTop: "5px",
                                        marginBottom: "15px",
                                        input: {
                                            padding: "10px 15px"
                                        }
                                    }}
                                    type="text"
                                    id="titulo"
                                    name="titulo"
                                    value={formData.titulo}
                                    onChange={handleInputChange}
                                />
                                <>
                                    {error.files &&
                                        <Alert severity="error"
                                            sx={{
                                                marginBottom: "10px"
                                            }}>{error.titulo}</Alert>
                                    }
                                </>
                            </FormControl>

                            <FormControl sx={{ width: "100%" }}>
                                <p className="subtitulo-seccion-evento-expanded">Descripción <span className="text-danger">*</span></p>
                                <p className="descrip-seccion-evento-expanded mb-0">Ingrese una descripcion concisa y atrayente para su publicacion</p>
                                <TextField
                                    sx={{
                                        marginTop: "5px",
                                        marginBottom: "15px",
                                        input: {
                                            padding: "10px 15px"
                                        }
                                    }}
                                    multiline
                                    rows={2}
                                    id="descripcion"
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleInputChange}

                                />
                                <div style={{ position: "relative", top: "-10px" }}>
                                    {lengthDescrip === 80 ? (
                                        <p className="text-right" style={{ color: "#c44536", fontSize: "12px" }}>
                                            {lengthDescrip}/80
                                        </p>
                                    ) : (
                                        <p className="text-right" style={{ fontSize: "12px", color: "#666" }}>
                                            {lengthDescrip}/80
                                        </p>
                                    )}
                                </div>
                                <>
                                    {error.descripcion &&
                                        <Alert severity="error"
                                            sx={{
                                                position: "relative",
                                                top: "-25px",
                                                marginBottom: "15px"
                                            }}>{error.descripcion}</Alert>
                                    }
                                </>
                            </FormControl>

                            <div className="mb-3" style={{ position: "relative", top: "-20px" }}>
                                <p className="subtitulo-seccion-evento-expanded">Periodo de la publicación <span className="text-danger">*</span></p>
                                <p className="descrip-seccion-evento-expanded mb-0 text-justify">Selecciona las fechas en las que deseas que tu publicación sea visible.
                                    La publicación comenzará a mostrarse en la fecha de inicio y dejará de estar disponible después de la fecha de finalización.</p>
                                <div className="d-flex flex-row gap-4 flex-wrap mt-1">
                                    <FormControl sx={{ width: "35%" }}>
                                        <TextField
                                            sx={{
                                                marginTop: "5px",
                                                marginBottom: "15px",
                                                input: {
                                                    padding: "10px 15px"
                                                }
                                            }}
                                            id="desde"
                                            name="desde"
                                            type="date"
                                            value={formData.desde}
                                            onChange={handleInputChange}
                                        />
                                        <small style={{ position: "relative", top: "-10px" }}>Fecha de inicio</small>

                                    </FormControl>
                                    <FormControl sx={{ width: "35%" }}>
                                        <TextField
                                            sx={{
                                                marginTop: "5px",
                                                marginBottom: "15px",
                                                input: {
                                                    padding: "10px 15px"
                                                }
                                            }}
                                            id="hasta"
                                            name="hasta"
                                            type="date"
                                            value={formData.hasta}
                                            onChange={handleInputChange}
                                        />
                                        <small style={{ position: "relative", top: "-10px" }}>Fecha de finalizacion</small>
                                    </FormControl>
                                    <>
                                        {error.desde &&
                                            <Alert severity="error"
                                                sx={{
                                                    position: "relative",
                                                    top: "-20px",
                                                }}>{error.desde}</Alert>
                                        }
                                        {error.hasta &&
                                            <Alert severity="error"
                                                sx={{
                                                    position: "relative",
                                                    top: "-20px",
                                                }}>{error.hasta}</Alert>
                                        }
                                        {error.fechas &&
                                            <Alert severity="error"
                                                sx={{
                                                    position: "relative",
                                                    top: "-20px",
                                                }}>{error.fechas}</Alert>
                                        }
                                    </>
                                </div>
                            </div>

                            <FormControl sx={{ width: "100%" }}>
                                <p className="subtitulo-seccion-evento-expanded mb-2">Imagenes para tu publicacion <span className="text-danger">*</span></p>
                                <p className="descrip-seccion-evento-expanded">
                                    Sube hasta 5 imágenes y videos que muestren los detalles y momentos destacados para promocionar tu evento.
                                    Asegúrate de elegir archivos de alta calidad para un mejor impacto visual.
                                </p>
                                <div className="info-input-img">
                                    <ul className="ul-info-img">
                                        <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño recomendado de imagen: 19200x1280</li>
                                        <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño máximo de archivo: 15MB</li>
                                        <li style={{ fontSize: "10px", fontWeight: "normal" }}>Formatos admitidos de imagen: JPEG, PNG</li>
                                    </ul>
                                </div>
                                <div className={`input-file ${files.length === 0 ? "" : "border-0"}`}>
                                    <input
                                        id="files"
                                        name="files"
                                        type="file"
                                        accept="image/*,video/*"
                                        multiple
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                    />

                                    {files.length === 0 ? (
                                        <label
                                            className="placeholder-input-img"
                                            htmlFor="files"
                                        >
                                            {t("event.inputImage")}
                                        </label>

                                    ) : (
                                        <>
                                            <div className="imagen-contenedor">
                                                {renderMainPreview()}
                                                <div className="thumbnails-contenedor">
                                                    <div className="boton-agregar-img" onClick={handleAddFileClick}>
                                                        <AddOutlinedIcon sx={{ color: "#3659E3" }} />
                                                    </div>
                                                    {files.map(renderFilePreview)}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <>
                                    {error.files &&
                                        <Alert severity="error"
                                            sx={{
                                                marginTop: "10px"
                                            }}>{error.files}</Alert>
                                    }
                                </>
                            </FormControl>
                        </Box>
                        {/* Formulario FAQS */}
                        <Box component="form" id="formFAQS" onSubmit={handleSubmitFAQS} sx={{ width: "100%", marginTop: "30px" }}>
                            <Box sx={{marginBottom: "7px", display: "flex", alignItems: "center", gap: "5px"}}>
                                <p className="subtitulo-seccion-evento-expanded m-0">Preguntas frecuentes</p>
                                <Chip label="Opcional" variant="outlined" size="small"/>
                            </Box>
                            
                            <p className="descrip-seccion-evento-expanded mb-0">
                                Agrega preguntas frecuentes personalizadas para ayudar a las personas interesadas en tu evento a resolver dudas comunes.
                            </p>

                            {!showFAQSection ? (
                                <Box sx={{display: "flex", justifyContent: "end"}}>
                                    <Button
                                        variant="text"
                                        onClick={() => setShowFAQSection(true)}
                                        sx={{ marginBottom: "20px", fontWeight: 500 }}
                                    >
                                        + Agregar preguntas
                                    </Button>
                                </Box>
                            ) : (
                                <>
                                    <FormControl sx={{ width: "100%", marginTop: "7px" }}>
                                        <TextField
                                            sx={{
                                                marginTop: "5px",
                                                input: {
                                                    padding: "10px 15px",
                                                },
                                            }}
                                            type="text"
                                            placeholder="Pregunta"
                                            name="pregunta"
                                            value={dataFAQS.pregunta}
                                            onChange={handleChangeFAQS}
                                            error={Boolean(errorFAQS.pregunta)}
                                        />
                                        <TextField
                                            sx={{
                                                marginTop: "5px",
                                                input: {
                                                    padding: "10px 15px",
                                                },
                                            }}
                                            multiline
                                            rows={2}
                                            placeholder="Respuesta"
                                            name="respuesta"
                                            value={dataFAQS.respuesta}
                                            onChange={handleChangeFAQS}
                                            error={Boolean(errorFAQS.respuesta)}
                                        />
                                    </FormControl>
                                    {errorFAQS.pregunta && (
                                        <Alert severity="error" sx={{ marginTop: "5px" }}>
                                            {errorFAQS.pregunta}
                                        </Alert>
                                    )}
                                    {errorFAQS.respuesta && (
                                        <Alert severity="error" sx={{ marginTop: "5px" }}>
                                            {errorFAQS.respuesta}
                                        </Alert>
                                    )}
                                    <div className="d-flex justify-content-end mb-2">
                                        <Button variant="text" type="submit" className="fw-medium">
                                            Agregar +
                                        </Button>
                                    </div>
                                </>
                            )}

                            {faqs?.map((faq) => (
                                <Accordion key={faq.id} style={{ backgroundColor: "#f9f9f9", marginBottom: "7px" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id={`panel1-header-${faq.id}`}
                                        sx={{ width: "100%", fontSize: "14px", fontWeight: "500" }}
                                    >
                                        <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                                            <span>{faq.pregunta}</span>
                                            <Box sx={{display: "flex", alignItems: "center", gap: "7px", marginLeft: "7px"}}>
                                                <IconButton sx={{padding: "0px" }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditFAQ(faq);
                                                    }}
                                                >
                                                    <ModeEditIcon sx={{fontSize: "19px"}} />
                                                </IconButton>
                                                <IconButton 
                                                    onClick={(e) => {
                                                        e.stopPropagation(); 
                                                        DeleteFAQS(faq.id);
                                                    }}
                                                    sx={{padding: "0px"}}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ fontSize: "14px" }}>
                                        {faq.respuesta}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                            
                        {error && <Alert severity="error">Por favor, chequea la información ingresada.</Alert>}
                        {submitted && (
                            <Alert color="success">
                                {t("publications.successCreatedAlert")}
                            </Alert>
                        )}
                        {!submitted && (
                            <div className="d-flex justify-content-end mb-4">
                                <Button
                                    type="submit"
                                    form="formPublicacion"
                                    className="mt-3 boton-submit"
                                >
                                    {t("button.create")} publicacion
                                </Button>
                            </div>
                        )}
                    </div>
                </div >
            </div >
        </div>
    )
}
