import React, { useState, useEffect } from "react";
import { Drawer, Button, TextField, CircularProgress } from "@mui/material";
import { CloseOutlined, ArrowBackIosNewOutlined, ShoppingCartOutlined, } from "@mui/icons-material";

const SecondDrawer = ({ open, onClose, tarifasSeleccionadas, dataTarifas, setDataTarifas, cantidad, onBack, handlePagoDirecto }) => {
  const [loadingCarrito, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});


  const validateFields = () => {
    let isValid = true;
    const newErrors = {};
  
    tarifasSeleccionadas.forEach((tarifa) => {
      Array.from({ length: cantidad[tarifa.id] }).forEach((_, indexdos) => {
        const key = `${tarifa.id}-${indexdos}`;
        
        tarifa?.adicionales?.forEach((pregunta) => {
          const respuesta = dataTarifas[key]?.respuestas?.[pregunta.id];
          if (!respuesta || respuesta.trim() === '') {
            newErrors[`${key}-${pregunta.id}`] = true;
            isValid = false;
          }
        });
      });
    });

    setFormErrors(newErrors);
    return isValid;
  }

  const handleSubmit = () => {
    if (!validateFields()) {
      return;
    }
    handlePagoDirecto();
  };

  useEffect(() => {
    if (!open) {
      setDataTarifas([]);
    }
  }, [open]);

  return (
    <Drawer
      transitionDuration={500}
      anchor="bottom"
      open={open}
      onClose={onClose}
      sx={{
        minWidth: "80%",
        padding: "40px",
        "& .MuiDrawer-paper": { borderRadius: "10px 10px 0 0", minHeight: "80%" },
      }}
    >
      <div
        style={{
          padding: "15px",
          minHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <div className="d-flex justify-content-around align-items-center">
          <button type="button" onClick={onBack}>
            <ArrowBackIosNewOutlined
              sx={{ width: "16px", height: "17px", marginRight: "5px" }}
            />
          </button>
          <p
            className="fw-bold mb-0"
            style={{ color: "#221A60", fontSize: "20px" }}
          >
            Informacion adicional
          </p>
          <button type="button">
            <CloseOutlined
              sx={{ width: "16px", height: "17px", marginRight: "5px" }}
              onClick={onClose}
            />
          </button>
        </div>

        <div
          style={{
            maxWidth: "550px",
            margin: "15px auto",
            textAlign: "left",
            /* display:"flex", */
            /* paddingRight: "10px", */
          }}
        >
          <div
            style={{ maxWidth: "500px", margin: "5px auto", textAlign: "left", height: "60%" }}
          >
            {tarifasSeleccionadas.map((tarifa, index) =>
              Array.from({ length: cantidad[tarifa.id] }).map((_, indexdos) => {
                const key = `${tarifa.id}-${indexdos}`;
                return (
                  <div key={key}>
                    {tarifa?.adicionales?.length > 0 && (
                      <p className="mb-0" style={{ fontSize: "24px" }}>
                        {`Tarifa ${tarifa.concepto} N° ${indexdos + 1}`}
                      </p>
                    )}
                    {tarifa?.adicionales && tarifa?.adicionales?.length > 0 &&
                      tarifa?.adicionales?.filter(pregunta => pregunta).map((pregunta) => (
                        <>
                          <TextField
                            key={`${key}-${pregunta.id}`}
                            placeholder={pregunta.informacion_adicional}
                            fullWidth
                            required
                            error={formErrors[`${key}-${pregunta.id}`]}
                            helperText={formErrors[`${key}-${pregunta.id}`] ? 'Este campo es requerido' : ''}
                            sx={{
                              marginTop: "5px",
                              marginBottom: "15px",
                              "& .MuiOutlinedInput-root": { borderRadius: "15px" },
                              input: { padding: "10px 15px", fontSize: "14px", fontFamily: "Inter" },
                            }}
                            value={dataTarifas[key]?.respuestas?.[pregunta.id] || ""}
                            onChange={(e) => {
                              setFormErrors((prev) => ({
                                ...prev,
                                [`${key}-${pregunta.id}`]: false,
                              }));
                              setDataTarifas((prev) => ({
                                ...prev,
                                [key]: {
                                  ...prev[key],
                                  respuestas: {
                                    ...prev[key]?.respuestas,
                                    [pregunta.id]: e.target.value,
                                  },
                                },
                              }));
                            }}
                          />
                        </>
                      ))}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="boton-submit"
            /* style={{ width: "45%" }} */
            onClick={handleSubmit}
            sx={{
              '@media (max-width: 600px)': {
                width: '80%',
                fontSize: '16px',
                padding: '12px',
                backgroundColor: '#1976d2',
                color: 'white',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#1565c0',
                },
              },
              width: '45%',
              fontSize: '16px',
              padding: '12px',
              backgroundColor: '#1976d2',
              color: 'white',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#1565c0',
              },
            }}
          >
            {loadingCarrito ? (
              <CircularProgress size="18px" sx={{ color: "white" }} />
            ) : (
              <span>
                <ShoppingCartOutlined
                  sx={{
                    color: "white",
                    width: "18px",
                    height: "18px",
                    marginRight: "5px",
                  }}
                />{" "}
                Finalizar compra
              </span>
            )}
          </Button>
        </div>

      </div>
    </Drawer>
  );
};

export default SecondDrawer;
