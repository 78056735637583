import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"
import "moment/locale/es";
import moment from "moment-timezone";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { api } from '../../services/api';
import { useMediaQuery } from "react-responsive";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import StoreIcon from '@mui/icons-material/Store';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MenuIcon from '@mui/icons-material/Menu';

export const MenuMobil = ({ evento }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const param = useParams();
  const [t] = useTranslation("global");
  // const [qrOption, setQROption] = useState('')

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await api.participaciones.getParticipaciones();
        if (response.status === "success") {
          const rol = response.data.participaciones.find((p) => p.idevento == param.id)?.idrol;
          setUserRole(rol);
        } else {
          setState({ ...state, error: "Error al obtener el rol." });
        }
      } catch (error) {
        setState({ ...state, error: "Error en la red." });
      }
    };
    fetchRole([]);
  }, [param.id]);

  const attach = evento?.attachs?.[0];
  const urlImg = attach?.url;
  const imagen = urlImg || process.env.PUBLIC_URL + '/img_fiesta.jpg';

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [openMenu, setOpenMenu] = useState(null);
  const toggleMenu = (menuName) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };

  return (
    <div>
      {isMobile && (
        <>
          <a className="button-offcanvas" onClick={toggle}>
            <MenuIcon/>
          </a>

          <Offcanvas direction="end" isOpen={isOpen} toggle={toggle} fade={false} style={{ width: "330px", zIndex: "99999" }}>
            <OffcanvasHeader toggle={toggle}>
              <div className='menu-header d-flex align-items-center gap-2'>
                <div className='menu-header-img' style={{ width: "50px", height: "50px", flexShrink: "0" }}>
                  <img src={imagen} alt="Fiesta" className='rounded-circle' style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center" }} />
                </div>
                <div className='menu-header-info_evento'>
                  <p className='m-0'>{evento.evento}</p>
                  <small>
                    {
                      moment(evento.fechas[0].fecha)
                        .clone()
                        .local()
                        .format("D [de] MMMM YYYY")
                    }
                  </small>
                </div>
              </div>
            </OffcanvasHeader>
            <OffcanvasBody>
              <div className='menu-body'>
                <ul>
                  {userRole !== 3 && (
                    <li className='menu-body-item' onClick={toggle}>
                      <Link className="link" to={"/eventos/" + param.id}>
                        <i className="material-symbols-outlined">home</i>
                        <p style={{ fontSize: "16px" }}>{t("menuTemplate.home")}</p>
                      </Link>
                    </li>
                  )}

                  {(userRole === 6 || userRole === 1) && (
                    <>
                      { <li className='menu-body-item' onClick={toggle}>
                        <Link className="link"
                          to={"/eventos/" + param.id + "/qrvouchers/camara"}
                        >
                          <CameraAltIcon />
                          <p>{t("menuTemplate.cameraVoucher")}</p>
                        </Link>
                      </li> }
                      <li className='menu-body-item' onClick={toggle}>
                        <Link className="link"
                          to={"/eventos/" + param.id + "/qrvouchers/escaner"}
                        >
                          <QrCodeScannerIcon />
                          <p>{t("menuTemplate.scanVoucher")}</p>
                        </Link>
                      </li>
                    </>
                  )}
                  {(userRole === 5 || userRole === 1 ) && (
                    <>
                      { <li className='menu-body-item' onClick={toggle}>
                        <Link className="link"
                          to={`/eventos/${param.id}/qrtickets/camara`}
                        >
                          <CameraAltIcon />
                          <p>{t("menuTemplate.cameraAccess")}</p>
                        </Link>
                      </li> }
                      <li className='menu-body-item' onClick={toggle}>
                        <Link className="link"
                          to={`/eventos/${param.id}/qrtickets/escaner`}
                        >
                          <QrCodeScannerIcon />
                          <p>{t("menuTemplate.scanAccess")}</p>
                        </Link>
                      </li>
                    </>
                  )}

                  {(userRole !== 3 && userRole !== 5 && userRole !== 6 && userRole !== 7) && (
                    <li className="dropdown_list">
                      <a onClick={() => toggleMenu("menu4")} className="dropdown_link">
                        <div className="d-flex" style={{ marginRight: "10px" }}>
                          <i className="material-symbols-outlined" style={{ marginRight: "10px" }}>
                            qr_code
                          </i>
                          <p>{t("event.scan")}</p>
                        </div>
                        <i className={`material-symbols-outlined dropdown_arrow ${openMenu === "menu4" ? "open" : ""}`}>
                          arrow_drop_down
                        </i>
                      </a>
                      {openMenu === "menu4" && (
                        <div className="dropdown_content">
                          <ul className="dropdown_sub" style={{ marginLeft: "12px" }}>
                            {/* <li className='menu-body-item dropdown_sub' onClick={toggle}>
                              <Link className="link"
                                to={"/eventos/" + param.id + "/qrvouchers/camara"}
                              // onClick={() => setQROption("Camara")}
                              >
                                <p>{t("menuTemplate.cameraVoucher")}</p>
                              </Link>
                            </li> */}
                            <li className='menu-body-item dropdown_sub' onClick={toggle}>
                              <Link className="link"
                                to={"/eventos/" + param.id + "/qrvouchers/escaner"}
                              // onClick={() => setQROption("Escaner")}
                              >
                                <p>{t("menuTemplate.scanVoucher")}</p>
                              </Link>
                            </li>
                            {/* <li className='menu-body-item dropdown_sub' onClick={toggle}>
                              <Link className="link"
                                to={`/eventos/${param.id}/qrtickets/camara`}
                              // onClick={() => setQROption("Camara")}
                              >
                                <p>{t("menuTemplate.cameraAccess")}</p>
                              </Link>
                            </li> */}
                            <li className='menu-body-item dropdown_sub' onClick={toggle}>
                              <Link className="link"
                                to={`/eventos/${param.id}/qrtickets/escaner`}
                              // onClick={() => setQROption("Escaner")}
                              >
                                <p>{t("menuTemplate.scanAccess")}</p>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                  )}
                  {userRole !== 3 && userRole !== 5 && userRole !== 7 &&
                    <li className='menu-body-item' onClick={toggle}>
                      <Link className="link" to={`/eventos/${param.id}/caja`}>
                        <StoreIcon />
                        <p>{t("menuTemplate.cashier")}</p>
                      </Link>
                    </li>
                  }
                  {userRole !== 2 && userRole !== 5 && userRole !== 6 && userRole !== 7 && (
                    <li className='menu-body-item' onClick={toggle}>
                      <Link className="link" to={`/eventos/${param.id}/ventas`}>
                        <i className="material-symbols-outlined">trending_up</i>
                        <p>{t("menuTemplate.sales")}</p>
                      </Link>
                    </li>
                  )}
                  {userRole === 1 && (
                    <li className='menu-body-item' onClick={toggle}>
                      <Link className="link" to={"/eventos/" + param.id + "/cuentas"}>
                        <i className="material-symbols-outlined">account_balance_wallet</i>
                        <p>{t("menuTemplate.wallet")}</p>
                      </Link>
                    </li>
                  )}
                  {userRole !== 2 && userRole !== 5 && userRole !== 6 && evento?.privado !== true &&(
                    <li className='menu-body-item' onClick={toggle}>
                      <Link className="link" to={`/eventos/${param.id}/publicaciones`}>
                        <i className="material-symbols-outlined">newspaper</i>
                        <p>{t("menuTemplate.publications")}</p>
                      </Link>
                    </li>
                  )}
                  
                  {userRole !== 5 && userRole !== 6 && userRole !== 7 && (
                    <li className='dropdown_list'>
                      <a onClick={() => toggleMenu("menu1")} className='dropdown_link'>
                        <div className='d-flex' style={{ marginRight: "10px" }}>
                          <i className="material-symbols-outlined" style={{ marginRight: "10px" }}>group</i>
                          <p>Staff</p>
                        </div>
                        <i className={`material-symbols-outlined dropdown_arrow ${openMenu === "menu1" ? 'open' : ''}`}>arrow_drop_down</i>
                      </a>
                      {openMenu === "menu1" && (
                        <div className='dropdown_content'>
                          <ul className='dropdown_sub' style={{ marginLeft: "12px" }}>
                            {userRole === 1 && (
                              <li className='menu-body-item dropdown_sub' onClick={toggle}>
                                <Link className="link" to={`/eventos/${param.id}/owners`}>
                                  <p>Owners</p>
                                </Link>
                              </li>
                            )}
                            {userRole !== 2 && (
                              <li className='menu-body-item dropdown_sub' onClick={toggle}>
                                <Link className="link" to={`/eventos/${param.id}/clientes`}>
                                  <p>{t("menuTemplate.customers")}</p>
                                </Link>
                              </li>
                            )}
                            {userRole !== 3 && (
                              <li className='menu-body-item dropdown_sub' onClick={toggle}>
                                <Link className="link" to={`/eventos/${param.id}/colaboradores`}>
                                  <p>{t("menuTemplate.collaborators")}</p>
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </li>
                  )}
                  {userRole !== 5 && userRole !== 6 && (
                    <li className='dropdown_list'>
                      <a onClick={() => toggleMenu("menu2")} className='dropdown_link'>
                        <div className='d-flex'>
                          <i className="material-symbols-outlined" style={{ marginRight: "10px" }}>confirmation_number</i>
                          <p>{t("menuTemplate.manageEntries")}</p>
                        </div>
                        <i className={`material-symbols-outlined dropdown_arrow ${openMenu === "menu2" ? 'open' : ''}`}>arrow_drop_down</i>
                      </a>
                      {openMenu === "menu2" && (
                        <div className='dropdown_content'>
                          <ul className='dropdown_sub' style={{ marginLeft: "12px" }}>
                            {userRole === 1 && (
                              <li className='menu-body-item' onClick={toggle}>
                                <Link className="link" to={"/eventos/" + param.id + "/tarifas"}>
                                  <p>{t("rates.rate")}</p>
                                </Link>
                              </li>
                            )}
                            <li className='menu-body-item' onClick={toggle}>
                              <Link className="link" to={"/eventos/" + param.id + "/tickets"}>
                                <p>Tickets</p>
                              </Link>
                            </li>
                            {userRole === 1 && (
                              <li className='menu-body-item' onClick={toggle}>
                                <Link className="link" to={"/eventos/" + param.id + "/vouchers"}>
                                  <p>Vouchers</p>
                                </Link>
                              </li>
                            )}
                            
                          </ul>
                        </div>
                      )}
                    </li>
                  )}
                  {userRole !== 5 && userRole !== 6 && userRole !== 7 && (
                    <li className='dropdown_list'>
                      <a onClick={() => toggleMenu("menu3")} className='dropdown_link'>
                        <div className='d-flex' >
                          <i className="material-symbols-outlined" style={{ marginRight: "10px" }}>database</i>
                          <p>{t("menuTemplate.manageData")}</p>
                        </div>
                        <i className={`material-symbols-outlined dropdown_arrow ${openMenu === "menu3" ? 'open' : ''}`}>arrow_drop_down</i>
                      </a>
                      {openMenu === "menu3" && (
                        <div className='dropdown_content'>
                          <ul className='dropdown_sub' style={{ marginLeft: "12px" }}>
                            {/* {userRole !== 3 && (
                              <li className='menu-body-item' onClick={toggle}>
                                <Link className="link" to={"/eventos/" + param.id + "/categorias"}>
                                  <p>{t("menuTemplate.categories")}</p>
                                </Link>
                              </li>
                            )} */}
                            {userRole !== 3 && (
                              <li className='menu-body-item' onClick={toggle}>
                                <Link className="link" to={"/eventos/" + param.id + "/ubicaciones"}>
                                  <p>{t("menuTemplate.locations")}</p>
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </li>
                  )}
                  {userRole == 1 && (
                    <li className='menu-body-item' onClick={toggle}>
                      <Link className="link" to={"/eventos/" + param.id + "/editar"}>
                        <i className="material-symbols-outlined">settings</i>
                        <p>{t("menuTemplate.eventConfiguration")}</p>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </OffcanvasBody>
          </Offcanvas>
        </>
      )}
    </div>
  )
}
