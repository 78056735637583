import React, { useState, useEffect } from "react";
import { KeyboardArrowUpOutlined, KeyboardArrowLeft, KeyboardArrowRight, PlaceOutlined, KeyboardArrowDownOutlined, CalendarMonthOutlined, AccessAlarmOutlined, ExpandMore, } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, IconButton, } from "@mui/material";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import moment from "moment";

//escapado de caracteres para el wysiwyg
const sanitizeHtml = (html) => {
  // crea un div temporal
  const div = document.createElement("div");
  // guarda el html en el div
  div.innerHTML = html;

  // filtra las etiquetas permitidas
  const allowedTags = [
    "ol",
    "ul",
    "li",
    "p",
    "strong",
    "em",
    "br",
    "h1",
    "h2",
    "u",
    "a",
  ]; // etiquetas permitidas
  Array.from(div.querySelectorAll("*")).forEach((node) => {
    if (!allowedTags.includes(node.nodeName.toLowerCase())) {
      node.parentNode.removeChild(node); // elimina los que no permite
    }
  });

  return div.innerHTML; // devuelve el html seguro
};

const EventoInfo = ({ data }) => {
  const [showMap, setShowMap] = useState(false);
  const [mapUrl, setMapUrl] = useState("");

  const direccion = data?.direccion + " " + data?.ciudad;
  const apiKey = "AIzaSyDancleRLY1vcyNtjo6uGo6dJDGPpkT3Ug";

  /* console.log("data", data); */
  useEffect(() => {
    if (direccion) {
      const direccionEncoded = encodeURIComponent(direccion);
      const newMapUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${direccionEncoded}`;

      setMapUrl(newMapUrl);
    }
  }, [direccion, apiKey]);

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  const imagesDescrip = data?.attachs?.filter(
      (attach) => !attach.avatar && !attach.banner
    );
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesDescrip.length);
    };
  
    const handlePrev = () => {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + imagesDescrip.length) % imagesDescrip.length
      );
    };
  
    const showControls = imagesDescrip?.length > 1;

  return (

    <>
      <div
        className="mx-4 pb-4 d-flex flex-wrap flex-lg-nowrap justify-content-center gap-5"
        style={{
          paddingTop: "16px",
          paddingLeft: "32px",
          paddingRight: "32px",
        }}
      >
        <div className="w-100" style={{ paddingTop: "28px" }}>
          <div className="d-flex flex-row gap-5">
            <div className="d-flex flex-column">
              <p
                style={{
                  color: "#221A60",
                  fontSize: "21px",
                  marginBottom: "5px",
                }}
              >
                Ubicacion
              </p>
              <div className="d-flex gap-2" style={{ marginBottom: "10px" }}>
                <PlaceOutlined
                  sx={{ width: "19px", height: "19px", marginTop: "1px" }}
                />
                <div className="d-flex flex-column">
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "5px",
                    }}
                  >
                    {data.direccion}
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "18px",
                      marginBottom: "5px",
                    }}
                  >
                    {data.ciudad}
                  </p>
                  {!showMap ? (
                    <p
                      style={{
                        fontSize: "18px",
                        marginBottom: "0",
                        color: "#5656FF",
                      }}
                      onClick={toggleMap}
                    >
                      Mostrar mapa{" "}
                      <KeyboardArrowDownOutlined
                        sx={{ width: "16px", height: "16px" }}
                      />
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: "18px",
                        marginBottom: "0",
                        color: "#5656FF",
                      }}
                      onClick={toggleMap}
                    >
                      Ocultar mapa{" "}
                      <KeyboardArrowUpOutlined
                        sx={{ width: "16px", height: "16px" }}
                      />
                    </p>
                  )}
                </div>
              </div>
            </div>
            {data.fechas.length > 1 ? (
              <div className="d-flex flex-column">
                <p
                  style={{
                    color: "#221A60",
                    fontSize: "21px",
                    marginBottom: "5px",
                  }}
                >
                  Fechas
                </p>
                {data.fechas.map((fecha) => (
                  <>
                    <p
                      className="d-flex align-items-center mb-0"
                      style={{ fontSize: "18px" }}
                    >
                      <CalendarMonthOutlined
                        sx={{
                          width: "18px",
                          height: "18px",
                          marginRight: "10px",
                        }}
                      />
                      {format(new Date(fecha.fecha), "d 'de' MMMM", {
                        locale: es,
                      })}
                    </p>
                    <p
                      className="d-flex align-items-center"
                      style={{ fontSize: "18px", marginBottom: "5px" }}
                    >
                      <AccessAlarmOutlined
                        sx={{
                          width: "18px",
                          height: "18px",
                          marginRight: "10px",
                        }}
                      />
                      {moment.parseZone(fecha.hora_inicio).format("HH:mm")}{" "}
                      hs.
                    </p>
                  </>
                ))}
              </div>
            ) : (
              <div className="d-flex flex-column">
                <p
                  style={{
                    color: "#221A60",
                    fontSize: "21px",
                    marginBottom: "5px",
                  }}
                >
                  Fecha
                </p>
                <p
                  className="d-flex align-items-center"
                  style={{ fontSize: "19px", marginBottom: "5px" }}
                >
                  <CalendarMonthOutlined
                    sx={{
                      width: "18px",
                      height: "18px",
                      marginRight: "10px",
                    }}
                  />
                  {format(new Date(data.fechas[0].fecha), "d 'de' MMMM", {
                    locale: es,
                  })}
                </p>
                <p
                  className="d-flex align-items-center"
                  style={{ fontSize: "18px", marginBottom: "5px" }}
                >
                  <AccessAlarmOutlined
                    sx={{
                      width: "18px",
                      height: "18px",
                      marginRight: "10px",
                    }}
                  />
                  {moment
                    .parseZone(data.fechas[0].hora_inicio)
                    .format("HH:mm")}{" "}
                  hs.
                </p>
              </div>
            )}
          </div>
          {showMap && (
            <div className="d-flex justify-content-center">
              <iframe
                title="location"
                className="rounded shadow"
                width="90%"
                height="250"
                id="map"
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={mapUrl}
              ></iframe>
            </div>
          )}
          <div className="mt-2 ">
            <p
              style={{
                color: "#221A60",
                fontSize: "22px",
                marginBottom: "10px",
              }}
            >
              Acerca del evento
            </p>
            <p
              style={{ fontSize: "18px" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(data?.descripcion),
              }}
            />
            <div
              style={{
                position: "relative",
                width: "100%",
                margin: "auto",
                overflow: "hidden",
              }}
            >
              {showControls && (
                <IconButton
                  style={{
                    position: "absolute",
                    border: "none",
                    cursor: "pointer",
                    padding: "10px",
                    zIndex: 1,
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    color: "#5656FF",
                  }}
                  onClick={handlePrev}
                >
                  <KeyboardArrowLeft />
                </IconButton>
              )}
              <div
                style={{
                  display: "flex",
                  transition: "transform 0.5s ease",
                  width: "100%",
                  transform: `translateX(-${currentIndex * 100}%)`,
                }}
              >
                {imagesDescrip?.map((attach, index) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "100%",
                      boxSizing: "border-box",
                    }}
                    key={index}
                  >
                    {attach.type === "image" ? (
                      <img
                        src={attach.url}
                        alt={`Imagen ${index}`}
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "600px",
                        }}
                      />
                    ) : attach.type === "video" ? (
                      <video
                        controls
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "500px",
                          objectFit: "contain",
                        }}
                      >
                        <source src={attach.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : null}
                  </div>
                ))}
              </div>
              {showControls && (
                <IconButton
                  style={{
                    position: "absolute",
                    border: "none",
                    cursor: "pointer",
                    padding: "10px",
                    zIndex: 1,
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    color: "#5656FF",
                  }}
                  onClick={handleNext}
                >
                  <KeyboardArrowRight />
                </IconButton>
              )}
              {showControls && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: "10px",
                    width: "100%",
                  }}
                >
                  {imagesDescrip.map((_, index) => (
                    <div
                      key={index}
                      onClick={() => setCurrentIndex(index)}
                      style={{
                        width: "10px",
                        height: "10px",
                        margin: "0 5px",
                        borderRadius: "50%",
                        backgroundColor:
                          currentIndex === index ? "#5656FF" : "#ddd",
                        cursor: "pointer",
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          {data.FAQ && data.FAQ.length > 0 && (
            <div className="mt-2">
              <p
                style={{
                  color: "#221A60",
                  fontSize: "22px",
                  marginBottom: "10px",
                }}
              >
                Preguntas frecuentes
              </p>
              {data.FAQ?.map((faq) => (
                <Accordion
                  key={faq.id}
                  style={{ backgroundColor: "#f9f9f9", marginBottom: "10px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel${faq.id}-content`}
                    id={`panel${faq.id}-header`}
                  >
                    <p className="m-0 fw-medium">{faq.pregunta}</p>
                  </AccordionSummary>
                  <AccordionDetails as="p" className="m-0">
                    {faq.respuesta}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}


export default EventoInfo;