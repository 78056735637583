import React from "react";
import PassGoLogo from "../../src/assets/PassGoLogo.png";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Footer = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const history = useHistory();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleButtonClick = () => {
    if (isAuthenticated) {
      history.push("/eventos");
    } else {
      loginWithRedirect({
        appState: { returnTo: "/eventos" }
      });
    }
  };
  return (
    <>
      {isMobile ? (
        <>
          <footer id="footer" className="d-flex flex-column" style={{ backgroundColor: "#0B0062", paddingTop: "40px", paddingBottom: "40px", paddingLeft: "12%", paddingRight: "12%" }}>
            <div>
              <div className="mb-4">
                <img src={`${PassGoLogo}`} width="120px" alt="Logo" />
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="text-center mb-4">
                  <p className="mb-1" style={{ color: "#5656FF", fontWeight: "600" }}>Contacto</p>
                  <p className="mb-1" style={{ color: "white", fontWeight: "300" }}>contactopassgo@gmail.com</p>
                  <p className="mb-1" style={{ color: "white", fontWeight: "300" }}>+342 5060341</p>
                </div>
                <div>
                  <div className="d-flex flex-row justify-content-center gap-4 text-center">
                    <Link to="/" style={{ textAlign: "center", color: "white", fontWeight: "300", textDecoration: "none" }}>Registrarme</Link>
                    <button type="button" onClick={handleButtonClick} style={{ textAlign: "center", color: "white", fontWeight: "300" }}>Crear evento</button>
                  </div>
                  <Link to="/ayuda" style={{ textAlign: "left", color: "white", fontWeight: "300", textDecoration: "none" }}>Preguntas frecuentes</Link>
                  <div style={{ marginTop:"20px", display: "flex", justifyContent: "center", gap: "20px" }}>
                    <button type="button" style={{ backgroundColor: "#FFD400", border: "none", borderRadius: "100%", height: "20px", width: "20px" }}></button>
                    <button type="button" style={{ backgroundColor: "#FFD400", border: "none", borderRadius: "100%", height: "20px", width: "20px" }}></button>
                    <button type="button" style={{ backgroundColor: "#FFD400", border: "none", borderRadius: "100%", height: "20px", width: "20px" }}></button>
                  </div>
                  <p className="my-4 text-center" style={{ color: "#FFD400", fontSize: "14px" }}>
                  PassGo es una plataforma de venta de entradas de Argentina,
                  que ofrece venta de entradas online y asistencia para organizadores de eventos.
                </p>
                </div>
              </div>
            </div>

          </footer>
        </>
      ) : (
        <>
          <footer id="footer" className="d-flex flex-column align-items-center" style={{ gap: "35px", backgroundColor: "#0B0062", paddingTop: "70px", paddingLeft: "12%", paddingRight: "12%" }}>
            <div className="d-flex flex-row align-items-center justify-content-between w-100">
              <div className="img-footer">
                <img src={`${PassGoLogo}`} width="120px" alt="Logo" />
              </div>
              <div className="contact-footer">
                <div className="text-left">
                  <p className="mb-1" style={{ color: "#5656FF", fontWeight: "600" }}>Contacto</p>
                  <p className="mb-1" style={{ color: "white", fontWeight: "300" }}>contactopassgo@gmail.com</p>
                  <p className="mb-1" style={{ color: "white", fontWeight: "300" }}>+342 5060341</p>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div className="d-flex flex-column text-left">
                  <Link to="/" style={{ textAlign: "left", color: "white", fontWeight: "300", textDecoration: "none" }}>Registrarme</Link>
                  <button type="button" onClick={handleButtonClick} style={{ textAlign: "left", color: "white", fontWeight: "300" }}>Crear evento</button>
                  <Link to="/ayuda" style={{ textAlign: "left", color: "white", fontWeight: "300", textDecoration: "none" }}>Preguntas frecuentes</Link>
                </div>
              </div>
              <div className="contact-footer">
                <div className="text-left">
                  <p className="mb-1" style={{ color: "#5656FF", fontWeight: "600" }}>Contacto</p>
                  <p className="mb-1" style={{ color: "white", fontWeight: "300" }}>contactopassgo@gmail.com</p>
                  <p className="mb-1" style={{ color: "white", fontWeight: "300" }}>+342 5060341</p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between w-100 my-4 pt-2" >
              <div className="" style={{ width: "60%" }}>
                <p className="mb-0 text-left" style={{ color: "#FFD700", fontSize: "14px" }}>
                  PassGo es una plataforma de venta de entradas de Argentina,
                  que ofrece venta de entradas online y asistencia para organizadores de eventos.
                </p>
              </div>
              <div className="" style={{ width: "40%" }}>
                <div style={{ display: "flex", justifyContent: "end", gap: "20px" }}>
                  <button type="button" style={{ backgroundColor: "#FFD700", border: "none", borderRadius: "100%", height: "40px", width: "40px" }}></button>
                  <button type="button" style={{ backgroundColor: "#FFD700", border: "none", borderRadius: "100%", height: "40px", width: "40px" }}></button>
                  <button type="button" style={{ backgroundColor: "#FFD700", border: "none", borderRadius: "100%", height: "40px", width: "40px" }}></button>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default Footer;