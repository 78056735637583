import SideBar from "./SideBar";
import UseTemplate from "./UseTemplate";

const TemplateSideBar = ({onClose, evento, tarifa}) => {
  return (
      <SideBar id='templateBar'>
        <div className='d-flex flex-column'>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div>
              <p className="p-0 m-0 fs-4">Plantillas</p>
            </div>
            <div>
              <button className="d-flex align-items-center" onClick={() => onClose()}>
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
          </div>
          <div>
            <p className="m-0 p-0 fs-5">Recomendaciones</p>
          </div>
        </div>
        <div className='w-100 d-flex flex-column gap-1'>
          <UseTemplate template={'pulsera'} evento={evento} tarifa={tarifa}>Plantilla de Pulsera</UseTemplate>
          <UseTemplate template={'tarjeta'} evento={evento} tarifa={tarifa}>Plantilla de Tarjeta</UseTemplate>
          {/* <UseTemplate template={'voucher'}>Plantilla de Voucher</UseTemplate> */}
        </div>
      </SideBar>
  )
};

export default TemplateSideBar;