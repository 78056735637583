import { useEffect } from "react";
import useQuery from "../../../../hooks/useQuery";
import { showErrorToastify } from "../../../../utils";
import { getCompra } from "../api";

const useCompra = (compraId) => {
  const { data, refetch, loading } = useQuery({
    autoFetch: false,
    queryFn: getCompra,
    onError: (err) => showErrorToastify({ err }),
  });

  useEffect(() => {
    if (!compraId) return;
    refetch(compraId);
  }, [compraId]);

  return {
    compra: data?.data?.compra,
    loading,
    fetchCompra: refetch,
  }
}

export default useCompra;