import React, { useState } from "react";
import { api } from "../../services/api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { Button, Box, Modal, Alert, IconButton, Divider } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { grey } from '@mui/material/colors';

const DeleteTarifaModal = ({ id, callApi }) => {
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage ] = useState("")
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: isMobile ? 330 : 600,
    p: 3,
  };

  const DeleteTarifa = async (id) => {
    try {
      const response = await api.tarifas.deleteTarifa(id); // Espera la respuesta de la API
  
      if (response.status === "error") {
        setErrors(true);
        setErrorMessage(response.message);
        setSubmitted(false);
      } else {
        setSubmitted(true);
        setTimeout(() => {
          callApi(); 
          toast.success(t("rates.deleteMessage"), {
            position: "top-right",
            autoClose: 2000,
          });
        }, 3000);
      }
    } catch (error) {
      setErrors(true);
      setErrorMessage("Hubo un error al intentar eliminar la tarifa.");
      setSubmitted(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setErrors(false)
    setErrorMessage("");
  } 

  return (
    <div>
      <IconButton style={{padding: "0px"}} aria-label="delete" color="error" size="large" onClick={handleOpen}>
        <DeleteOutlineOutlinedIcon/>
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h4 id="modal-modal-title">{t("rates.deleteRate")}</h4>
          <div id="modal-modal-description">
            <p>¿Estás seguro de que deseas eliminar este registro?</p>
          </div>
          {errors &&
            <Alert severity="error">{errorMessage}</Alert>
          }
          {submitted &&
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">Tarifa eliminada exitosamente</Alert>
          }
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "15px" }}>
            <Button size="small" variant="contained" sx={{background: grey[500]}} style={{marginRight: "10px"}} onClick={handleClose}>
              {t("button.cancel")}
            </Button>
            <Button variant="contained" color="error" size="small" onClick={() => DeleteTarifa(id)}>
              {t("button.delete")}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
export default DeleteTarifaModal;