import React, { useState, useEffect } from "react";
import {
  TextField,
  Alert,
  Button,
} from "@mui/material";
import { api } from "../../services/api";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import useUbicaciones from "../../hooks/hookUbicacion";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const PerfilFacturacion = ({ usuario }) => {
  const [error, setError] = useState("");
  const [t] = useTranslation("global");
  const [formData, setFormData] = useState({
    nombre: "",
    direccion: "",
    ciudad: "",
    provincia: "",
    cp: "",
    pais: "",
  });
  const [editProfile, setEditProfile] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 770px)" });

  const {
    paises,
    provincias,
    ciudades,
    setPaisSeleccionado,
    setProvSeleccionada,
    setCiudadSeleccionada,
  } = useUbicaciones();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const buscarNombrePais = (iso2) => {
    const paisEncontrado = paises.find((pais) => pais.iso2 === iso2);
    return paisEncontrado ? paisEncontrado.name : iso2;
  };

  const buscarNombreProvincia = (iso2Provincia) => {
    const provinciaEncontrada = provincias.find(
      (provincia) => provincia.iso2 === iso2Provincia
    );
    return provinciaEncontrada ? provinciaEncontrada.name : iso2Provincia;
  };

  const buscarNombreCiudad = (ciudadId) => {
    const ciudadEncontrada = ciudades.find((ciudad) => ciudad.id === ciudadId);
    return ciudadEncontrada ? ciudadEncontrada.name : ciudadId;
  };

  const handleCountryChange = (event) => {
    const selectedPais = event.target.value;
    const nombrePais = buscarNombrePais(selectedPais);
    setPaisSeleccionado(selectedPais);
    setFormData((prevState) => ({
      ...prevState,
      pais: nombrePais,
      provincia: "",
      ciudad: "",
    }));
    setProvSeleccionada("");
    setCiudadSeleccionada("");
  };

  const handleProvChange = (event) => {
    const selectedProv = event.target.value;
    const nombreProvincia = buscarNombreProvincia(selectedProv);
    setProvSeleccionada(selectedProv);
    setFormData((prevState) => ({
      ...prevState,
      provincia: nombreProvincia,
      ciudad: "",
    }));
    setCiudadSeleccionada("");
  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const nombreCiudad = buscarNombreCiudad(selectedCityId);
    setCiudadSeleccionada(selectedCityId);
    setFormData((prevState) => ({
      ...prevState,
      ciudad: nombreCiudad,
    }));
  };

  const callApi = async () => {
    try {
      const response = await api.facturacion.getFacturacion();
      if (response.status === "success") {
        const data = response.data.facturacion;
        setFormData({
          nombre: data.nombre,
          direccion: data.direccion,
          ciudad: data.ciudad,
          provincia: data.provincia,
          cp: data.cp,
          pais: data.pais,
        });
      }
    } catch (error) {
      setError("Error al cargar los datos de la publicación.");
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const regexE = /^(?!([a-zA-Z])\1+$)[a-zA-Z\sñÑ\u00C0-\u017F]*$/;

    if (formData.nombre?.trim() === "") {
      setError(<>{t("errors.emptyField")}</>);
      return;
    }

    if (!regexE.test(formData.nombre)) {
      setError(<>{t("errors.charactersEvent")}</>);
      return;
    }

    if (formData.nombre?.length > 50) {
      setError(<>{t("errors.eventName")}</>);
      return;
    }

    if (formData.apellido?.trim() === "") {
      setError(<>{t("errors.emptyField")}</>);
      return;
    }

    if (!regexE.test(formData.apellido)) {
      setError(<>{t("errors.charactersEvent")}</>);
      return;
    }

    if (formData.apellido?.length > 50) {
      setError(<>{t("errors.charactersDescription")}</>);
      return;
    }

    const data = {
      nombre: formData.nombre,
      direccion: formData.direccion,
      ciudad: formData.ciudad,
      provincia: formData.provincia,
      cp: formData.cp,
      pais: formData.pais,
    };

    try {
      const response = await api.facturacion.updateFacturacion(data);
      if (response.status === "error") {
        setError("Error: " + response.message);
      } else if (response.status === "success") {
        setSubmitted(true);
        setTimeout(() => window.location.reload(), 1500);
      }
    } catch (error) {
      console.error("Error al editar usuario:", error);
      setError("Error al editar usuario.");
    }
  };

  return (
    <div className="container">
      {isMobile ? (
        <>
          <h3 className="d-flex align-items-center my-2">
            {t("billing.tittle")}
            <div>
              {!editProfile && (
                <button
                  className="py-0 mx-2"
                  color="grey"
                  style={{ border: "none" }}
                  onClick={() => setEditProfile(true)}
                >
                  <EditOutlinedIcon />
                </button>
              )}
            </div>
          </h3>

          <div>
            {editProfile ? (
              <>
                <div className="">
                  <form id="form-profile" onSubmit={handleSubmit}>
                    <div className="form-seccion my-3">
                      <p className="mb-0">{t("billing.fullname")}</p>
                      <TextField
                        sx={{
                          marginTop: "5px",
                          marginBottom: "15px",
                          width: "100%",
                          input: {
                            padding: "10px 15px",
                          },
                        }}
                        id="nombre"
                        name="nombre"
                        value={formData.nombre || ""}
                        onChange={handleInputChange}
                      /* style={{ width: "70%" }} */
                      />
                    </div>
                    <div className="form-seccion my-3">
                      <p className="m-0">{t("billing.country")}</p>
                      <select
                        id="pais"
                        name="pais"
                        value={
                          paises.find((p) => p.name === formData.pais)?.iso2 ||
                          ""
                        }
                        onChange={handleCountryChange}
                        className="profile-input"
                        style={{
                          marginTop: "5px",
                          marginBottom: "10px",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          borderColor: "#c4c4c4",
                        }}
                        disabled={!paises.length}
                      >
                        <option value="" disabled>
                          Selecciona un país
                        </option>
                        {paises.map((pais) => (
                          <option key={pais.iso2} value={pais.iso2}>
                            {pais.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-seccion my-3">
                      <p className="m-0">{t("billing.province_state")}</p>
                      <select
                        id="provincia"
                        name="provincia"
                        style={{
                          marginTop: "5px",
                          marginBottom: "10px",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          borderColor: "#c4c4c4",
                        }}
                        value={
                          provincias.find((p) => p.name === formData.provincia)
                            ?.iso2 || ""
                        }
                        onChange={handleProvChange}
                        className="profile-input"
                        disabled={!formData.pais || !provincias.length}
                      >
                        <option value="" disabled>
                          Selecciona una provincia
                        </option>
                        {provincias.map((provincia) => (
                          <option key={provincia.iso2} value={provincia.iso2}>
                            {provincia.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-seccion my-3">
                      <p className="m-0">{t("billing.city")}</p>
                      <select
                        id="ciudad"
                        name="ciudad"
                        value={formData.ciudad || ""}
                        onChange={handleCityChange}
                        className="profile-input"
                        style={{
                          marginTop: "5px",
                          marginBottom: "10px",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          borderColor: "#c4c4c4",
                        }}
                        disabled={!formData.provincia || !ciudades.length}
                      >
                        <option value="" disabled>
                          Selecciona una ciudad
                        </option>
                        {ciudades.map((ciudad) => (
                          <option key={ciudad.id} value={ciudad.name}>
                            {ciudad.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-seccion my-3">
                      <p className="m-0">{t("billing.address")}</p>
                      <TextField
                        sx={{
                          marginTop: "5px",
                          marginBottom: "15px",
                          width: "100%",
                          input: {
                            padding: "10px 15px",
                          },
                        }}
                        id="direccion"
                        name="direccion"
                        value={formData.direccion || ""}
                        onChange={handleInputChange}
                      /* style={{ width: "70%" }}  */
                      />
                    </div>
                    <div className="form-seccion my-3">
                      <p className="m-0">Codigo Postal</p>
                      <TextField
                        sx={{
                          marginTop: "5px",
                          marginBottom: "15px",
                          width: "30%",
                          input: {
                            padding: "10px 15px",
                          },
                        }}
                        id="cp"
                        name="cp"
                        value={formData.cp || ""}
                        onChange={handleInputChange}
                        style={{ width: "30%" }}
                      />
                    </div>

                    {submitted && (
                      <Alert color="success">
                        {t("profile_info.edit_user")}
                      </Alert>
                    )}

                    {editProfile && (
                      <div className="d-flex flex-row ml-auto justify-content-end mt-2 mb-4">
                        <Button
                          sx={{
                            color: "red",
                            border: "1px solid red",
                            padding: "5px 15px",
                          }}
                          className="mr-2 d-inline-flex"
                          onClick={() => setEditProfile(false)}
                        >
                          {t("button.cancel")}
                        </Button>{" "}
                        <Button
                          type="submit"
                          className="boton-submit"
                          form="form-profile"
                          style={{}}
                        >
                          {t("button.save")}
                        </Button>{" "}
                      </div>
                    )}
                  </form>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-between my-3 pb-2 border-bottom ">
                  <p className="m-0" style={{ color: "#797D88" }}>
                    {t("billing.fullname")}
                  </p>
                  <p className="m-0 text-capitalize fw-bold">
                    {formData.nombre}
                  </p>
                </div>
                <div className="d-flex justify-content-between my-3 pb-2 border-bottom ">
                  <p className="m-0" style={{ color: "#797D88" }}>
                    {t("billing.country")}
                  </p>
                  <p className="m-0 fw-bold">{formData.pais}</p>
                </div>
                <div className="d-flex justify-content-between my-3 pb-2 border-bottom  ">
                  <p className="m-0" style={{ color: "#797D88" }}>
                    Provincia / Estado
                  </p>
                  <p className="m-0 fw-bold">{formData.provincia}</p>
                </div>
                <div className="d-flex justify-content-between my-3 pb-2 border-bottom ">
                  <p className="m-0" style={{ color: "#797D88" }}>
                    {t("billing.city")}
                  </p>
                  <p className="m-0 fw-bold">{formData.ciudad}</p>
                </div>
                <div className="d-flex justify-content-between my-3 pb-2 border-bottom ">
                  <p className="m-0" style={{ color: "#797D88" }}>
                    {t("billing.zip_code")}
                  </p>
                  <p className="m-0 fw-bold">{formData.cp}</p>
                </div>
                <div className="d-flex justify-content-between my-3 pb-2 border-bottom  ">
                  <p className="m-0" style={{ color: "#797D88" }}>
                    {t("billing.address")}
                  </p>
                  <p className="m-0 fw-bold">{formData.direccion}</p>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <h3 className="d-flex align-items-center ml-4 my-2">
            {t("billing.tittle")}
            <div>
              {!editProfile && (
                <button
                  className="py-0 mx-2"
                  color="grey"
                  style={{ border: "none" }}
                  onClick={() => setEditProfile(true)}
                >
                  <EditOutlinedIcon />
                </button>
              )}
            </div>
          </h3>

          {error && <Alert color="danger">{error}</Alert>}

          {editProfile ? (
            <>
              <form id="form-profile" onSubmit={handleSubmit}>
                <div className="container mt-2 ml-4">
                  <div className="row g-2">
                    <div className="mb-3 d-flex flex-column">
                      <small className="text-muted">
                        {t("billing.fullname")}
                      </small>
                      <TextField
                        sx={{
                          marginTop: "5px",
                          marginBottom: "15px",
                          width: "85%",
                          input: {
                            padding: "10px 15px",
                          },
                        }}
                        id="nombre"
                        name="nombre"
                        value={formData.nombre || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="d-flex flex-column">
                          <small className="text-muted">
                            {t("billing.country")}
                          </small>
                          <select
                            id="pais"
                            type="select"
                            name="pais"
                            value={
                              paises.find((p) => p.name === formData.pais)
                                ?.iso2 || ""
                            }
                            onChange={handleCountryChange}
                            className="profile-input"
                            style={{
                              marginTop: "5px",
                              marginBottom: "10px",
                              width: "70%",
                              padding: "10px",
                              borderRadius: "4px",
                              borderColor: "#c4c4c4",
                            }}
                            disabled={!paises.length}
                          >
                            <option value="" hidden>
                              Selecciona un país
                            </option>
                            {paises.map((pais) => (
                              <option key={pais.iso2} value={pais.iso2}>
                                {pais.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="mb-3 d-flex flex-column">
                        <small className="text-muted">
                          {t("billing.city")}
                        </small>
                        <select
                          id="ciudad"
                          name="ciudad"
                          value={formData.ciudad || ""}
                          onChange={handleCityChange}
                          className="profile-input"
                          style={{
                            marginTop: "5px",
                            marginBottom: "10px",
                            width: "70%",
                            padding: "10px",
                            borderRadius: "4px",
                            borderColor: "#c4c4c4",
                          }}
                          disabled={!formData.provincia || !ciudades.length}
                        >
                          <option value="" disabled>
                            Selecciona una ciudad
                          </option>
                          {ciudades.map((ciudad) => (
                            <option key={ciudad.id} value={ciudad.name}>
                              {ciudad.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 d-flex flex-column">
                        <small className="text-muted">
                          {t("billing.province_state")}
                        </small>
                        <select
                          id="provincia"
                          name="provincia"
                          value={
                            provincias.find(
                              (p) => p.name === formData.provincia
                            )?.iso2 || ""
                          }
                          onChange={handleProvChange}
                          className="profile-input"
                          style={{
                            marginTop: "5px",
                            marginBottom: "10px",
                            width: "70%",
                            padding: "10px",
                            borderRadius: "4px",
                            borderColor: "#c4c4c4",
                          }}
                          disabled={!formData.pais || !provincias.length}
                        >
                          <option value="" disabled>
                            Selecciona una provincia
                          </option>
                          {provincias.map((provincia) => (
                            <option key={provincia.iso2} value={provincia.iso2}>
                              {provincia.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 d-flex flex-column">
                        <small className="text-muted">
                          {t("billing.zip_code")}
                        </small>
                        <TextField
                          sx={{
                            marginTop: "5px",
                            marginBottom: "15px",
                            width: "30%",
                            input: {
                              padding: "10px 15px",
                            },
                          }}
                          id="cp"
                          name="cp"
                          value={formData.cp || ""}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="mb-3 d-flex flex-column">
                      <small className="text-muted">
                        {t("billing.address")}
                      </small>
                      <TextField
                        sx={{
                          marginTop: "5px",
                          marginBottom: "15px",
                          width: "85%",
                          input: {
                            padding: "10px 15px",
                          },
                        }}
                        id="direccion"
                        name="direccion"
                        value={formData.direccion || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                {submitted && (
                  <Alert color="success">{t("profile_info.edit_user")}</Alert>
                )}

                {editProfile && (
                  <div className="d-flex flex-row justify-content-end mt-2 ml-4">
                    <Button
                      sx={{
                        color: "red",
                        border: "1px solid red",
                        padding: "5px 15px",
                      }}
                      type="button"
                      className="mt-3 mr-3 d-inline-flex "
                      onClick={() => setEditProfile(false)}
                    >
                      {t("button.cancel")}
                    </Button>{" "}
                    <Button
                      className="mt-3 d-inline-flex boton-submit"
                      type="submit"
                      style={{}}
                    >
                      {t("button.edit")} perfil
                    </Button>{" "}
                  </div>
                )}
              </form>
            </>
          ) : (
            <>
              <div className="container mt-2 ml-4">
                <div className="row g-2">
                  <div className="mb-3">
                    <small className="text-muted">
                      {t("billing.fullname")}
                    </small>
                    <p className="fs-6 mb-0">{formData?.nombre}</p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="mb-3">
                      <small className="text-muted">
                        {t("billing.country")}
                      </small>
                      <p className="mb-0">{formData?.pais}</p>
                    </div>
                    <div className="mb-3">
                      <small className="text-muted">{t("billing.city")}</small>
                      <p className="mb-0">{formData?.ciudad}</p>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="mb-3">
                      <small className="text-muted">
                        {t("billing.province_state")}
                      </small>
                      <p className="mb-0"> {formData?.provincia}</p>
                    </div>
                    <div className="mb-3">
                      <small className="text-muted">
                        {t("billing.zip_code")}
                      </small>
                      <p className="mb-0">{formData?.cp}</p>
                    </div>
                  </div>
                  <div className="mb-3">
                    <small className="text-muted">{t("billing.address")}</small>
                    <p className="mb-0">{formData?.direccion}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PerfilFacturacion;
