import { config } from "../../../services/config";
import { pivateOptions } from "../../../services/api";

export const getAllCompras = async (q = "") => {
  const url = `${config.URL_API}/usuario/compras?${q}`;
  const response = await fetch(url, pivateOptions('GET'));
  const data = response.json();
  return data;
}

export const getCompra = async (id) => {
  const url = `${config.URL_API}/usuario/compra/${id}`;
  const response = await fetch(url, pivateOptions('GET'));
  const data = response.json();
  return data;
}