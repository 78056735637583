import { useMediaQuery } from "react-responsive";
import moment from 'moment-timezone';
import { useLocation, useParams, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { MenuMobil } from "./Menu/MenuMobil";
import { useState, useEffect } from "react";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import StoreIcon from '@mui/icons-material/Store';
moment.locale('es');


export const Header = ({ evento }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
    const location = useLocation();
    const param = useParams();
    const [titulo, setTitulo] = useState('')
    const [subtitulo, setSubtitulo] = useState('');
    const [icono, setIcono] = useState('');
    const { t } = useTranslation("global");
    const { id } = useParams();

    useEffect(() => {
        const url = "/eventos/" + param.id;

        switch (location.pathname) {
            case url:
                setIcono(<BarChartOutlinedIcon />)
                setTitulo("Dashboard")
                setSubtitulo('');
                break;
            case url + "/ubicaciones":
                setIcono(<LocationOnOutlinedIcon />);
                setTitulo(t("location.locations"));
                setSubtitulo('');
                break;
            case url + "/tarifas":
                setIcono(<LocalAtmOutlinedIcon />);
                setTitulo(t("rates.rate"));
                setSubtitulo('');
                break;
            case url + "/creartarifa":
                setIcono(<LocalAtmOutlinedIcon />);
                setTitulo(t("rates.rate"));
                setSubtitulo(t("button.create"));
                break;
            case url + "/qrvouchers/camara":
                setIcono(<QrCodeOutlinedIcon />);
                setTitulo(t("menuTemplate.cameraVoucher"));
                setSubtitulo('');
                break;
            case url + "/qrvouchers/escaner":
                setIcono(<QrCodeOutlinedIcon />);
                setTitulo(t("menuTemplate.scanVoucher"));
                setSubtitulo('');
                break;
            case url + "/qrtickets/camara":
                setIcono(<QrCodeOutlinedIcon />);
                setTitulo(t("menuTemplate.cameraAccess"));
                setSubtitulo('');
                break;
            case url + "/qrtickets/escaner":
                setIcono(<QrCodeOutlinedIcon />);
                setTitulo(t("menuTemplate.scanAccess"));
                setSubtitulo('');
                break;
            case url + "/caja":
                setIcono(<StoreIcon />);
                setTitulo(t("menuTemplate.cashier"));
                setSubtitulo('');
                break;
            case url + "/tickets":
                setIcono(<ConfirmationNumberOutlinedIcon />);
                setTitulo("Tickets");
                setSubtitulo('');
                break;
            case url + "/vouchers":
                setIcono(<ConfirmationNumberOutlinedIcon />);
                setTitulo("Vouchers");
                setSubtitulo('');
                break;
            // case url + `/eventos/${id}` :
            //     setIcono("book_online");
            //     setTitulo("Tickets & Vouchers");
            //     setSubtitulo('');
            // break;
            // case url + `/eventos/${id}` :
            //     setIcono("book_online");
            //     setTitulo("Tickets & Vouchers");
            //     setSubtitulo('');
            // break;
            case url + "/ventas":
                setIcono(<TrendingUpOutlinedIcon />);
                setTitulo(t("menuTemplate.sales"));
                setSubtitulo('');
                break;
            case url + "/owners":
                setIcono(<SupervisorAccountOutlinedIcon />);
                setTitulo(t("menuTemplate.owners"));
                setSubtitulo('');
                break;
            case url + "/clientes":
                setIcono(<SupervisorAccountOutlinedIcon />);
                setTitulo(t("menuTemplate.customers"));
                setSubtitulo('');
                break;
            case url + "/cuentas":
                setIcono(<AccountBalanceWalletOutlinedIcon />);
                setTitulo(t("menuTemplate.wallet"));
                setSubtitulo('');
                break;
            case url + "/crear-cuenta":
                setIcono(<AccountBalanceWalletOutlinedIcon />);
                setTitulo(t("menuTemplate.wallet"));
                setSubtitulo(t("button.add"))
                break;
            case url + "/publicaciones":
                setIcono(<PublicOutlinedIcon />);
                setTitulo(t("menuTemplate.publications"));
                setSubtitulo('')
                break;
            case url + "/publicaciones/nueva":
                setIcono(<PublicOutlinedIcon />);
                setTitulo(t("menuTemplate.publications"));
                setSubtitulo(t("button.create"))
                break;
            case url + "/colaboradores":
                setIcono(<SupervisorAccountOutlinedIcon />);
                setTitulo(t("menuTemplate.collaborators"));
                setSubtitulo('');
                break;
            case url + "/nuevoVoucher":
                setIcono(<ConfirmationNumberOutlinedIcon />);
                setTitulo('Vouchers');
                setSubtitulo(t("button.create"));
                break;
            case url + "/nuevoVoucher/importarExcel":
                setIcono(<ConfirmationNumberOutlinedIcon />);
                setTitulo('Vouchers');
                setSubtitulo(t("excelImport.massiveCreation"));
                break;
            case url + "/nuevoTicket":
                setIcono(<ConfirmationNumberOutlinedIcon />);
                setTitulo('Ticket');
                setSubtitulo(t("button.create"));
                break;
            case url + "/nuevoTicket/importarExcel":
                setIcono(<ConfirmationNumberOutlinedIcon />);
                setTitulo('Ticket');
                setSubtitulo(t("excelImport.massiveCreation"));
                break;
            case url + "/nuevoTicket/recepcion":
                setIcono(<ConfirmationNumberOutlinedIcon />);
                setTitulo('Ticket');
                setSubtitulo(t("excelImport.massiveCreation"));
                break;
            case url + "/editar":
                setIcono(<SettingsOutlinedIcon />);
                setTitulo(t("event.editEvent"));
                setSubtitulo('');
                break;
            default:
                setIcono('');
                setTitulo('');
                setSubtitulo('');
                break;
        }
    }, [location.pathname, param.id, t]);

    return (
        <header
            style={{
                padding: "20px 10px",
            }}
        >
            {isMobile && (
                <p className="fs-6 fw-bold mb-4 text-center font-italic">{evento.evento}</p>
            )}
            <div className="d-flex align-items-center">
                <div className="d-flex justify-content-between align-items-center" style={{ flex: 1 }}>
                    <div className="d-flex">
                        <div className="d-flex justify-content-center align-items-center mr-2 text-light" style={{ width: "35px", height: "35px", borderRadius: "50%", backgroundColor: "#2d2e2c" }}>
                            <span>{icono}</span>
                        </div>
                        <div>
                            <h2 style={{ margin: "0px", display: "flex", alignItems: "center", fontWeight: "400" }}>{titulo}</h2>
                            {subtitulo.length !== 0 && (
                                <h5 className="text-secondary fw-normal">{subtitulo}</h5>
                            )}
                        </div>

                    </div>
                    {isMobile && (
                        <MenuMobil evento={evento} />
                    )}
                </div>
            </div>
        </header>

    )
}