import React, { useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import { Button, Box, Modal, TextField, Alert, FormControl, InputLabel, Select, OutlinedInput, Chip, MenuItem, Checkbox, IconButton } from '@mui/material';
import useMutation from '../../hooks/useMutation';
import { api } from '../../services/api';
import { showErrorToastify, showSuccessToastify } from '../../utils';
import moment from 'moment';
import useGetFechasEvento from '../../hooks/useGetFechasEvento';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { toast } from "react-toastify";

export const EditTarifaModal = ({ tarifa, onClose }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 490px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 700px)` });
  const [dataForm, setDataForm] = useState({
    concepto: "",
    precio: "",
    limite_persona: "",
    limite_maximo: "",
    inicio_venta: "",
    fin_venta: "",
    fechas: [],
    inicio_venta_fecha: "",
    inicio_venta_hora: "",
    fin_venta_fecha: "",
    fin_venta_hora: "",
  });
  const [errors, setErrors] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const { fechasEvento } = useGetFechasEvento(tarifa?.idevento);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: isMobile ? 320 : isTablet ? 400 : 600,
    p: 3,
    overflowY: 'auto'
  };

  const separarFechaYHora = (fechaISO) => {
    const fechaObj = new Date(fechaISO);
    // Extraer la fecha en formato local
    const fecha = fechaObj.toLocaleDateString('en-CA'); // Formato YYYY-MM-DD
    // Extraer la hora en formato local
    const hora = fechaObj.toTimeString().slice(0, 5); // Formato HH:mm
    
    return { fecha, hora };
  };
  
  useEffect(() => {
    if (tarifa) {
      const { fecha: inicioVentaFecha, hora: inicioVentaHora } = separarFechaYHora(tarifa.inicio_venta);
      const { fecha: finVentaFecha, hora: finVentaHora } = separarFechaYHora(tarifa.fin_venta);
      
      setDataForm({
        ...tarifa,
        fechas: tarifa.fechas?.map(item => item.fecha.id), // Extraer solo los IDs,
        inicio_venta_fecha: inicioVentaFecha,
        inicio_venta_hora: inicioVentaHora,
        fin_venta_fecha: finVentaFecha,
        fin_venta_hora: finVentaHora,
      });
    }
  }, [tarifa]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setDataForm((prev) => ({
      ...prev,
      [name]: fieldValue,
    }));

    if (name === "fechas") {
      setDataForm((prev) => ({
        ...prev,
        [name]: [...new Set(value)],  // Usamos `new Set` para asegurarnos de que no haya valores duplicados
      }));
    }
  };

  const update = useMutation({
    mutationFn: api.tarifas.updateTarifa,
    onSuccess: (res) => {
      setErrors(false);
      setErrorMessage("");
      showSuccessToastify( {res: res.message });
      
      setTimeout(() => {
        onClose();
      }, 1000);
    },
    onError: (err) => {
      showErrorToastify({ err: err.message })
      setErrors(true);
      setErrorMessage(err.message);
    }
  });

  const handleSave = async () => {
    const inicioVenta = new Date(`${dataForm.inicio_venta_fecha}T${dataForm.inicio_venta_hora}:00-03:00`);
    const finVenta = new Date(`${dataForm.fin_venta_fecha}T${dataForm.fin_venta_hora}:00-03:00`);
    
    const payload = {
      concepto: dataForm.concepto,
      precio: parseFloat(dataForm.precio),
      limite_persona: parseInt(dataForm.limite_persona, 10),
      limite_maximo: parseInt(dataForm.limite_maximo, 10),
      inicio_venta: inicioVenta.toISOString(),  
      fin_venta: finVenta.toISOString(),  
      fechas: dataForm.fechas?.map(fechaId => ({ id: fechaId })),
    };

    // ejecutamos el mutation
    update.mutate(tarifa.id, payload);
    // ejecuta el gusdado de las nuevas preguntas (si es que hay)
    handleGuardarPreguntasNuevas();
  };

  const [preguntasAdicionales, setPreguntasAdicionales] = useState({
    informaciones_adicionales: []  
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.infoAdicional.getPregunta(tarifa.idevento, tarifa.id); 
        setPreguntasAdicionales(data.data);
        setDisabledState(new Array(data.data.informaciones_adicionales.length).fill(true));
      } catch (error) {
        console.error("Error al obtener la pregunta:", error);
      }
    };
    fetchData();
  }, [tarifa.idevento, tarifa.id]);

  const handleInputChange = (index, value) => {
    const updatedPreguntas = [...preguntasAdicionales.informaciones_adicionales];
    updatedPreguntas[index].informacion_adicional = value; 
    setPreguntasAdicionales({
      ...preguntasAdicionales,
      informaciones_adicionales: updatedPreguntas
    });
  };
  

  const handleDeletePregunta = async (idPregunta) => {
    try {
      const response = await api.infoAdicional.deletePregunta(idPregunta);
      
      if (response.status === "success") {  
        toast.success("Pregunta eliminada correctamente.");
        
        setPreguntasAdicionales(prevState => ({
          ...prevState,
          informaciones_adicionales: prevState.informaciones_adicionales.filter(p => p.id !== idPregunta)
        }));
        
      } else {
        throw new Error(response.message || "Error al eliminar la pregunta.");
      }
    } catch (error) {
      console.error("Error al eliminar la pregunta:", error);
      toast.error("Error al eliminar la pregunta.");
    }
  };

  const [disabledState, setDisabledState] = useState([]);
 
  const handleSavePregunta = async (id, index) => {
    const updatedPregunta = preguntasAdicionales.informaciones_adicionales[index];

    if (!updatedPregunta.informacion_adicional.trim()) {
      toast.error("El campo de información adicional no puede estar vacío.");
      return; 
    }

    try {
      const updatedData = { informacion_adicional: updatedPregunta.informacion_adicional };

      const response = await api.infoAdicional.updatePregunta(id, updatedData);

      if (response.status === "success") {
        toast.success("Pregunta actualizada correctamente.");

        setPreguntasAdicionales((prevState) => {
          const updatedPreguntas = [...prevState.informaciones_adicionales];
          updatedPreguntas[index] = updatedPregunta; 
          return { ...prevState, informaciones_adicionales: updatedPreguntas };
        });

        setDisabledState((prevState) => {
          const newState = [...prevState];
          newState[index] = true; 
          return newState;
        });
      } else {
        toast.error("Error al actualizar la pregunta.");
      }
    } catch (error) {
      console.error("Error al guardar la pregunta:", error);
      toast.error("Error al guardar la pregunta.");
    }
  };

  const handleEnableInput = (index) => {
    setDisabledState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = false;  
      return updatedState;
    });
  };
  
  // ---------------------------------- Cargar nueva/s pregunta/s ----------------------------------
  const [preguntasNuevas, setPreguntasNuevas] = useState([]);

  const handleButtonClick = () => {
    if (preguntasNuevas.length === 0) {
      setPreguntasNuevas([{ id_tarifa: tarifa.id, id_evento: tarifa.idevento, informacion_adicional: "" }]);
    } 
  }


  const handlePreguntaNuevasChange = (index, value) => {
    const nuevasPreguntas = [...preguntasNuevas];
    nuevasPreguntas[index].informacion_adicional = value;
    setPreguntasNuevas(nuevasPreguntas);
  };
  
  const agregarPregunta = () => {
    if (preguntasNuevas[preguntasNuevas.length - 1].informacion_adicional.trim() !== "") {
      setPreguntasNuevas([
        ...preguntasNuevas,
        { id_tarifa: tarifa.id, id_evento: tarifa.idevento, informacion_adicional: "" }
      ]);
    }
  
  };

  const eliminarPreguntaNueva = (index) => {
    setPreguntasNuevas(preguntasNuevas.filter((_, i) => i !== index));
  };

  const handleGuardarPreguntasNuevas = async () => {
    const preguntasFiltradas = preguntasNuevas.filter(pregunta => pregunta.informacion_adicional.trim() !== "");
    
    if (preguntasFiltradas.length === 0) return;
  
    const dataToSend = preguntasFiltradas.map(pregunta => ({
      id_tarifa: tarifa.id,
      id_evento: tarifa.idevento,
      informacion_adicional: pregunta.informacion_adicional
  }));


  try {
    const response = await api.infoAdicional.createPreguntas(tarifa.idevento, dataToSend );
      if (response.status !== "success") {
        throw new Error(response.message);
      }
      setPreguntasNuevas([]); 
    } catch (error) {
      console.error(error.message)
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h4 id="modal-modal-title">Editar tarifa</h4>
        <Box 
          component="form" 
          noValidate 
          autoComplete="off" 
          sx={{
            maxHeight: '70vh',  
            overflowY: 'auto', 
            paddingRight: '10px', 
          }}>
          <TextField
            name="concepto"
            label="Concepto"
            variant="outlined"
            value={dataForm.concepto}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="precio"
            label="Precio"
            variant="outlined"
            type="number"
            value={dataForm.precio}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          {!tarifa.privado && (
            <>
              <div style={{ display: 'flex', gap: '10px', marginTop: '15px' }}>
                <TextField
                  label="Inicio de Venta - Fecha"
                  type="date"
                  name="inicio_venta_fecha"
                  InputLabelProps={{ shrink: true }}
                  value={dataForm.inicio_venta_fecha}
                  onChange={handleChange}
                  required
                  fullWidth
                />
                <TextField
                  label="Inicio de Venta - Hora"
                  type="time"
                  name="inicio_venta_hora"
                  InputLabelProps={{ shrink: true }}
                  value={dataForm.inicio_venta_hora}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </div>
              <div style={{ display: 'flex', gap: '10px', marginTop: '15px' }}>
                <TextField
                  label="Fin de Venta - Fecha"
                  type="date"
                  name="fin_venta_fecha"
                  InputLabelProps={{ shrink: true }}
                  value={dataForm.fin_venta_fecha}
                  onChange={handleChange}
                  required
                  fullWidth
                />
                <TextField
                  label="Fin de Venta - Hora"
                  type="time"
                  name="fin_venta_hora"
                  InputLabelProps={{ shrink: true }}
                  value={dataForm.fin_venta_hora}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </div>
            </>
          )}

          <FormControl fullWidth margin="normal">
            <InputLabel>Fechas del evento</InputLabel>
            <Select
              multiple
              name="fechas"
              onChange={handleChange}
              value={dataForm.fechas || []}
              input={<OutlinedInput label="Fechas del evento" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const selectedOption = fechasEvento?.find((opt) => opt.id === value);
                    return <Chip key={value} label={moment(selectedOption?.fecha).clone().local().format("D [de] MMMM")} />;
                  })}
                </Box>
              )}
            >
              {fechasEvento?.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                >
                  <Checkbox checked={dataForm.fechas?.includes(item.id)} />
                  {moment(item.fecha).clone().local().format("D [de] MMMM Y")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Límite de compra por pedido"
            variant="outlined"
            fullWidth
            name="limite_persona"
            onChange={handleChange}
            value={dataForm.limite_persona}
            margin="normal"
          />
          <TextField
            label="Límite de tarifas"
            variant="outlined"
            fullWidth
            name="limite_maximo"
            onChange={handleChange}
            value={dataForm.limite_maximo}
            margin="normal"
          />
          {tarifa.informacion_adicional && preguntasAdicionales?.informaciones_adicionales?.length > 0 && (
            <div>
              {preguntasAdicionales.informaciones_adicionales
                .filter(pregunta => pregunta.informacion_adicional.trim() !== "")
                .map((pregunta, index) => (
                  <div key={pregunta.id} style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      value={pregunta.informacion_adicional || ""}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      fullWidth
                      margin="normal"
                      disabled={disabledState[index]}
                      sx={{marginRight: '10px'}}
                    />
                    <IconButton color='primary' onClick={() => disabledState[index] ? handleEnableInput(index) : handleSavePregunta(pregunta.id, index)}>
                      {disabledState[index] ? (
                        <ModeEditOutlineOutlinedIcon />  
                      ) : (
                        <SaveOutlinedIcon />  
                      )}
                    </IconButton>
                    <IconButton 
                      aria-label="delete" 
                      color="error"
                      onClick={() => handleDeletePregunta(pregunta.id)}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </div>
                ))
              }
              <div>
                {preguntasNuevas.length === 0 && (
                  <div style={{display: 'flex', justifyContent: 'end'}}>
                    <Button variant="text" onClick={handleButtonClick}>
                      <small>Agregar Preguntas</small>
                    </Button>
                  </div>
                )}

                {preguntasNuevas.length > 0 && (
                  <Box mt={2}>
                    {preguntasNuevas.map((pregunta, index) => (
                      <Box key={index} display="flex" alignItems="center" gap={1} mb={1}>
                        <TextField
                          label="Pregunta"
                          value={pregunta.informacion_adicional}
                          onChange={(e) => handlePreguntaNuevasChange(index, e.target.value)}
                          fullWidth
                        />
                        <IconButton 
                          aria-label="delete" 
                          color="error"
                          onClick={() => eliminarPreguntaNueva(index)}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Box>
                    ))}
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                      <Button variant="text" onClick={agregarPregunta} disabled={preguntasNuevas[preguntasNuevas.length - 1].informacion_adicional.trim() === ""}>
                        <small>Agregar más</small>
                      </Button>
                    </div>
                  </Box>
                )}
              </div>
            </div>
          )}
          {(errors) && (
            <Alert severity="error">
              {errorMessage}
            </Alert>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={onClose} variant="outlined" color="primary" sx={{ marginRight: 2 }}>
              Cancelar
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              disabled={update.loading}
            >
              {update.loading ? "Guardando..." : "Guardar"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};