import { Alert, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, ListSubheader, MenuItem, Radio, RadioGroup, Select, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { api } from "../../services/api";

const NewDiseno = ({ onClose, onCreate }) => {
  const [loading, setLoading] = useState(true);
  const [tarifasTickets, setTarifasTickets] = useState([]);
  const [tarifasVouchers, setTarifasVouchers] = useState([]);
  const [selectedTarifa, setSelectedTarifa] = useState(null);
  const [selectedFormato, setSelectedFormato] = useState(null);
  const [alertFormato, setAlertFormato] = useState(null);
  const [alertTarifa, setAlertTarifa] = useState(null);

  const [error, setError] = useState(null);
  const history = useHistory()
  const param = useParams();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const response = await api.tarifas.getTarifasSinDiseno(param.id);
        if (response.status === "success" && response.data.tarifas) {
          setTarifasTickets(response.data.tarifas.filter(tarifa => tarifa.idtipo === 1))
          setTarifasVouchers(response.data.tarifas.filter(tarifa => tarifa.idtipo === 2))
        }
        if (!response.data.tarifas){
          setAlertTarifa("Todas tus tarifas tienen diseño, crea una nueva o edita los diseños actuales")
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, []);

  const createDiseno = async () => {
    let json = {
      type: {
        type: '',
        width: 0,
        height: 0,
      },
      elements: []
    }
    if (selectedFormato === "pulsera") {
      json.type.type = "pulsera"
      json.type.width = 250
      json.type.height = 25
    }
    if (selectedFormato === "tarjeta") {
      json.type.type = "tarjeta"
      json.type.width = 100
      json.type.height = 50
    }
    if (!selectedFormato) {
      setAlertFormato("Seleccione un formato");
      return;
    }
    if (!selectedTarifa) {
      setAlertTarifa("Seleccione una tarifa")
      return;
    }
    const data = {
      idevento: parseInt(param.id),
      idtarifa: parseInt(selectedTarifa),
      json: JSON.stringify(json)
    }
    setLoading(true)
    try {
      const response = await api.impresion.create(param.id, selectedTarifa, data);
      if (response.status === "error") {
        throw Error(response.message);
      };
      onClose();
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
      onCreate(selectedTarifa);
    };
  };

  const handleTarifaSelected = (event) => {
    setAlertTarifa(null);
    setSelectedTarifa(event.target.value);
  };
  const handleFormatoSelected = event => {
    setAlertFormato(null);
    setSelectedFormato(event.target.value);
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
    >
      <Box
        sx={{
          padding: 0,

        }}
      >
        <DialogTitle
          variant="h6"
        >
          Creá un nuevo diseño
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBottom: "0px",
          }}
        >
          <form autoComplete="off" className="w-100" >
            <FormControl
              fullWidth={true}
              required
              error={alertTarifa ? true : false}
            >
              <FormLabel color="primary">Seleccioná la tarifa a diseñar:</FormLabel>
              <Select
                onChange={handleTarifaSelected}
              >
                <ListSubheader>Tickets</ListSubheader>
                {tarifasTickets?.map(ticket => (
                  <MenuItem
                    value={ticket.id}
                    key={ticket.id}
                  >
                    {ticket.concepto}
                  </MenuItem>
                ))}
                {!tarifasTickets &&
                  <Box className="px-4">
                    <Typography >No tienes tienes tickets</Typography>
                  </Box>
                }
                <ListSubheader>Vouchers 1</ListSubheader>
                {tarifasVouchers?.map(voucher => (
                  <MenuItem
                    value={voucher.id}
                    key={voucher.id}
                  >
                    {voucher.concepto}
                  </MenuItem>
                ))}
                {!tarifasVouchers &&
                  <Box className="px-4">
                    <Typography>No tienes tienes vouchers</Typography>
                  </Box>
                }
              </Select>
              <FormHelperText>{alertTarifa}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth={true}
              required
              error={alertFormato ? true : false}
            >
              <FormLabel sx={{ paddingTop: "1rem" }} color="primary">Tipo de formato:</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleFormatoSelected}
              >
                <FormControlLabel value="pulsera" control={<Radio />} label="Pulsera" />
                <FormControlLabel value="tarjeta" control={<Radio />} label="Tarjeta" />
              </RadioGroup>
              <FormHelperText>{alertFormato}</FormHelperText>
            </FormControl>
          </form>
        </DialogContent>
        {!(loading || (tarifasTickets && tarifasVouchers)) &&
          <Box className="d-block px-3 pt-2">
            <Alert severity="info">
              <Typography>Para comenzar un diseño debes tener una tarifa</Typography>
            </Alert>
          </Box>
        }
        <DialogActions>
          {loading || (tarifasTickets && tarifasVouchers) ?
            (
              <Stack
                direction="row"
                sx={{
                  height: "100%",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: "1rem",
                  padding: "1rem"
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading || (!tarifasTickets && !tarifasVouchers)}
                  startIcon={loading ? <CircularProgress size={20} color="inherit" /> : ''}
                  onClick={createDiseno}
                >
                  Comenzar
                </Button>
                <Button variant="contained" disabled={loading} color="default" onClick={onClose}>Cancelar</Button>
              </Stack>
            ) : (
              <Stack>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push(`/eventos/${param.id}/tarifas`);
                  }}
                >
                  Crear Tarifa
                </Button>
              </Stack>
            )
          }
        </DialogActions>
        {error &&
          <Alert severity="error" sx={{ margin: "1rem" }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        }
      </Box>

    </Dialog>
  )
};

export default NewDiseno;