import { updateTicketImage } from './api';
import useQuery from "../../../hooks/useQuery";

const useUpdate = ({ idevento, idticket }) => {
  const { refetch, loading } = useQuery({
    autoFetch: false,
    queryFn: updateTicketImage,
    onSuccess: res => {
      window.location.reload();
    },
    args: [idevento, idticket],
  });

  const fetch = () => {
    refetch(idevento, idticket);
  };

  return {
    fetch,
    loading,
  }
};

export default useUpdate;