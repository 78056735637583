import { Modal, Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";

export const MensajeFinalizarCreacionEvento = ({ open, handleClose, eventoId }) => {
    const history = useHistory();
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? 340 : 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: isMobile ? 2 : 4,
    }; 

    const handleActivateEvent = (eventId) => {
        history.push('/crearEvento/segundoPaso', { eventId }); 
    };

    return (
        <Modal
            open={open} onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{ ...style}}>
                <h2 id="child-modal-title">Activación de Evento</h2>
                <p id="child-modal-description">
                    Para activar tu evento, asegúrate de completar todos los pasos requeridos en el proceso de creación. Una vez que hayas finalizado, podrás gestionar y administrar tu evento.
                </p>
                <div className="d-flex justify-content-end">
                    {/* <div>
                        <Button size="small"  sx={{ backgroundColor: red[700], color: 'white' }} onClick={handleEliminarEvento}>Eliminar Evento</Button>
                    </div> */}
                    <div>
                        <Button sx={{marginRight: "10px"}} size="small" onClick={handleClose}>Cerrar</Button>
                        <Button variant="contained" onClick={() => handleActivateEvent(eventoId)}>Activar evento</Button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
