import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Container, Button, Stack } from '@mui/material';
import useTicket from './useTicket';
import useUpdate from './useUpdate';
import { config } from '../../../services/config';

const apiOrigin = config.URL_API;


const Ticket = () => {
  const param = useParams();
  const [t] = useTranslation("global");
  const { ticket } = useTicket();
  // console.log(param.idticket);
  const { fetch } = useUpdate({ idevento: param.id, idticket: param.idticket });
  const routeImage = `${apiOrigin}/static/eventos/tickets/ticket${param.idticket}.png`;

  const imageStyle = {
    width: window.innerWidth > 880 ? "350px" : "100px"
  }

  return (
    <Container
      fluid="sm"
      style={{
        marginLeft: "10px",
        marginTop: "20px",
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          //display row
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Paper className="d-flex justify-content-between text-left"> */}
          <div className="mb-1">
            <div className="w-100" style={{margin: "0 auto"}}>
              {ticket ? (
                <div key={param.idticket} className=" d-flex flex-column align-item-center align-items-center p-4">
                  {!ticket.codigo &&
                    <Alert color="info">{t("tickets.noCode")}.<br />
                      {t("tickets.support")}</Alert>
                  }
                  <div className="w-100 d-flex flex-column align-item-center">
                    <p className="m-0 text-secondary">ID: {ticket.id}</p>
                    <p className="m-0 ">{t("tickets.name")}: {ticket.nombre}</p>
                    <p className="m-0 ">{t("tickets.lastName")}: {ticket.apellido}</p>
                    {/* <p className="m-0 ">{t("tickets.event")}: {ticket.categoria?.categoria}</p> */}
                  </div>
                </div>) : (
                <Alert color="info">{t("tickets.error")}</Alert>
              )
              }
            </div>
          </div>
        </Box>
        <img src={routeImage} alt='Imagen del ticket' style={imageStyle}/>
        <Box
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={fetch}
          >
            Actualizar diseño ticket
          </Button>
        </Box>
      </Stack>
    </Container>
  );
};

export default Ticket;