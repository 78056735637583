import { useContext } from "react";
import { ImpresionContext } from "../../providers/Impresion";
import { PaginatorContext } from "../../providers/Paginator";
import { Button, Icon, Typography } from "@mui/material";
import { AddCircleRounded } from "@mui/icons-material";
  
const UseTemplate = ({ children, template, evento, tarifa }) => {
  const {
    elements,
    setElements,
    dimensionContainer,
    setDimensionContainer,
    uploadHistory,
  } = useContext(ImpresionContext)

  const { event } = useContext(PaginatorContext)

  const HandleTemplate = (template) => {
    if (template === 'pulsera') {
      setDimensionContainer({ ...dimensionContainer, type: 'pulsera', width: 1000, height: 100 })
      setTimeout(() => {
        setElements([
          {
            "id": "128",
            "x": 146,
            "y": 11,
            "width": 80,
            "height": 80,
            "type": "qr"
          },
          {
            "id": "129",
            "x": 476.99999999999955,
            "y": 26.999999999999986,
            "text": evento,
            "fontSize": 21,
            "rotation": 0,
            "width": 228.69632969085396,
            "height": 25.03974412673583,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text-evento"
          },
          {
            "id": "131",
            "x": 499,
            "y": 59,
            "text": "Nombre Apellido",
            "fontSize": 14,
            "rotation": 0,
            "width": 128,
            "height": 15,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text-nombre"
          }
        ])
      }, 300)

    }
    if (template === 'tarjeta') {
      setDimensionContainer({ ...dimensionContainer, type: 'tarjeta', width: 480, height: 240 })
      setTimeout(() => {
        setElements([
          {
            "id": "117",
            "x": 287.0125537552901,
            "y": 16.833047947574727,
            "width": 165.77157238457835,
            "height": 165.7715723845781,
            "type": "qr",
            "fontSize": 0
          },
          {
            "id": "122",
            "x": 84,
            "y": 196,
            "text": "Nombre Apellido",
            "fontSize": 14,
            "rotation": 0,
            "width": 170,
            "height": 15,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text-nombre"
          },
          {
            "id": "124",
            "x": 26,
            "y": 196,
            "text": "Nombre:",
            "fontSize": 14,
            "rotation": 0,
            "width": 60.999999999999986,
            "height": 14.999999999999984,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text"
          },
          {
            "id": "126",
            "x": 19.999999999999943,
            "y": 16.00000000000003,
            "text": evento,
            "fontSize": 39,
            "rotation": 0,
            "width": 169.52659490477916,
            "height": 121.26719927495176,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text-evento"
          },
          {
            "id": "127",
            "x": 356.0000000000001,
            "y": 211.00000000000028,
            "text": "012345678912",
            "fontSize": 14,
            "rotation": 0,
            "width": 175,
            "height": 15.000000000000028,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text-codigo"
          }
        ])
      }, 300)
    }
    if (template === 'voucher') {
      setDimensionContainer({ ...dimensionContainer, type: 'voucher', width: 100, height: 50 })
      setTimeout(() => {
        setElements([])
      }, 300)
    }
    setTimeout(() => {
      uploadHistory(elements)
    }, 600)
  }

  return (
    <Button
      variant="contained"
      color="default"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "50px",
      }}
      onClick={() => HandleTemplate(template)}
    >
      <Typography sx={{ width: "auto" }} variant="p">{children}</Typography>
      <Icon sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <AddCircleRounded color="primary" sx={{ fontSize: 24 }} />
      </Icon>
    </Button>
  )
};

export default UseTemplate;