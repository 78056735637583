import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { api } from '../../services/api';
import './CookieConsent.css';
import { useTranslation } from 'react-i18next';

const CookieConsent = () => {
    const [cookies, setCookie] = useCookies(['userConsent']);
    const [showBanner, setShowBanner] = useState(false);
    const [error, setError] = useState(null);
    const [t] = useTranslation("global");

    useEffect(() => {
        if (typeof cookies.userConsent === 'false') {
            setShowBanner(true);
        } else {
            setShowBanner(false);
        }
    }, [cookies.userConsent]);

    const handleConsent = (consent) => {
        const expirationDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
        const isProduction = process.env.NODE_ENV === 'production';
        const formData = { consent };

        setCookie('userConsent', consent, { path: '/', expires: expirationDate, secure: isProduction });
        setCookie('functionalConsent', true, { path: '/', expires: expirationDate });
        setCookie('analyticsConsent', consent, { path: '/', expires: expirationDate });
        setCookie('advertisingConsent', consent, { path: '/', expires: expirationDate });
        setCookie('thirdPartyConsent', consent, { path: '/', expires: expirationDate });

        setShowBanner(false);
        callApi(formData);
    };

    const callApi = async (formData) => {
        try {
            const response = await api.cookies.updateCookies(formData);
            if (response.status === "success") {
                return null;
            }
        } catch (e) {
            setError(e);
        }
    };

    return (
        <>
            {/* {showBanner && (
                <div className="cookie-banner">
                    <p>{t("cookies.cookies_banner")}</p>
                    <div>
                        <button onClick={() => handleConsent(true)}>{t("cookies.accept")}</button>
                        <button onClick={() => handleConsent(false)}>{t("cookies.reject")}</button>
                    </div>
                </div>
            )} */}
        </>
    );
};

export default CookieConsent;
