import Slider from 'react-slick';
import { useMediaQuery } from "react-responsive";

import img1 from '../../../assets/imgs-slider/Slider 1.png'
import img2 from '../../../assets/imgs-slider/Slider 2.png'
import img3 from '../../../assets/imgs-slider/Slider 3.png'

import imgMobile1 from '../../../assets/imgs-slider/Slider-Mobile-1.jpg'
import imgMobile2 from '../../../assets/imgs-slider/Slider-Mobile-2.jpg'
import imgMobile3 from '../../../assets/imgs-slider/Slider-Mobile-3.png'

import "./homeHeader.css";

export const HomeHeader = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 940px)` });
  const settings = {
    infinite: true,
    speed: 500, 
    slidesToShow: 1, 
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };
  return (
    <>
      {isMobile ? (
        <div className='slider-mobile'>
          <Slider {...settings}>
            <div className='div-img-slider-home'>
              <img className='img-slider-home' src={imgMobile1} alt="Imagen 1" style={{ width: '100%', height: 'auto'  }} />
            </div>
            <div className='div-img-slider-home'>
              <img className='img-slider-home' src={imgMobile2} alt="Imagen 2" style={{ width: '100%', height: 'auto' }} />
            </div>
            <div className='div-img-slider-home'>
              <img className='img-slider-home' src={imgMobile3} alt="Imagen 3" style={{ width: '100%', height: 'auto' }} />
            </div>
          </Slider>
        </div>
      ) : (
        <div className="slider-container">
          <Slider {...settings}>
            <div className='div-img-slider-home'>
              <img className='img-slider-home' src={img1} alt="Imagen 1" style={{ width: '100%', height: 'auto'  }} />
            </div>
            <div className='div-img-slider-home'>
              <img className='img-slider-home' src={img2} alt="Imagen 2" style={{ width: '100%', height: 'auto' }} />
            </div>
            <div className='div-img-slider-home'>
              <img className='img-slider-home' src={img3} alt="Imagen 3" style={{ width: '100%', height: 'auto' }} />
            </div>
          </Slider>
        </div>
      )}
    </>
  )
}
