import { useState, useEffect, useContext } from "react";
import { api } from "../../services/api";
import Loading from "../../components/Loading";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PaginatorContext } from "../../providers/Paginator";
import VolverButton from "../../components/VolverButton";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Container, Button, Table, TablePagination, Menu, ListItemIcon, ListItemText, TableBody, InputAdornment, MenuItem, Select, Paper, TableContainer, TableHead, TableRow, TableCell, CircularProgress, Alert, TextField, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PrintIcon from '@mui/icons-material/Print';
// import ImprimirTickets from "../../components/modales/ImprimirTickets/ImprimirTicketsModal";

const Vouchers = () => {
  const history = useHistory();
  const param = useParams()
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState();
  const [tarifas, setTarifas] = useState([]);
  const [tarifasId, setTarifasId] = useState([]);
  const [filtroTarifas, setFiltroTarifas] = useState("Todo");
  const [tarifaFiltrada, setTarifaFiltrada] = useState("");
  const [data, setData] = useState([]);
  const [showSearch, setShowSearch] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElID, setAnchorElID] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const {
    query,
    setQuery,
    totalLength,
    setTotalLength,
    currentPage,
    setCurrentPage,
    setPageSize,
    pageSize
  } = useContext(PaginatorContext);


  const handleRowClick = (ticket) => {
    const url = `/eventos/${param.id}/vouchers/${ticket.id}`;
    window.open(url, "_blank");
  };

  const notify = (type, message, position = "bottom-center") => {
    const options = {
      position,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    switch (type) {
      case 'success':
        toast.success(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'info':
        toast.info(message, options);
        break;
      case 'warning':
        toast.warning(message, options);
        break;
      default:
        toast(message, options);
    }
  }

  const openSeeMore = Boolean(anchorEl);
  const ITEM_HEIGHT = 48

  const handleClickSeeMore = (e) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    setAnchorElID(e.currentTarget.id)
  }

  const handleCloseSeeMore = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
    setAnchorElID(null)
  }

  useEffect(() => {
    const callApi = async (id) => {
      setLoading(true);

      try {
        const response = await api.vouchers.getVouchers(
          id,
          query,
          pageSize,
          currentPage
        );

        if (response.status === "success") {
          const vouchers = response.data.vouchers || [];
          setData(vouchers);
          setTotalLength(response.data.totalDataSize || 0);
         /*  console.log("resp", response.data.totalDataSize); */

          /*  const tarifasUnicas = [
             ...new Set(res?.map((tarifa) => tarifa.tarifa?.concepto)),
           ];
           setTarifas(tarifasUnicas); */

          const tarifasConIdsUnicas = [
            ...new Map(
              vouchers.map((tarifa) => [
                tarifa.tarifa.id,
                { id: tarifa.tarifa.id, concepto: tarifa.tarifa.concepto }
              ])
            ).values(),
          ];
          setTarifasId(tarifasConIdsUnicas);
        }

      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 4000,
        });
        setData([]);
        setTotalLength(0);
      } finally {
        setLoading(false);
      }
    };

    callApi(param.id);
    document.title = `Vouchers | PassGo`;
  }, [param.id, query, pageSize, currentPage, setTotalLength]);


  const HandleDownloadReport = (e) => {
    e.stopPropagation()

    const callExcel = async (e) => {
      try {
        const response = await api.reporte.downloadReporteVoucher(param.id)
        if (!response) {
          notify("error", "Error al realizar la descarga")
          throw new Error('Error al descargar el Excel')
        }
        const blob = await response;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ReporteVoucher-${param.id}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        notify("success", "La descarga se realizó con éxito")
      } catch (error) {
        notify("error", "Hubo un problema al descargar el archivo");
      }
    }
    callExcel()
  }
  // Manejar el cambio de página
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Manejar el cambio de tamaño de página
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reiniciar la página al cambiar el tamaño
  };

  const handleToggleSearch = () => {
    setShowSearch(!showSearch); // Cambia el estado de visibilidad del campo de búsqueda
    setQuery(""); // Limpia el campo de búsqueda al mostrarlo
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  /* useEffect(() => {
    let filtro = data;

    if (filtroTarifas !== "Todo") {
      filtro = filtro?.filter((tarifa) =>
        tarifa?.tarifa?.concepto === filtroTarifas
      )
    }

    setTarifaFiltrada(filtro);
  }, [filtroTarifas, data]); */

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(e.target.q.value);
    setCurrentPage(0);
  };

  return (
    <Container>
      <div className="d-flex justify-content-between flex-wrap">
        <div className="d-flex flex-row gap-2">
          <form onSubmit={handleSearch}>
            <div className="w-100 d-flex" >
              <TextField
                id="exampleSearch"
                name="q"
                placeholder="Buscar..."
                type="search"
                variant="outlined"
                size="small"
                sx={{ height: "100%" }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </div>
          </form>

          {/* <Select
            sx={{
              borderRadius: "4px", height: "95%", padding: "4px !important",
              ".MuiSelect-select": {
                padding: "5px 8px !important",
              },
            }}
            className=""
            value={filtroTarifas}
            onChange={(event) => {
              setFiltroTarifas(event.target.value);
            }}
            displayEmpty
            IconComponent={() => null}
            renderValue={() => (
              <IconButton sx={{ padding: "0px" }}>
                <FilterAltOutlinedIcon />
              </IconButton>
            )}
          >
            <MenuItem sx={{
              fontFamily: "Inter", padding: "4px !important"
            }} value='' hidden>
              <FilterAltOutlinedIcon sx={{ padding: "4px !important" }} />
            </MenuItem>
            <MenuItem sx={{
              fontFamily: "Inter"
            }} value='Todo'>
              Todo
            </MenuItem>
            {tarifas?.map((tarifa) => (
              <MenuItem sx={{
                fontFamily: "Inter",
              }} key={tarifa} value={tarifa}>
                {tarifa}
              </MenuItem>
            ))}
          </Select> */}
        </div>
        <div className="d-flex flex-row gap-3 align-items-center">
          <Button variant="contained" color="success" startIcon={<AddIcon />}
            onClick={() => history.push("/eventos/" + param.id + "/nuevoVoucher")}
          >
            Nuevo voucher
          </Button>

          <IconButton
            aria-label="see more"
            id={param.id}
            aria-controls={openSeeMore ? 'long-menu' : undefined}
            aria-expanded={openSeeMore ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClickSeeMore}
            sx={{ p: 0 }}
            className="ms-auto"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={param.id}
            aria-label="report"
            anchorEl={anchorEl}
            open={param.id === anchorEl?.id}
            onClose={handleCloseSeeMore}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={HandleDownloadReport}>
              <ListItemIcon>
                <FileDownloadOutlinedIcon sx={{ color: "#5656FF" }} />
              </ListItemIcon>
              <ListItemText>Descargar vouchers</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleOpenModal}>
              <ListItemIcon>
                <PrintIcon sx={{ color: "#5656FF" }} />
              </ListItemIcon>
              <ListItemText>Imprimir vouchers</ListItemText>
            </MenuItem>
            {/* <ImprimirTickets open={openModal} onClose={handleCloseModal} filtroTarifas={filtroTarifas} tarifasId={tarifasId} idevento={param.id} tipo="vouchers"/> */}
          </Menu>
        </div>
      </div>

      <TableContainer component={Paper} className="mt-3 table-responsive">
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{t("tickets.code")}</TableCell>
              <TableCell>Vouchers</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>{t("tickets.name")}</TableCell>
              <TableCell>{t("tickets.lastName")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              data?.length > 0 ? (
                data?.map((voucher) => (
                  <TableRow key={voucher.id}
                    hover
                    onClick={() => handleRowClick(voucher)}>
                    <TableCell component="th" scope="row">
                      <div className="d-flex align-items-center gap-2">
                        {/* <Button variant="contained" size="small" onClick={() => history.push(`/eventos/${param.id}/tickets/${ticket.id}`)}>
                          <LocalPrintshopOutlinedIcon/>
                        </Button> */}
                        {voucher.id}
                      </div>
                    </TableCell>

                    <TableCell>{voucher.codigo}</TableCell>

                    <TableCell>{voucher.tarifa.concepto}</TableCell>

                    <TableCell>{voucher.cantidad}</TableCell>

                    <TableCell>{voucher.nombre}</TableCell>

                    <TableCell>{voucher.apellido}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Alert severity="info" >
                      No hay vouchers aún.
                    </Alert>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          className="d-flex justify-content-center"
          rowsPerPageOptions={[10, 25, 50]} // Opciones de tamaño de página
          count={totalLength || 0} // Total de registros
          rowsPerPage={pageSize} // Tamaño de la página actual
          page={currentPage} // Página actual
          onPageChange={handleChangePage} // Evento de cambio de página
          onRowsPerPageChange={handleChangeRowsPerPage} // Evento de cambio de tamaño de página
          labelRowsPerPage="Registros p/página"
          showFirstButton
          showLastButton
        />
      </TableContainer>


      <div className="d-flex justify-content-end mt-3">
        <VolverButton />
      </div>
    </Container>
  )
};

export default withAuthenticationRequired(Vouchers, {
  onRedirecting: () => <Loading />,
});