import { Button, Container, Icon, Paper, Stack, Typography } from "@mui/material";
import SideBar from "./SideBar";
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import { ImpresionContext } from "../../providers/Impresion";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const DesignSideBar = ({ onClose, getDiseno }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState()
  const { elements, setElements, setTarifa, tarifa } = useContext(ImpresionContext)

  const param = useParams()

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const response = await api.impresion.getAll(param.id)
        if (response.status === "error") {
          throw Error("Error al obtener los diseños")
        }
        setData(response.data.disenos)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    fetch()
  }, [])



  return (
    <SideBar id="designBar">
      <Container
        sx={{
          padding: 0
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
            gap: "0.5 rem"
          }}
        >
          <Typography variant="h5">Tus Diseños</Typography>
          <Button
            variant="text"
            color="default"
            onClick={() => onClose()}
            size="small"
            sx={{
              padding: "0px"
            }}
          >
            <CloseIcon />
          </Button>
        </Stack>
        <Stack
          spacing={2}
          sx={{
            padding: "1rem 0rem",
          }}
        >
          {!loading &&
            data?.map((diseno) => (
              <Button
                key={diseno.id}
                variant="contained"
                color="default"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  minHeight: "50px",
                }}
                onClick={() => {
                  getDiseno(diseno.id);
                }}
              >
                <Typography sx={{ width: "auto" }} variant="p">{diseno?.tarifa}</Typography>
                <Icon sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <ArrowForwardIcon sx={{ fontSize: 16 }} />
                </Icon>
              </Button>
            ))
          }
        </Stack>
      </Container>
    </SideBar>
  )
};

export default DesignSideBar;