import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Container from '@mui/material/Container';
import { useHistory, useParams } from "react-router-dom";
import Canva from '../../components/Canva';
import NavCanva from '../../components/Impresion/NavCanva';
import RedoIcon from '@mui/icons-material/Redo';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import { ImpresionContext } from '../../providers/Impresion';
import { PaginatorContext } from '../../providers/Paginator';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { api } from '../../services/api';
import { notify } from "../../utils/notifyToast"
import { useState } from 'react';
import "../../components/Impresion/fuentes.css"
import NewDiseno from '../../components/Impresion/NewDiseno';
import AddIcon from '@mui/icons-material/Add';

const Impresion = (setToggle = true) => {
  const {
    // tarifa,
    dimensionContainer,
    setDimensionContainer,
    typeTicket,
    setTypeTicket,
    driverObj,
    ableUndo,
    ableRedo,
    setAbleUndo,
    setAbleRedo,
    indexHistory,
    setIndexHistory,
    setElements,
    isCreated,
    setIsCreated,
    elements,
    setEdited,
    setError,
    setSaved,
    history,
    setIndex,
  } = useContext(ImpresionContext);

  const { setIsToggled } = useContext(PaginatorContext);
  const [eventoNombre, setEventoNombre] = useState(null);
  const [tarifaNombre, setTarifaNombre] = useState(null);
  const [tarifaId, setTarifaId] = useState(null);

  const [loading, setLoading] = useState(true);
  const [disenoId, setDisenoId] = useState(null);
  const [disenos, setDisenos] = useState([])
  const [openNew, setOpenNew] = useState(true)
  const [openDisenosMade, setOpenDisenosMade] = useState(false)
  const [showCanva, setShowCanva] = useState(false)

  const [alertDiseno, setAlertDiseno] = useState(null);

  const param = useParams();
  const historyBrowser = useHistory();

  const getDiseno = async (idDiseno) => {
    if (!idDiseno) {
      setAlertDiseno("Seleccione un diseño")
      return;
    }
    setAlertDiseno(null);
    await setDisenoId(idDiseno);
    setIsCreated(true);
    setLoading(true);
    try {
      const response = await api.impresion.getDisenoByID(idDiseno)
      if (response === "error") {
        throw Error("Error al obtener el evento: " + response.message)
      }
      const data = JSON.parse(response.data.diseno.json)
      await setElements(data.elements)
      await setDimensionContainer({ ...dimensionContainer, ...data.type })
      setIndex(1 + parseInt(data.elements?.reduce((max, element) => element.id > max ? element.id : max, 0)))
      setEventoNombre(response.data.diseno.evento.evento)
      setTarifaNombre(response.data.diseno.tarifa.concepto)
      setTarifaId(response.data.diseno.tarifa.id)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setIsToggled(setToggle)
    const header = document.getElementsByTagName('header')
    for (let i = 0; i < header.length; i++) {
      header[i].style.display = 'none';
    }

    window.addEventListener('popstate', () => HandleBackward());

    document.title = `Imprimir | PassGo`;

    setLoading(false)

    const callApi = async () => {
      setLoading(true)
      try {
        const response = await api.impresion.getAll(param.id);
        if (response.status === "error") {
          throw Error();
        }
        if (response.data.disenos && (response.data.disenos.length > 0)) {
          const resDisenos = response.data.disenos.slice()
          await setDisenos(resDisenos)
          setOpenNew(false)
          setOpenDisenosMade(true)
          return
        }
        setOpenNew(true)
      } catch (error) {
        console.log(error.message)
      } finally {
        setLoading(false)
      }
    };
    callApi()

    return () => {
      window.removeEventListener('popstate', HandleBackward)
    }
  }, [])

  const HandleBackward = () => {
    setIsToggled(false)
    const header = document.getElementsByTagName('header')
    for (let i = 0; i < header.length; i++) {
      header[i].style.display = 'block';
    }

    historyBrowser.push(`/eventos/${param.id}`)
  }


  const handleTypeTicket = (type) => {
    if (type === "pulsera") {
      setTypeTicket(true)
      setDimensionContainer({ ...dimensionContainer, type: "pulsera", width: 250, height: 25 })
    } else if (type === "tarjeta") {
      setTypeTicket(true)
    } else {
      alert("Debe seleccionar un tipo de ticket")
    }
    driverObj.drive()
  }

  const HandleUndo = () => {
    setAbleRedo(true)

    if (indexHistory >= history.length - 1) {
      setAbleUndo(false)
      return // No puedes deshacer más allá del inicio del historial
    }
    // Calcula el nuevo índice de historial
    const newIndexHistory = indexHistory + 1

    // Accede al estado correspondiente en el historial
    const newElements = history[history.length - 1 - newIndexHistory]

    // Actualiza el estado con el nuevo valor
    setElements(newElements)
    setIndexHistory(newIndexHistory)

    if (newIndexHistory >= history.length - 1) {
      setAbleUndo(false)
      return // No puedes deshacer más allá del inicio del historial
    }
  }

  const HandleRedo = () => {
    setAbleUndo(true)

    if (indexHistory <= 0) {
      setAbleRedo(false)
      return // No puedes rehacer más allá del final del historial
    }
    // Calcula el nuevo índice de historial
    const newIndexHistory = indexHistory - 1;

    // Accede al estado correspondiente en el historial
    const newElements = history[history.length - 1 - newIndexHistory];

    // Actualiza el estado con el nuevo valor
    setElements(newElements);
    setIndexHistory(newIndexHistory);

    if (newIndexHistory <= 0) {
      setAbleRedo(false)
      return // No puedes deshacer más allá del inicio del historial
    }
  };

  const HandleEdit = () => {
    let stringDimensiones = ''

    let newElements = elements?.map((element) => {
      return {
        ...element,
        x: element.x,
        y: element.y,
        width: element.width,
        height: element.height,
        fontSize: element.fontSize
      }
    })

    const data = {
      type: dimensionContainer,
      elements: newElements
    }
    const callApi = async () => {
      setLoading(true)
      try {
        if (!(data instanceof String)) {
          stringDimensiones = JSON.stringify(data)
        }

        const response = await api.impresion.update(param.id, disenoId, stringDimensiones);

        if (response.status === "success") {
          setEdited(true)//estado que maneja el alert
          notify("success", "Se guardaron los cambios")
        } else {
          setError(true)
          notify("error", "No se guardaron los cambios")
        }

      } catch (error) {
        notify("error", "No se guardaron los cambios")
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    callApi();

  }

  const SaveStage = () => {
    let stringDimensiones = '';

    const newElements = elements?.map((element) => {
      return {
        ...element,
        x: element.x,
        y: element.y,
        width: element.width,
        height: element.height,
        fontSize: element.fontSize,
      }
    })

    const data = {
      type: dimensionContainer,
      elements: newElements
    };

    const callApi = async () => {
      setLoading(true);
      try {
        if (!(data instanceof String)) {
          stringDimensiones = JSON.stringify(data);
        };
        const response = await api.impresion.create(param.id, disenoId, { json: stringDimensiones });

        if (response.status === "success") {
          setIsCreated(true);//estado maneja el mostrados de botones Guardar o Editar
          setSaved(true);//estado que maneja el alert
          notify("success", "El diseño se creó con éxito");
        };

      } catch (error) {
        notify("error", "No se puede guardar el diseño")
      } finally {
        setLoading(false)
      }
    }
    callApi();
  }

  useEffect(() => {
    const handlePressKey = (event) => {
      if (event.ctrlKey && event.key === 'z') {
        HandleUndo()
      }
      if (event.ctrlKey && event.key === 'y') {
        HandleRedo()
      }
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault()
        if (!isCreated) {
          SaveStage()
          return
        }
        HandleEdit()
      }
    };

    window.addEventListener('keydown', handlePressKey);

    return () => {
      window.removeEventListener('keydown', handlePressKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  const handleChangeSize = (event) => {
    setDimensionContainer({ ...dimensionContainer, [event.target.name]: parseFloat(event.target.value) })
  };

  return (
    <Container
      maxWidth="xl"
      className="h-100 p-0"
    >
      <NavCanva getDiseno={getDiseno} evento={eventoNombre} tarifa={tarifaNombre} idTarifa={tarifaId} />
      <Container
        maxWidth="xl"
        className="h-100 bg-dark-subtle"
        style={{ padding: "0 0 0 120px" }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem"
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            variant="contained"
            onClick={() => HandleBackward()}
          >
            Atras
          </Button>
          <Box>
            <Typography variant='h5'>
              {tarifaNombre ? tarifaNombre : "Nombre del diseño"}
            </Typography>
          </Box>
          <Stack direction="row" spacing={1}>
            <FormControl onChange={handleChangeSize}>
              <Stack
                direction="row"
                gap={1}
                width={250}
              >
                <TextField
                  label="Ancho"
                  size="small"
                  name='width'
                  value={dimensionContainer.width}
                >
                </TextField>
                <TextField
                  label="Alto"
                  size="small"
                  name='height'
                  value={dimensionContainer.height}
                >
                </TextField>
              </Stack>
            </FormControl>
            <Button variant="contained" disabled={!ableUndo} onClick={HandleUndo}>
              <UndoIcon />
            </Button>
            <Button variant="contained" disabled={!ableRedo} onClick={HandleRedo}>
              <RedoIcon />
            </Button>
            {isCreated ? (
              <Button
                variant="contained"
                color='primary'
                onClick={HandleEdit}
                type='button'
                disabled={loading}
                startIcon={!loading ? <SaveIcon /> : <CircularProgress size={20} color="inherit" />}
              >
                Guardar
              </Button>
            ) : (
              <Button
                variant="contained"
                color='primary'
                onClick={SaveStage}
                disabled={loading | (!disenoId ? true : false)}
                startIcon={!loading ? <SaveIcon /> : <CircularProgress size={20} color="inherit" />}
                type='button' className=''>
                Guardar Diseño
              </Button>
            )}
          </Stack>
        </Stack>
        {showCanva ? (
          <Canva
            id='canva'
            width={dimensionContainer.width}
            height={dimensionContainer.height}
            getDiseno={getDiseno}
            idTarifa={tarifaId}
          />
        ) : (
          <Box className="p-4">
            <Alert severity="info" className='align-items-center d-flex gap-3'>
              <Stack direction="column" spacing={1}>
                <Typography>Selecciona una tarifa para comenzar a diseñar</Typography>
                <Box>
                  <Button variant="contained" onClick={() => { setOpenNew(true) }}>Seleccionar Tarifa</Button>
                </Box>
              </Stack>
            </Alert>
          </Box>
        )
        }
      </Container>
      {openNew &&
        <NewDiseno
          onClose={() => {
            setOpenNew(false)
          }}
          onCreate={(value) => {
            setShowCanva(true)
            setDisenoId(value)
            getDiseno(value)
          }}
        />
      }
      {openDisenosMade &&
        <Dialog
          open={true}
          fullWidth={true}
        >
          <DialogTitle
            variant="h6"
          >
            Tus diseños
          </DialogTitle>
          <DialogContent
            sx={{
              paddingBottom: "0px",
            }}
          >
            <Stack
              direction="row"
            >
              <FormControl fullWidth sx={{ m: 1 }} required error={alertDiseno ? true : false}>
                <InputLabel id="diseno">Tus diseños</InputLabel>
                <Select
                  labelId="diseno"
                  onChange={(event) => getDiseno(event.target.value)}
                  label="Tus diseños"
                  required
                >
                  <ListSubheader>Tus diseños</ListSubheader>
                  {disenos?.map(diseno => (
                    <MenuItem
                      value={diseno.id}
                      key={diseno.id}
                    >
                      {diseno.tarifa}
                    </MenuItem>
                  ))}
                  {!disenos &&
                    <Box className="px-4">
                      <Typography >No tienes tienes diseños</Typography>
                    </Box>
                  }
                </Select>
                <FormHelperText>{alertDiseno}</FormHelperText>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "space-between", margin: "0rem 1.5rem 1.5rem 1.5rem" }}>
            <Button
              variant='contained'
              color=''
              size='small'
              onClick={() => {
                HandleBackward()
              }}
            >
              Cancelar
            </Button>
            <Stack
              direction="row"
              sx={{
                height: "100%",
                justifyContent: "end",
                alignItems: "center",
                gap: "1rem",
                // padding: "1rem"
              }}
            >
              <Button
                variant="contained"
                size='small'
                color="primary"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : ''}
                onClick={() => {
                  if (!disenoId) {
                    setAlertDiseno("Seleccione un diseño");
                    return;
                  }
                  setShowCanva(true);
                  setOpenDisenosMade(false);
                }}
              >
                Continuar diseño
              </Button>
              <Button
                variant="outlined"
                disabled={loading}
                size='small'
                color="primary"
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <AddIcon size={20} />}
                onClick={() => {
                  setOpenDisenosMade(false);
                  setOpenNew(true);
                }}
              >
                Crear Nuevo
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      }
      <Dialog
        open={!typeTicket}
      // onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          {"Diseña tus tickets"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Con qué formato te gustaria comenzar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => handleTypeTicket("tarjeta")}>
            Tarjetas
          </Button>
          <Button variant='contained' onClick={() => handleTypeTicket("pulsera")}>
            Pulseras
          </Button>
        </DialogActions>
      </Dialog>
    </Container >
  )
}

export default Impresion