import React, { useState,} from "react";
import { api } from "../../../services/api";
import { useParams } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from "react-i18next";
import {
  Container,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Alert,
  IconButton,
} from '@mui/material';
import DeleteTarifaModal from "../../../components/modales/DeleteTarifaModal";
import { EditTarifaModal } from "../../../components/modales/EditTarifaModal";
import useQuery from "../../../hooks/useQuery";
import { showErrorToastify } from "../../../utils";
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

export const EventoVouchers = () => {
  const [vouchers, setVouchers] = useState([]);
  const param = useParams();
  const [t] = useTranslation("global");
  const [selectedTarifa, setSelectedTarifa] = useState(null);
  const [openEditar, setOpenEditar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const { refetch } = useQuery({
    autoFetch: true,
    queryFn: api.tarifas.getAllVouchers,
    onSuccess: (res) => {
      setVouchers(res.data.tarifas);
      setTotalItems(res.data.totalDataSize);
    },
    onError: (err) => showErrorToastify({ err }),
    args: [param.id, "", pageSize, currentPage],
  });

  return (
    <Container>

      <h4>Para vouchers</h4>
      <TableContainer component={Paper} sx={{ marginBottom: "25px" }} >
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
            <TableRow>
              <TableCell>Vouchers</TableCell>
              <TableCell>{t("rates.price")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vouchers && vouchers.length > 0 ? (
              vouchers.map((tarifa) => (
                <TableRow key={tarifa.id}>
                  <TableCell component="th" scope="row">{tarifa.concepto}</TableCell>
                  <TableCell sx={{ maxWidth: 300 }}>$ {tarifa.precio}</TableCell>
                  <TableCell align="center" sx={{ width: 50 }}>
                    <div className="d-flex gap-4 align-items-center">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setSelectedTarifa(tarifa);
                          setOpenEditar(true);
                        }}
                      >
                        <EditOutlinedIcon sx={{ fontSize: "27px" }} />
                      </IconButton>
                      <DeleteTarifaModal id={tarifa.id} callApi={refetch} />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Alert severity="info" >
                    No hay vouchers registrados
                  </Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalItems}
          page={currentPage - 1}
          onPageChange={(event, newPage) => {
            setCurrentPage(newPage + 1);
            refetch(param.id, "", pageSize, newPage + 1);
          }}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(event) => {
            const newSize = parseInt(event.target.value, 10);
            setPageSize(newSize);
            setCurrentPage(1);
            refetch(param.id, "", newSize, 1);
          }}
          labelRowsPerPage="Filas por página"
        />
      </TableContainer>

      {openEditar &&
        <EditTarifaModal
          tarifa={selectedTarifa}
          onClose={() => {
            setOpenEditar(false);
            refetch(param.id);
          }}
        />
      }

    </Container>
  );
};

