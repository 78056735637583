import queryString from "query-string";
import useQuery from "../../../hooks/useQuery";
import { showErrorToastify, showSuccessToastify } from "../../../utils";
import { downloadImpresionConFiltro } from "./api";
import { useState } from "react";
import { useParams } from 'react-router-dom';


const useImprimir = () => {
  const [filter, setFilter] = useState({
    desde: "",
    hasta: "",
    nombre: ""
  });
  const [dimensionesImpresion, setDimensionesImpresion] = useState({
    ancho: "",
    alto: "",
    cantidad_fila: "",
    margen: ""
  });
  const [tarifaId, setTarifaId] = useState(null);
  const { id } = useParams();
  // const [blob, setBlob] = useState(null);

  const { loading, refetch, data } = useQuery({
    queryFn: downloadImpresionConFiltro,
    onSuccess: response => {
      showSuccessToastify({ res: "La descarga se realizó con éxito" })
      // setBlob(response)
    },
    onError: err => showErrorToastify({ err }),
    autoFetch: false,
  });

  const onTarifaChange = (value) => {
    setTarifaId(value)
  };

  const fetch = () => {
    const query = queryString.stringify({
      desde: filter.desde,
      hasta: filter.hasta,
      nombre: filter.nombre,
      ancho: dimensionesImpresion.ancho,
      alto: dimensionesImpresion.alto,
      cantidad_fila: (!dimensionesImpresion.cantidad_fila || dimensionesImpresion.cantidad_fila === "" || dimensionesImpresion.cantidad_fila === 0) ? 1 : dimensionesImpresion.cantidad_fila,
      margen: dimensionesImpresion.margen,
    })

    if (!tarifaId) return showErrorToastify({ err: "Debes seleccionar una tarifa" })

    refetch(id, tarifaId, query);
  };

  const onFormChange = (newFilter) => {
    setFilter(filter => ({ ...filter, ...newFilter }));
  }

  const onFormDimensionesChange = (newDimensiones) => {
    setDimensionesImpresion(dimension => ({ ...dimension, ...newDimensiones }))
  }

  return {
    fetch,
    archivo: data,
    loading,
    onFormChange,
    onTarifaChange,
    filter,
    onFormDimensionesChange,
  };
};

export default useImprimir;