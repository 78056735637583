import React, { Fragment, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../services/api";
import Footer from "../components/Footer";
import Ads from "../components/publicaciones-home/Ads";
import CtaHome from "../components/home/content/CtaHome";
import { HomeHeader } from "../components/home/header/homeHeader";
import "../App.css";

const Home = () => {
  const { user, isAuthenticated } = useAuth0();
  const [searchPub, setSearchPub] = useState("");
  const [usuario, setUsuario] = useState(null)

  const handleSearchChange = (pub) => {
    setSearchPub(pub);
  };

  useEffect(() => {
    const fetchUser = async () => {
    if (isAuthenticated && user) {
      try {
        const response = await api.usuarios.getUsuario();
        if (response.status === "success") {
          setUsuario(response.data)
      }}
      catch (error) {
        console.error('Error al obtener el usuario:', error);
      }
    };
  };
    fetchUser();
  }, [isAuthenticated, user]);

  return (
    <Fragment>

      {/* <HomeHeader searchPub={searchPub} onSearchChange={handleSearchChange}/> */}
      <HomeHeader/>

      <Ads usuario={usuario} searchPub={searchPub}/>
      
      <CtaHome/>
      
      <Footer />
      
    </Fragment>
  );
};

export default Home;
