import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment-timezone";
import "./creaEventos.css"
import { api } from '../../../services/api';
import { useMediaQuery } from "react-responsive";

import { FormControl, TextField, Button, Select, MenuItem } from '@mui/material';

const FormConsultas = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [t] = useTranslation("global");
  const [nombre, setNombre] = useState();
  const [email, setEmail] = useState();
  const [telefono, setTelefono] = useState();
  const [consulta, setConsulta] = useState();
  const [nombreEvento, setNombreEvento] = useState();
  const [fechaEvento, setFechaEvento] = useState();
  const [asistentesEvento, setAsistentesEvento] = useState();
  const [nombreError, setNombreError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [consultaError, setConsultaError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [tiposEvento, setTiposEvento] = useState([])
  const [tipoEventoSeleccionado, setTipoEventoSeleccionado] = useState("");

  useEffect(() => {
    const getEventosTipos = async () => {
      try {
        const response = await api.eventos.getAllEventosTipos();
        if (response.status === "success") {
          setTiposEvento(response.data.eventoTipos);
        }
      } catch (error) {
        console.error("Error: " + error);
      }
    };
    getEventosTipos();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nombre?.trim() === '') {
      setNombreError(<>{t("errors.emptyField")}</>);
    }
    if (email?.trim() === '') {
      setEmailError(<>{t("errors.emptyField")}</>);
    }
    if (consulta?.trim() === '') {
      setConsultaError(<>{t("errors.emptyField")}</>);
    }

    else {

      const newConsulta = {
        nombre: nombre,
        email: email,
        telefono: telefono,
        consulta: consulta,
        nombreEvento: nombreEvento,
        fechaEvento: fechaEvento ? moment.tz(fechaEvento, "America/Buenos_Aires").format() : null,
        asistentesEvento: parseInt(asistentesEvento),
        tipoEvento: parseInt(tipoEventoSeleccionado),
      }
      try {
        const response = await api.consultas.create(newConsulta);
        if (response.status === "success") {
          setNombre('')
          setEmail('')
          setTelefono('')
          setConsulta('')
          setNombreEvento('')
          setFechaEvento('')
          setAsistentesEvento('')
          setTipoEventoSeleccionado('')
          setSubmitted(true);
        }
      } catch (error) {
        setNombre('')
        setEmail('')
        setTelefono('')
        setConsulta('')
      }
    }
  }

  return (
    <>
      {isMobile ? (
        <>
          <div id="contactus" className="contactus-div" style={{ paddingLeft: "12%", paddingRight: "12%" }}>
            <p className="my-4" style={{ marginBottom: "30px", fontWeight: "bold", color: "#221A60", fontSize: "28px", textAlign: "center" }}>Hagamos que tu evento despegue</p>
            <form className="contactus-div-form" onSubmit={handleSubmit} >
              <FormControl sx={{ width: "100%" }}>
                <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Nombre y Apellido</span>
                <TextField
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      "& fieldset": {
                        borderColor: "#6E6E9C",
                      },
                      "&:hover fieldset": {
                        borderColor: "#6E6E9C",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#6E6E9C",
                      },
                    },
                    input: {
                      padding: "5px 10px",
                      fontSize: "16px",
                      fontFamily: "Bricolage Grotesque",
                    },
                  }}
                  id="name"
                  name="name"
                  type="string"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Correo electronico</span>
                <TextField
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      "& fieldset": {
                        borderColor: "#6E6E9C",
                      },
                      "&:hover fieldset": {
                        borderColor: "#6E6E9C",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#6E6E9C",
                      },
                    },
                    input: {
                      padding: "5px 10px",
                      fontSize: "16px",
                      fontFamily: "Bricolage Grotesque",
                    },
                  }}
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Teléfono (opcional)</span>
                <TextField
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      "& fieldset": {
                        borderColor: "#6E6E9C",
                      },
                      "&:hover fieldset": {
                        borderColor: "#6E6E9C",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#6E6E9C",
                      },
                    },
                    input: {
                      padding: "5px 10px",
                      fontSize: "16px",
                      fontFamily: "Bricolage Grotesque",
                    },
                  }}
                  id="phone"
                  name="phone"
                  type="string"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                />
              </FormControl>

              <div className="d-flex flex-row gap-3">
                <FormControl sx={{ width: "70%" }}>
                  <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Nombre del evento (Si ya lo tienes definido)</span>
                  <TextField
                    sx={{
                      marginTop: "5px",
                      marginBottom: "15px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        "& fieldset": {
                          borderColor: "#6E6E9C",
                        },
                        "&:hover fieldset": {
                          borderColor: "#6E6E9C",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#6E6E9C",
                        },
                      },
                      input: {
                        padding: "5px 10px",
                        fontSize: "16px",
                        fontFamily: "Bricolage Grotesque",
                      },
                    }}
                    id="event"
                    name="event"
                    type="string"
                    value={nombreEvento}
                    onChange={(e) => setNombreEvento(e.target.value)}
                  />
                </FormControl>
                <FormControl sx={{ marginBottom: "15px", width: "40%" }}>
                  <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }} id="tipo-evento-label">Tipo de Evento</span>
                  <Select
                    labelId="tipo-evento-label"
                    id="tipo-evento"
                    sx={{
                      marginTop: "5px",
                      fieldset: {
                        borderColor: "#6E6E9C",
                        borderWidth: "1px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6E6E9C",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6E6E9C",
                      },
                      "& .MuiSelect-select": {
                        padding: "5px 10px",
                        fontSize: "16px",
                        fontFamily: "Bricolage Grotesque",
                      },
                    }}
                    value={tipoEventoSeleccionado || ""}
                    onChange={(e) => setTipoEventoSeleccionado(e.target.value)}
                  >
                    {tiposEvento?.map((tipo) => (
                      <MenuItem key={tipo.clid} value={tipo.id} sx={{
                        fontSize: "16px",
                        fontFamily: "Bricolage Grotesque",
                      }}>
                        {tipo.evento_tipo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="d-flex flex-row gap-3">
                <FormControl sx={{ width: "50%" }}>
                  <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Fecha estimada del evento (opcional)</span>
                  <TextField
                    sx={{
                      marginTop: "5px",
                      marginBottom: "15px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        "& fieldset": {
                          borderColor: "#6E6E9C",
                        },
                        "&:hover fieldset": {
                          borderColor: "#6E6E9C",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#6E6E9C",
                        },
                      },
                      input: {
                        padding: "5px 10px",
                        fontSize: "16px",
                        fontFamily: "Bricolage Grotesque",
                      },
                      "& input[type=date]::-webkit-calendar-picker-indicator": {
                        display: "none"
                      }
                    }}
                    id="fecha"
                    name="fecha"
                    type="date"
                    value={fechaEvento}
                    onChange={(e) => setFechaEvento(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ width: "50%" }}>
                  <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>N° de asistentes al evento (opcional)</span>
                  <TextField
                    sx={{
                      marginTop: "5px",
                      marginBottom: "15px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        "& fieldset": {
                          borderColor: "#6E6E9C",
                        },
                        "&:hover fieldset": {
                          borderColor: "#6E6E9C",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#6E6E9C",
                        },
                      },
                      input: {
                        padding: "5px 10px",
                        fontSize: "16px",
                        fontFamily: "Bricolage Grotesque",
                      },
                    }}

                    id="asistentes"
                    name="asistentes"
                    type="number"
                    value={asistentesEvento}
                    onChange={(e) => setAsistentesEvento(e.target.value)}
                  />

                </FormControl>
              </div>
              <FormControl className="mb-3" sx={{ width: "100%" }}>
                <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Mensaje</span>
                <TextField
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      "& fieldset": {
                        borderColor: "#6E6E9C",
                      },
                      "&:hover fieldset": {
                        borderColor: "#6E6E9C",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#6E6E9C",
                      },
                    },
                    input: {
                      padding: "5px 10px",
                      fontSize: "16px",
                      fontFamily: "Bricolage Grotesque",
                    },
                  }}
                  id="text"
                  name="text"
                  value={consulta}
                  onChange={(e) => setConsulta(e.target.value)}
                  multiline
                  minRows={3}
                />
              </FormControl>
              <div className="d-flex justify-content-end">
                  <Button type="submit" className="d-flex align-items-center" style={{ marginLeft: "10px", marginBottom: "30px", borderRadius: "7px", backgroundColor: "#5656FF", padding: "6px 22px", color: "white" }}>
                    Enviar
                  </Button>
                </div>
                {!submitted ? (
                  <div></div>
                ) : (
                  <div><p style={{ fontSize: "18px", fontWeight: "700", color: "green", padding: "10px" }}>Gracias por comunicarte con nosotros. Responderemos tu consulta a la brevedad.</p></div>
                )}
            </form>

          </div>
        </>
      ) : (
        <>
          <div id="contactus" className="contactus-div">
            <div className="contactus-div-form">
              <p className="my-4" style={{ marginBottom: "30px", fontWeight: "bold", color: "#221A60", fontSize: "28px", textAlign: "left" }}>Hagamos que tu evento despegue</p>
              <form className="contactus-div-form-form" onSubmit={handleSubmit} >
                <div className="d-flex flex-row gap-3 mb-3">
                  <FormControl sx={{ width: "100%" }}>
                    <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Nombre y Apellido</span>
                    <TextField
                      sx={{
                        marginTop: "5px",
                        marginBottom: "15px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          "& fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&:hover fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#6E6E9C",
                          },
                        },
                        input: {
                          padding: "5px 10px",
                          fontSize: "16px",
                          fontFamily: "Bricolage Grotesque",
                        },
                      }}
                      id="name"
                      name="name"
                      type="string"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Correo electronico</span>
                    <TextField
                      sx={{
                        marginTop: "5px",
                        marginBottom: "15px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          "& fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&:hover fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#6E6E9C",
                          },
                        },
                        input: {
                          padding: "5px 10px",
                          fontSize: "16px",
                          fontFamily: "Bricolage Grotesque",
                        },
                      }}
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  {' '}
                </div>

                <div className="d-flex flex-row gap-3 mb-3">
                  <FormControl sx={{ width: "100%" }}>
                    <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Teléfono (opcional)</span>
                    <TextField
                      sx={{
                        marginTop: "5px",
                        marginBottom: "15px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          "& fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&:hover fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#6E6E9C",
                          },
                        },
                        input: {
                          padding: "5px 10px",
                          fontSize: "16px",
                          fontFamily: "Bricolage Grotesque",
                        },
                      }}
                      id="phone"
                      name="phone"
                      type="string"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Nombre del evento (Si ya lo tienes definido)</span>
                    <TextField
                      sx={{
                        marginTop: "5px",
                        marginBottom: "15px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          "& fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&:hover fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#6E6E9C",
                          },
                        },
                        input: {
                          padding: "5px 10px",
                          fontSize: "16px",
                          fontFamily: "Bricolage Grotesque",
                        },
                      }}
                      id="event"
                      name="event"
                      type="string"
                      value={nombreEvento}
                      onChange={(e) => setNombreEvento(e.target.value)}
                    />
                  </FormControl>
                  {' '}
                </div>

                <div className="d-flex flex-row gap-3 w-100 mb-3">
                  <FormControl sx={{ marginBottom: "15px", width: "33%" }}>
                    <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }} id="tipo-evento-label">Tipo de Evento</span>
                    <Select
                      labelId="tipo-evento-label"
                      id="tipo-evento"
                      sx={{
                        marginTop: "5px",
                        fieldset: {
                          borderColor: "#6E6E9C",
                          borderWidth: "1px",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6E6E9C",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6E6E9C",
                        },
                        "& .MuiSelect-select": {
                          padding: "5px 10px",
                          fontSize: "16px",
                          fontFamily: "Bricolage Grotesque",
                        },
                      }}
                      value={tipoEventoSeleccionado || ""}
                      onChange={(e) => setTipoEventoSeleccionado(e.target.value)}
                    >
                      {tiposEvento?.map((tipo) => (
                        <MenuItem key={tipo.clid} value={tipo.id} sx={{
                          fontSize: "16px",
                          fontFamily: "Bricolage Grotesque",
                        }}>
                          {tipo.evento_tipo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: "33%" }}>
                    <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Fecha estimada del evento (opcional)</span>
                    <TextField
                      sx={{
                        marginTop: "5px",
                        marginBottom: "15px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          "& fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&:hover fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#6E6E9C",
                          },
                        },
                        input: {
                          padding: "5px 10px",
                          fontSize: "16px",
                          fontFamily: "Bricolage Grotesque",
                        },
                        "& input[type=date]::-webkit-calendar-picker-indicator": {
                          display: "none"
                        }
                      }}
                      id="fecha"
                      name="fecha"
                      type="date"
                      value={fechaEvento}
                      onChange={(e) => setFechaEvento(e.target.value)}
                    />
                  </FormControl>

                  <FormControl sx={{ width: "33%" }}>
                    <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Cantidad de asistencia al evento (opcional)</span>
                    <TextField
                      sx={{
                        marginTop: "5px",
                        marginBottom: "15px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          "& fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&:hover fieldset": {
                            borderColor: "#6E6E9C",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#6E6E9C",
                          },
                        },
                        input: {
                          padding: "5px 10px",
                          fontSize: "16px",
                          fontFamily: "Bricolage Grotesque",
                        },
                      }}

                      id="asistentes"
                      name="asistentes"
                      type="number"
                      value={asistentesEvento}
                      onChange={(e) => setAsistentesEvento(e.target.value)}
                    />

                  </FormControl>
                </div>

                <FormControl className="mb-3" sx={{ width: "100%" }}>
                  <span style={{ fontSize: "16px", color: "#221A60", fontWeight: "400" }}>Mensaje</span>
                  <TextField
                    sx={{
                      marginTop: "5px",
                      marginBottom: "15px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        "& fieldset": {
                          borderColor: "#6E6E9C",
                        },
                        "&:hover fieldset": {
                          borderColor: "#6E6E9C",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#6E6E9C",
                        },
                      },
                      input: {
                        padding: "5px 10px",
                        fontSize: "16px",
                        fontFamily: "Bricolage Grotesque",
                      },
                    }}
                    id="text"
                    name="text"
                    value={consulta}
                    onChange={(e) => setConsulta(e.target.value)}
                    multiline
                    minRows={3}
                  />
                </FormControl>
                {' '}

                <div className="d-flex justify-content-end">
                  <Button type="submit" className="d-flex align-items-center" style={{ marginLeft: "10px", marginBottom: "30px", borderRadius: "7px", backgroundColor: "#5656FF", padding: "6px 22px", color: "white" }}>
                    Enviar
                  </Button>
                </div>
                {!submitted ? (
                  <div></div>
                ) : (
                  <div><p style={{ fontSize: "18px", fontWeight: "700", color: "green", padding: "10px" }}>Gracias por comunicarte con nosotros. Responderemos tu consulta a la brevedad.</p></div>
                )}
              </form>

            </div>
          </div>
        </>
      )}
    </>
  )
}


export default FormConsultas;