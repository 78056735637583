import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImpresionContext } from "../../providers/Impresion";
import { api } from "../../services/api";
import SideBar from "./SideBar";
import { Box, CircularProgress } from "@mui/material";

const ImageSideBar = ({ onClose }) => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const {
    elements,
    setElements,
    index,
    dimensionContainer,
    setIndex,
    setOpenImage
  } = useContext(ImpresionContext)
  const [addImageBar, setToggleImageBar] = useState(false)
  const [isImgUploaded, setImgUploaded] = useState(false)
  const [urlStore, setURLStore] = useState([])
  const [loading, setLoading] = useState(true)
  const notify = (type, message, position = "bottom-center") => {
    const options = {
      position,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    switch (type) {
      case 'success':
        toast.success(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'info':
        toast.info(message, options);
        break;
      case 'warning':
        toast.warning(message, options);
        break;
      default:
        toast(message, options);
    }
  }

  const param = useParams()

  const HandleAddImg = (url) => {
    const img = new Image()
    img.src = url
    img.onload = () => {
      if (img.height > dimensionContainer.height) {
        const reScaled = img.height / (dimensionContainer.height)
        img.width = img.width / reScaled
        img.height = img.height / reScaled
      }
      const imgGenerated = elements.slice();
      setIndex(index + 1)
      imgGenerated.push({
        id: index.toString(),
        x: ((dimensionContainer.width) / 2) - img.width,
        y: ((dimensionContainer.height) / 2) - (img.height / 2),
        width: img.width,
        height: img.height,
        imgURL: url,
        type: 'image',
      })
      setElements(imgGenerated)
    }

  }

  const HandleToggleAddImage = () => {
    setToggleImageBar(true)
  }

  //funcion para subir la imagen a cloudinary
  const HandleImageUpload = async (e) => {
    const selectedImage = Array.from(e.target.files)

    const formData = new FormData()
    selectedImage.forEach((image) => {
      formData.append("files", image)
    })

    const callSaveInDB = async (medias) => {
      try {
        const data = {
          attachs: medias.map((media) => ({
            url: media.url,
            type: media.type,
            format: media.format,
            public_id: media.public_id,
          }))
        }
        const response = await api.impresion.uploadImage(param.id, data)
        if (response.status === "success") {
          setImgUploaded(true)
          notify("success", "¡Tu Imagen ha sido subida!")
        }
      } catch (error) {
        setState({
          ...state,
          showResult: true,
          apiMessage: "Error al subir imagen a la DB",
          error: error.error
        })
      }
    }

    const callApi = async () => {
      try {
        const response = await api.media.upload(formData)
        if (response.status === "success") {
          const medias = response.data.media
          callSaveInDB(medias)
        }
      } catch (error) {
        setState({
          ...state,
          showResult: true,
          apiMessage: "Error al subir imagen a Cloudinary",
          error: error.error
        })
      }
    }
    callApi()

  }

  const HandleImageDelete = (img) => {
    const deleteImage = async () => {
      try {
        const response = await api.impresion.deleteImage(param.id, img.id, { public_id: img.public_id })

        if (response.status === "success") {
          notify("warning", "Se eliminó correctamente")
        }
      } catch (error) {
        setState({
          ...state,
          showResult: true,
          apiMessage: "Error al eliminar la imagen",
          error: error.error
        })
      }
    }
    deleteImage()
    const newStore = urlStore.slice()
    const index = newStore.findIndex((i) => i.id === img.id)
    newStore.splice(index, 1)
    setURLStore(newStore)
  }

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.impresion.getImages(param.id)
        if (response.status === "success") {
          setURLStore(response.data.imagenes)
        }
        setImgUploaded(false)
      } catch (error) {
        setState({
          ...state,
          showResult: true,
          apiMessage: "Error al obtener imagenes de la DB",
          error: error.error
        })
      } finally {
        setLoading(false)
      }
    }
    callApi()
  }, [isImgUploaded])

  return (
    <>
      <SideBar id='imageBar'>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p className="p-0 m-0 fs-4">Imagenes</p>
          </div>
          <div>
            <button className="d-flex align-items-center" onClick={() => onClose()}>
              <i className="material-symbols-outlined">close</i>
            </button>
          </div>
        </div>
        <div className="w-100">
          <p className="m-0 p-0 fs-5">Tus imagenes</p>
        </div>
        <div className="h-100 overflow-auto p-0 m-0">
          {loading ?
            (
              <Box className="w-100">
                <CircularProgress className="text-center" />
              </Box>
            ) :
            (
              urlStore?.length > 0 ?
                (urlStore?.map((img, i) => (
                  <div
                    key={i}
                    className="position-relative"
                  >
                    <div className="position-absolute" style={{ top: '5px', right: '5px' }}>
                      <button
                        onClick={() => HandleImageDelete(img)}
                      >
                        <i className="material-symbols-outlined">close</i>
                      </button>
                    </div>
                    <div
                      key={i}
                      onClick={() => {
                        HandleAddImg(img.url)
                      }}
                      className="btn rounded-3 overflow-hidden border p-0 mb-1"
                      style={{
                        width: '100%',
                        height: '200px',
                        backgroundImage: `url(${img.url})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}>
                    </div>
                  </div>
                ))) : (
                  <div className="w-100">
                    <p className="text-center">No hay imagenes subidas</p>
                  </div>
                )
            )
          }
        </div>
        <div
          className={`w-100 pb-2 d-flex flex-column gap-1 ${addImageBar ? '' : ''}`}
          style={{
            transition: 'height 1s',
            height: '54px'
          }}
        >
          <div className="w-100 d-flex flex-column align-items-center gap-1 overflow-hidden">
            <div className="w-100 d-flex gap-1">
              <button
                className="btn btn-primary w-100 rounded-3 d-flex align-items-center p-1 m-0"
              >
                <style>{
                  `.custom-file-upload input[type="file"]{
                  display:none
                  }
                  `
                }</style>
                <label
                  className="custom-file-upload w-100 h-100 p-0 m-0"
                  style={{
                    cursor: 'pointer',
                  }}>
                  <input
                    type="file"
                    name="files"
                    id="files"
                    accept="image/*,"
                    multiple
                    onChange={HandleImageUpload}
                  />
                  <p className="w-100 text-center m-0 fs-5"
                    onClick={HandleToggleAddImage}
                  >Subí una imagen</p>
                </label>
              </button>
            </div>
          </div>
        </div>
      </SideBar >
    </>
  )
}

export default ImageSideBar