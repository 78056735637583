import { config } from "../../../services/config";
import { privateOptions } from "../../../utils";

const apiOrigin = config.URL_API;

export const downloadImpresionConFiltro = async (id, tarifaId, q = "") => {
  const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/${tarifaId}/download?${q}`, privateOptions('GET'));
  if (!response.ok) {
    return await response.json();
  }
  const resBlob = await response.blob();
  return resBlob;
};

export const downloadImpresionVoucher = async (id, tarifaId, q = "") => {
  const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/${tarifaId}/downloadVoucher?${q}`, privateOptions('GET'));
  // const response = await fetch(apiOrigin + `/eventos/${id}/impresion/${tarifaId}/downloadVoucher?desde=2010&hasta=2309`, pivateOptions('GET'));
  if (!response.ok) {
    return await response.json();
  }
  const resBlob = await response.blob();
  return resBlob;
};

export const getCantidadByTarifa = async (idEvento, tarifaId, q) => {
  const response = await fetch(`${apiOrigin}/${idEvento}/tarifas/${tarifaId}/cantidad?${q}`, privateOptions('GET'));
  const data = await response.json();
  return data;
};