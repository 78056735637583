import { CloseOutlined, InfoOutlined, CampaignOutlined, ShoppingCartOutlined } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Button, Zoom, Tooltip, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, } from "@mui/material";

const EventoTarifas = ({ data, cantidad, toggleDrawer, setCantidad, tarifasTicketsFiltrados, tarifasVouchersFiltrados, setTarifasSeleccionadas }) => {

  const incremento = (idtarifa) => {
    setCantidad((prevCantidades) => {
      const nuevaCantidad = (prevCantidades[idtarifa] || 0) + 1;

      const tarifa = data?.tarifas?.find((tarifa) => tarifa.id === idtarifa);
      let limite = tarifa
        ? Math.min(tarifa.restantes, tarifa.limite_persona)
        : 0;

      if (nuevaCantidad > parseInt(limite)) {
        return prevCantidades;
      }

      return {
        ...prevCantidades,
        [idtarifa]: nuevaCantidad,
      };
    });
  };

  const decremento = (idtarifa) => {
    setCantidad((prevCantidades) => ({
      ...prevCantidades,
      [idtarifa]: Math.max(0, (prevCantidades[idtarifa] || 0) - 1),
    }));
  };

  /* CALCULOS TARIFAS */
  const calculoSubtotalTarifa = (tarifa) => {
    const cantidadTarifa = cantidad[tarifa.id] || 0;
    return cantidadTarifa * tarifa.precio;
  };

  const subtotal =
    data?.tarifas?.reduce((acc, tarifa) => {
      return acc + calculoSubtotalTarifa(tarifa);
    }, 0) || 0;

  const comision =
    data?.tarifas?.reduce((acc, tarifa) => {
      const cantidadTarifa = cantidad[tarifa.id] || 0;
      const precioConComision =
        tarifa?.precio *
        (!tarifa?.asumir_comision
          ? tarifa?.tipo_comision?.porcentaje / 100
          : 0);

      return acc + cantidadTarifa * precioConComision;
    }, 0) || 0;

  const calcularTotal = () => {
    const total = subtotal + comision;
    return total;
  };

  const totalFormateado = calcularTotal().toLocaleString("es-ES", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  });

  const handleStartPurchase = () => {
    const seleccionadas = data.tarifas.filter(
      (tarifa) => (cantidad[tarifa.id] || 0) > 0
    );

    setTarifasSeleccionadas(seleccionadas);
    toggleDrawer(true)();
  };

  return (
    <div className="w-100 pb-4" style={{ maxWidth: "400px", marginRight:"40px" }}>
      {(!tarifasTicketsFiltrados && !tarifasVouchersFiltrados) ||
        (tarifasTicketsFiltrados?.length === 0 &&
          tarifasVouchersFiltrados?.length === 0) ? (
        <>
          <div
            className="d-flex align-items-center flex-column "
            style={{ position: "sticky", top: "0", paddingTop: "28px" }}
          >
            <div
              className="contenedor-badge px-4 py-2"
              style={{
                background: "#dadaff",
                color: "#3d2eab",
                fontSize: "24px",
              }}
            >
              <CampaignOutlined
                sx={{
                  width: "45px",
                  height: "45px",
                  marginRight: "22px",
                }}
              />
              <div className="d-flex flex-column">
                <small className="fw-bold" style={{ fontSize: "18px" }}>
                  ¡Próxima apertura!
                </small>
                <small style={{ fontSize: "14px" }}>
                  Estamos trabajando en algo único para ti. ¡Esperalo!
                </small>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {(tarifasTicketsFiltrados.length > 0 ||
            tarifasVouchersFiltrados.length > 0) && (
              <div
                style={{ position: "sticky", top: "0", paddingTop: "28px" }}
              >
                {tarifasTicketsFiltrados.length > 0 && (
                  <TableContainer
                    sx={{ borderRadius: "10px", border: "1px solid #ddd" }}
                  >
                    <Table size="small">
                      <TableHead sx={{ backgroundColor: "#FFD400" }}>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontFamily: "Inter, sans-serif",
                              padding: "6px 16px",
                            }}
                          >
                            ENTRADAS
                          </TableCell>
                          <TableCell
                            sx={{ fontFamily: "Inter, sans-serif" }}
                          >
                            PRECIO
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "Inter, sans-serif",
                              textAlign: "center",
                            }}
                          >
                            CANT.
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tarifasTicketsFiltrados?.map((tarifa) => (
                          <TableRow key={tarifa.id} sx={{}}>
                            <TableCell
                              sx={{
                                fontFamily: "Inter, sans-serif",
                                border: "0",
                              }}
                            >
                              {tarifa.concepto}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Inter, sans-serif",
                                border: "0",
                              }}
                            >
                              ${tarifa.precio}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Inter, sans-serif",
                                border: "0",
                              }}
                            >
                              <div className="d-flex flex-row gap-4">
                                {tarifa?.restantes > 0 ? (
                                  <>
                                    <Button
                                      className=""
                                      disabled={cantidad[tarifa.id] === 0}
                                      onClick={() => decremento(tarifa.id)}
                                      sx={{
                                        minWidth: "3px",
                                        width: "17px !important",
                                        height: "17px",
                                        color:
                                          cantidad[tarifa.id] === 0
                                            ? "#ddd"
                                            : "white",
                                        backgroundColor:
                                          cantidad[tarifa.id] === 0
                                            ? "#ddd"
                                            : "#5656ff",
                                        padding: "0",
                                        fontSize: "15px !important",
                                        textAlign: "center",
                                      }}
                                      aria-label="restar"
                                    >
                                      -
                                    </Button>
                                    <Button
                                      className="fw-bold"
                                      disabled
                                      sx={{
                                        minWidth: "3px",
                                        width: "17px !important",
                                        height: "17px",
                                        color: "black",
                                        cursor: "default",
                                        padding: "0",
                                        "&.Mui-disabled": {
                                          color: "black",
                                          pointerEvents: "none",
                                        },
                                      }}
                                    >
                                      {cantidad[tarifa.id] || 0}
                                    </Button>
                                    <Button
                                      className=""
                                      onClick={() => incremento(tarifa.id)}
                                      sx={{
                                        minWidth: "3px",
                                        width: "17px !important",
                                        height: "17px",
                                        color: "white",
                                        backgroundColor: "#5656ff",
                                        padding: "0",
                                        fontSize: "15px !important",
                                        textAlign: "center",
                                      }}
                                      aria-label="sumar"
                                    >
                                      +
                                    </Button>
                                  </>
                                ) : (
                                  <small className="cartel-agotados p-1 rounded">
                                    <CloseOutlined
                                      sx={{ width: "16px", height: "16px" }}
                                    />{" "}
                                    Agotado
                                  </small>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <div style={{ marginTop: "15px" }}>
                  {tarifasVouchersFiltrados.length > 0 && (
                    <TableContainer
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid #ddd",
                      }}
                    >
                      <Table size="small">
                        <TableHead sx={{ backgroundColor: "#FFD400" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                fontFamily: "Inter, sans-serif",
                                padding: "6px 16px",
                              }}
                            >
                              VOUCHER
                            </TableCell>
                            <TableCell
                              sx={{ fontFamily: "Inter, sans-serif" }}
                            >
                              PRECIO
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Inter, sans-serif",
                                textAlign: "center",
                              }}
                            >
                              CANT.
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tarifasVouchersFiltrados.map((tarifa) => (
                            <TableRow key={tarifa.id} sx={{}}>
                              <TableCell
                                sx={{
                                  fontFamily: "Inter, sans-serif",
                                  border: "0",
                                }}
                              >
                                {tarifa.concepto}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Inter, sans-serif",
                                  border: "0",
                                }}
                              >
                                ${tarifa.precio}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Inter, sans-serif",
                                  border: "0",
                                }}
                              >
                                <div className="d-flex flex-row gap-4">
                                  {tarifa?.restantes > 0 ? (
                                    <>
                                      <Button
                                        className=""
                                        disabled={cantidad[tarifa.id] === 0}
                                        onClick={() =>
                                          decremento(tarifa.id)
                                        }
                                        sx={{
                                          minWidth: "3px",
                                          width: "17px !important",
                                          height: "17px",
                                          color:
                                            cantidad[tarifa.id] === 0
                                              ? "#ddd"
                                              : "white",
                                          backgroundColor:
                                            cantidad[tarifa.id] === 0
                                              ? "#ddd"
                                              : "#5656ff",
                                          padding: "0",
                                          fontSize: "15px !important",
                                          textAlign: "center",
                                        }}
                                        aria-label="restar"
                                      >
                                        -
                                      </Button>
                                      <Button
                                        className="fw-bold"
                                        disabled
                                        sx={{
                                          minWidth: "3px",
                                          width: "17px !important",
                                          height: "17px",
                                          color: "black",
                                          cursor: "default",
                                          padding: "0",
                                          "&.Mui-disabled": {
                                            color: "black",
                                            pointerEvents: "none",
                                          },
                                        }}
                                      >
                                        {cantidad[tarifa.id] || 0}
                                      </Button>
                                      <Button
                                        className=""
                                        onClick={() =>
                                          incremento(tarifa.id)
                                        }
                                        sx={{
                                          minWidth: "3px",
                                          width: "17px !important",
                                          height: "17px",
                                          color: "white",
                                          backgroundColor: "#5656ff",
                                          padding: "0",
                                          fontSize: "15px !important",
                                          textAlign: "center",
                                        }}
                                        aria-label="sumar"
                                      >
                                        +
                                      </Button>
                                    </>
                                  ) : (
                                    <small className="cartel-agotados p-1 rounded">
                                      <CloseOutlined
                                        sx={{
                                          width: "16px",
                                          height: "16px",
                                        }}
                                      />{" "}
                                      Agotado
                                    </small>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
                <div
                  className="d-flex flex-column"
                  style={{
                    width: "100%",
                    backgroundColor: grey[200],
                    borderRadius: "5px",
                    marginTop: "15px",
                  }}
                >
                  <div
                    className="d-flex justify-content-between flex-wrap py-2"
                    style={{
                      backgroundColor: "transparent",
                      padding: "6px 16px",
                    }}
                  >
                    <div className="d-flex flex-row align-items-center">
                      <p
                        className="fw-bold mb-0 fs-6"
                        style={{
                          color: !Object.values(cantidad).some(
                            (val) => val > 0
                          )
                            ? grey[800]
                            : "black",
                        }}
                      >
                        TOTAL
                      </p>
                      <Tooltip
                        arrow
                        TransitionComponent={Zoom}
                        placement="right"
                        title="El precio incluye impuestos"
                      >
                        <InfoOutlined
                          sx={{
                            width: "18px",
                            height: "18px",
                            marginLeft: "5px",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <span className="text-end">
                      <p
                        className="fw-bold mb-0 fs-6"
                        style={{
                          color: !Object.values(cantidad).some(
                            (val) => val > 0
                          )
                            ? grey[800]
                            : "black",
                        }}
                      >
                        $ {totalFormateado}
                      </p>
                    </span>
                  </div>
                  <Button
                    className=""
                    onClick={handleStartPurchase}
                    sx={{
                      padding: "8px 10px",
                      fontFamily: "Inter, sans-serif",
                      backgroundColor: "#5656FF",
                      outline: "none",
                      color: "white",
                      borderRadius: "10px !important",
                      "&:disabled": {
                        backgroundColor: "#ddd",
                        color: grey[800],
                      },
                    }}
                    disabled={
                      !Object.values(cantidad).some((val) => val > 0)
                    }
                  >
                    Comenzar compra{" "}
                    <ShoppingCartOutlined
                      sx={{
                        color: !Object.values(cantidad).some(
                          (val) => val > 0
                        )
                          ? grey[800]
                          : "white",
                        width: "18px",
                        height: "18px",
                        marginLeft: "5px",
                      }}
                    />
                  </Button>
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default EventoTarifas;