import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth0 } from "@auth0/auth0-react";

const CtaHome = () => {
  const history = useHistory();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleButtonClick = () => {
    if (isAuthenticated) {
      history.push("/eventos");
    } else {
      loginWithRedirect({
        appState: { returnTo: "/eventos" }
      });
    }
  };

  return (
    <div>
      <div className="d-flex flex-column justify-content-center" style={{ marginLeft: "12%", marginRight: "12%", paddingTop: "5%" }}>
        <p className="hero-title">Empezá ahora</p>
        <div className="text-center mb-3 px-3">
          <p className="mb-1" style={{ color: "#0B0062", textAlign: "center", fontSize:"18px" }}>Diseñá experiencias inolvidables con nuestro sistema de venta <br/> de entradas flexible y personalizado.</p>
          <p className="mb-0" style={{ color: "#0B0062", textAlign: "center", fontSize:"18px" }}>Descubrí todas las herramientas que ponemos a tu disposición <br/> para optimizar la gestión y garantizar el éxito.</p>
        </div>
        <div className="d-flex justify-content-center mt-4 mb-4 pb-4">
          <button type="button" className="d-flex align-items-center" style={{ marginLeft: "10px", marginBottom: "30px", borderRadius: "7px", backgroundColor: "#5656FF", padding: "6px 32px", color: "white" }} onClick={handleButtonClick}>
            Crear evento
          </button>
        </div>
      </div>
    </div>
  );
}

export default CtaHome;