import { useEffect, useState } from "react";
import useQuery from "../../../hooks/useQuery";
import { showErrorToastify } from "../../../utils";
import { getAllCompras } from "./api";
import queryString from "query-string";

const useCompras = () => {
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
    showSizeChanger: true
  });
  const [sorting, setSorting] = useState({
    field: "",
    order: ""
  });

  const { data, refetch, loading } = useQuery({
    autoFetch: false,
    queryFn: getAllCompras,
    onError: (err) => showErrorToastify({ err }),
  });

  const onSearch = (q) => {
    setPagination(prevPagination => ({ ...prevPagination, current: 0 }));
    setSearchValue(q);
  }

  const fetch = () => {
    const query = queryString.stringify({
      q: searchValue,
      limit: pagination.pageSize,
      page: pagination.current,
      sortField: sorting.field === "" && "id",
      sortOrder: sorting.order === "ascend" ? "ASC" : "DESC",
    });

    refetch(query);
  }

  useEffect(() => {
    fetch();
  }, [searchValue]);
  
  return {
    compras: data?.data?.compras,
    total: data?.data?.totalDataSize,
    loading,
    pagination,
    onSearch,
  }
}

export default useCompras;