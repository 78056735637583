import React, { useState } from "react";
import { CardBody, Container, Spinner, } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import '../publicaciones-home/ads.css';
import moment from 'moment-timezone';
import ShareButton from "../shareButton/shareButton";
import { useMediaQuery } from "react-responsive";
import LikeButton from "../Like/LikeButton";
import { Card, CardMedia, CardContent, MobileStepper, IconButton, Button } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';

moment.locale('es');
moment.locale('en-GB');

const translations = {
    Deportivos: {
        es: "Deportivos",
        en: "Sports",
    },
    Beneficios: {
        es: "Beneficios",
        en: "Profits",
    },
    Recepciones: {
        es: "Recepciones",
        en: "Social events",
    },
};

const translateCategory = (category, language) => {
    return translations[category] ? translations[category][language] : category;
};

const CardPublicacionSOLO = ({ publicacion, isLoading, usuario }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const history = useHistory();
    const { i18n, t } = useTranslation("global");
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
    const fechaPublicacion = new Date(publicacion?.desde);
    const fechaBajaPublicacion = new Date(publicacion?.hasta)
    const hoy = new Date();

    moment.locale(i18n.language === "es" ? "es" : "en-gb");

    const maxSteps = publicacion.attachs?.length || 0;

    const next = () => {
        const nextIndex = activeIndex === publicacion.attachs?.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        const nextIndex = activeIndex === 0 ? publicacion.attachs?.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const handleIconClick = (e) => {
        e.stopPropagation();
    };

    const recienteApertura = () => {
        const diferenciaDias = Math.floor((hoy - fechaPublicacion) / (1000 * 60 * 60 * 24));
        return diferenciaDias >= 0 && diferenciaDias <= 4;
    };
    const proximoAFinalizar = () => {
        const diferenciaDias = Math.floor((fechaBajaPublicacion - hoy) / (1000 * 60 * 60 * 24));
        return diferenciaDias >= 0 && diferenciaDias <= 4;
    };

    if (isLoading) {
        return (
            <Container
                fluid
                className={"my-4"}
                style={{ width: "350px", height: "auto" }}
            >
                <Card className="card-indiv border-0" style={{ height: "100%", boxShadow: "0px 5px 14px -6px black !important" }}>
                    <CardBody
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100%" }}
                    >
                        <Spinner color="primary" />
                    </CardBody>
                </Card>
            </Container>
        );
    }

/* console.log("publicacion", publicacion); */
    return (
        <div className="mt-4 contenedor-card" style={{ display: "flex", justifyContent: "center" }}>
            <Card className="card-publicacion-indiv" sx={{ boxShadow: 'none', borderRadius:"10px", padding:"5px"}}
                /* onClick={() =>
                    history.push(`/evento/${publicacion.idevento}/informacion`)
                } */>
                {publicacion.attachs && publicacion.attachs.length > 0 && (
                    <div style={{ position: 'relative', height: "100%", maxHeight:"300px"}}>
                        <CardMedia
                            sx={{ width: "370px" }}
                            className="media-publicacion-indiv"
                            component={publicacion.attachs[activeIndex].url.includes("video") ? "video" : "img"}
                            src={publicacion.attachs[activeIndex].url}
                            alt={publicacion.titulo}
                            controls={publicacion.attachs[activeIndex].url.includes("video")}
                        />
                        {maxSteps > 1 && (
                            <>
                                <IconButton
                                    style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', color: "#5656FF" }}
                                    onClick={(e) => {
                                        handleIconClick(e);
                                        previous();
                                    }}
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>
                                <IconButton
                                    style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', color: "#5656FF" }}
                                    onClick={(e) => {
                                        handleIconClick(e);
                                        next();
                                    }}
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                                <MobileStepper
                                    steps={maxSteps}
                                    position="static"
                                    activeStep={activeIndex}
                                    style={{
                                        background: 'transparent',
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '100%',
                                    }}
                                    nextButton={<div />}
                                    backButton={<div />}
                                />
                            </>
                        )}
                    </div>
                )}
                <CardContent className="contenido-publicacion-indiv">
                    <p className="title-publicacion-indiv">
                        {publicacion.titulo}
                    </p>
                    <div className="contenedor-botones"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: recienteApertura() || proximoAFinalizar() ? "space-between" : "flex-end"
                        }}
                    >
                        {proximoAFinalizar() ? (
                            <div className="contenedor-badge" style={{ background: "#ffc0bc", color: "#a91a11" }}>
                                <NotificationsActiveOutlinedIcon fontSize="small" />
                                <small>Pronto finalizará</small>
                            </div>
                        ) : recienteApertura() && (
                            <div className="contenedor-badge" style={{ background: "#dadaff", color: "#3d2eab" }}>
                                <NotificationsActiveOutlinedIcon fontSize="small" />
                                <small>Reciente apertura</small>
                            </div>
                        )}

                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <div className="fechaHora-publicacion">
                            <div className="fecha-publicacion d-flex align-items-center">
                                <CalendarMonthOutlinedIcon fontSize="small" sx={{ marginRight: "5px" }} />
                                <small>
                                    {i18n.language === 'es'
                                        ? moment(publicacion.evento?.fechas[0].fecha).clone().local().format("D [de] MMM")
                                        : moment(publicacion.evento?.fechas[0].fecha).clone().local().format("MMM[,] D")}
                                </small>
                            </div>
                            <div className="d-flex align-items-center">
                                <AccessAlarmsOutlinedIcon fontSize="small" sx={{ marginRight: "5px" }} />
                                <small>{moment(publicacion.evento?.fechas[0].hora_inicio).clone().local().format('LT')}</small>
                            </div>
                        </div>
                    </div>
                    <p className="mb-0">
                        Lugar: {publicacion.evento?.direccion}
                    </p>
                    <p className="descripcion">
                        {publicacion.descripcion.length > 80
                            ? `${publicacion.descripcion.substring(
                                0,
                                80
                            )}...`
                            : publicacion.descripcion
                        }
                    </p>

                    <div>
                        <div className="d-flex align-items-center justify-content-end gap-3" onClick={handleIconClick}>
                            <LikeButton isLoading={isLoading} usuario={usuario} publicacionId={publicacion.id}  style={{ color: "#5656FF" }}/>
                            <ShareButton idEvento={publicacion.idevento} style={{ color: "#5656FF" }} />
                            <Button sx={{
                                fontSize: '16px',
                                padding: '8px 15px',
                                backgroundColor: '#5656FF',
                                color: 'white',
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: '#3939AA',
                                },
                            }}
                                onClick={() =>
                                    history.push(`/evento/${publicacion.idevento}/informacion`)
                                }
                            > Ver más</Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default CardPublicacionSOLO;
