import React, { useState, useEffect, useRef } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { api } from "../services/api";
import { formatDistance } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import "./noti.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { useNotificacionesContext } from "../components/context/NotificationContext";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays as faSolidCalendarDays } from '@fortawesome/free-solid-svg-icons';

export const Notificaciones = () => {
  const { notificaciones, marcarComoLeida, eliminarNotificacion } = useNotificacionesContext();
  // const [notificaciones, setNotificaciones] = useState([]);
  const [t, i18n] = useTranslation("global");
  const [mostrar, setMostrar] = useState("todo");
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const [showDetails, setShowDetails] = useState(null);
  const detailsRef = useRef(null);
  const history = useHistory();

  const handleClickNotification = () => {
    history.push('/profile', { view: 'invitaciones' });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (detailsRef.current && !detailsRef.current.contains(event.target) && !event.target.closest('.bell-icon')) {
        setShowDetails(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filtrarNotificaciones = () => {
    if (mostrar === "todo") return notificaciones;
    if (mostrar === "noleidas") {
      return notificaciones.filter(notificacion => !notificacion.atendido);
    }
    return notificaciones;
  };

  /*
  const marcarComoLeida = async (id) => {
    const confirmAtender = async () => {
      const body = {
        id: parseInt(id),
        atendido: new Date().toISOString()
      };

      try {
        const response = await api.notificaciones.atenderSolicitud(id, body);

        if (response.status === "success") {
          setNotificaciones(prevNotificaciones =>
            prevNotificaciones.map(notificacion =>
              notificacion.id === id ? { ...notificacion, atendido: body.atendido } : notificacion
            )
          );
          setShowDetails(null);
        } else {
          setState({
            ...state,
            error: "Error al marcar la notificación como leída",
          });
        }
      } catch (error) {
        console.error("Error en la Red al marcar como leída:", error);
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };

    confirmAtender();
  };

  
  const eliminarNotificacion = async (id) => {
    const confirmEliminar = async () => {
      const body = {
        id: parseInt(id)
      };

      try {
        const response = await api.notificaciones.eliminarNotificacion(id);

        if (response.status === "success") {
          setNotificaciones(prevNotificaciones =>
            prevNotificaciones.filter(notificacion =>
              notificacion.id !== id
            )
          );
          setShowDetails(null);
        } else {
          setState({
            ...state,
            error: "Error al querer eliminar la notificación...",
          });
        }
      } catch (error) {
        console.error("Error en la Red al eliminar notificacion:", error);
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };

    confirmEliminar();
  }
*/

  const handleMarcarComoLeida = async (id) => {
    try {
      const body = {
        id: parseInt(id),
        atendido: new Date().toISOString()
      };
      const response = await api.notificaciones.atenderSolicitud(id, body);

      if (response.status === "success") {
        marcarComoLeida(id, body.atendido); // Usar la función del contexto
        setShowDetails(null);
      } else {
        setState({
          ...state,
          error: "Error al marcar la notificación como leída",
        });
      }
    } catch (error) {
      console.error("Error en la Red al marcar como leída:", error);
      setState({
        ...state,
        error: "Error en la Red.",
      });
    }
  };

  const handleEliminarNotificacion = async (id) => {
    try {
      const response = await api.notificaciones.eliminarNotificacion(id);
      if (response.status === "success") {
        eliminarNotificacion(id); // Usar la función del contexto
        setShowDetails(null);
      } else {
        setState({
          ...state,
          error: "Error al querer eliminar la notificación...",
        });
      }
    } catch (error) {
      console.error("Error en la Red al eliminar notificacion:", error);
      setState({
        ...state,
        error: "Error en la Red.",
      });
    }
  };

  const handleBellClick = (id) => {
    setShowDetails(prevId => (prevId === id ? null : id));
  };

  if (loading) {
    return <Loading />;
  }

  const notificacionesFiltradas = filtrarNotificaciones();
  const noNotificaciones = notificacionesFiltradas.length === 0;

  return (
    <div className="notifications-container">
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">{t("notifications.notifications")}</h2>
        </div>
        <div className="card-content">
          <div className="unique-scroll" />
          <div className="tabs" id="notifications-tabs">
            <button
              className={`tab ${mostrar === "todo" ? "active" : ""}`}
              onClick={() => setMostrar("todo")}
            >
              {t("notifications.all")}
            </button>
            <button
              className={`tab ${mostrar === "noleidas" ? "active" : ""}`}
              onClick={() => setMostrar("noleidas")}
            >
              {t("notifications.unread")}
            </button>
          </div>

          {filtrarNotificaciones().length === 0 ? (
            <div className="empty-notifications">
              <img src="https://img.freepik.com/vector-gratis/hombre-sysadmine-o-programador-informatico-que-trabaja-computadora_575670-70.jpg?t=st=1725402209~exp=1725405809~hmac=894d9edd95a2c53294e571737506f6ba9ffa795230cee4b5610eef4f505c35a5&w=740" alt="no notifications" className="empty-image"></img>
              <h3 className="empty-message">
                {mostrar === "noleidas" ? t("notifications.unreadNotifications") : t("notifications.noNotifications")}
              </h3>
            </div>
          ) : (
            notificaciones
              .filter(notificacion => mostrar === "noleidas" ? !notificacion.atendido : true)
              .sort((a, b) => new Date(b.createon) - new Date(a.createon)) // Ordenar por fecha
              .map((notificacion) => (
                <div key={notificacion.id} className="notification-card">
                  <div className="notification-content">
                    <h3 className="notification-title" style={{ cursor: "pointer" }} onClick={handleClickNotification} >{notificacion.titulo}
                    </h3>
                    <p className="notification-description" style={{ cursor: "pointer" }} onClick={handleClickNotification} >{notificacion.descripcion}</p>
                    <div className="notification-date">
                      <FontAwesomeIcon icon={faSolidCalendarDays} style={{ color: "#456ee8" }} />
                      <span style={{ fontSize: '14px', margin: "5px" }}>
                        {t("notifications.xDays", {
                          days: formatDistance(new Date(notificacion.createon), new Date(), {
                            locale: i18n.language === 'es' ? es : enUS
                          })
                        })}
                      </span>
                    </div>
                  </div>

                  <div className="notification-actions">
                    <button
                      className={`btn-icon bell-icon mb-4 ${notificacion.atendido ? "read" : ""}`}
                      onClick={() => handleMarcarComoLeida(notificacion.id)}
                    >
                      {notificacion.atendido ? (
                        <NotificationsOffIcon /> 
                        ) : (
                        <NotificationsActiveIcon />
                      )}
                    </button>
                    <button
                      className={`btn-icon trash-icon ${notificacion.baja ? "red" : ""}`}
                      onClick={() => handleEliminarNotificacion(notificacion.id)}
                    >
                      <DeleteForeverIcon />
                    </button>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Notificaciones, {
  onRedirecting: () => <Loading />,
});