import { Card, CardContent, CircularProgress } from "@mui/material";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useCompra from './useCompra';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../../Loading';
import moment from "moment";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';

const Compra = () => {
  const { id } = useParams();
  const { compra, loading } = useCompra(id);
  const items = [
    ...(compra?.tickets || []),
    ...(compra?.vouchers || [])
  ];

  return (
    <div className="container-fluid">
      <h3 className="my-3 text-center">
        {compra?.carrito?.evento?.evento}
      </h3>
      <div className="d-flex justify-content-center align-items-center flex-wrap gap-4">
        {loading ? (
          <CircularProgress />
        ) : (
          items?.map((item) => (
            <Card key={item.id} sx={{ maxWidth: 200, backgroundColor: "#5656FF50" }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <QRCode value={item.codigo} className="w-100 h-75" />
                <p className="m-0 fw-bold text-center" style={{ color: '#5656FF' }} >
                  {item?.tarifa?.concepto}
                </p>
                <div>
                  <small>Válido para:</small>
                  {item.tarifa?.fechas?.map((f, index) => (
                    <div className="d-flex justify-content-between">
                      <small>
                        Día {index+1}:
                      </small>
                      <small>
                        {moment(f.fecha.hora_inicio).clone().local().format("DD/MM/YY HH:mm")}
                      </small>
                    </div>
                  ))}
                </div>
                <div className="d-flex justify-content-center gap-1 fw-bold" style={{ color: '#5656FF' }}>
                  {item?.tarifa?.idtipo === 1
                    ? <ConfirmationNumberOutlinedIcon />
                    : <FastfoodOutlinedIcon />
                  }
                  x{item.cantidad}
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Compra, {
  onRedirecting: () => <Loading />,
});