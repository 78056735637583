import { useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation, Switch, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import { toast, Bounce } from 'react-toastify';
import { api } from '../services/api';
import CrearEvento from '../views/eventos/evento/CrearEvento';
import { SegundoPaso } from '../views/eventos/evento/SegundoPaso';
import { AgregarColaboradores } from '../views/eventos/evento/AgregarColaboradores';
import { PublicarEvento } from '../views/eventos/evento/PublicarEvento';

const steps = [
    'Información',
    'Tarifas',
    'Colaboradores',
    'Publicar'
];

export const StepperCrearEvento = ({ eventoIdInicial }) => {
    const history = useHistory();
    const location = useLocation();
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
    const [eventId, setEventId] = useState(eventoIdInicial || null);
    const [eventoCreado, setEventoCreado] = useState(null);
    const [isPublic, setIsPublic] = useState(false); 
    const [stepsToShow, setStepsToShow] = useState(steps.slice(0, 3)); 
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        if (activeStep === stepsToShow.length - 1) {
            api.eventos.confirmEvento(eventId)
                .then(response => {
                    if (response.status === 'success') {
                        toast.success('Tu evento se ha creado exitosamente', {
                            position: 'top-center',
                            autoClose: 3000,
                            theme: 'dark',
                            transition: Bounce,
                        });
                        setTimeout(() => {
                            history.push(`/eventos/${eventId}`);
                        }, 2000);
                    }
                })
                .catch(error => console.error("Error confirmando el evento:", error));
        } else {
            setActiveStep(prevActiveStep => {
                const nextStep = prevActiveStep + 1;
                switch (nextStep) {
                    case 0:
                        history.push('/crearEvento/creacion');
                        break;
                    case 1:
                        history.push('/crearEvento/segundoPaso');
                        break;
                    case 2:
                        history.push('/crearEvento/agregarColaboradores');
                        break;
                    case 3:
                        history.push('/crearEvento/publicarEvento');
                        break;
                    default:
                        break;
                }
                return nextStep;
            });
        }
    };

    // useEffect(() => {
    //     if (!eventId) {
    //       const storedEventId = localStorage.getItem('eventId');
    //       if (storedEventId) {
    //         setEventId(storedEventId); 
    //       }
    //     }
    //   }, [eventId]);


    // useEffect(() => {
    //     const { state } = location;
    //     if (state && state.eventId) {
    //         setEventId(state.eventId);
    //         localStorage.setItem('eventId', state.eventId); 
    //     } else {
    //         const storedEventId = localStorage.getItem('eventId'); 
    //         if (storedEventId) {
    //             setEventId(storedEventId);
    //         }
    //     }
    // }, [location]);

    const handlePublicoChange = (isPublico) => {
        setIsPublic(isPublico);
    };

    useEffect(() => {
        if (isPublic) {
            setStepsToShow(steps); 
        } else {
            setStepsToShow(steps.slice(0, 3)); 
        }
    }, [isPublic]);

    useEffect(() => {
        const callApi = async () => {
            try {
                const response = await api.eventos.get(eventId);
                if (response.status === 'success') {
                    setIsPublic(!response.data.evento.privado);
                }
            } catch (error) {
                toast.error("Error en la red.", { position: "top-center", autoClose: 4000 });
            }
        };

        if (eventId) {
            callApi();
        }
    }, [eventId]);

    return (
        <>
            {isMobile ? (
                <Container className="my-3" maxWidth="lg">
                    <div>
                        <h3
                            style={{color: "#001D67"}}
                        >
                            Crear evento &gt; <span style={{fontWeight: 700}}>{steps[activeStep]}</span></h3>
                    </div>
                    <div style={{ textAlign: "center"}}>
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {stepsToShow.map((label, index) => (
                                    <Step key={label}>
                                    <StepLabel disabled={index < activeStep}
                                    StepIconProps={{
                                        sx: {
                                            '&.Mui-active': {
                                                color: '#6161FF', // Color cuando está activo
                                            },
                                            '&.Mui-completed': {
                                                color: '#6161FF', // Color cuando está completo
                                            },
                                        },
                                    }}></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </div>
                </Container>
            ) : (
                <Container className="my-3" maxWidth="lg">
                    <div>
                        <h1 style={{ color: "#001D67" }}>Crear evento</h1>
                    </div>
                    <div>
                        <Box sx={{ width: '800px', margin: '20px auto' }}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {stepsToShow.map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel
                                            optional={index === 1 || index === 2 || index === 3 ? <Typography variant="caption">Opcional</Typography> : null}
                                            disabled={index < activeStep}
                                        >
                                            {label}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </div>
                </Container>
            )}

            <Switch>
                <Route exact path="/crearEvento/creacion">
                    <CrearEvento setEventId={setEventId} setEventoCreado={setEventoCreado} onPublicoChange={handlePublicoChange} />
                </Route>
                <Route exact path="/crearEvento/segundoPaso">
                    <SegundoPaso eventId={eventId} />
                </Route>
                <Route exact path="/crearEvento/agregarColaboradores">
                    <AgregarColaboradores eventId={eventId} />
                </Route>
                <Route exact path="/crearEvento/publicarEvento">
                    <PublicarEvento eventId={eventId} />
                </Route>
            </Switch>

            <Container className="my-3" maxWidth="lg">
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginRight: "20px", marginBottom: "20px" }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant="contained" onClick={handleNext} disabled={activeStep === 0 && !eventoCreado}>
                        {activeStep === stepsToShow.length - 1 ? 'Finalizar' : 'Siguiente'}
                    </Button>
                </Box>
            </Container>
        </>
    );
}