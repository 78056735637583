import { withAuthenticationRequired } from "@auth0/auth0-react";
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import QrReader from 'react-weblineindia-qrcode-scanner';
import { Alert, Button, Card, CardBody, CardImg, CardSubtitle, CardTitle, Container, ListGroup, ListGroupItem } from "reactstrap";
import Loading from "../../components/Loading";
import { api } from "../../services/api";
import "./Styles.css";
import { useMediaQuery } from "react-responsive";
import Typography from '@mui/material/Typography';

export const QrTickets = () => {
  const beepSoundOkRef = useRef(null);
  const beepSoundErrRef = useRef(null);
  const [ticket, setTicket] = useState();
  const [success, setSuccess] = useState();
  const [accesos, setAccesos] = useState();
  const [scan, setScan] = useState(true);
  const [alerta, setAlerta] = useState();
  const [qr, setQr] = useState("CODIGO");
  const param = useParams();
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [scannedCode, setScannedCode] = useState('');

  const location = useLocation()
  useEffect(() => {
    if (document.baseURI.includes("camara")) {
      setShowScanner(false)
      setShowCamera(true)
      setScan(true)
    } else {
      setShowScanner(true)
      setShowCamera(false)
      setScan(true)
    }
  }, [location]);

  useEffect(() => {
    beepSoundOkRef.current = new Audio('/ok.mp3');
    beepSoundOkRef.current.preload = 'auto';
    beepSoundErrRef.current = new Audio('/error.wav');
    beepSoundErrRef.current.preload = 'auto';
  }, []);


  const setEmptyTicketValue = () => {
    setTicket(null)
    setScan(true)
    setScannedCode('');
  }

  const callQr = async (data) => {
    setQr(data)
    try {
      const response = await api.tickets.setAccesoTicket(param.id, data);

      if (response.status === "success") {
   
        await beepSoundOkRef.current.play();
        setSuccess(true)
        setTicket(response.data.ticket);
        setAccesos(response.data.accesos)
      } else {
        if (response.data) {
          await beepSoundErrRef.current.play();
          setSuccess(false)
          setTicket(response.data.ticket);
          setAlerta(response.message);


          setAccesos((response.data.accesos ? response.data.accesos : response.data.accesos))
          if (response.data.accesos[0].evento !== "Salida Definitiva") {
            //Comportamiento cuando el usuario salio definitivamente
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sizeQr = {
    width: isMobile ? "90%" : "40%"
  };

  const previewStyle = {
    width: "100%",
    height: "40vh",
  }
  
  const handleScan = (data) => {
    if (data) {
      setScan(false)
      callQr(data)
    }
  }

  const handleError = (err) => {
    console.log(err)
  }

  const Salida = async (tipoSalida) => {
    await api.tickets.setSalidaTicket(param.id, qr, tipoSalida);
    setAlerta("Se registro la Salida " + tipoSalida);
  }

  const [showScanner, setShowScanner] = useState(true)
  const [showCamera, setShowCamera] = useState(false)

  const handleScannerRead = (e) => {
    callQr(scannedCode)
  }

  const handleSelectOption = (e) => {
    const option = e.currentTarget.id
    if (option === "camera") {
      setShowScanner(false)
      setShowCamera(true)
    }
    if (option === "scanner") {
      setShowScanner(true)
      setShowCamera(false)
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key;

      if (key === 'Enter') {
        if (scannedCode?.length > 12) {
          return
        }
        handleScannerRead(scannedCode);
      } else {
        // Agregar teclas a la cadena actual
        if (key === "Shift") return

        setScannedCode((prevCode) => prevCode + key);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Limpiar el listener al desmontar el componente
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };

  }, [scannedCode])


  return (
    <Container fluid="sm" className="d-flex flex-column justify-content-center">
      <div className="d-flex flex-column align-items-center gap-2">
        {showScanner &&
          <div className="w-100 d-flex flex-column justify-content-center align-items-center gap-3">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center gap-2">
              {!ticket &&
                < CardImg
                  alt="Empty"
                  src="/qrgris.png"
                  top
                  style={sizeQr}
                />
              }
              {success && ticket &&
                <CardImg
                  alt="OK"
                  src="/qrverde.png"
                  top
                  style={sizeQr}
                />
              }
              {!success && ticket &&
                <CardImg
                  alt="Error"
                  src="/qrrojo.png"
                  top
                  style={sizeQr}
                />
              }
            </div>
            <div className="w-100 ">
              {ticket &&
                <CardBody>
                  <Typography align="center" variant="h6">Ticket: {ticket.id}</Typography>
                  <CardTitle className="text-center fs-1">
                    {ticket.apellido} {ticket.nombre}
                  </CardTitle>
                </CardBody>
              }
            </div>
          </div>
        }
        {showCamera && scan &&
          <div style={{ maxWidth: '400px' }}>
            <QrReader
              delay={1000}
              legacyMode={false}
              facingMode={"rear"}
              style={previewStyle}
              onError={(error) => { handleError(error) }}
              onScan={(data) => { handleScan(data) }}
            />
          </div>
        }
        <div className="w-100 d-flex flex-column justify-content-center align-items-center gap-3">
          {showCamera && ticket && !scan &&
            <>
              <div className="w-100 d-flex justify-content-center align-items-center gap-2">
                {success &&
                  <CardImg
                    alt="OK"
                    src="/qrverde.png"
                    top
                    style={sizeQr}
                  />
                }
                {!success &&
                  <CardImg
                    alt="Error"
                    src="/qrrojo.png"
                    top
                    style={sizeQr}
                  />
                }
              </div>
              {alerta && 
                <Alert color="danger" >{alerta}</Alert>
              }
              <div className="w-100">
                <CardBody>
                  <CardTitle className="text-center fs-1">
                    {ticket.apellido} {ticket.nombre}
                  </CardTitle>
                    <CardSubtitle
                      className="mb-2 text-center"
                      tag="h6"
                    >
                      {ticket.mail}
                    </CardSubtitle>
                    <Button
                      block
                      color="primary"
                      onClick={setEmptyTicketValue}
                    >
                      Aceptar
                    </Button>
                </CardBody>
              </div>
            </>
          }
        </div>

      </div>
    </Container>
  )

}
export default withAuthenticationRequired(QrTickets, {
  onRedirecting: () => <Loading />,
});

