import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { api } from '../../services/api';
import Loading from '../Loading';
import { useHistory, useLocation } from 'react-router-dom';

const Authorized = () => {
  const { user, isAuthenticated } = useAuth0();
   const [loading, setLoading] = useState(true)
    const history = useHistory();
    const location = useLocation();

  useEffect(() => {
    const authorizedUserAndCreation = async () => {
      if (isAuthenticated && user) {
        const data = {
          usuario: user.email,
          auth0id: user.sub,
          nombre: user.given_name || '',
          apellido: user.family_name || '',
          telefono: user.phone_number || ''
        };

        try {
          const userResponse = await api.usuarios.getUserExist(user.email);
          if (userResponse.status === "error") {
            await api.usuarios.createUsuario(data);
          }
        } catch (error) {
          console.error("Error: ", error);
        } finally {
          setLoading(false);
          const returnTo = location.state?.returnTo || '/';
          history.replace(returnTo);
        }
      }
    };

    authorizedUserAndCreation();
  }, [isAuthenticated, user, history, location]);

  if (loading) {
    return <Loading />
  };

  return null;
};

export default Authorized;