import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from '../../../services/api';
import { Table } from 'reactstrap';
import Loading from '../../../components/Loading';
import VolverButton from '../../../components/VolverButton';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { Card, CardContent } from '@mui/material';
import QRCode from 'react-qr-code';
import useCompra from '../../../components/perfil/Compras/VerCompra/useCompra';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import moment from 'moment';

const ReciboVenta = () => {
  const history = useHistory();
  const [recibo, setRecibo] = useState(null);
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(5);
  const { compra } = useCompra(param?.id);

  const total = recibo?.compra?.carrito?.items?.reduce((acc, item) => acc + item.tarifa.precio * item.cantidad, 0);

  const HandleDownload = async (idCompra) => {
    try {
      const response = await api.ventas.getReciboPDF(param.id, idCompra)
      
      if (!response) {
        throw new Error('Error al descargar el PDF');
      }
      const blob = response;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `recibo_${idCompra}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      toast.success(response.message, {
        position: "top-right",
        autoClose: 4000,
      });

    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  }

  useEffect(() => {
    const callApi = async (id) => {
      setLoading(true);
      
      try {
        const response = await api.ventas.getRecibo(id);

        if (response.status === "success") {
          setRecibo(response.data.recibo);
        }
        else {
          const timer = setInterval(() => {
            setSecondsLeft((prev) => prev - 1); // Restar 1 cada segundo.
          }, 1000);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 4000,
        });
      } finally {
        setLoading(false);
      }
    }

    callApi(param.id);
  }, [param.id]);

  useEffect(() => {
    if (secondsLeft === 0) {
      history.goBack(); // Redirige cuando el contador llegue a 0.
    }
  }, [secondsLeft]);

  if (loading) {
    return <Loading />
  }

  const items = [...(compra?.tickets || []), ...(compra?.vouchers || [])];

  return (
    <div className="container-fluid container-sm mx-auto my-5">
      <div className="d-flex justify-content-center gap-4 flex-wrap mb-5">
        {items?.map((item) => (
          <Card key={item.id} sx={{ maxWidth: 200, backgroundColor: "#5656FF50" }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <QRCode value={item.codigo} className="w-100 h-75" />
              <p className="m-0 fw-bold text-center" style={{ color: '#5656FF' }} >
                {item?.tarifa?.concepto}
              </p>
              <div>
                <small>Válido para:</small>
                {item.tarifa?.fechas?.map((f, index) => (
                  <div className="d-flex justify-content-between">
                    <small>
                      Día {index+1}:
                    </small>
                    <small>
                      {moment(f.fecha.hora_inicio).clone().local().format("DD/MM/YY HH:mm")}
                    </small>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center gap-1 fw-bold" style={{ color: '#5656FF' }}>
                {item?.tarifa?.idtipo === 1
                  ? <ConfirmationNumberOutlinedIcon />
                  : <FastfoodOutlinedIcon />
                }
                x{item.cantidad}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      {recibo ? (
        <div>
          <div className="py-4 d-flex flex-wrap row-gap-4 align-items-center text-center bg-secondary text-white rounded-top">
            <p className="col-12 col-md-6 px-2 mb-0">
              Pago realizado por<br />
              <span className="fw-bold">{recibo.email}</span>
            </p>
            <p className="col-12 col-md-6 px-2 mb-0">
              Método de pago: <span className="fw-bold">{recibo.recibo_mp.mp.medio}</span>
            </p>
          </div>
          
          <div className="py-4 d-flex align-items-center text-center bg-secondary-subtle ">
            <p className="col-4 mb-0">
              Recibo N°<br />
              #{recibo.id}
            </p>
            <p className="col-4 mb-0">
              Fecha<br />
              {new Date(recibo.fecha_aprobado).toLocaleString('es-AR')}
            </p>
            <p className="col-4 mb-0">
              Total abonado<br />
              {recibo.recibo_mp.importe} {recibo.divisa}
            </p>
          </div>
          
          <Table striped borderless responsive >
            <thead>
              <tr className="text-center">
                <th className="col-2">Tipo</th>
                <th className="col-4 text-start">Descripción</th>
                <th className="col-2">Precio</th>
                <th className="col-2">Cantidad</th>
                <th className="col-2">Total</th>
              </tr>
            </thead>
            <tbody>
              {recibo.compra.carrito?.items ?
                recibo.compra.carrito.items.map((item, i) => (
                  <tr key={i}>
                    <td align="center" className="py-3">{item.tarifa.tipo.tipo}</td>
                    <td>{item.tarifa.concepto}</td>
                    <td align="center">$ {item.tarifa.precio}</td>
                    <td align="center">{item.cantidad}</td>
                    <td align="center">$ {(item.tarifa.precio * item.cantidad).toFixed(2)}</td>
                  </tr>
                )
                ) : (
                  <tr>
                    <th colSpan={5}>Nada para mostrar.</th>
                  </tr>
                )}
              <tr className="text-center">
                <td className="py-2" colSpan={3}><hr /></td>
                <td className="border border-3 border-secondary-subtle border-end-0 fw-bold">Total</td>
                <td className="border border-3 border-secondary-subtle border-start-0 fw-bold">$ {total?.toFixed(2)}.-</td>
              </tr>
            </tbody>
          </Table>
          
          <div className="d-flex justify-content-between">
            <VolverButton />
            <button
              onClick={() => { HandleDownload(recibo.idcompra) }}
              className="btn btn-primary"
              style={{ margin: '5px' }}
            >
              <FontAwesomeIcon icon={faDownload} size="lg" /> Descargar
            </button>
          </div>
        </div>
      ) : (
        <p>No se encontro recibo vinculado a la compra</p>
      )}
    </div>
  );
}

export default withAuthenticationRequired(ReciboVenta, {
  onRedirecting: () => <Loading />,
});