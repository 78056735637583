import React, { useState } from "react";
import { api } from "../../services/api";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Button, Box, Modal, Alert, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { grey } from '@mui/material/colors';
import moment from "moment-timezone";

moment.locale("es");
moment.locale("en-GB");

const DeleteLocationModal = ({ id }) => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage , setErrorMessage] = useState("");
  const [t, i18n] = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 490px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 700px)` });

  moment.locale(i18n.language === "es" ? "es" : "en-gb");

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width:isMobile ? 320 : isTablet ? 400 : 600,
    p: 3,
  }; 

  const DeleteLocation = (id) => {
    const response = api.locations.deleteLocation(id);
    return response
    .then(response => {
      if (response.status === "error"){
        if (response.message.includes("Error 1451")){
          setError(true)
          setErrorMessage(<>{t("categorie.deleteError")}</>)
        }else{
          setError(true)
          setErrorMessage(response.message)
        }
      }
      if (response.status === "success") {
        setSubmitted(true)
        setTimeout(() => {
          handleClose();
        }, 2000);
      }
    })    
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false); 
  } 

  return (
    <>
      <IconButton sx={{ color: "red" }} onClick={handleOpen}>
          <DeleteForeverIcon sx={{ fontSize: "27px" }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h4 id="modal-modal-title">
            {t("location.deleteLocation")}
          </h4>
          <div id="modal-modal-description" sx={{ mt: 2 }}>
            <p>{t("location.question")}</p>
          </div>

          {error && 
            <Alert severity="error" style={{marginTop: "10px"}}>{errorMessage}</Alert>
          }
          {submitted && 
            <Alert severity="success" style={{marginTop: "10px"}}>{t("location.successAlert")}</Alert>
          }

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "15px" }}>
            <Button size="small" variant="contained" sx={{background: grey[500]}} style={{marginRight: "10px"}} onClick={handleClose}>
              {t("button.cancel")}
            </Button>
            <Button variant="contained" color="error" size="small" onClick={() => DeleteLocation(id)}>
              {t("button.delete")}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default DeleteLocationModal;