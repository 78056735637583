import { useContext, useEffect, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { ImpresionContext } from '../providers/Impresion';
import HelpIcon from '@mui/icons-material/Help';
import ImageField from './Impresion/ImageField';
import QRField from './Impresion/QRField';
import TextField from './Impresion/TextField';
import IconButton from '@mui/material/IconButton';
import "./Impresion/fuentes.css";
import { api } from '../services/api';
import { useParams } from 'react-router-dom';


const Canva = ({ width, height, getDiseño, idTarifa }) => {
  const {
    // setEditSucces,
    // setIndex,
    // isCreated,
    textArea,
    setTextArea,
    setOpenFont,
    setOpenImage,
    setOpenQr,
    setOpenTemplates,
    setOpenFontToolBar,
    elements,
    setElements,
    selectedId,
    setSelectedId,
    dimensionContainer,
    // setDimensionContainer,
    // setScale,
    // history,
    setHistory,
    // indexHistory,
    // setIndexHistory,
    uploadHistory,
    // ableUndo,
    // setAbleUndo,
    // ableRedo,
    // setAbleRedo,
    // typeTicket,
    // setTypeTicket,
    driverObj,
    isMoving,

  } = useContext(ImpresionContext);

  const param = useParams()

  const [margenes, setMargenes] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  const stage = useRef(null);
  const textAreaRef = useRef(null)
  const layerRef = useRef(null);

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
      setOpenFontToolBar(false);
      setOpenFont(false);
      setOpenImage(false)
      setOpenQr(false)
      setOpenTemplates(false)
      setTextArea(false)
    }
  };

  const HandleDeleteElement = (e) => {
    if (selectedId) {
      const newElements = elements.filter((element) => element.id !== selectedId);
      setElements(newElements);
      setSelectedId(null);
      setTextArea(false);
      setOpenFontToolBar(false);

    }
  }

  const HandleZIndex = (num) => {
    const index = elements?.findIndex((element) => element.id === selectedId)
    const newElements = elements.slice();
    const element = newElements[index];
    newElements.splice(index, 1);
    newElements.splice(index + num, 0, element);
    setElements(newElements);
  }

  useEffect(() => {
    const newData = elements?.map((element) => {
      return {
        ...element,
        x: element.x,
        y: element.y,
        width: element.width,
        height: element.height,
        fontSize: element.fontSize
      }
    })
    setElements(newData)
    setHistory([newData])

    //seteamos margenes solo para el diseño de pulseras
    if (dimensionContainer.type === "pulsera") {
      setMargenes({ ...margenes, left: 30 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPressDownKey = () => {
    setSelectedId(null);
  };

  useEffect(() => {
    const handlePressKey = (event) => {
      if (event.key === 'Escape') {
        onPressDownKey()
      }
    };

    window.addEventListener('keydown', handlePressKey);

    return () => {
      window.removeEventListener('keydown', handlePressKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [preguntasAdicionales, setPreguntasAdicionales] = useState([]);	
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.infoAdicional.getPregunta(param.id, idTarifa); 
        setPreguntasAdicionales(data.data);
      } catch (error) {
        console.error("Error al obtener la pregunta:", error);
      }
    };
    fetchData();
  }, [param.id, idTarifa]);
  // console.log(preguntasAdicionales)

  return (
    <>
      <div style={{ paddingLeft: '50px', paddingBottom: '80px' }} className='h-100 d-flex flex-column justify-content-center align-items-center'>
        <div className='position-relative d-flex justify-content-end'>
          {dimensionContainer.type === "pulsera" &&
            <div style={{
              position: "absolute",
              width: width + "px",
              height: height + "px",
              zIndex: 8,
            }}>
              <img
                src="/template25.png"
                alt="template pulsera"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          }
          {dimensionContainer.type !== "pulsera" &&
            <div style={{
              position: "absolute",
              backgroundColor: "white",
              width: width + "px",
              height: height + "px",
              zIndex: 8,
            }}>
            </div>
          }
          <Stage
            width={width}
            height={height}
            ref={stage}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            style={{
              zIndex: 9,
            }}
          >
            <Layer ref={layerRef}>
              {elements?.map((element) => {
                if (element.type === 'image') {
                  return (
                    <ImageField
                      key={element.id}
                      imgProp={element}
                      isSelected={element.id === selectedId}

                      margenes={margenes}

                      onSelect={() => {
                        setSelectedId(element.id);
                        setOpenFont(false);
                      }}

                      onChange={(newAttrs) => {
                        const newElements = elements.slice();
                        const index = elements?.findIndex((i) => i.id === element.id)

                        newElements[index] = newAttrs;
                        setElements(newElements);
                        uploadHistory(newElements)
                      }}
                    />
                  )
                }
                if (element.type === 'qr') {
                  return (
                    <QRField
                      key={element.id}
                      qrProp={element}
                      isSelected={element.id === selectedId}

                      margenes={margenes}

                      onSelect={() => {
                        setSelectedId(element.id);
                        setOpenFont(false);
                      }}

                      onChange={(newAttrs) => {
                        const newQrField = elements.slice();
                        const index = elements?.findIndex((q) => q.id === element.id)
                        newQrField[index] = newAttrs;
                        setElements(newQrField);
                        uploadHistory(newQrField)
                      }}
                    />
                  )
                }
                if (
                  element.type === 'text' |
                  element.type === 'text-evento' |
                  element.type === 'text-codigo' |
                  element.type === 'text-nombre' |
                  element.type === 'text-tarifa' |
                  preguntasAdicionales?.informaciones_adicionales?.map((pregunta)=> {
                    return element.type === `text-${pregunta.id}`
                  }).includes(true)
                  // element.type === `text-${preguntasAdicionales?.informaciones_adicionales?.map((pregunta) => pregunta.id)}`
                ) {
                  return (
                    <TextField
                      key={element.id}
                      textProp={element}
                      isSelected={element.id === selectedId}

                      margenes={margenes}

                      onSelect={() => {
                        setSelectedId(element.id);
                        setOpenFont(true);
                        setOpenImage(false);
                        setOpenQr(false);
                        setOpenTemplates(false);
                      }}
                      onChange={(newAttrs) => {
                        const newTextField = elements.slice();
                        const index = elements?.findIndex((t) => t.id === element.id)
                        newTextField[index] = newAttrs;
                        setElements(newTextField)
                        uploadHistory(newTextField)
                      }}
                    />
                  )
                }
                return null
              })
              }
            </Layer>
          </Stage>

          {textArea && (
            elements?.map((text) => {
              let condition = text.type === "text" ||
                text.type === "text-evento" ||
                text.type === "text-codigo" ||
                text.type === "text-nombre"

              if (condition && text.id === selectedId) {
                return (
                  < textarea
                    key={text.id}
                    id={text.id}
                    ref={textAreaRef}
                    value={text.text}
                    style={{
                      position: 'absolute',
                      zIndex: 10,
                      top: `${text.y}px`,
                      left: `${text.x}px`,
                      width: `${text.width}px`,
                      height: `${text.height}px`,
                      border: 'none',
                      padding: '0px',
                      margin: '0px',
                      fontSize: `${text.fontSize}px`,
                      outline: 'true',
                      background: 'none',
                      transform: ` rotate(${text.rotation}deg)`,
                      fontFamily: `${text.fontFamily}`,
                      color: `${text.fill}`,
                      lineHeight: 1,
                      fontWeight: `${text.fontStyle === ('bold' | 'bold italic') ? 'bold' : 'normal'} `,
                      fontStyle: `${text.fontStyle === ('italic' | 'bold italic') ? 'italic' : 'normal'} `,
                      textDecoration: `${text.textDecoration}`,
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        setTextArea(false);
                      }
                    }}

                    onChange={(e) => {
                      const newTextField = elements.slice()
                      const index = elements?.findIndex((t) => t.id === e.target.id)
                      newTextField[index] = { ...newTextField[index], text: e.target.value }
                      setElements(newTextField)
                      uploadHistory(newTextField)
                    }}
                  />
                )
              }
              return null
            })
          )}

          {selectedId && elements && !isMoving &&
            (
              elements?.map((element) => {
                if (element.id === selectedId) {
                  return (
                    <div
                      className='position-absolute p-1 w-auto h-auto rounded-3 border d-flex bg-light align-items-center gap-2'
                      style={{
                        top: `${element.height + element.y + 12}px`,
                        left: `${element.width / 2 + element.x - 64}px`,
                        zIndex: 10,
                      }}
                    >
                      <button className='btn btn-danger p-0' onClick={HandleDeleteElement}>
                        <div className='d-flex align-items-center p-1'>
                          <i className='material-symbols-outlined'>delete</i>
                        </div>
                      </button>
                      <button className='btn btn-outline-secondary p-0' onClick={() => HandleZIndex(1)}>
                        <div className='d-flex align-items-center p-1'>
                          <i className="material-symbols-outlined">stat_2</i>
                        </div>
                      </button>
                      <button className='btn btn-outline-secondary p-0' onClick={() => HandleZIndex(-1)}>
                        <div className='d-flex align-items-center p-1'>
                          <i className="material-symbols-outlined">stat_minus_2</i>
                        </div>
                      </button>
                    </div>
                  )
                }
                return null
              }
              )
            )
          }
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 10 + 'px',
          right: 10 + 'px',
          zIndex: 10,
        }}>
        <IconButton
          sx={{ padding: 0 }}
          color="warning"
          onClick={() => { driverObj.drive() }}>
          <HelpIcon fontSize="large" />
        </IconButton>
      </div>
    </>
  );
};

export default Canva;