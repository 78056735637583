import React, { useState, useEffect, useRef } from "react";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import { 
  Container, 
  Button,
  TextField, 
  FormControl,
  Alert, 
  CircularProgress, 
  TableContainer, 
  Paper,
  Table,
  TableHead, 
  TableRow, 
  TableCell,
  TableBody
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import DeleteCollaboratorModal from "../../components/modales/DeleteCollabModal";
import VolverButton from "../../components/VolverButton";
import { useTranslation } from "react-i18next";

export const Colaboradores = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [evento, setEvento] = useState();
  const [eventocolaboradores, setEventoColaboradores] = useState();
  const history = useHistory();
  const param = useParams();
  const form = useRef();
  const [t] = useTranslation("global");
  const [userRole, setUserRole] = useState(null);
  const [subrol, setSubrol] = useState()

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.eventos.get(param.id);
        if (response.status === "success") {
          setEvento(response.data.evento);
          setEventoColaboradores(response.data.colaboradores);
        }
      } catch (error) {
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };
    callApi();
  }, []);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await api.participaciones.getParticipaciones();

        if (response.status === "success") {
          const rol = response.data.participaciones.find(
            (p) => p.idevento == param.id
          )?.idrol;
          setUserRole(rol);
        } else {
          setState({ ...state, error: "Error al obtener el rol." });
        }
      } catch (error) {
        setState({ ...state, error: "Error en la red." });
      }
    };
    fetchRole([]);
  }, [param.id]);

  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (email === "") {
      setError("El correo electrónico es obligatorio");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Introduce un correo electrónico válido");
      return;
    }

    const data = { email, subrol };
    const response = api.colaboradores.createColaborador(param.id, data);
    return response.then((response) => {
      if (response.status === "error") {
        setError("Error: " + response.message);
        setSubmitting(false);
      }
      if (response.status === "success") {
        setSubmitting(false);
        setSubmitted(true);
        setError("");
        const data = {
          recipient: email,
          evento: evento.evento,
        };
        const response = api.email.send(data);
        setEmail("");
        setTimeout(function () {
          history.go(0);
        }, 1500);
        if (response.status === "error") {
          setError("Error: " + response.message);
        }
      }
    });
  };

  const roles = [
    {
      value: 0, 
      label: "Colaborador General", 
      description: "Rol encargado de diversas tareas como controlar el acceso y los vouchers."
    },
    {
      value: 2, 
      label: "Control de Acceso", 
      description: "Rol encargado de controlar el acceso de las personas autorizadas al evento."
    },
    {
      value: 3, 
      label: "Control de Voucher", 
      description: "Rol encargado de controlar y gestionar el canje de los vouchers del evento."
    },
    {
      value: 7, 
      label: "Administrador de datos", 
      description: "Rol encargado de la carga y diseño de tickets."
    }
  ];

  return (
    <>
      {evento && (
        <Container>
          {userRole === 1 && (
            <>
              <form onSubmit={handleSubmit}>
                <TextField 
                  type="email"
                  name="email"
                  id="email"
                  label={t("collaborator.email")}
                  variant="outlined" 
                  fullWidth 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormControl
                  // error={errorRol}
                >
                  <p className='mb-1 mt-3'>Seleccione un rol para su colaborador</p>
                  <div className="row">
                    {roles.map((role) => (
                      <div key={role.value} className="col-sm-6 col-md-4 mb-3">
                        <button
                          type="button"
                          onClick={() => setSubrol(role.value)}
                          className={`w-100 boton-tipo ${subrol === role.value ? 'boton-tipo-active' : ''}`}
                          style={{
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '10px',
                            background: subrol === role.value ? '#e3f2fd' : 'white',
                            cursor: 'pointer',
                            textAlign: 'left',
                          }}
                        >
                          <div className='d-flex flex-column'>
                            <span className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                              {role.label}
                            </span>
                            <span style={{ fontSize: "13px" }}>{role.description} </span>
                          </div>
                          <span className="indicator-wrapper mx-2">
                            <span className="indicator-fuera"></span>
                            <span className="indicator-dentro" style={{ marginLeft: "3px" }}></span>
                          </span>
                        </button>
                      </div>
                    ))}
                  </div>
                </FormControl>
                {submitting && (
                  <Alert icon={false} severity="info" className="mb-3">
                    <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                      <CircularProgress size="30px"/>
                      Estamos verificando el correo ingresado. Por favor espera unos momentos.
                    </div>
                  </Alert>
                )}
                {error &&
                  <Alert severity="error" className="mb-3">{error}</Alert>
                }
                {submitted && (
                  <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success" className="mb-3">
                    {t("collaborator.successEmailAlert")}
                  </Alert>
                )}
                <div className="d-flex justify-content-end">
                  <Button variant="contained" type="submit">
                    {t("button.register")}
                  </Button>
                </div>
                
              </form>
            </>
          )}
          <br />
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Subrol</TableCell>
                  <TableCell>Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventocolaboradores?.length > 0 ? (
                  eventocolaboradores.map((colaborador) => (
                    <TableRow key={colaborador.id}>
                      <TableCell>{colaborador.colaborador}</TableCell>
                      <TableCell>{colaborador.estado.estado}</TableCell>
                      <TableCell>
                        { colaborador.subrol === 0 ? "General" 
                        : colaborador.subrol === 2 ? "Control de acceso"
                        : colaborador.subrol === 3 ? "Voucher" 
                        : colaborador.subrol === 7 ? "Administador de datos" 
                        : "General"
                        }
                      </TableCell>
                      <TableCell>
                        {userRole === 1 &&
                          colaborador.idestado !== 3 &&
                          colaborador.idestado !== 4 && (
                            <DeleteCollaboratorModal id={colaborador.id}/>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="4">
                      <Alert severity="info" >
                        No hay colaboradores registrados
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <br />
            <VolverButton/>
          </div>
        </Container>
      )}
    </>
  );
};

export default withAuthenticationRequired(Colaboradores, {
  onRedirecting: () => <Loading />,
});
