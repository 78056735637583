import React, { useState, useEffect } from "react";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import {
  Container,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  IconButton,
  Alert,
  CircularProgress 
} from "@mui/material";
import VolverButton from "../../components/VolverButton";
import AddLocationModal from "../../components/modales/AddLocationModal";
import DeleteLocationModal from "../../components/modales/DeleteLocationModal";
import EditLocationModal from "../../components/modales/EditLocationModal";
import moment from "moment-timezone";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

moment.locale("es");
moment.locale("en-GB");

export const Ubicaciones = () => {
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("");
  const { id: eventoId } = useParams();
  const [showSearch, setShowSearch] = useState();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [t, i18n] = useTranslation("global");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalLength, setTotalLength] = useState(0);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);

  moment.locale(i18n.language === "es" ? "es" : "en-gb");


  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.locations.getAll(eventoId, query, pageSize, currentPage);
        if (response.status === "success") {
          setLocations(response.data.ubicaciones || []);
          setTotalLength(response.data.totalDataSize);
        } else {
          setLocations([]);
          setTotalLength(0);
        }
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          error: "Error en la Red.",
        }));
      } finally {
        setLoading(false);
      }
    };

    callApi();
    document.title = `Ubicaciones | PassGo`;
  }, [eventoId, query, pageSize, currentPage, location]);


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
    if (showSearch) setQuery('');
  };


  const editLocation = async (value) => {
    try {
      const response = await api.locations.updateLocation(value);
      if (response.status === "success")
      setLocation("");
      setState({
        ...state,
        apiMessage: "Elemento editado.",
      });
    } catch (error) {}
  };

  return (
        <Container>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>

{showSearch ? (
  <div style={{ display: "flex", alignItems: "center" }}>
    <TextField
      value={query}
      onChange={(e) => setQuery(e.target.value)}
      placeholder="Buscar.."
      variant="outlined"
      size="small"
    />
    <IconButton onClick={handleToggleSearch}>
      <CloseOutlinedIcon />
    </IconButton>
  </div>
) : (
  <IconButton onClick={handleToggleSearch}>
    <SearchOutlinedIcon />
  </IconButton>
)}

<AddLocationModal
              eventoId={eventoId}
              locations={locations}
            />
</div>
        {locations.length > 0 || loading ? (
        <>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#EDEDED" }}>
                <TableRow>
                  <TableCell sx={{ fontFamily: 'Inter, sans-serif', padding: "12px" }}>
                  {t("location.location")}
                  </TableCell>
                  <TableCell sx={{ fontFamily: 'Inter, sans-serif' }} align="right">
                    {t("location.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress color="primary" />
                    </TableCell>
                  </TableRow>
                ) : (
                  locations.map((loc) => (
                    <TableRow key={loc.id}>
                      <TableCell sx={{ fontFamily: 'Inter, sans-serif' }}>{loc.ubicacion}</TableCell>
                      <TableCell align="right">
                      <EditLocationModal
                          id={loc.id}
                          loc={loc}
                          ubicaciones={locations}
                          onUpdate={editLocation}
                        ></EditLocationModal>
                      <DeleteLocationModal
                          id={loc.id}
                        ></DeleteLocationModal>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!loading && (
            <div style={{ display: "flex", alignItems: "center", marginTop: "1rem", position: "relative" }}>
              <small>{t("location.totalEntries")} {totalLength}</small>
              <div style={{ margin: "0 auto" }}>
                <Pagination
                  count={Math.ceil(totalLength / pageSize)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <Alert severity="info">{t("location.locationsAlert")}</Alert>
      )}

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
        <VolverButton />
      </div>
    </Container>
  );
};
export default withAuthenticationRequired(Ubicaciones, {
  onRedirecting: () => <Loading />,
});
