import React, {  useEffect } from "react";
import { useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import {
  Container,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VolverButton from "../../components/VolverButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EventoVouchers } from "./tarifas/EventoVouchers";
import { EventoTickets } from "./tarifas/EventoTickets";

<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

export const Tarifas = () => {
  const param = useParams();
  const [t] = useTranslation("global");
  const history = useHistory();

  useEffect(() => {
    document.title = `Tarifas | PassGo`;
  }, []);

  return (
    <Container>
      <div className="d-flex justify-content-end mb-3 gap-1">
        <div className="d-flex flex-column justify-content-end">
          <Button sx={{
            backgroundColor: "#FFC300",
            color: "black",
            outline: "none",
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: 'Inter, sans-serif',
            padding: "5px 10px",
            '&:hover': {
              backgroundColor: "#FFB000",
            }
          }}
            startIcon={<AddIcon />}
            onClick={() => history.push(`/eventos/${param.id}/creartarifa`)}
          >
            {t("button.add")}
          </Button>
        </div>
      </div>

      <EventoTickets/>

      <EventoVouchers/>

      <div className="d-flex justify-content-end">
        <VolverButton />
      </div>
    </Container>
  );
};
export default withAuthenticationRequired(Tarifas, {
  onRedirecting: () => <Loading />,
});