import {create} from 'zustand';

// Definimos el store
const useCarritoStore = create((set) => ({
  carrito: {
    id: null,
    idusuario: null,
    idevento: null,
    items: []
  },

  // Accion para obtener el carrito activo
  refrescarCarrito: (nuevoCarrito) => set((state) => ({
    carrito: nuevoCarrito,
  })),


  // Acción para actualizar la cantidad de un item
  actualizarCantidadItem: (idTarifa, nuevaCantidad) => set((state) => {
    if (nuevaCantidad < 1) return;

    return {
      carrito: {
        ...state.carrito,
        items: state.carrito.items.map(item =>
          item.idtarifa === idTarifa ? { ...item, cantidad: nuevaCantidad } : item
        )
      }
    };
  }),

  // Acción para eliminar un item
  eliminarItemEntero: (id) => set((state) => {
    return {
      carrito: {
        ...state.carrito,
        items: state.carrito.items.filter(item => item.id !== id)
      }
    };
  }),
}));

export default useCarritoStore;