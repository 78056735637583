import { useContext, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { ImpresionContext } from "../../providers/Impresion";
import SideBar from "./SideBar";
import { Icon } from "@mui/material";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';


const FontSideBar = ({ onClose }) => {

  const {
    setElements,
    elements,
    dimensionContainer,
    index,
    setIndex,
    setOpenFont,
    tipografia,
    tipografiaSelected,
    setTipografiaSelected,
    selectedId,
    sizeSelected,
    setSizeSelected,
  } = useContext(ImpresionContext);

  const indexSelected = elements?.findIndex((t) => t.id === selectedId);



  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [color, setColor] = useState("#000000");
  const [alignLeft, setAlignLeft] = useState(true);
  const [alignCenter, setAlignCenter] = useState(false);
  const [alignRight, setAlignRight] = useState(false);
  const [align, setAlign] = useState('left');

  useEffect(() => {
    if (elements.length > 0 && selectedId) {
      let condition = (elements[indexSelected].type === 'text' ||
        elements[indexSelected].type === 'text-nombre' ||
        elements[indexSelected].type === 'text-evento' ||
        elements[indexSelected].type === 'text-codigo')

      if (selectedId && condition) {
        const newText = elements[indexSelected]
        setBold(newText.fontStyle === 'bold' || newText.fontStyle === 'italic bold')
        setItalic(newText.fontStyle === 'italic' || newText.fontStyle === 'italic bold')
        setUnderline(newText.textDecoration === 'underline')
        setColor(newText.fill)
        setAlignLeft(newText.align === 'left')
        setAlignCenter(newText.align === 'center')
        setAlignRight(newText.align === 'right')
        setTipografiaSelected(newText.fontFamily)

        setSizeSelected(newText.fontSize)
      }
    }
  }, [selectedId])

  const handleAddTextField = () => {
    const textGenerated = elements.slice();
    setIndex(index + 1)
    textGenerated.push({
      id: index.toString(),
      x: ((dimensionContainer.width) / 2) - 100,
      y: ((dimensionContainer.height) / 2) - 25,
      text: 'Agrega tu nuevo texto',
      fontSize: 14,
      rotation: 0,
      width: 137,
      height: 15,
      state: false,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      textDecoration: '',
      fill: '#000000',
      align: 'left',
      type: 'text',
    })
    setElements(textGenerated)
  }
  const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);

  const HandleAlign = (e) => {
    if (e.target.id === 'left') {
      setAlignCenter(false);
      setAlignRight(false);
      setAlignLeft(true);
      setAlign('left');
    }
    if (e.target.id === 'center') {
      setAlignLeft(false);
      setAlignRight(false);
      setAlignCenter(true);
      setAlign('center');
    }
    if (e.target.id === 'right') {
      setAlignLeft(false);
      setAlignCenter(false);
      setAlignRight(true);
      setAlign('right');
    }
  }

  const HandleChange = (newAttrs) => {
    const newElelements = elements.slice();
    newElelements[indexSelected] = newAttrs;
    setElements(newElelements);
  }

  useEffect(() => {
    if (elements && elements[indexSelected]) {
      if (elements[indexSelected].fontFamily !== tipografiaSelected) {
        HandleChange({ ...elements[indexSelected], fontFamily: tipografiaSelected });
      }
      if (elements[indexSelected].fontSize !== sizeSelected) {
        HandleChange({ ...elements[indexSelected], fontSize: sizeSelected });
      }
      if (elements[indexSelected].fill !== color) {
        HandleChange({ ...elements[indexSelected], fill: color });
      }
    }
  }, [tipografiaSelected, sizeSelected, color, alignLeft, alignCenter, alignRight])

  useEffect(() => {
    if (elements && elements[indexSelected]) {
      if (bold | italic) {
        HandleChange({ ...elements[indexSelected], fontStyle: 'bold' });
        if (italic) {
          HandleChange({ ...elements[indexSelected], fontStyle: 'italic' });
        }
        if (bold & italic) {
          HandleChange({ ...elements[indexSelected], fontStyle: 'italic bold' });
        }
      } else if (!bold & !italic & elements[indexSelected].fontStyle !== 'normal') {
        HandleChange({ ...elements[indexSelected], fontStyle: 'normal' });
      }
      if (underline) {
        HandleChange({ ...elements[indexSelected], textDecoration: 'underline' });
      } else if (elements[indexSelected].textDecoration !== '') {
        HandleChange({ ...elements[indexSelected], textDecoration: '' });
      }
      if (elements[indexSelected].align !== align) {
        HandleChange({ ...elements[indexSelected], align: align });
      }
    }
  }, [bold, italic, underline, align])


  return (
    <>
      <SideBar id='fontBar'>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p className="p-0 m-0 fs-4">Texto</p>
          </div>
          <div>
            <button className="d-flex align-items-center" onClick={() => onClose()}>
              <i className="material-symbols-outlined">close</i>
            </button>
          </div>
        </div>
        <div id='fontBar' className="w-full d-flex flex-column gap-2">
          <button type="button" className="btn rounded-3 w-100 bg-primary" onClick={handleAddTextField}>
            <p className="text-center p-0 m-0 fs-5 text-white" >Añade tu texto</p>
          </button>
          <div className="d-flex">
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown} direction={'down'} className='w-100'>
              <DropdownToggle className=' w-100 d-flex justify-content-between align-items-center bg-body-secondary text-body pb-1 pt-1 pl-1 pr-2' style={{ border: 'none' }} caret>
                <input type="text" className='w-full h-full bg-transparent flex-grow-1 mr-1' style={{ border: 'none' }} value={tipografiaSelected}
                  readOnly
                />
              </DropdownToggle>
              <DropdownMenu className='w-100 position-absolute h-auto overflow-visible'>

                {Object.keys(tipografia).map(key => (
                  <DropdownItem key={key} active={tipografia[key] === tipografiaSelected} onClick={() => setTipografiaSelected(tipografia[key])}>
                    <div className='d-flex justify-content-between'>
                      <p className='p-0 m-0'>{tipografia[key]}</p>
                      <p className={`p-0 m-0 texto-${key}`}>Texto</p>
                    </div>
                  </DropdownItem>
                ))}

              </DropdownMenu>
            </Dropdown>
          </div>
          <div className='d-flex gap-1'>
            <div className='w-100 d-flex justify-content-start gap-1'>
              <button className={`btn d-flex align-items-center ${bold ? 'active bg-dark text-white' : 'bg-body-secondary'} `} id='bold' onClick={() => setBold(!bold)}>
                {/* <i className='material-symbols-outlined fs-5'>format_bold</i> */}
                <FormatBoldIcon />
              </button>
              <button className={`btn d-flex align-items-center ${italic ? 'active bg-dark text-white' : 'bg-body-secondary'} `} id='italic' onClick={() => setItalic(!italic)}>
                <FormatItalicIcon />
              </button>
              <button className={`btn d-flex align-items-center ${underline ? 'active bg-dark text-white' : 'bg-body-secondary'}`} id='underlined' onClick={() => setUnderline(!underline)}>
                <FormatUnderlinedIcon />
              </button>
            </div>
            <div className='d-flex' style={{ gap: '-1px' }}>
              <button type='button' className='btn bg-body-secondary d-flex align-items-center rounded-end-0' onClick={() => setSizeSelected(sizeSelected - 1)}>
                <RemoveIcon />
              </button>
              <input
                type="text"
                className="bg-body-secondary"
                style={{
                  width: '60px',
                  margin: 0,
                  textAlign: 'center',
                  border: 'none',
                }}
                value={sizeSelected}
                readOnly
                onChange={(e) => setSizeSelected(parseFloat(e.currentTarget.value))}
              />
              <button type='button' className='btn bg-body-secondary d-flex align-items-center rounded-start-0' onClick={() => setSizeSelected(sizeSelected + 1)}>
                <AddIcon />
              </button>
            </div>
          </div>
          <div className='d-flex gap-1'>
            <div className="d-flex gap-1">
              <button type='button' className={`btn d-flex align-items-center ${alignLeft ? 'active bg-dark text-white' : 'bg-body-secondary'}`} id='left' onClick={(e) => HandleAlign(e)}>
                <FormatAlignLeftIcon id="left" />
              </button>
              <button type='button' className={`btn d-flex align-items-center ${alignCenter ? 'active bg-dark text-white' : 'bg-body-secondary'}`} id='center' onClick={(e) => HandleAlign(e)}>
                <FormatAlignCenterIcon id="center" />
              </button>
              <button type='button' className={`btn d-flex align-items-center ${alignRight ? 'active bg-dark text-white' : 'bg-body-secondary'}`} id='right' onClick={(e) => HandleAlign(e)} >
                <FormatAlignRightIcon id="right" />
              </button>
            </div>
            <div className="bg-body-secondary rounded-2 w-100 d-flex align-items-center justify-content-center" >
              <input type="text" className='d-flex text-uppercase bg-transparent pb-0 ' style={{ border: 'none', width: '143px' }} value={color} onChange={(e) => setColor(e.currentTarget.value)} />
            </div>

          </div>
          <div className='w-100 d-flex justify-content-center'>
            <HexColorPicker color={color} onChange={setColor} > </HexColorPicker>
          </div>
        </div>
      </SideBar>
    </>
  )
}

export default FontSideBar;