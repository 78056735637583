import { useParams } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import { getTicket } from "./api";

const useTicket = () => {
  const { id, idticket } = useParams();

  const { data, loading, refetch } = useQuery({
    // autoFetch: false,
    queryFn: getTicket,
    // onSuccess: response => {
    // },
    args: [id, idticket],
  })

  return {
    ticket: data?.data.ticket,
    loading,
    refetch
  };

};

export default useTicket;