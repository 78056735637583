import React, { useState, useEffect, useRef } from "react";
import Loading from "../../components/Loading";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../services/api";
import { 
  Container, 
  Button,
  TextField, 
  Alert, 
  CircularProgress, 
  TableContainer, 
  Paper,
  Table,
  TableHead, 
  TableRow, 
  TableCell,
  TableBody
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import DeleteClientModal from "../../components/modales/DeleteClientModal";
import { useTranslation } from "react-i18next";
import RouteTitleManager from "../../utils/RouteTitleManager";
const UsuarioClientes = () => {
  const [evento, setEvento] = useState(null);
  const [eventoclientes, setEventoClientes] = useState([]);
  const history = useHistory();
  const param = useParams();
  const form = useRef();
  const [t] = useTranslation("global");
  const [userRole, setUserRole] = useState(null);
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });

  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const response = await api.eventos.get(param.id);
        if (response.status === "success") {
          setEvento(response.data.evento);
          setEventoClientes(response.data.clientes || []); // Asegurar que sea un array
        } else {
          setError(response.message || "Error al cargar el evento");
        }
      } catch (error) {
        setError(error.message || "Error al cargar el evento");
      }
    };
    fetchEventos();
  }, []);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await api.participaciones.getParticipaciones();

        if (response.status === "success") {
          const rol = response.data.participaciones.find(
            (p) => p.idevento == param.id
          )?.idrol;
          setUserRole(rol);
        } else {
          setState({ ...state, error: "Error al obtener el rol." });
        }
      } catch (error) {
        setState({ ...state, error: "Error en la red." });
      }
    };
    document.title = `Clientes | PassGo`;
    fetchRole([]);
  }, [param.id]);

  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (email === "") {
      setError("El correo electrónico es obligatorio");
      setSubmitting(false)
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Introduce un correo electrónico válido");
      setSubmitting(false)
      return;
    }

    const data = { email };
    const response = api.eventos.createCliente(param.id, data);
    return response.then((response) => {
      if (response.status === "error") {
        setError("Error: " + response.message);
        setSubmitting(false);
      }
      if (response.status === "success") {
        setSubmitting(false);
        setSubmitted(true);
        setError("");
        const data = {
          recipient: email,
          evento: evento.evento,
        };
        const response = api.email.send(data);
        setEmail("");
        setTimeout(function () {
          history.go(0);
        }, 1500);
        if (response.status === "error") {
          setError("Error: " + response.message);
        }
      }
    });
  };

  return (
    <>
      {evento && (
        <Container fluid="sm">
          {userRole === 1 && (
            <>
              <form onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "15px"
                }}
              >
                <TextField 
                  type="email"
                  name="email"
                  id="email"
                  label={t("customers.email")}
                  variant="outlined" 
                  size="small"
                  fullWidth 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button variant="contained" type="submit">
                  {t("button.register")}
                </Button>
              </form>
              {submitting && (
                <Alert icon={false} severity="info">
                  <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                    <CircularProgress size="30px"/>
                    Estamos verificando el correo ingresado. Por favor espera unos momentos.
                  </div>
                </Alert>
              )}
              {error && 
                <Alert severity="error">{error}</Alert>
              }
              {submitted && (
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                  {t("customers.successEmailAlert")}
                </Alert>
              )}
            </>
          )}
          <br />
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventoclientes?.length > 0 ? (
                  eventoclientes.map((cliente) => (
                    <TableRow key={cliente.id}>
                      <TableCell>{cliente.cliente}</TableCell>
                      <TableCell>{cliente.estado.estado}</TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        {userRole === 1 &&
                          cliente.idestado !== 3 &&
                          cliente.idestado !== 4 && (
                            <DeleteClientModal
                              eventoId={param.id}
                              clienteId={cliente.id}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="3">
                      <Alert severity="info" >
                        No hay clientes registrados
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
};

export default withAuthenticationRequired(UsuarioClientes, {
  onRedirecting: () => <Loading />,
});
