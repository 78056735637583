import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PaginatorContext } from '../../../providers/Paginator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Table, TablePagination, TableBody, Paper, Menu, TableContainer, TableHead, TableRow, TableCell, CircularProgress, IconButton, MenuItem, CardMedia, Chip } from "@mui/material";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import useCompras from './useCompras';
import empty from "../../../assets/empty.png";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Input } from 'reactstrap';
moment.locale('es');

const Compras = () => {
  const {
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = useContext(PaginatorContext);
  const history = useHistory();
  const { compras, loading, total, onSearch } = useCompras();
  const [showSearch, setShowSearch] = useState();

  const [menuState, setMenuState] = useState({
    anchorEl: null,
    selectedCompraId: null, // Agrega el id de la compra seleccionada
  });
  
  const open = Boolean(menuState.anchorEl);

  const handleClick = (event, id) => {
    setMenuState({
      anchorEl: event.currentTarget, // Elemento anclado
      selectedCompraId: id, // ID de la compra
    });
  };
  
  const handleClose = () => {
    setMenuState({
      anchorEl: null,
      selectedCompraId: null, // Resetea el id seleccionado
    });
  };

  // Manejar el cambio de página
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Manejar el cambio de tamaño de página
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
    onSearch("");
  };

  return (
    <div className="container-fluid">
      <h3 className="my-3">
        Mis compras
      </h3>
      <form onSubmit={(e) => e.preventDefault()} className="d-flex justify-content-end">
        {showSearch ? (
          <div className="w-50 d-flex gap-2">
            <Input
              id="exampleSearch"
              name="q"
              onChange={(e) => onSearch(e.target.value)}
              placeholder="Buscar.."
              type="search"
            />
            <Button onClick={() => setShowSearch(false)} color="light">
              <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
            </Button>
          </div>
        ) : (
          <Button color="secondary" onClick={handleToggleSearch}>
            <FontAwesomeIcon icon={faSearch} className="fs-6" />
          </Button>
        )}
      </form>
      
      {compras?.length > 0 ? (
        <TableContainer component={Paper} className="mt-3 table-responsive">
          <Table aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#EDEDED" }}>
              <TableRow>
                <TableCell>Evento</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Fecha de compra</TableCell>
                <TableCell align="center">Monto total</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                compras?.map((compra) => (
                  <TableRow
                    key={compra.id}
                    onDoubleClick={() => history.push(`/compra/${compra.id}`)}
                  >
                    <TableCell className="fw-medium fs-6">
                      {compra.carrito?.evento?.evento}
                    </TableCell>

                    <TableCell align="center">
                      <Chip
                        size="small"
                        label={compra.atendido ? "Aprobada" : "Pendiente"}
                        sx={{
                          backgroundColor: compra.atendido ? '#B7FFCC' : '#FFF2B4',
                          color: '#216735',
                        }}
                      />
                    </TableCell>

                    <TableCell>
                      <div className="d-flex justify-content-center align-items-center gap-1">
                        <CalendarMonthOutlinedIcon/>
                        {moment(compra.atendido).clone().local().format("DD/MM/YYYY HH:mm [hs]")}
                      </div>
                    </TableCell>

                    <TableCell align="center" className="fw-semibold">
                      $ {compra.monto_total}
                    </TableCell>

                    <TableCell align="center">
                      {compra.atendido &&
                        <>
                          <IconButton
                            className="m-0"
                            aria-label="see more"
                            aria-expanded="true"
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, compra.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            aria-label="report"
                            anchorEl={menuState.anchorEl ?? undefined}
                            open={open}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                history.push(`/compra/${menuState.selectedCompraId}`);
                              }}
                            >
                              Ver compra
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                history.push(`/compra/${menuState.selectedCompraId}/recibo`);
                              }}
                            >
                              Ver recibo
                            </MenuItem>
                          </Menu>
                        </>
                      }
                    </TableCell>
                  </TableRow>
                )
              ))}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            className="d-flex justify-content-center"
            rowsPerPageOptions={[10, 25, 50]}
            count={total}
            rowsPerPage={pageSize}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Registros p/página"
            showFirstButton
            showLastButton
          />
        </TableContainer>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <CardMedia
            component="img"
            sx={{ width: 200 }}
            image={empty}
            alt="Empty"
          />
          <small className="text-secondary">
            No tienes compras realizadas.
          </small>
        </div>
      )}
    </div>
  );
};

export default Compras;