import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import Invitacion from "./components/invitacion/invitacion";
import NavBar from "./components/NavBar";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Eventos from "./views/eventos/Eventos";
import Evento from "./views/eventos/Evento";
import Ventas from "./views/eventos/ventas/Ventas";
import ReciboVenta from "./views/eventos/ventas/ReciboVenta";
import { QrTickets } from "./views/eventos/QrTickets";
import Tickets from "./views/eventos/tickets";
import Ticket from "./views/eventos/ticket/index";
import Colaboradores from "./views/eventos/Colaborador";
import UsuarioClientes from "./views/eventos/usuarioCliente";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import { NuevoTicket } from "./views/eventos/NuevoTicket";
import { ExcelImport } from "./views/eventos/ExcelImport";
import { Ubicaciones } from "./views/eventos/Ubicaciones";
import Impresion from "./views/eventos/Impresion";
import Precios from "./views/Precios"
import Notificaciones from "./views/Notificaciones";
import Inscripcion from "./views/encuentro/Inscripcion";
import { EventoPublic } from "./views/eventos/evento/evento-public/EventoPublic";
import { CrearPublicacion } from "./views/eventos/publicaciones/CrearPublicacion";
import EditPublicacion from "./views/eventos/publicaciones/EditPublicacion";
import Publicaciones from "./views/eventos/publicaciones/Publicaciones";
import Vouchers from "./views/eventos/Vouchers";
import Voucher from "./views/eventos/voucher";
import { CrearVoucher } from "./views/eventos/CrearVoucher";
import { Vales } from "./views/eventos/Vales";
import SetEvent from "./views/eventos/evento/SetEvent";
import { ExcelImportVouchers } from "./views/eventos/ExcelImportVouchers";
//import CrearTarifa from "./views/eventos/CrearTarifa-old";
import CrearTarifa from "./views/eventos/CrearTarifa";
import { Ayuda } from "./views/ayuda";
//import { TercerPaso } from "./views/eventos/evento/TercerPaso";
import EliminarCuenta from "./components/perfil/EliminarCuenta";
import Favoritos from "./components/Like/favoritos";
// styles
import "./App.css";
// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import { PaginatorProvider } from "./providers/Paginator";
import VincularCuentas from "./views/eventos/cuentas/VincularCuentas";
//import CuentaBancaria from "./views/eventos/cuentas/CuentaBancaria";
//import CuentasBancarias from "./views/eventos/cuentas/CuentasBancarias";
//import CrearCuentaBancaria from "./views/eventos/cuentas/CrearCuentaBancaria";
import Layout from "./views/eventos/Layout";
import { StepperCrearEvento } from "./components/StepperCrearEvento";
import Tarifas from "./views/eventos/Tarifas";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";
import ProtectedLayout from "./components/ProtectedRoutes/ProtectedLayout";
import Unauthorized from "./components/ProtectedRoutes/Unauthorized";
import { UserProvider } from "./components/ProtectedRoutes/UserContext";
import CarritoCompra from "./views/eventos/CarritoCompra";
import CreaEventos from "./views/eventos/creaEvento/CreaEventos";
import NotificacionComponent from "./components/Socket/cliente";
import { useTranslation } from "react-i18next";
import { ToastContainer } from 'react-toastify';
import { ImpresionProvider } from "./providers/Impresion";
import CookieConsent from "./components/cookies/CookieConsent";
import { TickerRecepImport } from "./views/eventos/TickerRecepImport";
import Compra from "./components/perfil/Compras/VerCompra/Compra";
import ReciboCompra from "./components/perfil/Compras/VerCompra/ReciboCompra";
import RouteTitleManager from "./utils/RouteTitleManager";
import Caja from "./views/eventos/Caja";
import Owners from "./views/eventos/Owners";
import Authorized from "./components/ProtectedRoutes/Authorized";

initFontAwesome();

const App = () => {
  const { isAuthenticated, getAccessTokenSilently, error } = useAuth0();
  const { i18n } = useTranslation();

  useEffect(() => {
    const setToken = async () => {
      const token = await getAccessTokenSilently();

      localStorage.setItem("token", token);
      window.location.reload();
    };

    const isTokenExpired = (token) => {
      try {
        const { exp } = JSON.parse(atob(token.split(".")[1]));  // Decodificacion del payload del token
        return Date.now() >= exp * 1000;  // Comparamos la expiracion en milisegundos
      } catch (error) {
        console.error("error decoding token: ", error);
        return true;
      }
    };

    const token = localStorage.getItem("token");

    if (isAuthenticated && (!token || isTokenExpired(token))) {
      setToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);
  
  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <UserProvider>
      <Router history={history}>
        <RouteTitleManager/>
        <CookieConsent />
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <ToastContainer />
          <NotificacionComponent />
          <Container fluid className="flex-grow-1" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Switch>
              <Route exact path="/ayuda" component={Ayuda} />
              <Route exect path="/invitacion" component={Invitacion} />
              <Route exact path="/" component={Home} />
              <Route exact path="/creaeventos" component={CreaEventos} />
              <Route path="/notificaciones" component={Notificaciones} />
              <Route exact path="/eventos/:id/inscripcion" component={Inscripcion} />
              <Route exact path="/eventos" component={Eventos} />
              <Route exact path="/precios" component={Precios} />
              <Route exact path="/eventos/ventas/:id" component={ReciboVenta} />
              <Route exact path="/eventos/ventas/:id/recibo" component={ReciboVenta} />
              <Route exact path="/compra/:id" component={Compra} />
              <Route exact path="/compra/:id/recibo" component={ReciboCompra} />
              <Route exact path="/evento/:id/informacion" component={EventoPublic} />
              <Route exact path="/profile/eliminar-cuenta" component={EliminarCuenta} />
              {/* <Route exact path="/eventos/creacion" component={NuevoEvento} />  */}
              <Route exact path="/publicaciones/:id/editar" component={EditPublicacion} />
              <Route path="/unauthorized" component={Unauthorized} />
              <Route exact path="/authorized" component={Authorized} />
              <Route exact path="/carrito" component={CarritoCompra} />
              {/* <Route exact path="/socket/cliente" component={NotificacionComponent}></Route> */}
              <PaginatorProvider>
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/favoritos" component={Favoritos} />
                <Route path="/eventos/:id">
                  <ProtectedLayout allowedRoles={[1, 2, 3, 5, 6, 7]}>
                    <Layout>
                      <Switch>
                        <Route exact path="/eventos/:id" component={Evento} />
                        <ProtectedRoute exact path="/eventos/:id/caja" component={Caja} allowedRoles={[1, 2, 6]} />
                        <ProtectedRoute exact path="/eventos/:id/colaboradores" component={Colaboradores} allowedRoles={[1, 2]} />
                        <ProtectedRoute exact path="/eventos/:id/clientes" component={UsuarioClientes} allowedRoles={[1, 3]} />
                        <ProtectedRoute exact path="/eventos/:id/owners" component={Owners} allowedRoles={[1]} />
                        {/* <ProtectedRoute exact path="/eventos/:id/qrtickets" component={LecturaQR} allowedRoles={[1, 2, 5]} /> */}
                        <ProtectedRoute exact path="/eventos/:id/qrtickets/:lector" component={QrTickets} allowedRoles={[1, 2, 5]} />
                        <ProtectedRoute exact path="/eventos/:id/qrvouchers/:lector" component={QrTickets} allowedRoles={[1, 2, 6]} />
                        <ProtectedRoute exact path="/eventos/:id/ubicaciones" component={Ubicaciones} allowedRoles={[1, 2]} />
                        <ProtectedRoute exact path="/eventos/:id/tarifas" component={Tarifas} allowedRoles={[1, 2]} />
                        <Route exact path="/eventos/:id/crearTarifa" component={CrearTarifa} />
                        <Route exact path="/eventos/:id/nuevoTicket" component={NuevoTicket} />
                        <Route exact path="/eventos/:id/nuevoTicket/importarExcel" component={ExcelImport} />
                        <ProtectedRoute exact path="/eventos/:id/publicaciones" component={Publicaciones} allowedRoles={[1, 3]} />
                        <ProtectedRoute exact path="/eventos/:id/cuentas" component={VincularCuentas} allowedRoles={[1]} />
                        {/* <ProtectedRoute exact path="/eventos/:id/cuentas" component={CuentasBancarias} allowedRoles={[1]} /> */}
                        {/*  <Route exact path="/eventos/:id/crear-cuenta" component={CrearCuentaBancaria} /> */}
                        {/* <Route exact path="/eventos/:id/crear-cuenta" component={VincularCuentas} /> */}
                        <ProtectedRoute exact path="/eventos/:id/ventas" component={Ventas} allowedRoles={[1, 3]} />
                        <ProtectedRoute exact path="/eventos/:id/tickets" component={Tickets} allowedRoles={[1, 2, 3, 7]} />
                        <Route exact path="/eventos/:id/publicaciones/nueva" component={CrearPublicacion} />
                        <Route exact path="/eventos/:id/vouchers" component={Vouchers} />
                        <Route exact path="/eventos/:id/nuevoVoucher" component={CrearVoucher} />
                        <Route exact path="/eventos/:id/nuevoVoucher/importarExcel" component={ExcelImportVouchers} />
                        <Route exact path="/eventos/:id/nuevoTicket/recepcion" component={TickerRecepImport} />
                        <Route exact path="/eventos/:id/vouchers/:idvoucher" component={Voucher} />
                        <Route exact path="/eventos/:id/tickets/:idticket" component={Ticket} />
                        <Route exact path="/eventos/:id/tickets&vouchers" component={Vales} />
                        <Route exact path="/eventos/:id/editar" component={SetEvent} />
                        <ImpresionProvider>
                          <ProtectedRoute exact path="/eventos/:id/impresion" component={Impresion} allowedRoles={[1, 2, 7]} />
                        </ImpresionProvider>
                      </Switch>
                    </Layout>
                  </ProtectedLayout>
                </Route>
                <Route path = "/crearEvento" component = {StepperCrearEvento} />
              </PaginatorProvider>
            </Switch>
          </Container>
          <div id="fb-root"></div>
          <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v20.0" nonce="M5gDFpMz"></script>
        </div>
      </Router>
    </UserProvider>
  );
};

export default App;
