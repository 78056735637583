import { toast } from "react-toastify";

export const showSuccessToastify = ({ res }) => {
  const title = (res && typeof res === "string") ? res : res?.message || JSON.stringify(res);
  toast.success((title || "Acción exitosa"), {
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top-right',
  });
};

export const showErrorToastify = ({ err }) => {
  const title = (err && typeof err === "string") ? err : err?.message || JSON.stringify(err);
  toast.error((title || "Error"), {
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
};

export const privateOptions = (method, data) => {
  let opt = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'AUTHORIZATION': `Bearer ${localStorage.getItem('token')}`
    }
  };
  if (data) {
    opt.body = JSON.stringify(data);
  }

  return opt;
};