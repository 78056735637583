import React, { useEffect, useState } from 'react';
import { api } from '../../services/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import VolverButton from '../../components/VolverButton';
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
import { 
  Container, 
  Box, 
  TextField, 
  FormControlLabel, 
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox, 
  Button,
  FormHelperText,
  OutlinedInput,
  Chip,
  IconButton
} from '@mui/material';
import { useMediaQuery } from "react-responsive";
import { showErrorToastify, showSuccessToastify } from '../../utils';
import useMutation from '../../hooks/useMutation';
import useGetFechasEvento from '../../hooks/useGetFechasEvento';
import useGetCuentas from '../../hooks/useGetCuentas';
import DeleteIcon from '@mui/icons-material/Delete';

const CrearTarifa = () => {
  const history = useHistory();
  const [t] = useTranslation("global");
  const param = useParams();
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [errorDate, setErrorDate] = useState(false);
  const { fechasEvento } = useGetFechasEvento(param?.id);
  const { cuenta } = useGetCuentas(param?.id);

  const initForm = {
    idevento: param.id,
    idmoneda: 1,
    concepto: '',
    precio: 0,
    idtipo: '',
    limite_persona: 10,
    limite_maximo: 100,
    asumir_comision: false,
    inicio_venta_fecha: "",
    inicio_venta_hora: "",
    fin_venta_fecha: "",
    fin_venta_hora: "",
    privado: false,
    fechas: [],
    informacion_adicional: false
  }
  const [formData, setFormData] = useState(initForm);

  const [formErrors, setFormErrors] = useState({
    concepto: false,
    //precio: false,
    idtipo: false,
    limite_persona: false,
    limite_maximo: false,
  });

  useEffect(() => {
    document.title = `Crear tarifa | PassGo`;
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "limite_persona" || name === "precio" || name === "limite_maximo") {
      if (/^\d*\.?\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
    else if (name === "fechas") {
      setFormData({
        ...formData,
        [name]: [...new Set(value)],  // Usamos `new Set` para asegurarnos de que no haya valores duplicados
      });
    }
    else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name !== "fechas" && typeof value === "string" && value.trim() !== "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,  
      }));
    }
  }

  const create = useMutation({
    mutationFn: api.tarifas.createTarifa,
    onSuccess: (res) => {
      showSuccessToastify({ res: res.message });

      const tarifaId = res.data?.tarifa.id;
      if (tarifaId) {
        handleGuardarPreguntas(tarifaId);
      }

      setTimeout(() => {
        history.push(`/eventos/${param.id}/tarifas`);
        window.scrollTo(0, 0);
      }, 1000);
    },
    onError: (err) => {
      showErrorToastify({ err: err.message });
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.privado) {
      if (!formData.inicio_venta_fecha || !formData.inicio_venta_hora || !formData.fin_venta_fecha || !formData.fin_venta_hora) {
        setErrorDate(true);
        return;
      }
    }

    let inicioVenta = null;
    let finVenta = null;

    if (!formData.privado) {
      inicioVenta = new Date(`${formData.inicio_venta_fecha}T${formData.inicio_venta_hora}`);
      finVenta = new Date(`${formData.fin_venta_fecha}T${formData.fin_venta_hora}`);
    }

    const errors = {
      concepto: formData.concepto.trim() === '',
      idtipo: formData.idtipo === '',
      limite_persona: formData.limite_persona === '',
      limite_maximo: formData.limite_maximo === '',
      fechas: formData.fechas?.length === 0,
    };

    setFormErrors(errors);
    
    const isValid = Object.values(errors).every(error => !error);

    const newTarifa = {
      ...formData,
      idevento: parseInt(formData.idevento),
      precio: parseFloat(formData.precio),
      idtipo: parseInt(formData.idtipo),
      limite_persona: parseInt(formData.limite_persona),
      limite_maximo: parseInt(formData.limite_maximo),
      fechas: formData.fechas?.map(fechaId => ({ id: fechaId })),
      ...(inicioVenta && { inicio_venta: inicioVenta.toISOString() }),
      ...(finVenta && { fin_venta: finVenta.toISOString() }),
      informacion_adicional: formData.informacion_adicional,
    }
    
    if (isValid) {
      create.mutate(newTarifa);
    }
    else {
      showErrorToastify({ err: "Asegurate de que todos los campos sean correctos" });
    }
  }

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      asumir_comision: checked
    }));
  }
  
  const handleChangeVisivilidad = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value === "true", 
    });
  };

  const tarifasOpciones = [
    {
      value: 1, label: "Para tickets", 
      description: "Administra el acceso a tu evento."
    },
    {
      value: 2, label: "Para consumiciones", 
      description: "Gestiona las consumiciones para tu evento."
    }
  ];

  const [checked, setChecked] = useState(false);
  const [preguntas, setPreguntas] = useState([{
    informacion_adicional: "",
    id_tarifa: 0,
    id_evento: parseInt(param.id), 
  }]);

  useEffect(() => {
    if (formData?.idtipo != 1) {
      setPreguntas([{informacion_adicional: "",
        id_tarifa: 0,  
        id_evento: parseInt(param.id) }]); 
      setChecked(false); 
      setFormData((prevFormData) => ({
        ...prevFormData,
        informacion_adicional: false,
      }));
    }
  }, [formData?.idtipo]);

  const handleCheckboxChange2 = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    setFormData((prevData) => ({
      ...prevData,
      informacion_adicional: isChecked,
    }));

    if (isChecked && preguntas.length === 0) {
      setPreguntas([{ id_tarifa: 0, id_evento: param.id, informacion_adicional: "" }]);
    } else if (!isChecked) {
      setPreguntas(preguntas.map(pregunta => ({
        ...pregunta,
        informacion_adicional: "",
      })));
    }
  };

  const agregarPregunta = () => {
    setPreguntas([
      ...preguntas,
      { id_tarifa: 0, id_evento: parseInt(param.id), informacion_adicional: "" }
    ]);
  };

  const handlePreguntaChange = (index, value) => {
    if (preguntas.length === 0) return;
    
    const nuevasPreguntas = [...preguntas];
    if (index >= 0 && index < nuevasPreguntas.length) {
      nuevasPreguntas[index].informacion_adicional = value;
      setPreguntas(nuevasPreguntas);
    }
  };

  //elimina una pregunta solo si hay mas de una
  const eliminarPregunta = (index) => {
    if (preguntas.length > 1) {
      setPreguntas(preguntas.filter((_, i) => i !== index));
    }
  };

  const handleGuardarPreguntas = async (tarifaId) => {
    const preguntasFiltradas = preguntas.filter(pregunta => pregunta.informacion_adicional.trim() !== "");
    
    if (preguntasFiltradas.length === 0) return;
  
    const dataToSend = preguntasFiltradas.map(pregunta => ({
      id_tarifa: tarifaId,
      id_evento: parseInt(param.id),
      informacion_adicional: pregunta.informacion_adicional
  }));


    try {
        const response = await api.infoAdicional.createPreguntas(param.id, dataToSend );
  
        if (response.status !== "success") {
          throw new Error(response.message);
        }
      
  
      setPreguntas([]); // Limpiar preguntas después de guardar
  
  
    } catch (error) {
      console.error(error.message)
    }
  };
  

  /* console.log(preguntas) */

  return (
    <Container maxWidth='sm' className="d-flex justify-content-center flex-wrap">
      <div className='w-100'>
        <Box 
          component="form" 
          onSubmit={handleSubmit}
        >
          <div style={{
            display: isMobile ? 'block' : 'flex',
            gap: '10px', 
            marginTop: '16px'
          }}>
            {tarifasOpciones.map((opcion) => (
              <div key={opcion.value} className="mb-3">
                <button
                  type="button"
                  onClick={() => setFormData({ ...formData, idtipo: opcion.value })}
                  className={`w-100 boton-tipo ${formData.idtipo === opcion.value ? 'boton-tipo-active' : ''}`} 
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '10px',
                    background: formData.idtipo === opcion.value ? '#e3f2fd' : 'white', 
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}
                >
                  <div className='d-flex flex-column'>
                    <span className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                      {opcion.label}
                    </span>
                    <span style={{ fontSize: "13px" }}>{opcion.description} </span>
                  </div>
                  <span className="indicator-wrapper mx-2">
                    <span className="indicator-fuera"></span>
                    <span className="indicator-dentro" style={{ marginLeft: "1px" }}></span>
                  </span>
                </button>
              </div>
            ))}
          </div>
          <FormControl fullWidth>
            <InputLabel id="privado-label">Visibilidad</InputLabel>
            <Select
              labelId="privado-label"
              id="privado"
              name="privado"
              value={formData.privado.toString()} 
              label="Privacidad"
              onChange={handleChangeVisivilidad}
            >
              <MenuItem value="true">Privado</MenuItem>
              <MenuItem value="false">Público</MenuItem>
            </Select>
            <FormHelperText>
              {formData.privado === false ? 
                  "Las tarifas públicas serán visibles para todos y estarán disponibles para la venta pública online. Para poder crear una tarifa pública, primero debes vincular tu cartera." 
                : (
                  "Las tarifas privadas no estarán disponibles para la venta pública online."
                )}
            </FormHelperText>
          </FormControl>
          {!isMobile ? (
            <div>
              <div 
                style={{
                  display: 'flex',
                  gap: '10px',
                }}  
              >
                <TextField 
                  name="concepto"
                  label={t("rates.concept")} 
                  variant="outlined" 
                  value={formData.concepto}
                  onChange={handleChange}
                  fullWidth 
                  margin="normal"
                  error={formErrors.concepto}
                  helperText={formErrors.concepto && 'Este campo es obligatorio'}
                />
                <TextField 
                  label={t("rates.price")} 
                  variant="outlined"
                  fullWidth
                  name="precio" 
                  onChange={handleChange}
                  value={formData.precio || ''}
                  margin="normal"
                  helperText={formErrors.precio && 'Este campo es obligatorio'}
                  sx={{ width: '30%' }}
                />
              </div>  
              {formData.precio > 0 && (
                <FormControlLabel 
                  control={<Checkbox checked={formData.asumir_comision} />} 
                  label={<FormHelperText>{t("tickets.assumeTheCosts")} </FormHelperText>} 
                  name="asumirCostos"
                  onChange={handleCheckboxChange}
                />
              )}
            </div>
          ) : (
            <>
              <TextField 
                name="concepto"
                label={t("rates.concept")} 
                variant="outlined" 
                value={formData.concepto}
                onChange={handleChange}
                fullWidth 
                margin="normal"
                error={formErrors.concepto}
                helperText={formErrors.concepto && 'Este campo es obligatorio'}
              />
              <TextField 
                label={t("rates.price")} 
                variant="outlined"
                fullWidth
                name="precio" 
                onChange={handleChange}
                value={formData.precio || ''}
                margin="normal"
                helperText={formErrors.precio && 'Este campo es obligatorio'}
              />
              {formData.precio > 0 && (
                <FormControlLabel 
                  control={<Checkbox checked={formData.asumir_comision} />} 
                  label={<FormHelperText>{t("tickets.assumeTheCosts")} </FormHelperText>} 
                  name="asumirCostos"
                  onChange={handleCheckboxChange}
                />
              )}
            </>
          )}
          {!formData.privado && (
            <>
              <div style={{width: "100%", display: "flex", gap: "10px", marginTop: "15px"}}>
                <TextField
                  label="Inicio de Venta - Fecha"
                  type="date"
                  name="inicio_venta_fecha"
                  value={formData.inicio_venta_fecha}
                  onChange={handleChange}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  //required
                  fullWidth
                  error={errorDate}
                />
                <TextField
                  label="Inicio de Venta - Hora"
                  type="time"
                  name="inicio_venta_hora"
                  value={formData.inicio_venta_hora}
                  onChange={handleChange}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  //required
                  fullWidth
                  error={errorDate}
                />
              </div>
              <div style={{width: "100%", display: "flex", gap: "10px"}}>
                <TextField
                  label="Fin de Venta - Fecha"
                  type="date"
                  name="fin_venta_fecha"
                  value={formData.fin_venta_fecha}
                  onChange={handleChange}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  margin="normal"
                  fullWidth
                  error={errorDate}
                  helperText={errorDate && 'Las fechas y horas son obligatorias'}
                />
                <TextField
                  label="Fin de Venta - Hora"
                  type="time"
                  name="fin_venta_hora"
                  value={formData.fin_venta_hora}
                  onChange={handleChange}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  margin="normal"
                  fullWidth
                  error={errorDate}
                />  
              </div>
            </>
          )}

          <FormControl fullWidth margin="normal">
            <InputLabel>Fechas del evento</InputLabel>
            <Select
              multiple
              name="fechas"
              onChange={handleChange}
              value={formData.fechas || []}
              input={<OutlinedInput label="Fechas del evento" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const selectedOption = fechasEvento.find((opt) => opt.id === value);
                    return <Chip key={value} label={moment(selectedOption?.fecha).clone().local().format("D [de] MMMM")} />;
                  })}
                </Box>
              )}
            >
              {fechasEvento?.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                >
                  <Checkbox checked={formData.fechas?.includes(item.id)} />
                  {moment(item.fecha).clone().local().format("D [de] MMMM Y")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{display: !isMobile ? 'flex' : 'block', gap: !isMobile && '10px'}}>
            <TextField 
              label="Límite de compra por pedido"
              variant="outlined" 
              fullWidth
              name="limite_persona" 
              onChange={handleChange}
              value={formData.limite_persona}
              margin="normal"
              error={formErrors.limite_persona}
              helperText={formErrors.limite_persona && 'Este campo es obligatorio'}
            />
            <TextField 
              label="Limite de tarifas"
              variant="outlined" 
              fullWidth
              name="limite_maximo" 
              onChange={handleChange}
              value={formData.limite_maximo}
              margin="normal"
              error={formErrors.limite_maximo}
              helperText={formErrors.limite_maximo && 'Este campo es obligatorio'}
            />
          </div>
          {formData?.idtipo === 1 && (
            <div>
              <FormControlLabel
              control={ <Checkbox checked={formData.informacion_adicional} onChange={handleCheckboxChange2}/>}
              label="Solicitar información adicional"
            />
              {formData.informacion_adicional && (
                <div mt={2}>
                  {preguntas.map((pregunta, index) => (
                    <Box key={index} display="flex" alignItems="center" gap={1} mb={1}>
                      <TextField
                        label="Pregunta"
                        value={pregunta.informacion_adicional}
                        onChange={(e) => handlePreguntaChange(index, e.target.value)}
                        fullWidth
                        size="small"
                      />
                      {preguntas.length > 1 && (
                        <IconButton 
                          aria-label="delete" 
                          color="error"
                          onClick={() => eliminarPregunta(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        
                      )}
                    </Box>
                  ))}
                  <div style={{display: 'flex', justifyContent: 'end', marginBottom: '15px'}}>
                    <Button 
                      variant="text" 
                      style={{padding: '0px'}} 
                      onClick={agregarPregunta}
                      disabled={preguntas[preguntas.length - 1]?.informacion_adicional?.trim() === ""}
                    >
                      Agregar +
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          
          
          <div className="d-flex justify-content-between">
            <Button
              type="submit"
              style={{margin: "5px"}}
              variant="contained"
              disabled={(!formData.privado && !cuenta) || create.loading}
            >
              {t("button.create")}
            </Button>
            <VolverButton/>
          </div>  
        </Box>
      </div>
    </Container>
  );
};

export default CrearTarifa;