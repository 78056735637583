import EventoInfo from "./lado-izq/EventoInfo";
import EventoTarifas from "./lado-dcho/EventoTarifas";

export const ContentDesktop = ({data, cantidad, toggleDrawer, setCantidad, tarifasTicketsFiltrados, tarifasVouchersFiltrados, setTarifasSeleccionadas}) =>{

  return (
    <div className="d-flex justify-content-between">
      <EventoInfo data={data}/>
      <EventoTarifas data={data} cantidad={cantidad} toggleDrawer={toggleDrawer} setCantidad={setCantidad} tarifasTicketsFiltrados={tarifasTicketsFiltrados} tarifasVouchersFiltrados={tarifasVouchersFiltrados} setTarifasSeleccionadas={setTarifasSeleccionadas}/>
    </div>
  );
}
