import { createContext, useEffect, useState } from "react";
import { api } from "../services/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { driver } from "driver.js";
import "driver.js/dist/driver.css"
import "../assets/driverPopOver.css"

export const ImpresionContext = createContext();

export const ImpresionProvider = ({ children }) => {

  const param = useParams()
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  })
  const [font, setOpenFont] = useState(false);
  const [fontToolBar, setOpenFontToolBar] = useState(false);
  const [image, setOpenImage] = useState(false);
  const [qrCanva, setOpenQr] = useState(false);
  const [templates, setOpenTemplates] = useState(false);
  const [index, setIndex] = useState(0);
  const [saved, setSaved] = useState(false)
  const [edited, setEdited] = useState(false)
  const [error, setError] = useState(false)
  const [tipografia, setTipografia] = useState({
    roboto: 'Roboto',
    merriweather: 'Merriweather',
    pacifico: 'Pacifico',
    playfairDisplay: 'Playfair Display',
    oswald: 'Oswald',
    lobster: 'Lobster',
    anton: 'Anton',
    indieFlower: 'Indie Flower',
    abrilFatface: 'Abril Fatface',
    fjallaOne: 'Fjalla One',
  });
  const [tarifa, setTarifa] = useState('')
  const [selectedId, setSelectedId] = useState(null);
  const [tipografiaSelected, setTipografiaSelected] = useState('Roboto');
  const [sizeSelected, setSizeSelected] = useState(14);
  const [textCount, setTextCount] = useState(0);
  const [scale, setScale] = useState(6);
  const [isCreated, setIsCreated] = useState(false);
  const [typeTicket, setTypeTicket] = useState(true)
  const [textField, setTextField] = useState([]);
  const [qrField, setQrField] = useState([]);
  const [imgField, setImageField] = useState([])
  const [elements, setElements] = useState([]);
  const [isEditSucces, setEditSucces] = useState(false);
  const [dimensionContainer, setDimensionContainer] = useState({
    type: 'tarjeta',
    width: 100,
    height: 50,
  });
  const [textArea, setTextArea] = useState(false);
  const [history, setHistory] = useState([elements])
  const [indexHistory, setIndexHistory] = useState(0)
  const [ableUndo, setAbleUndo] = useState(false)
  const [ableRedo, setAbleRedo] = useState(false)
  const [isMoving, setIsMoving] = useState(false)



  const uploadHistory = (newElements) => {
    setAbleUndo(true)
    const newHistory = history.slice()
    if (indexHistory > 0) {
      newHistory.splice(history.length - indexHistory)
      setIndexHistory(0)
    }
    if (indexHistory === 0) {
      setAbleRedo(false)
    }
    if (indexHistory === history.length) {
      setAbleUndo(false)
    }
    newHistory.push(newElements)
    setHistory(newHistory)
  }

  // useEffect(() => {
  //   const callApi = async () => {
  //     try {
  //       const response = await api.eventos.getById(param.id)
  //       if (response.status === "success") {
  //         if (response.data.impresion.json) {
  //           const data = JSON.parse(response.data.impresion.json)
  //           if (data) {
  //             setScale(6)
  //             const newData = data.elements?.map((element) => {
  //               return {
  //                 ...element,
  //                 x: element.x,
  //                 y: element.y,
  //                 width: element.width,
  //                 height: element.height,
  //                 fontSize: element.fontSize
  //               }
  //             })
  //             setElements(newData)
  //             if (data.type === 'voucher') {
  //               setDimensionContainer({ type: 'voucher', width: 100, height: 50 })
  //               setScale(6)
  //             } else if (data.type === 'tarjeta') {
  //               setDimensionContainer({ type: 'tarjeta', width: 100, height: 50 })
  //               setScale(6)
  //             } else if (data.type === 'pulsera') {
  //               setDimensionContainer({ type: 'pulsera', width: 220, height: 25 })
  //               setScale(4) //revisar incluir este valor en el estado
  //             } else {
  //               throw new Error('Error al cargar la plantilla')
  //             }
  //             setIndex(1 + parseInt(data.elements?.reduce((max, element) => element.id > max ? element.id : max, 0)))//encuentra el id mas grande y le suma 1
  //             setIsCreated(true)
  //           }
  //         } else {
  //           setTypeTicket(false)
  //         }
  //       }
  //     } catch (error) {
  //       setState({
  //         ...state,
  //         error: error
  //       });
  //     }
  //   }
  //   callApi()
  // }, [])


  //Driver.js: herramienta para realizar una guia para ayudar al usuario a entender la herramienta
  const driverObj = driver({
    popoverClass: 'driverjs-theme',
    allowClose: true,
    steps: [
      {
        element: '#canva',
        popover: {
          title: '¡Bienvenido a la herramienta de diseño de entrada!',
          description: 'Esta es una pequeña guía de nuestra herramienta para diseñar entradas'
        }
      },
      {
        element: '#menuTools',
        popover: {
          title: 'Menu de herramientas',
          description: 'Estas son las herramientas principales que te ayudaran en tu diseño',
          onNextClick: () => {
            setOpenFont(true)
            driverObj.moveNext();
          },
          onPrevClick: () => {
            setOpenFont(false)
            driverObj.movePrevious()
          }
        },
      },
      {
        element: '#fontBar',
        popover: {
          title: 'Barra de Texto',
          description: 'Acá podes agregar textos y personalizarlos',
          onNextClick: () => {
            setOpenFont(false)
            setOpenQr(true)
            driverObj.moveNext();
          },
          onPrevClick: () => {
            setOpenFont(false)
            driverObj.movePrevious()
          }
        }
      },
      {
        element: '#dinamicBar',
        popover: {
          title: 'Barra de Variables dinamicas',
          description: 'Estas variables son campos de textos que se reemplazaran por valores de la base de datos, cuando quieras imprimir las entradas',
          onNextClick: () => {
            setOpenQr(false)
            setOpenImage(true)
            driverObj.moveNext();
          },
          onPrevClick: () => {
            setOpenFont(true)
            setOpenQr(false)
            driverObj.movePrevious()
          }
        }
      },
      {
        element: '#imageBar',
        popover: {
          title: 'Barra de Imagenes',
          description: 'Podes subir imagenes para despues añadirlas a sus entradas',
          onNextClick: () => {
            setOpenImage(false)
            setOpenTemplates(true)
            driverObj.moveNext();
          },
          onPrevClick: () => {
            setOpenQr(true)
            setOpenImage(false)
            driverObj.movePrevious()
          }
        }
      },
      {
        element: '#templateBar',
        popover: {
          title: 'Barra de Plantillas',
          description: 'Podes usar nuestras plantillas prediseñadas',
          onNextClick: () => {
            setOpenTemplates(false)
            driverObj.moveNext();
          },
          onPrevClick: () => {
            setOpenTemplates(false)
            setOpenImage(true)
            driverObj.movePrevious()
          }
        }
      },
      {
        element: '#canva',
        popover: {
          title: 'Fin del la guia',
          onPrevClick: () => {
            setOpenTemplates(true)
            driverObj.movePrevious()
          }
        },
      }
    ]
  })

  return (
    <ImpresionContext.Provider value={{
      //states and handlers
      font,
      image,
      qrCanva,
      templates,
      setOpenFont,
      setOpenImage,
      setOpenQr,
      setOpenTemplates,

      setState,
      state,

      dimensionContainer,
      textField,
      setTextField,
      textArea,
      setTextArea,
      textCount,
      setTextCount,
      qrField,
      setQrField,
      index,
      setIndex,
      imgField,
      setImageField,
      tipografia,
      setTipografia,
      tipografiaSelected,
      setTipografiaSelected,
      sizeSelected,
      setSizeSelected,
      fontToolBar,
      setOpenFontToolBar,
      selectedId,
      setSelectedId,
      elements,
      setElements,
      isEditSucces,
      setEditSucces,
      isCreated,
      setIsCreated,
      scale,
      setScale,
      setDimensionContainer,
      error,
      setError,
      saved,
      setSaved,
      edited,
      setEdited,
      history,
      setHistory,
      indexHistory,
      setIndexHistory,
      uploadHistory,
      ableUndo,
      setAbleUndo,
      ableRedo,
      setAbleRedo,
      typeTicket,
      setTypeTicket,
      driverObj,
      isMoving,
      setIsMoving,
      tarifa,
      setTarifa,
    }}>
      {children}
    </ImpresionContext.Provider>
  )
}