import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { toast } from "react-toastify";

const useMutation = ({
  mutationFn,
  onSuccess,
  onError
}) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { loginWithRedirect } = useAuth0();

  const mutate = async (...args) => {
    setLoading(true);

    try {
      const data = await mutationFn(...args);

      if (data.message === "invalid or expired jwt") {
        toast.error({
          title: "La sesión ha expirado",
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top-center',
        });

        await loginWithRedirect({
          appState: { returnTo: window.location.pathname },
        });
        return;
      }

      if (data.status ===  "error") {
        throw new Error(data.message || "Error de servidor inesperado.");
      }

      setData(data);
      onSuccess && onSuccess(data);

    } catch (error) {
      const msg = error || "El servicio no está disponible en este momento";

      setError(msg);
      onError && onError(msg);
    } finally {
      setLoading(false);
    }
  }

  return {
    mutate,
    data,
    loading,
    error,
  }
}
export default useMutation;