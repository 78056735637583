import React from "react";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardGroup,
  CardSubtitle,
} from "reactstrap";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next"

export const Precios = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <Container fluid className="p-5 text-center text-white" style={{ backgroundColor: "#0C5556", height: "350px" }}>
        <h1 className="mt-3 display-3 fw-bolder">Lorem Ipsum</h1>
        <p className="lead">
          "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
          consectetur, adipisci velit..."
        </p>
        <Button className="mt-3 text-white fw-bold fs-5 px-5" style={{ backgroundColor: "#0F7173", border: "1px solid #0F7173" }} size="">
          Lorem Ipsum
        </Button>
      </Container>

      <Container fluid className="p-0 my-5">
        <CardGroup className="d-flex justify-content-center align-items-center flex-wrap">
          <Card className="shadow-sm mb-3 ml-2 mr-2 rounded" style={{ border: "1px solid #13876F", minWidth: "200px", maxWidth: "380px", width: "280px" }}>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle tag="h2" className="mt-1" style={{ color: "#13876F" }}>FREE</CardTitle>
                <h2 className="fw-bold">$0</h2>
              </div>
              <hr className="mt-2 mx-3" style={{ border: "1px solid #13876F" }} />
              <CardSubtitle className="mb-2 text-muted" tag="h5">
                {t("pricing.benefits")}
              </CardSubtitle>
              <div>
                <ul className="list-unstyled">
                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>

                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Suspendisse quis eros sed est aliquam maximus. Mauris.
                  </li>
                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Phasellus leo augue, aliquet sit amet dolor in.
                  </li>
                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Vestibulum ut sagittis nisi. Quisque sollicitudin lectus dictum.
                  </li>
                </ul>
              </div>
              <Button className="fw-bold text-center" style={{ backgroundColor: "#13876F", border: "1px solid #13876F" }}>{t("pricing.start")}</Button>
            </CardBody>
          </Card>
          <Card className="shadow-sm mx-2 mb-3 rounded" style={{ border: "1px solid #13876F", minWidth: "250px", maxWidth: "380px", width: "280px" }}>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle tag="h2" className="mt-1" style={{ color: "#13876F" }}>LOREM</CardTitle>
                <h2 className="fw-bold">$25</h2>
              </div>
              <hr className="mt-2 mx-3" style={{ border: "1px solid #13876F" }} />
              <CardSubtitle className="mb-2 text-muted" tag="h5">
                {t("pricing.benefits")}
              </CardSubtitle>
              <div>
              <ul className="list-unstyled">
                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>

                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Suspendisse quis eros sed est aliquam maximus. Mauris.
                  </li>
                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Phasellus leo augue, aliquet sit amet dolor in.
                  </li>
                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Vestibulum ut sagittis nisi. Quisque sollicitudin lectus dictum.
                  </li>
                </ul>
              </div>
              <Button className="fw-bold " style={{ backgroundColor: "#13876F", border: "1px solid #13876F" }}>{t("pricing.buy")}</Button>
            </CardBody>
          </Card>
          <Card className="shadow-sm mx-2 mb-3 rounded" style={{ border: "1px solid #13876F", minWidth: "250px", maxWidth: "380px", width: "280px" }}>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle tag="h2" className="mt-1" style={{ color: "#13876F" }}>LOREM</CardTitle>
                <h2 className="fw-bold">$50</h2>
              </div>
              <hr className="mt-2 mx-3" style={{ border: "1px solid #13876F" }} />
              <CardSubtitle className="mb-2 text-muted" tag="h5">
                  {t("pricing.benefits")}
              </CardSubtitle>
              <div>
              <ul className="list-unstyled">
                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>

                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Suspendisse quis eros sed est aliquam maximus. Mauris.
                  </li>
                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Phasellus leo augue, aliquet sit amet dolor in.
                  </li>
                  <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#13876F', marginRight: '5px' }}> check </i>
                    Vestibulum ut sagittis nisi. Quisque sollicitudin lectus dictum.
                  </li>
                </ul>
              </div>
              <Button className="fw-bold" style={{ backgroundColor: "#13876F", border: "1px solid #13876F" }}>{t("pricing.buy")}</Button>
            </CardBody>
          </Card>
        </CardGroup>
      </Container>
      <Container className="bg-light">

      </Container>
    <Footer />
    </div>
  );
};

export default Precios;
