import React, { useState, useEffect } from 'react';
import { api } from '../../services/api';
import { useContext } from "react";
import { ImpresionContext } from "../../providers/Impresion";
import AddLabel from "./AddLabel";
import SideBar from "./SideBar";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const QrSideBar = ({onClose, evento, tarifa, idTarifa}) => {
  const {
    // elements, 
    // setElements, 
    // scale,
    //  dimensionContainer, 
    //  index,
    //   setIndex, 
    setOpenQr,
    // event 
  } = useContext(ImpresionContext);

  const param = useParams();
  const [preguntasAdicionales, setPreguntasAdicionales] = useState([]);	
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.infoAdicional.getPregunta(param.id, idTarifa); 
        setPreguntasAdicionales(data.data);
      } catch (error) {
        console.error("Error al obtener la pregunta:", error);
      }
    };
    fetchData();
  }, [param.id, idTarifa]);
  // console.log(preguntasAdicionales)

  return (
    <>
      <SideBar id='dinamicBar'>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p className="p-0 m-0 fs-4">Variables Dinamicas</p>
          </div>
          <div>
            <button className="d-flex align-items-center" onClick={() => onClose()}>
              <i className="material-symbols-outlined">close</i>
            </button>
          </div>
        </div>
        <div>
          <p className="fs-6">Estas variables se reemplazaran por los datos registrados cuando descargues el PDF.
            Los valores son representativos.</p>
        </div>
        <div className="d-flex flex-column gap-2">
          <AddLabel type={'text-nombre'}>Nombre y Apellido</AddLabel>
          <AddLabel type={'text-evento'} event={evento}>Nombre del evento</AddLabel>
          <AddLabel type={'text-codigo'}>Codigo</AddLabel>
          <AddLabel type={'qr'}>Codigo QR</AddLabel>
          <AddLabel type={'text-tarifa'} tarifa={tarifa}>Nombre de la tarifa</AddLabel>
          {preguntasAdicionales?.informaciones_adicionales?.map((pregunta, index) => (
            <AddLabel key={index} type={`text-${pregunta.id}`} pregunta={pregunta}>
              {pregunta.informacion_adicional}
            </AddLabel>
          ))}
        </div>
      </SideBar>
    </>
  )
};

export default QrSideBar;