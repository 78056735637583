import React, { useState, useEffect, useRef } from "react";
import Loading from "../../components/Loading";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../services/api";
import { 
  Container, 
  Button,
  TextField, 
  Alert, 
  CircularProgress, 
  TableContainer, 
  Paper,
  Table,
  TableHead, 
  TableRow, 
  TableCell,
  TableBody
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import DeleteOwnerModal from "../../components/modales/DeleteOwnerModal";
import { useTranslation } from "react-i18next";
import RouteTitleManager from "../../utils/RouteTitleManager";
const Owners = () => {
  const [evento, setEvento] = useState(null);
  const [eventoOwners, setEventoOwners] = useState([]);
  const history = useHistory();
  const param = useParams();
  const form = useRef();
  const [t] = useTranslation("global");

  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const response = await api.eventos.get(param.id);
        if (response.status === "success") {
          setEvento(response.data.evento);
          setEventoOwners(response.data.owners || []);
        } else {
          setError(response.message || "Error al cargar el evento");
        }
      } catch (error) {
        setError(error.message || "Error al cargar el evento");
      }
    };
    fetchEventos();
    document.title = `Owners | PassGo`;
  }, []);

  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (email === "") {
      setError("El correo electrónico es obligatorio");
      setSubmitting(false)
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Introduce un correo electrónico válido");
      setSubmitting(false)
      return;
    }

    const data = { email };
    const response = api.owners.createOwner(param.id, data);
    return response.then((response) => {
      if (response.status === "error") {
        setError("Error: " + response.message);
        setSubmitting(false);
      }
      if (response.status === "success") {
        setSubmitting(false);
        setSubmitted(true);
        setError("");
        const data = {
          recipient: email,
          evento: evento.evento,
        };
        const response = api.email.send(data);
        setEmail("");
        setTimeout(function () {
          history.go(0);
        }, 1500);
        if (response.status === "error") {
          setError("Error: " + response.message);
        }
      }
    });
  };

  return (

        <Container fluid="sm">
              <form onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "15px"
                }}
              >
                <TextField 
                  type="email"
                  name="email"
                  id="email"
                  label={t("customers.email")}
                  variant="outlined" 
                  size="small"
                  fullWidth 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button variant="contained" type="submit">
                  {t("button.register")}
                </Button>
              </form>
              {submitting && (
                <Alert icon={false} severity="info">
                  <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                    <CircularProgress size="30px"/>
                    Estamos verificando el correo ingresado. Por favor espera unos momentos.
                  </div>
                </Alert>
              )}
              {error && 
                <Alert severity="error">{error}</Alert>
              }
              {submitted && (
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                  {t("customers.successEmailAlert")}
                </Alert>
              )}
          <br />
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Accion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventoOwners?.length > 0 ? (
                  eventoOwners.map((owner) => (
                    <TableRow key={owner.id}>
                      <TableCell>{owner.owner}</TableCell>
                      <TableCell>{owner.estado.estado}</TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        {owner.idestado !== 3 &&
                          owner.idestado !== 4 && (
                            <DeleteOwnerModal
                              eventoId={param.id}
                              ownerId={owner.id}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="3">
                      <Alert severity="info" >
                        No hay owners registrados
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
  );
};

export default withAuthenticationRequired(Owners, {
  onRedirecting: () => <Loading />,
});
