import { useEffect } from "react";
import { api } from "../services/api";
import useQuery from "./useQuery";
import { showErrorToastify } from "../utils";

const useGetCuentas = (eventoId) => {
  const { data, refetch } = useQuery({
    autoFetch: false,
    queryFn: api.eventoCuentas.getAll,
    onError: (err) => showErrorToastify({ err })
  });

  useEffect(() => {
    if (!eventoId) return;

    refetch(eventoId);
  }, [eventoId]);

  return {
    cuenta: data?.data?.vinculacion,
    fetchCuentas: () => refetch,
  }
}

export default useGetCuentas;