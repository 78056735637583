import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useQuery = ({
  queryFn,
  onSuccess,
  onError,
  args = [],
  autoFetch = true
}) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(autoFetch ? true: false);
  const { loginWithRedirect } = useAuth0();

  const refetch = async (...args) => {
    setLoading(true);

    try {
      const data = await queryFn(...args);
      
      if (data.message === "invalid or expired jwt") {
        toast.error({
          title: "La sesión ha expirado",
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top-center',
        });

        await loginWithRedirect({
          appState: { returnTo: window.location.pathname },
        });
        return;
      }

      if (data.status !== "error") {
        setData(data);
        onSuccess && onSuccess(data);

        return data;
      }

      throw new Error(data.message || "Error de servidor inesperado.");
      
    } catch (error) {
      const msg = error || "El servicio no está disponible en este momento.";

      setError(msg);
      onError && onError(msg);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (!autoFetch) return;
    
    refetch(...args);
  }, [...args, autoFetch]);

  return {
    data,
    error,
    loading,
    refetch,
  }
}
export default useQuery;