import React, { useEffect, useState } from 'react';
import { useHistory} from 'react-router-dom';
import LockPersonIcon from '@mui/icons-material/LockPerson';

const PrivateEvent = () => {
  const history = useHistory();

  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(interval);
      history.push('/');
    }

    return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
  }, [countdown, history]);

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <LockPersonIcon color='error' style={{fontSize:'100px'}}/>
      <h2 style={{fontWeight:"500"}}>Evento Privado</h2>
      <p style={{fontStyle:"italic"}}>Lo sentimos, este evento es privado. Solo personas autorizadas pueden ver su información.<br/>
      Serás redirigido al inicio en <b>{countdown}</b> </p>
    </div>
  );
};

export default PrivateEvent;