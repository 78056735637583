import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useMediaQuery } from "react-responsive";
import { useAuth0 } from "@auth0/auth0-react";

import "./creaEventos.css"

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import checkbox1 from "../../../assets/checkbox1.png";
import checkbox2 from "../../../assets/checkbox2.png";
import checkbox3 from "../../../assets/checkbox3.png";
import checkbox4 from "../../../assets/checkbox4.png";
import checkbox5 from "../../../assets/checkbox5.png";
import checkbox6 from "../../../assets/checkbox6.png";

const SectionCheckbox = () => {
  const [selectedCheckbox, setSelectedCheckbox] = useState("checkbox1");
  const checks = ["checkbox1", "checkbox2", "checkbox3", "checkbox4", "checkbox5", "checkbox6"];
  const [changeCheck, setChangeCheck] = useState(true);
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 1100px)" });
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const imagesCheckbox = {
    checkbox1: `${checkbox1}`,
    checkbox2: `${checkbox2}`,
    checkbox3: `${checkbox3}`,
    checkbox4: `${checkbox4}`,
    checkbox5: `${checkbox5}`,
    checkbox6: `${checkbox6}`,
  }

  const handleButtonClick = () => {
    if (isAuthenticated) {
      history.push("/eventos");
    } else {
      loginWithRedirect({
        appState: { returnTo: "/eventos" }
      });
    }
  };

  useEffect(() => {
    let interval;

    if (changeCheck) {
      interval = setInterval(() => {
        setSelectedCheckbox(prevCheck => {
          const currentIndex = checks.indexOf(prevCheck);
          const nextIndex = (currentIndex + 1) % checks.length;
          return checks[nextIndex];
        });
      }, 3000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [changeCheck, checks]);

  const handleCheckboxChange = (check) => {
    setSelectedCheckbox(check);
    setChangeCheck(false);
    setTimeout(() => {
      setChangeCheck(true);
    }, 3000);
  };

  return (
    <>
      {isMobile ? (
        <>
          <div className="d-flex flex-column align-items-center" style={{ gap: "20px", width: "100%" }}>
            <div className="" style={{ width: "80%", height: "50%" }}>
              <img src={`${imagesCheckbox[selectedCheckbox]}`} alt="checkbox" style={{ width: "100%", }} />
            </div>
            <div className="">
              {checks.map((check, index) => (
                <div key={index}>
                  <label style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      checked={selectedCheckbox === check}
                      className="custom-checkbox"
                      onChange={() => handleCheckboxChange(check)}
                    />
                    <span className={`option-text ${selectedCheckbox === check ? 'selected' : ''}`}
                      style={{ fontSize: "16px", lineHeight: "22px", letterSpacing: "0.15px" }}>
                      {check === 'checkbox1' && 'Crea eventos'}
                      {check === 'checkbox2' && 'Vende entradas'}
                      {check === 'checkbox3' && 'Comparte eventos fácilmente'}
                      {check === 'checkbox4' && 'Diseña tus entradas y pulseras'}
                      {check === 'checkbox5' && 'Controla los accesos y los vouchers'}
                      {check === 'checkbox6' && 'Reportes en tiempo real'}
                    </span>
                  </label>
                </div>
              ))}
              <div>
                <label style={{ marginLeft: "10px", /* marginTop: "10px", */ fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "#0B0062" }}>y mucho más...</label>
              </div>
            </div>
            <button type="button" className="d-flex align-items-center" style={{ marginLeft: "10px", marginBottom: "30px", borderRadius: "7px", backgroundColor: "#5656FF", padding: "8px 10px", color: "white" }} onClick={handleButtonClick}>
              <EventOutlinedIcon sx={{ marginRight: "5px" }} /> Crear Evento
            </button>
          </div>
        </>
      ) : (
        <>

          <div className="d-flex flex-row justify-content-between" style={{ height: "450px", width: "100%" }}>
            <div className="left-pasos" style={{ width: "60%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "12%" }}>
              <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", flexDirection: "column", height: "360px" }}>
                <img src={`${imagesCheckbox[selectedCheckbox]}`} alt="checkbox" style={{ width: "100%", height: "100%", objectFit: "contain", filter: "drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.2))" }} />
              </div>
            </div>
            <div className="right-pasos" style={{ width: "40%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end", flexDirection: "column", marginRight: "12%" }}>
              <div className="" style={{ marginLeft: "20%" }}>
                {checks.map((check, index) => (
                  <div key={index}>
                    <label style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        checked={selectedCheckbox === check}
                        className="custom-checkbox"
                        onChange={() => handleCheckboxChange(check)}
                      />
                      <span className={`option-text ${selectedCheckbox === check ? 'selected' : ''}`}
                        style={{ fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px" }}>
                        {check === 'checkbox1' && 'Crea eventos'}
                        {check === 'checkbox2' && 'Vende entradas'}
                        {check === 'checkbox3' && 'Comparte eventos fácilmente'}
                        {check === 'checkbox4' && 'Diseña tus entradas y pulseras'}
                        {check === 'checkbox5' && 'Controla los accesos y los vouchers'}
                        {check === 'checkbox6' && 'Reportes en tiempo real'}
                      </span>
                    </label>
                  </div>
                ))}
                <div>
                  <label style={{ marginLeft: "20px", marginTop: "10px", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "#0B0062" }}>y mucho más...</label>
                </div>
                <button type="button" className="d-flex align-items-center" style={{ marginLeft: "20px", marginTop: "10px", borderRadius: "7px", backgroundColor: "#5656FF", padding: "8px 10px", color: "white" }} onClick={handleButtonClick}>
                  <EventOutlinedIcon sx={{ marginRight: "5px" }} /> Crear Evento
                </button>
              </div>
            </div>
          </div>
        </>
      )}

    </>
  )
}

export default SectionCheckbox;