import { useContext, useEffect, useRef } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";
import { ImpresionContext } from "../../providers/Impresion";

const ImageField = ({ onChange, imgProp, onSelect, isSelected, margenes }) => {

  const [image] = useImage(imgProp.imgURL);
  const {
    dimensionContainer,
    setIsMoving,
  } = useContext(ImpresionContext);

  const imgRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([imgRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected])


  return (
    <>
      <Image
        onClick={onSelect}
        onTap={onSelect}
        ref={imgRef}
        draggable
        image={image}
        {...imgProp}

        onDragMove={(e) => {
          setIsMoving(true)

          const newX = Math.max((margenes.left), Math.min(e.target.x(), dimensionContainer.width - imgProp.width - margenes.right))
          const newY = Math.max((margenes.top), Math.min(e.target.y(), dimensionContainer.height - imgProp.height - margenes.bottom))
          imgRef.current.x(newX);
          imgRef.current.y(newY);

          const node = imgRef.current;

          node.getLayer().batchDraw();
        }}

        onDragEnd={(e) => {
          setIsMoving(false)
          const node = imgRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChange({
            ...imgProp,
            x: e.target.x(),
            y: e.target.y(),

            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY)
          })

        }}

        onTransformStart={() => {
          setIsMoving(true)
        }}


        onTransformEnd={() => {
          setIsMoving(false)

          const node = imgRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const x = node.x()
          const y = node.y()

          node.scaleX(1)
          node.scaleY(1)

          onChange({
            ...imgProp,
            x: x,
            y: y,

            width: Math.max(50, node.width() * scaleX),
            height: Math.max(node.height() * scaleY)
          })
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            const isOut =
              newBox.x < 0 ||
              newBox.y < 0 ||
              newBox.x + newBox.width > dimensionContainer.width ||
              newBox.y + newBox.height > dimensionContainer.height;

            if (isOut) {
              return oldBox;
            }
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}

    </>
  )
}

export default ImageField;