import React, { useState, useEffect } from "react";
import { Container, Spinner, Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import LikeButton from "./LikeButton";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import moment from "moment";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";
import { api } from "../../services/api";


const Favoritos = () => {
  const [publicaciones, setPublicaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usuario, setUsuario] = useState()
  const history = useHistory();
  const { i18n, t } = useTranslation("global");

  const onClickDislike = (e) => {
    let idpublicacion = e.currentTarget.id
    const newPublicaciones = publicaciones.slice()
    const index = newPublicaciones?.findIndex((publicacion) => publicacion.id === parseInt(idpublicacion))
    newPublicaciones?.splice(index, 1)
    setPublicaciones(newPublicaciones)
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        // publicaciones activas
        const publicacionesData = await api.publicaciones.getAllActive();
        const publicaciones = publicacionesData.data.publicaciones;

        // likes del usuario
        const getUsuario = await api.usuarios.getUsuario();
        setUsuario(getUsuario.data)
        const userLikes = getUsuario.data.usuario.favoritos;

        // las que el usuario le dio like
        const publicacionesLiked = publicaciones.filter((publicacion) =>
          userLikes.some((like) => like.idpublicacion === publicacion.id && like.like === true)
        );

        setPublicaciones(publicacionesLiked);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (publicaciones.length === 0) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <p>No tienes publicaciones en favoritos.</p>
      </Container>
    );
  }

  return (
    <Container className="d-flex flex-column align-items-center">
      <h1 className="my-4">{t("favorites.favorites")}</h1>
      <Container className="d-flex flex-wrap justify-content-center gap-3">
        {publicaciones.map((publicacion) => (
          <Card key={publicacion.id} className="border-1" style={{ width: "350px", height: "470px", margin: "10px", boxShadow:"none", border:"1px solid #ccc"}}>
            {publicacion.attachs && publicacion.attachs.length > 0 && (
              <img src={publicacion.attachs[0].url} alt={publicacion.titulo} className="w-100 rounded-top" style={{ height: "250px", objectFit: "cover" }} />
            )}
            <CardBody className="d-flex flex-column justify-content-between">
              <CardTitle tag="h4" className="text-truncate" style={{ fontSize: "20px", fontWeight: "bold" }}>
                {publicacion.titulo || "Título no disponible"}
              </CardTitle>
              <CardText className="text-muted" style={{ fontSize: "17px" }}>
                {publicacion.descripcion.length > 50
                  ? `${publicacion.descripcion.substring(0, 50)}...`
                  : publicacion.descripcion || "Descripción no disponible"}
              </CardText>

              <div className="d-flex align-items-center">
                <CalendarMonthOutlinedIcon sx={{ marginRight: "5px" }} />
                <CardText className="text-secondary" style={{ fontSize: "15px", marginRight: "10px", marginTop: "5px" }}>
                  {moment(publicacion.evento?.fechas[0].fecha).format("D [de] MMMM")}
                </CardText>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <LikeButton usuario={usuario} publicacionId={publicacion.id} isLoading={loading} onClickDislike={onClickDislike} />
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => history.push(`/evento/${publicacion.idevento}/informacion`)}
                  style={{ fontSize: "15px" }}
                >
                  {t("favorites.seeMore")}
                </Button>
              </div>
            </CardBody>
          </Card>
        ))}
      </Container>
    </Container>
  );
};

export default Favoritos;
