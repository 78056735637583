
import React, { useState, useEffect } from "react";
import {
  KeyboardArrowLeft, KeyboardArrowRight, PlaceOutlined, CalendarMonthOutlined,
  AccessAlarmOutlined, ExpandMore, ExpandLess, NotificationsActiveOutlined,
} from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, IconButton, } from "@mui/material";
import ShareButton from "../../../../../../components/shareButton/shareButton";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import moment from "moment";

//escapado de caracteres para el wysiwyg
const sanitizeHtml = (html) => {
  // crea un div temporal
  const div = document.createElement("div");
  // guarda el html en el div
  div.innerHTML = html;

  // filtra las etiquetas permitidas
  const allowedTags = ["ol", "ul", "li", "p", "strong", "em", "br", "h1", "h2", "u", "a"]; // etiquetas permitidas
  Array.from(div.querySelectorAll("*")).forEach((node) => {
    if (!allowedTags.includes(node.nodeName.toLowerCase())) {
      node.parentNode.removeChild(node); // elimina los que no permite
    }
  });

  return div.innerHTML; // devuelve el html seguro
};

const ContentMobile = ({ data }) => {
  const [showMap, setShowMap] = useState(false);
  const [mapUrl, setMapUrl] = useState("");

  const direccion = data?.direccion + " " + data?.ciudad;
  const apiKey = "AIzaSyDancleRLY1vcyNtjo6uGo6dJDGPpkT3Ug";

  /* console.log("data", data); */
  useEffect(() => {
    if (direccion) {
      const direccionEncoded = encodeURIComponent(direccion);
      const newMapUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${direccionEncoded}`;

      setMapUrl(newMapUrl);
    }
  }, [direccion, apiKey]);

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  const imagesDescrip = data?.attachs?.filter(
    (attach) => !attach.avatar && !attach.banner
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesDescrip.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + imagesDescrip.length) % imagesDescrip.length
    );
  };

  const showControls = imagesDescrip?.length > 1;

  return (
    <div className="p-4 d-flex flex-column gap-4">
      {/*WIDGETS*/}
      <div className="d-flex justify-content-between">
        <p
          className="p-2 d-flex gap-2 rounded-lg my-auto"
          style={{ color: "#3d2eab", backgroundColor: "#dadaff" }}
        >
          <NotificationsActiveOutlined />
          Reciente apertura
        </p>
        <div className="d-flex align-items-center">
          {/* <LikeButton /> */}
          <ShareButton style={{ color: "#221a60" }} />
        </div>
      </div>

      {/*TITULO*/}
      <h2 className="fs-1 fw-bold m-0" style={{ color: "#221a60" }}>
        {data.evento}
      </h2>

      {/*UBICACION Y FECHA*/}
      <div className="d-flex flex-wrap gap-4">
        {data.fechas.length > 1 ? (
          <div className="mr-5">
            <h4 className="fs-3" style={{ color: "#221a60" }}>
              Fechas
            </h4>
            {data.fechas.map((fecha) => (
              <>
                <p className="mb-0 d-flex gap-2">
                  <CalendarMonthOutlined />{" "}
                  {format(new Date(fecha.fecha), "d 'de' MMMM", {
                    locale: es,
                  })}
                </p>
                <p className="mb-2 d-flex gap-2">
                  <AccessAlarmOutlined />{" "}
                  {moment.parseZone(fecha.hora_inicio).format("HH:mm")} hs.
                </p>
              </>
            ))}
          </div>
        ) : (
          <div className="mr-5">
            <h4 className="fs-3" style={{ color: "#221a60" }}>
              Fecha
            </h4>
            <p className="mb-2 d-flex gap-2">
              <CalendarMonthOutlined />{" "}
              {format(new Date(data.fechas[0].fecha), "d 'de' MMMM", {
                locale: es,
              })}
            </p>
            <p className="m-0 d-flex gap-2">
              <AccessAlarmOutlined />{" "}
              {moment.parseZone(data.fechas[0].hora_inicio).format("HH:mm")}{" "}
              hs.
            </p>
          </div>
        )}

        <div className="mr-5">
          <h4 className="fs-3" style={{ color: "#221a60" }}>
            Ubicación
          </h4>

          <div className="d-flex gap-2">
            <PlaceOutlined />
            <div>
              <p className="m-0 fw-bold">{data.direccion}</p>
              <p className="my-2 text-secondary">{data.ciudad}</p>
              <span
                onClick={() => setShowMap(!showMap)}
                style={{ color: "#5656ff", cursor: "pointer" }}
              >
                Mostrar mapa{" "}
                {!showMap ? (
                  <ExpandMore fontSize="small" />
                ) : (
                  <ExpandLess fontSize="small" />
                )}
              </span>
            </div>
          </div>
        </div>

        {/* Mapa */}
        {showMap && (
          <div className="w-100">
            <iframe
              title="location"
              width="100%"
              height="250"
              id="map"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={mapUrl}
              style={{
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
          </div>
        )}
      </div>
      
      {/*DESCRIPCION*/}
      <div>
        <h4 className="fs-3" style={{ color: "#221a60" }}>
          Acerca del evento
        </h4>
        <span
          className="descripcion-unstyled"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(data?.descripcion),
          }}
        />
        <div
          style={{
            position: "relative",
            maxWidth: "500px",
            margin: "auto",
            overflow: "hidden",
            marginTop: "10px",
          }}
        >
          {showControls && (
            <IconButton
              style={{
                position: "absolute",
                border: "none",
                cursor: "pointer",
                padding: "10px",
                zIndex: 1,
                top: "50%",
                left: "10px",
                transform: "translateY(-50%)",
                color: "#5656FF",
              }}
              onClick={handlePrev}
            >
              <KeyboardArrowLeft />
            </IconButton>
          )}
          <div
            style={{
              display: "flex",
              transition: "transform 0.5s ease",
              width: "100%",
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {imagesDescrip?.map((attach, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "100%",
                  boxSizing: "border-box",
                }}
                key={index}
              >
                {attach.type === "image" ? (
                  <img
                    src={attach.url}
                    alt={`Imagen ${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "600px",
                    }}
                  />
                ) : attach.type === "video" ? (
                  <video
                    controls
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "500px",
                      objectFit: "contain",
                    }}
                  >
                    <source src={attach.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : null}
              </div>
            ))}
          </div>
          {showControls && (
            <IconButton
              style={{
                position: "absolute",
                border: "none",
                cursor: "pointer",
                padding: "10px",
                zIndex: 1,
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                color: "#5656FF",
              }}
              onClick={handleNext}
            >
              <KeyboardArrowRight />
            </IconButton>
          )}
          {showControls && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                bottom: "10px",
                width: "100%",
              }}
            >
              {imagesDescrip.map((_, index) => (
                <div
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  style={{
                    width: "10px",
                    height: "10px",
                    margin: "0 5px",
                    borderRadius: "50%",
                    backgroundColor:
                      currentIndex === index ? "#5656FF" : "#ddd",
                    cursor: "pointer",
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {/*PREGUNTAS FRECUENTES*/}
      {data.FAQ && data.FAQ.length > 0 && (
        <div>
          <h5 className="fs-3" style={{ color: "#221a60" }}>
            Preguntas frecuentes
          </h5>
          {data.FAQ?.map((faq) => (
            <Accordion key={faq.id} style={{ backgroundColor: "#f9f9f9" }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${faq.id}-content`}
                id={`panel${faq.id}-header`}
              >
                <p className="m-0 fw-medium">{faq.pregunta}</p>
              </AccordionSummary>

              <AccordionDetails as="p" className="m-0">
                {faq.respuesta}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </div>
  );
};

export default ContentMobile;