import React, { useState } from 'react';
import { api } from '../../services/api'; 
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { grey } from '@mui/material/colors';
import { Button, Box, Modal, TextField, Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import moment from "moment-timezone";

moment.locale("es");
moment.locale("en-GB");

const AddLocationModal = ({ eventoId, locations }) => {
  const [location, setLocation] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage , setErrorMessage] = useState("");
  const [t, i18n] = useTranslation("global");
  const [enviado, setEnviado] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 490px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 700px)` });

  moment.locale(i18n.language === "es" ? "es" : "en-gb");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (location.trim() === '') {
      setError(true);
      setErrorMessage(<>{t("errors.emptyField")}</>);
    }else if (Array.isArray(locations) && locations !== undefined && locations.some(value => value.ubicacion === location)){
      setError(true);
      setErrorMessage(<>{t("errors.locationExist")}</>);
    } else if (location.length > 20) {
      setError(true);
      setErrorMessage(<>{t("errors.characters")}</>);
    }else {
      const newLocation = {
      idevento: parseInt(eventoId),
      ubicacion: location,
      };
      try {
        const response = await api.locations.createLocation(newLocation);
        if (response.status === "success") {
          setLocation("")
          setError(false)
          setErrorMessage("")
          setEnviado(true)
          setTimeout(() => {
            handleClose();
          }, 2000);
        }
      } catch (error) {
          setLocation("")
          setError(false)
          setErrorMessage("")
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false); 
    setLocation("")
    setError(false)
    setErrorMessage("")
    setEnviado(false)
  } 

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width:isMobile ? 320 : isTablet ? 400 : 600,
    p: 3,
  };  

  return (
    <>
      <Button sx={{
    backgroundColor: "#FFC300",
    color: "black",
    outline: "none",
    borderRadius: "5px",
    textTransform: "none",
    fontFamily: 'Inter, sans-serif',
    '&:hover': {
      backgroundColor: "#FFB000",
    }
  }}
  startIcon={<AddOutlinedIcon sx={{ marginLeft: "5px" }} />}  
  onClick={handleOpen}
  >
      {t("button.add")}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} onSubmit={handleSubmit}>
          <h4 id="modal-modal-title">
            {t("location.addLocation")}
          </h4>
          <div id="modal-modal-description" sx={{ mt: 2 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <TextField 
                label={t("location.newLocation")}
                id="location"
                name="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                variant="outlined"
                fullWidth
                error={error} 
                helperText= {error && errorMessage}
              />
              {enviado && (
                <Alert icon={<CheckIcon fontSize="inherit"/>} style={{marginTop: "10px"}} severity="success">
                  Ubicacion creada.
                </Alert>
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "15px" }}>
                <Button size="small" variant="contained" sx={{background: grey[500]}} style={{marginRight: "10px"}} onClick={handleClose}>
                  {t("button.cancel")}
                </Button>
                <Button variant="contained" type="submit" size="small">
                  {t("button.add")}
                </Button>
              </div>
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddLocationModal;